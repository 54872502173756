import styled from 'styled-components';


export const Footer = styled.footer`  
  background-color: rgb(3, 4, 94);
  color: white;
  bottom: 0;
  line-height: 30px;
  padding: 20px;
  text-align: center;
  width: 100%;
`

export const ContainerEmail = styled.span`
  svg {
    margin: 0px 3px 0px 5px;
  }
`

export const EmailLink = styled.a`
  color: white;
  font-size: 16px;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
`

