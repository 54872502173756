import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, Typography, Stack } from '@mui/material';
import { ErrorMessage } from '../../styles/globalStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UPDATE_USER_LANGUAGE } from "../../services/User.services";
import { useMutation } from "@apollo/client";
import { languages } from "../../staticData/languages";
import { SpinnerRoller } from "../../components/Loading/SpinnerRoller/SpinnerRoller";

export const Language = ({ handleOpenModal, selectedData, index, context }) => {
  const { data, refetch } = context;
  const { register, handleSubmit, control, formState: { errors } } = useForm({ defaultValues: selectedData });
  const [updateUser, { loading }] = useMutation(UPDATE_USER_LANGUAGE);
  const [spinner, setSpinner] = useState(false);

  const { _id } = data;

  useEffect(() => { setSpinner(loading) }, [loading]);

  const onSubmit = ((data) => {
    const updateLanguage = async () => {
      await updateUser({
        variables: { 
          userId: _id,
          _id: data._id,
          language: data.language,
          level: data.level,
        }
      });
      refetch();
      setSpinner(loading)
      handleOpenModal();
    };
    updateLanguage();
  });

  return (
    <>

      <Box
        component="form"
        sx={{ m: 2, minWidth: 240 }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >

        {spinner ? <SpinnerRoller option={"modal"} /> : 
          <>
            <Typography variant={'h5'} align={"center"}>Idiomas</Typography>

            <Stack direction="column">
              <FormControl 
                fullWidth
                sx={{ mt: 2, boxShadow: 1 }}
              >
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel id="language-label">Idiomas</InputLabel>
                      <Select
                        labelId="language-label"
                        id="language"
                        label="language"
                        {...field}
                        {...register("language", { required: 'true' })}
                      >
                        {languages.map(item => <MenuItem value={item.language}>{item.language}</MenuItem>)} 
                      </Select>
                    </>
                  )}
                />
              </FormControl>
              {errors.language ? <ErrorMessage variant={"caption"}>Por favor, preencha o nome do idioma</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">
              <FormControl 
                fullWidth
                sx={{ mt: 2, boxShadow: 1 }}
              >
                <Controller
                  name="level"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel id="level-label">Nível</InputLabel>
                      <Select
                        labelId="level-label"
                        id="level"
                        label="level"
                        {...field}
                        {...register("level", { required: 'true' })}
                      >
                        <MenuItem value={"Básico"}>Básico</MenuItem>
                        <MenuItem value={"Intermediário"}>Intermediário</MenuItem>
                        <MenuItem value={"Avançado"}>Avançado</MenuItem>
                        <MenuItem value={"Fluente"}>Fluente</MenuItem>
                      </Select>
                    </>
                  )}
                />
              </FormControl>
              {errors.language ? <ErrorMessage variant={"caption"}>Por favor, preencha nível do idioma</ErrorMessage> : null}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ mt: 3 }} justifyContent={'flex-end'}>

              <Button type="submit" variant="contained">Salvar</Button>

            </Stack>
          </>
        }

      </Box>

    </>
  );
}
