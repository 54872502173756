import React from 'react';
import { Container } from './CareerManagement.style';

export const CareerManagement = () => {
  return (
    <>
      <h1>Gestão de Carreira</h1>
    </>
  );
};
