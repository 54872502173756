import Typography from '@mui/material/Typography';

export const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      {new Date().getFullYear()}
      {' UP Carreiras. '}
      {' Todos os direitos reservados. '}
    </Typography>
  );
}