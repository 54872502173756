import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
      $password: String!
      $passwordCode: String!
    ){
    changePassword(
      password: $password
      passwordCode: $passwordCode
    ){
      ok
    }
  }
`;
