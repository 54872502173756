import React from 'react';
import { Aside } from './HamburguerMenu.styles';

export const HamburguerMenu = ({ backgroundColor = "#03045e", children, direction = "left" }) => {
  return(
    <Aside backgroundColor={backgroundColor} direction={direction}>
      {children}
    </Aside>
  )
};
