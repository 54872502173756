import React from 'react';
import { H2Tag } from './H2.styles';

export const H2 = ({ id, className, children, textAlign }) => {
  return (
    <H2Tag 
      id={id} 
      className={className} 
      text-align={textAlign}
    >
      {children}
    </H2Tag>
  );
};
