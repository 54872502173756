import React, { useState } from 'react';
import * as S from './ButtonSwitch.style';

export const ButtonSwitch = ({ labelText, onClick }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => { 
    setChecked(nextChecked);
    if(onClick) {
      onClick()
    }
  };

  return (
      <S.Container>
        <S.Label>
          <S.ButtonSwitch
            onChange={handleChange}
            checked={checked}
            className="react-switch"
            />
          <span>{labelText}</span>
        </S.Label>
      </S.Container>
  );
};
