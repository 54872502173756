import React from 'react';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';

export const Modal = ({ 
  openModal, 
  setOpenModal, 
  handleOpenModal, 
  children, 
  maxWidth,
  maxHeight,
  minWidth,
  width,
  height,
  textAlign,
  zIndex = 1000,
  }) => {
    const handleCloseModal = () => setOpenModal(false);

  const customStyles = {
    content: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: 'none',
      padding: '30px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: maxHeight,
      maxWidth: maxWidth,
      minWidth: minWidth,
      width: width,
      height: height,
      textAlign: textAlign,
      backgroundColor: '#fff',
    },
    overlay: {
      zIndex: zIndex,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <>
      <ReactModal 
        isOpen={openModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <span style={{ cursor: "pointer" }}>
          <FiX size={32} color="#000" onClick={handleCloseModal} />
        </span>

        {children}

      </ReactModal>
    </>
  );
}
