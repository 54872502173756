import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, CssBaseline, Stack, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SpinnerRoller } from '../../components/Loading/SpinnerRoller/SpinnerRoller';
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../services/changePassword";
import { compareTwoStrings } from "../../utils/compareTwoStrings";
import { ErrorMessage } from '../../styles/globalStyles';
import { Snackbar } from "../../components/Snackbar/Snackbar";

const theme = createTheme();

export const ChangePassword = () => {
  const { password_code } = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [passwordsMatch, setPasswordsMatch] = useState(false);  
  const [changePassword, { loading, data: response }] = useMutation(CHANGE_PASSWORD);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if(response) {
      setMessage('Email de alteração de senha enviado')
    }
  }, [response]);

  const onSubmit = (async (data) => {    
    if (compareTwoStrings(data.new_password, data.confirm_password) === true) {
      setPasswordsMatch(false);
      
      try {
        await changePassword({
          variables: { 
            password: data.new_password,
            passwordCode: password_code
          }
        });

        setOpenSnackbar(true);
        setTimeout(() => { navigate("/login") }, 3000);

      } catch (error) {}

    } else {
      setPasswordsMatch(true);
    }
    
  });

  return (    
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Snackbar
          openSnackbar={openSnackbar} 
          setOpenSnackbar={setOpenSnackbar}
          text="Senha alterada, redirecionando para a página de login"
        />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Typography component="h1" variant="h5">Criar nova senha</Typography>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ my: 5, width: '90%' }}>

            <Typography paragraph={true}>Por favor, insira abaixo uma nova senha</Typography>
            
            <Stack direction="column">
              <TextField
                id="new_password"
                name="new_password"
                type="password"
                label="Nova senha"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }} 
                {...register("new_password", { required: true })}
              />
              {errors.new_password ? <ErrorMessage variant={"caption"}>Por favor, preencha a senha</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">
              <TextField
                id="confirm_password"
                name="confirm_password"
                type="password"
                label="Confirme sua senha"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }}
                {...register("confirm_password", { required: true })}
              />
              {errors.confirm_password ? <ErrorMessage variant={"caption"}>Por favor, confirme a senha</ErrorMessage> : null}
              {passwordsMatch ? <ErrorMessage variant={"caption"}>Uma das senhas não coincide</ErrorMessage> : null}
              
            </Stack>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {loading === true ? <SpinnerRoller /> : "Criar nova senha" }
            </Button>

          </Box>
        </Box>
      
      </Container>
    </ThemeProvider>
  );
}