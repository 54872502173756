import React from 'react';
import { Container } from './PageNotFound.style';

export const PageNotFound = () => {
  return (
    <Container>
      <h1>Erro 404</h1>
      Conteúdo não encontrado, tente novamente mais tarde ou entre em contato com o suporte informando o código do erro.
      <br />
      <br />
      <p>Caso necessário entre em contato pelo email contato@upcarreiras.com.br</p>
    </Container>
  );
};
