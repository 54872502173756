import styled from 'styled-components';
import { FiCheckCircle } from "react-icons/fi";

export const Container = styled.div`
    background-color: rgb(3, 4, 94);
    font-family: Roboto, sans-serif;
`

export const ContainerFrame = styled.section`
    margin: 20px 0px;

    color: white;
    min-height: 550px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContainerTitle = styled.div`
    text-transform: uppercase;
    text-align: center;
    width: 100%;
`

export const Title = styled.h1`
    font-size: 36px;
    margin: 40px 0px;
`

export const ContainerShortText = styled.div`
    text-align: center;
    width: 100%;
`

export const Text = styled.div`
    font-size: 18px;
    margin: 20px 40px;
    text-align: center;
`

export const ContainerContent = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
`

export const ContainerContentDefaulSize = styled.div`
    height: 100%;
    width: 450px;
`

export const ContainerDemo = styled(ContainerContentDefaulSize)`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerHowWorksText = styled(ContainerContentDefaulSize)`
    font-size: 22px;
    display: flex;
    align-items: center;
    
    line-height: 40px;
    
    ul {
        position: absolute;
        width: inherit;

        li {
            margin-top: 30px;
        }
    }
`

export const ContainerFeatures = styled.div`
    margin: 0px 100px;
    width: 100%;

    svg {
        height: 35px;
        width: 35px;
    }
`

export const ContainerTestimonials = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`

export const ContainerContentAccordion = styled(ContainerContent)`
    display: flex;
    align-items: flex-start;
`


export const ContainerAccordion = styled.div`    
    margin: 100px 450px 0px 450px;
    width: 100%;

    .accordion__button {
        background-color: rgb(30, 167, 253);
        color: white;
    }
`
