import styled, { css } from 'styled-components';

export const Container = styled.div`
    font-family: inherit;
    color: #565656;

    .trial {
        font-size: 0.9rem;
        font-weight: 600;
        padding: 2px 21px 2px 21px;
        color: #4baef5ff;
        border: 1px solid #e8e8e8;
        display: inline-block;
        border-radius: 15px;
        background-color: white;
        position: relative;
        bottom: -20px;
    }

    ul {
        list-style: none;
        padding: 0;
        text-align: left;
        margin: 30px 20px;
    }

    li {
        display: flex;
        align-items: center;

        margin-bottom: 15px;
        
        svg {
            margin-right: 10px;
        }
    }

`;

export const Card = styled.div`
    box-sizing: border-box;
    width: 350px;
    min-height: 450px;
    border: 3px solid #e8e8e8;
    border-radius: 7px;
    display: inline-block;
    padding: 20px;
    text-align: center;
    float: left;
    transition: margin-top 0.5s linear;
    position: relative;
    margin-right: 11px;

    &:hover {
        margin-top: -30px;
        transition: margin-top 0.3s linear;
    };
    
    ${({ bestOffer }) =>
    bestOffer &&
    css`
        border: 3px solid #4baef5ff;
    `}

    ${({ bestOffer }) =>
    bestOffer &&
    css`
      &::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 64px 64px 0 0;
        border-color: #4baef5ff transparent transparent transparent;
        position: absolute;
        left: 0;
        top: 0;
        content: "";
      }
    `}

    ::after {
        color: white;
        position: absolute;
        left: 9px;
        top: 6px;
        text-shadow: 0 0 2px #4baef5ff;
        font-size: 1.4rem;
    }
`

export const PriceCondition = styled.div`
    text-transform: none!important;
`

export const Title = styled.div`
    font-weight: 800;
    font-size: 3rem;
    margin-bottom: 20px;
`

export const Price = styled.div`
    margin-bottom: 10px;
`

export const PriceValue = styled.span`
    font-size: 30px;
`