import { Button, Container } from '@mui/material';
import { FlexDirectionRowContainer } from '../../styles/globalStyles';
import { translateSection } from "../../utils/translateSection";
import * as S from "./ConfirmDelete.style";

export const ConfirmDelete = ({ handleOpenModal, section, itemToBeDeleted, functionToBeConfirmed }) => {

  const handleDeleteConfirmation = (boolean) => {
    if(boolean) { functionToBeConfirmed() };
    handleOpenModal();
  }

  return (
    <Container maxWidth="md">

      {itemToBeDeleted ?
        <>
          <S.Title variant="h5">Deseja deletar o item selecionado ?</S.Title>

          <S.Paragraph paragraph={true}>Essa ação vai deletar o item <S.Bold>{itemToBeDeleted}</S.Bold> deseja prosseguir ?</S.Paragraph>
        </>
      : 
        <>
          <S.Title variant="h5">Deseja deletar toda a seção ?</S.Title>

          <S.Paragraph paragraph={true}>Essa ação vai deletar todos os itens da seção <S.Bold>{translateSection(section)}</S.Bold> deseja prosseguir ?</S.Paragraph >
        </>
      }

      <FlexDirectionRowContainer style={{ justifyContent: "space-evenly" }}>
        <Button sx={{ width: '25%' }} variant="contained" onClick={() => handleDeleteConfirmation(false)}>Não</Button>
        <Button sx={{ width: '25%' }} variant="contained" onClick={() => handleDeleteConfirmation(true)}>Sim</Button>
      </FlexDirectionRowContainer>

    </Container>
  );
}
