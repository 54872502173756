import styled from 'styled-components';

export const Aside = styled.aside`
  display: ${({direction}) => direction === 'close' ? 'none' : "flex" };
  justify-content: center;
  flex-direction: column;

  background-color: #1976d2;
  border: 2px solid #1976d2;
  min-height: 50vh;
  border-radius: 30px;
  position: absolute;
  top: 140px;
  left: ${({direction}) => direction === 'left' ? '-439px' : direction === 'open-left' ? '10px' : null };
  right: ${({direction}) => direction === 'right' ? '-439px' : direction === 'open-right' ? '10px' : null };
  padding: 20px;
  z-index: 100;
  width: 500px;

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  &:hover{
    left: ${({direction}) => direction === 'left' ? '10px' : null };
    right: ${({direction}) => direction === 'right' ? '10px' : null };
  }

`

export const Section = styled.section`
  background-color: #fff;
  border-radius: 10px;
  min-height: 70vh;
  height: auto;
  padding: 40px 40px 10px 40px;
`

export const Wrapper = styled.span`
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: 35px;
`