import styled from 'styled-components';

export const Aside = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  width: 60%;
  height: 750px;
  position: absolute;
  top: 85px;
  padding: 40px;
  left: ${({ direction }) => direction === "left" ? 0 : null};
  right: ${({ direction }) => direction === "right" ? 0 : null};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`