import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Autocomplete, Button, TextField, Typography, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorMessage } from '../../styles/globalStyles';
import { UPDATE_USER_COURSE } from "../../services/User.services";
import { capitalizeFirstLetterOnlyInFirstWord } from "../../utils/capitalizeFirstLetterOnlyInFirstWord";
import { useMutation } from "@apollo/client";
import { status } from "../../staticData/status";
import { SpinnerRoller } from "../../components/Loading/SpinnerRoller/SpinnerRoller";

export const Course = ({ handleOpenModal, selectedData, index, context }) => {
  const { register, handleSubmit, control, formState: { errors } } = useForm({ defaultValues: selectedData || { "year": null }});
  // no useForm acima o "{ "year": null }" serve para que o ano não comece com o ano atual e isso estava causando bugs.
  const [updateUser, { loading }] = useMutation(UPDATE_USER_COURSE);
  const [spinner, setSpinner] = useState(false);

  const { data, refetch } = context;
  const { _id } = data;

  useEffect(() => { setSpinner(loading) }, [loading]);

  const onSubmit = ((data) => {
    const updateCourse = async () => {
      await updateUser({
        variables: { 
          userId: _id,
          _id: data._id,
          course: data.course,
          institution: data.institution,
          status: data.status,
          year: String(data.year.$y)
        }
      });      
      refetch()
      setSpinner(loading)
      handleOpenModal();
    }
    updateCourse()
  });

  return (
    <>

      <Box
        component="form"
        sx={{ m: 2 }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {spinner ? <SpinnerRoller option={"modal"} /> : 
          <>
            <Typography variant={'h5'} align={"center"}>Curso Livre</Typography>

            <Stack direction="column">
              <TextField
                id="course"
                label="Curso"
                variant="outlined"
                placeholder="Escreva o nome do curso"
                required={true}
                fullWidth
                sx={{ mt: 2, boxShadow: 1 }}
                {...register("course", { required: 'true' })}
              />
              {errors.course ? <ErrorMessage variant={"caption"}>Por favor, preencha o nome do curso</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">
              <TextField
                id="institution"
                label="Instituição"
                variant="outlined"
                placeholder="Escreva o nome da instituição de ensino"
                required={true}
                fullWidth
                sx={{ mt: 2, boxShadow: 1 }}
                {...register("institution", { required: 'true' })}
              />
              {errors.institution ? <ErrorMessage variant={"caption"}>Por favor, preencha o nome da instituição de ensino</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">
              <Controller
                name="status"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, data) => onChange(data)}
                    options={status?.map((option) => capitalizeFirstLetterOnlyInFirstWord(option))}
                    renderInput={(params) => <TextField 
                      {...params}
                      {...field}
                      id="status"
                      label="Status"
                      inputRef={ref}
                      required={true}
                      {...register("status", { required: 'true' })} 
                    />}
                    margin="normal"
                    fullWidth
                    sx={{ mt: 2, boxShadow: 1 }}
                  />
                )}
              />
              {errors.status ? <ErrorMessage variant={"caption"}>Por favor, preencha o status</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">    
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="year"
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MobileDatePicker
                    {...field}
                    label="Ano"
                    openTo="year"
                    views={['year']}
                    sx={{ mt: 2, boxShadow: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required={true}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.year ? <ErrorMessage variant={"caption"}>Por favor, preencha o ano</ErrorMessage> : null}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ mt: 3 }} justifyContent={'flex-end'}>

              <Button type="submit" variant="contained">Salvar</Button>

            </Stack>

          </>
        }

      </Box>

    </>
  );
}
