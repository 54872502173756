import styled from 'styled-components';

export const Card = styled.div`
  background-color: white;
  /* border: 2px solid rgb(4, 211, 97); */
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 3.5px #00000050;
  padding: 20px;

  min-height: 100px;
  min-width: 280px;
  
  width: 415px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  /* Font */
  color: #000000;
  font-size: 13px;
  font-family: 'Catamaran', sans-serif;
  font-weight: normal;
  text-align: left;
`
