import styled from 'styled-components';
import { Typography } from '@mui/material';

export const Title = styled(Typography)`
  font-weight: bold!important;
  text-align: center;
  text-transform: capitalize;
`;

export const Subtitle = styled(Typography)`
  font-weight: bold!important;
  margin-top: 20px!important;
  text-transform: capitalize;
`;

export const Paragraph = styled(Typography)`
  margin-top: 20px!important;
`;

export const Ul = styled.ul`
  list-style-position: inside;
  margin-top: 20px;
`
