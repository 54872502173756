import styled from 'styled-components';
import { SectionA4, CapitalizeFirstLetter, TextTransformNone, FlexDirectionRowContainer } from '../../../../styles/globalStyles';
import TextareaAutosize from 'react-autosize-textarea';

export const GSCapitalizeFirstLetter = styled(CapitalizeFirstLetter)``
export const GSTextTransformNone = styled(TextTransformNone)``

export const ContainerButtons = styled.div`
  position: absolute;
  right: 2.5em;
  padding: 3px;
  z-index: 10;
`

export const ActionButton = styled.button`
  /* background-color: transparent; */
  border: none;
  margin-left: 10px;
`

export const AddButton = styled(ActionButton)``
export const TipsButton = styled(ActionButton)``
export const EditButton = styled(ActionButton)``
export const DeleteButton = styled(ActionButton)``
export const EditItemButton = styled(ActionButton)``
export const DeleteItemButton = styled(ActionButton)`
  /* position: absolute; */
  /* right: 60px; */
  /* z-index: 10; */
  /* margin-right: 5px; */
`

export const TitleFullName = styled.h1`
  /* font-size: 28px; */
  font-size: 1.75em;
  margin: 0px;
  text-align: center;

  @media print {
    font-size: 28px;
  }
`

export const HRDivider = styled.hr`
  background: black;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 10px;
`

export const GeneralData = styled.div`
  line-height: 20px;
  text-align: center;
`

export const CV = styled.div`
  background-color: #e0e0e0;
  padding: 30px 20px 30px 20px;

  section {
    margin: auto;
  }

  button {
    background-color: transparent;
  }

  svg {
    background-color: transparent;
  }
`

export const SectionCV = styled(SectionA4)`
  background-color: ${ ({ theme }) => theme.background };
  color: ${ ({ theme }) => theme.text };
  cursor: default;
  padding: 2.5em;
`

export const Section = styled.section`
  & :hover {
    background-color: rgb(242, 242, 242);
  }
`

export const SectionGeneralData = styled(Section)``
export const SectionResume = styled(Section)``
export const SectionExperience = styled(Section)``
export const SectionEducation = styled(Section)``
export const SectionCourse = styled(Section)``
export const SectionLanguage = styled(Section)``
export const SectionVolunteerJob = styled(Section)``

export const CVSubtitle = styled.h2`
  /* font-size: 18px; */
  font-size: 1.125em;
  font-weight: 700;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 1px;
  text-transform: capitalize;

  @media print {
    font-size: 18px;
  }
`

export const Resume = styled.p`
  line-height: 20px;
  margin-bottom: 20px;
  text-align: justify;
`

export const ContainerNewResumeParagraph = styled(FlexDirectionRowContainer)`
  margin-bottom: 20px;
`

export const ResumeParagraph = styled(TextareaAutosize)`
  border: none;
  font-size: 1em;
  line-height: 20px;
  text-align: justify;
  padding: 5px; // Esse padding existe para que o cursor do mouse seja visível no início do parágrafo, caso contrário o curso ficaria piscando no hover.
  width: 100%;

  &:disabled {
    cursor: default;
    background-color: transparent;
    color: black;
    border-color: rgba(118, 118, 118, 0.3);
  }
`;

export const Education = styled.div`
  line-height: 20px;
  text-align: justify;
`

export const Italic = styled.span`
  font-style: italic;
`
export const ProfessionalExperience = styled.div`
  margin-bottom: 20px;
`

export const VolunteerJob = styled.div`
  margin-bottom: 20px;
`

export const Description = styled.div`
  line-height: 20px;
  margin-top: 10px;
  text-align: justify;
`

export const Bold = styled.span`
  font-weight: 700;
`

export const Li = styled.li`
  margin-left: 20px;
  line-height: 20px;
`

export const ContainerItem = styled.span`
  display: flex;
  justify-content: space-between;
`

export const Button = styled.div`
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 210mm;

  @media print {
    display: none;
  }

  button:nth-child(1) {
    margin-right: 10px;
  }
`
