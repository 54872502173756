export const handleItemName = ({ section }) => {
  switch (section) {
    case "experience":
    case "volunteerJob":
      return "role";
    case "language":
      return "language";
    case "education":
    case "course":
      return "course";
    default:
      return null;
  }
}
