import React, { useEffect, useState } from 'react';
import logo from "../../assets/logo/logo_main.png";
import { isSmallScreen } from "../../utils/isSmallScreen";
import { HamburguerButton } from "../../components/Buttons/HamburguerButton/HamburguerButton";
import { HamburguerMenu } from "../../components/Menu/HamburguerMenu/HamburguerMenu";
import  { NavBarButtons } from "../../components/NavBarButtons/NavBarButtons";
import * as S from './Navbar.style';

export const Navbar = () => {
  const [showHamburguerButton, setShowHamburguerButton] = useState(false);
  const [showHamburguerMenu, setShowHamburguerMenu] = useState(false);

  useEffect(() => {
    setShowHamburguerButton(isSmallScreen() ? true : false);
  }, [])

  // const [openSidebar, setOpenSidebar] = useState(false);

  // let getWidth = window.screen.width;

  // const isMobile = getWidth <= 1150 ? true : false

  // const handleHamburguerButton = () => {
  //   setOpenSidebar(!openSidebar)
  // }

  return(
    <>
      <S.NavBar>
          
          <S.Container>

            <S.ContainerLogo>
              <a href="#">
                <img src={logo} width={"200px"} alt="logo com as palavras up carreiras, sendo que a palavra up tem um fundo azul claro" />
              </a>
            </S.ContainerLogo>

            {showHamburguerButton ? 
              <HamburguerButton 
                color={"white"} 
                direction={"right"} 
                onClick={() => setShowHamburguerMenu(prevState => !prevState)}
                handleOpenClose={() => showHamburguerMenu}
              />
             : null}

            {!showHamburguerButton ? 
              <NavBarButtons setShowHamburguerMenu={setShowHamburguerMenu} />
            : null}

            {showHamburguerMenu ? 
              <HamburguerMenu
                children={<NavBarButtons setShowHamburguerMenu={setShowHamburguerMenu} />}
                direction="right"
              />
            : null}

            {/* {isMobile ?
              <button onClick={() => handleHamburguerButton()} style={{ background: "none", border: "none" }}>
                <HamburguerButton />
              </button>
            : null} */}

          </S.Container>

      </S.NavBar>
    </>
  )
};
