import React from 'react';
import { Container, Title } from './CardWithIconAndText.styles';
import { Paragraph } from '../../../atoms/Paragraph/Paragraph';
import { IconCheck } from '../../../assets/icons/IconCheck';

export const CardWithIconAndText = ({ iconWidth, iconHeight, text, title, width, height, icon }) => {
  return(
    <Container style={{ width: width, height: height }}>
      {icon ? icon : <IconCheck width={iconWidth} height={iconHeight} />}
      {/* <IconCheck width={iconWidth} height={iconHeight} /> */}
      <Title textAlign="center">{title}</Title>
      <Paragraph textAlign="center" fontSize="14px">{text}</Paragraph>
    </Container>
  );
};
