export const languages = [
  { "language": "Africâner" },
  { "language": "Aimará" },
  { "language": "Albanês" },
  { "language": "Alemão" },
  { "language": "Amárico" },
  { "language": "Árabe" },
  { "language": "Aragonês" },
  { "language": "Armênio" },
  { "language": "Assamês" },
  { "language": "Avar" },
  { "language": "Azeri" },
  { "language": "Basco" },
  { "language": "Bashkir" },
  { "language": "Bengalês" },
  { "language": "Bielorrusso" },
  { "language": "Birmanês" },
  { "language": "Bósnio" },
  { "language": "Bretão" },
  { "language": "Búlgaro" },
  { "language": "Canarês" },
  { "language": "Catalão" },
  { "language": "Caxemira" },
  { "language": "Cazaque" },
  { "language": "Cebuano" },
  { "language": "Checheno" },
  { "language": "Checo" },
  { "language": "Chichewa" },
  { "language": "Chinês" },
  { "language": "Chuvash" },
  { "language": "Cingalês" },
  { "language": "Coreano" },
  { "language": "Corso" },
  { "language": "Crioulo da Guiné-Bissau" },
  { "language": "Crioulo de Cabo Verde" },
  { "language": "Crioulo de São Tomé e Príncipe" },
  { "language": "Crioulo haitiano" },
  { "language": "Croata" },
  { "language": "Curdo" },
  { "language": "Córnico" },
  { "language": "Dinamarquês" },
  { "language": "Divehi" },
  { "language": "Eslovaco" },
  { "language": "Esloveno" },
  { "language": "Espanhol" },
  { "language": "Esperanto" },
  { "language": "Estoniano" },
  { "language": "Ewe" },
  { "language": "Faroês" },
  { "language": "Fijiano" },
  { "language": "Finlandês" },
  { "language": "Francês" },
  { "language": "Frísio" },
  { "language": "Fulfulde" },
  { "language": "Gaélico escocês" },
  { "language": "Galego" },
  { "language": "Galês" },
  { "language": "Georgiano" },
  { "language": "Grego" },
  { "language": "Gronelandês" },
  { "language": "Guarani" },
  { "language": "Gujarati" },
  { "language": "Hausa" },
  { "language": "Havaiano" },
  { "language": "Hebraico" },
  { "language": "Herero" },
  { "language": "Hindi" },
  { "language": "Hmong" },
  { "language": "Holandês" },
  { "language": "Húngaro" },
  { "language": "Igbo" },
  { "language": "Iídiche" },
  { "language": "Indonésio" },
  { "language": "Inglês" },
  { "language": "Interlíngua" },
  { "language": "Interlíngue" },
  { "language": "Inuktitut" },
  { "language": "Inupiaq" },
  { "language": "Irlandês" },
  { "language": "Islandês" },
  { "language": "Italiano" },
  { "language": "Japonês" },
  { "language": "Javanês" },
  { "language": "Kalaallisut" },
  { "language": "Kannada" },
  { "language": "Kanuri" },
  { "language": "Quirguiz" },
  { "language": "Kikuyu" },
  { "language": "Kirundi" },
  { "language": "Koissã" },
  { "language": "Khmer" },
  { "language": "Coreano" },
  { "language": "Crioulo haitiano" },
  { "language": "Croata" },
  { "language": "Curdo" },
  { "language": "Tcheco" },
  { "language": "Tâmil" },
  { "language": "Télugo" },
  { "language": "Tétum" },
  { "language": "Tailandês" },
  { "language": "Tibetano" },
  { "language": "Tigrínia" },
  { "language": "Tonganês" },
  { "language": "Tsonga" },
  { "language": "Turco" },
  { "language": "Turcomeno" },
  { "language": "Tuvaluano" },
  { "language": "Ucraniano" },
  { "language": "Umbundu" },
  { "language": "Urdu" },
  { "language": "Uzbeque" },
  { "language": "Venda" },
  { "language": "Vietnamita" },
  { "language": "Volapuque" },
  { "language": "Wallisiano" },
  { "language": "Waray-Waray" },
  { "language": "Wolof" },
  { "language": "Xhosa" },
  { "language": "Yiddish" },
  { "language": "Yoruba" },
  { "language": "Zulu" },
]