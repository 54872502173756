import styled from 'styled-components';
import Switch from 'react-switch';

export const Container = styled.div`
  margin-top: 10px;
  width: 350px;
`

export const ButtonSwitch = styled(Switch)`
  vertical-align: middle;
  margin-left: 4px;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
`
