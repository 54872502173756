import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Container, Stack, TextField, Typography } from "@mui/material";
// import { EditResumeExperienceTime } from '../EditResumeExperienceTime/EditResumeExperienceTime';
// import { BehaviourSkillsForm } from '../../components/Forms/BehaviourSkillsForm/BehaviourSkillsForm';
import { UPDATE_USER_RESUME } from "../../services/User.services";
import { useMutation } from "@apollo/client";
import { SpinnerRoller } from "../../components/Loading/SpinnerRoller/SpinnerRoller";

export const Resume = ({ handleOpenModal, selectedData, context }) => {
  const { data, refetch } = context;
  const { _id, resume } = data;
  const { register, control, handleSubmit } = useForm({ defaultValues: { resume } });
  const [updateUser, { loading }] = useMutation(UPDATE_USER_RESUME);
  const [spinner, setSpinner] = useState(false);
  
  useEffect(() => { setSpinner(loading) }, [loading]);

  const onSubmit = ((data) => {
    const updateResume = async () => {
      await updateUser({
        variables: {
          userId: _id,
          resume: data.resume,
        }
      });
      refetch();
      setSpinner(loading);
      handleOpenModal();
    }
    updateResume()
  });

  return (
    <>
      {spinner ? <SpinnerRoller option={"modal"} /> : 
        <Container maxWidth="md">

          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h1" align="center" sx={{ margin: 2 }}>Resumo Profissional</Typography>  
            
            <Controller
              name="resume"
              control={control}
              render={({ field: { ref, value, onChange, ...field } }) => (
                <TextField
                  {...field}
                  id="resume"
                  label="Resumo"
                  variant="outlined"
                  multiline
                  minRows={6}
                  maxRows={11}
                  placeholder="Digite aqui o seu resumo profissional"
                  fullWidth
                  sx={{ mt: 2, boxShadow: 1 }}
                  {...register("resume")}
                />
              )}
            />

            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 3 }}
            >

              <Button type="submit" variant="contained">Salvar</Button>

            </Stack>

          </form>

        </Container>
      }
    </>
  );
};

