import React from "react";
import { PriceCard } from "../../components/Cards/PriceCard/PriceCard";
import { ButtonPrimary } from "../../atoms/Buttons/ButtonPrimary/ButtonPrimary";
import { FooterSecondary } from "../../components/Footer/FooterSecondary/FooterSecondary";
import { priceCardText } from "../../staticData/priceCardText";
import { useNavigate } from "react-router";
import * as S from "./PremiumPage.style";

export const PremiumPage = () => {
  const navigate = useNavigate();

  return (
    <>

      <S.Container>
        
        <S.NavBar>
          <ButtonPrimary
            height={"40px"}
            text={"Voltar"}
            width={"150px"}
            margin={"20px 0px 20px 0px"}
            onClick={() => navigate("/cv")}
          />
        </S.NavBar>

        <S.ContainerCards id="container-price-cards">
          {priceCardText.map((data) => <PriceCard data={data} />)}
        </S.ContainerCards>
      
      <FooterSecondary />

      </S.Container>

    </>
  );
};
