export const educationLevel = [
  'ensino fundamental',
  'ensino médio',
  'técnico',
  'bacharel',
  'tecnólogo',
  'licenciatura',
  'pós-graduação',
  'mestrado',
  'doutorado',
];