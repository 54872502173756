export const dddStatesBR = [
  { "ddd": "11", "state": "São Paulo" },
  { "ddd": "12", "state": "São Paulo" },
  { "ddd": "13", "state": "São Paulo" },
  { "ddd": "14", "state": "São Paulo" },
  { "ddd": "15", "state": "São Paulo" },
  { "ddd": "16", "state": "São Paulo" },
  { "ddd": "17", "state": "São Paulo" },
  { "ddd": "18", "state": "São Paulo" },
  { "ddd": "19", "state": "São Paulo" },
  { "ddd": "21", "state": "Rio de Janeiro" },
  { "ddd": "22", "state": "Rio de Janeiro" },
  { "ddd": "24", "state": "Rio de Janeiro" },
  { "ddd": "27", "state": "Espírito Santo" },
  { "ddd": "28", "state": "Espírito Santo" },
  { "ddd": "31", "state": "Minas Gerais" },
  { "ddd": "32", "state": "Minas Gerais" },
  { "ddd": "33", "state": "Minas Gerais" },
  { "ddd": "34", "state": "Minas Gerais" },
  { "ddd": "35", "state": "Minas Gerais" },
  { "ddd": "37", "state": "Minas Gerais" },
  { "ddd": "38", "state": "Minas Gerais" },
  { "ddd": "41", "state": "Paraná" },
  { "ddd": "42", "state": "Paraná" },
  { "ddd": "43", "state": "Paraná" },
  { "ddd": "44", "state": "Paraná" },
  { "ddd": "45", "state": "Paraná" },
  { "ddd": "46", "state": "Paraná" },
  { "ddd": "47", "state": "Santa Catarina" },
  { "ddd": "48", "state": "Santa Catarina" },
  { "ddd": "49", "state": "Santa Catarina" },
  { "ddd": "51", "state": "Rio Grande do Sul" },
  { "ddd": "53", "state": "Rio Grande do Sul" },
  { "ddd": "54", "state": "Rio Grande do Sul" },
  { "ddd": "55", "state": "Rio Grande do Sul" },
  { "ddd": "61", "state": "Distrito Federal" },
  { "ddd": "62", "state": "Goiás" },
  { "ddd": "64", "state": "Goiás" },
  { "ddd": "63", "state": "Tocantins" },
  { "ddd": "65", "state": "Mato Grosso" },
  { "ddd": "66", "state": "Mato Grosso" },
  { "ddd": "67", "state": "Mato Grosso do Sul" },
  { "ddd": "68", "state": "Acre" },
  { "ddd": "69", "state": "Rondônia" },
  { "ddd": "71", "state": "Bahia" },
  { "ddd": "73", "state": "Bahia" },
  { "ddd": "74", "state": "Bahia" },
  { "ddd": "75", "state": "Bahia" },
  { "ddd": "77", "state": "Bahia" },
  { "ddd": "79", "state": "Sergipe" },
  { "ddd": "81", "state": "Pernambuco" },
  { "ddd": "82", "state": "Alagoas" },
  { "ddd": "83", "state": "Paraíba" },
  { "ddd": "84", "state": "Rio Grande do Norte" },
  { "ddd": "85", "state": "Ceará" },
  { "ddd": "86", "state": "Piauí" },
  { "ddd": "87", "state": "Pernambuco" },
  { "ddd": "88", "state": "Ceará" },
  { "ddd": "89", "state": "Piauí" },
  { "ddd": "91", "state": "Pará" },
  { "ddd": "92", "state": "Amazonas" },
  { "ddd": "93", "state": "Pará" },
  { "ddd": "94", "state": "Pará" },
  { "ddd": "95", "state": "Roraima" },
  { "ddd": "96", "state": "Amapá" },
  { "ddd": "97", "state": "Amazonas" },
  { "ddd": "98", "state": "Maranhão" },
  { "ddd": "99", "state": "Maranhão" },
];