import { Grid, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { positions } from "../../staticData/positions";
// import { company } from "../../staticData/company";
import { ErrorMessage } from "../../../styles/globalStyles";
import { currentYearMonth } from "../../../utils/currentYearMonth";

export const VolunteerJobForm = ({ valueDescription, control, register, errors, checked, handleChange, watch }) => {
  return (
    <>
      <Grid container spacing={3}>
        
        <Grid item xs={12} sm={6}>

          <TextField 
            label="Cargo"
            fullWidth
            sx={{ mt: 2, mb: 1, boxShadow: 1 }}
            {...register("role", { required: 'true' })} 
          />
          {errors.role ? <ErrorMessage variant={"caption"}>Por favor, preencha o cargo</ErrorMessage> : null}

        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField 
            label="Instituição" 
            fullWidth
            sx={{ mt: 2, mb: 1, boxShadow: 1 }}
            {...register("company", { required: 'true' })} 
          />
          {errors.company ? <ErrorMessage variant={"caption"}>Por favor, preencha com o nome da instituição de ensino</ErrorMessage> : null}
        
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 1 }}>
        
        <Grid item xs={12} sm={6}>
          <Stack direction="column">
            <TextField
              id="initialDate"
              label="Data Inicial"
              type="month"
              sx={{ mb: 1, boxShadow: 1 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  min: "1900-01",
                  max: currentYearMonth
                }
              }}
              fullWidth
              {...register("initialDate", { required: 'true' })}
            />
            {errors.initialDate ? <ErrorMessage variant={"caption"}>Por favor, preencha a data inicial</ErrorMessage> : null}
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack direction="column">
            <TextField
              id="finalDate"
              label="Data Final"
              type="month"
              sx={{ mb: 1, boxShadow: 1 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  min: "1900-01",
                  max: currentYearMonth
                }
              }}
              fullWidth
              disabled={watch("untilPresent")}
              {...register("finalDate", { required: watch("untilPresent") === true ? false : true })}
            />
            {errors.finalDate ? <ErrorMessage variant={"caption"}>Por favor, preencha a data final</ErrorMessage> : null}
          </Stack>
        </Grid>
        
      </Grid>

      <Controller
        name="untilPresent"
        control={control}
        defaultValue={false}
        {...register("untilPresent")}
        render={({ field: { value = checked, onChange = (e) => handleChange(e), ...field } }) => (
          <FormControlLabel
            label="Eu estou trabalhando nesse cargo no momento"
            control={
              <Checkbox
                {...field}
                checked={value}
                onChange={onChange}
              />
            }
          />
        )}
      />

      <TextField
        id="description"
        label="Descrição"
        variant="outlined"
        multiline
        minRows={6}
        maxRows={20}
        placeholder="Escreva suas funções e responsabilidades"
        fullWidth
        sx={{ mt: 2, boxShadow: 1 }}
        value={valueDescription}
        {...register("description")}
      />
    </>
  )
};
