import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Container, Stack } from "@mui/material";
// import { roleDescription } from "../../staticData/roleDescription";
// import { achievementsAndHighlights } from '../../staticData/achievementsAndHighlights';
import { Typography } from "@mui/material";
import { ProfessionalExperienceForm } from "../../../components/ProfessionalExperienceForm/ProfessionalExperienceForm";
import { DescriptionSuggestions } from "../../../components/DescriptionSuggestions/DescriptionSuggestions";
import { UPDATE_USER_EXPERIENCE } from "../../../services/User.services";
import { useMutation } from "@apollo/client";
import { SpinnerRoller } from "../../../components/Loading/SpinnerRoller/SpinnerRoller";

export const ProfessionalExperiencePage = ({ handleOpenModal, selectedData, index, context }) => {
  let startDateMonthString = null;

  if(selectedData?.initialDate != null) {
    const date = new Date(parseInt(selectedData.initialDate));
    startDateMonthString = date.toISOString().slice(0, 7);
  }

  let finalDateMonthString = null;

  if(selectedData?.finalDate != null) {
    const date = new Date(parseInt(selectedData.finalDate));
    finalDateMonthString = date.toISOString().slice(0, 7);
  }

  const newSelectedData = {
    ...selectedData,
    initialDate: startDateMonthString,
    finalDate: finalDateMonthString,
  }

  const { register, control, handleSubmit, watch, formState: { errors } } = useForm({ defaultValues: newSelectedData });
  const [updateUser, { loading }] = useMutation(UPDATE_USER_EXPERIENCE);
  const [valueDescription, setValueDescription] = useState('');
  const [checked, setChecked] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const { data, refetch } = context;
  const { _id } = data;

  // const { register, control, watch, errors } = props;
  // selectedData?.description.join(", "); // função para ajustar o campo descrição que vem como um array.
  // const { register, handleSubmit, control, watch, formState: { errors } } = useForm({ defaultValues: selectedData });

  // const [valueTextAreaConquest, setValueTextAreaConquest] = useState('');
  // const navigate = useNavigate();
  // const [searchValue, setSearchValue] = useState();
  // const [foundDescription, setFoundDescription] = useState(roleDescription);

  useEffect(() => { setSpinner(loading) }, [loading]);

  const handleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
  };

  const onSubmit = ((data) => {

    const updateExperience = async () => {
      await updateUser({
        variables: {
          userId: _id,
          _id: data._id,
          role: data.role,
          company: data.company,
          initialDate: data.initialDate,
          finalDate: data.untilPresent ? "" : data.finalDate,
          untilPresent: data.untilPresent,
          description: data.description,
        }
      });
      refetch()
      setSpinner(loading)
      handleOpenModal();
    }
    updateExperience()
  });

  // const searchFilter = (e) => {
  //   const keyword = e.target.value;

  //   if (keyword !== '') {
  //     const results = roleDescription.filter((item) => {
  //       return item.toLowerCase().includes(keyword.toLowerCase());
  //     });
  //     setFoundDescription(results);
  //   } else {
  //     setFoundDescription(roleDescription);
  //   }

  //   setSearchValue(keyword);
  // };

  const handleChangeDescription = (event) => {
    const { labels, checked } = event.target;
    const textContent = labels[0].textContent;

    if(checked) {
      setValueDescription(prevState => [...prevState, textContent]);
    } 
    
    if(checked === false) {      
      setValueDescription(prevState => prevState.filter((description) => description !== textContent));
    }
  };

  return (
    <Container maxWidth="md">

      {spinner ? <SpinnerRoller option={"modal"} /> :
        <>
          <Typography variant="h1" align="center" sx={{ margin: 2 }}>Experiência Profissional</Typography>  
          
          <Stack
            direction="row"
            spacing={2}
            justifyContent
          >

            <Box
              component="form"
              sx={{ m: 2 }}
              onSubmit={handleSubmit(onSubmit)}
            >

              <ProfessionalExperienceForm
                valueDescription={valueDescription}
                register={register}
                control={control}
                errors={errors}
                checked={checked}
                setChecked={setChecked}
                handleChange={handleChange}
                watch={watch}
                loading={loading}
                spinner={spinner}
                setSpinner={setSpinner}
              />

              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                sx={{ mt: 3 }}
              >

                <Button type="submit" variant="contained">Salvar</Button>

              </Stack>

            </Box>

          </Stack>
        </>       
      }

    </Container>
  );
};
