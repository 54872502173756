import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { GlobalStyle } from '../src/styles/globalStyles';
import { AuthProvider } from './context/AuthContext';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <GlobalStyle />
        <App />
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);
