import styled from 'styled-components';

export const Button = styled.button`
    background-color: #1ea7fd;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: filter 0.2s;
    
    min-height: 38px;
    min-width: 126px;

    height: ${ ({ height }) => height };
    width: ${ ({ width }) => width };
    margin: ${ ({ margin }) => margin };

    /* Font */
    color: #FFFFFF;
    font-size: 18px;
    text-transform: uppercase;

    &:hover {
        filter: brightness(90%);
    }
`
