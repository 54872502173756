import { useState } from 'react';
import { useForm } from "react-hook-form";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from '../../assets/logo/logo_instagram.png';
import { SpinnerRoller } from '../../components/Loading/SpinnerRoller/SpinnerRoller';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext';
import { Copyright } from '../../components/Copyright/Copyright';
import { useNavigate } from "react-router";
import { Stack } from '@mui/material';
import { ErrorMessage } from '../../styles/globalStyles';

const theme = createTheme();

export const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { signIn, loading } = useAuth();
  const navigate = useNavigate();
  const [error , setError] = useState('')

  const onSubmit = (async (data) => {

    try {
      await signIn({ data });
      navigate('/cv')

    } catch (error) {
      setError('E-mail ou senha inválido')
    }

  });

  return (    
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
            {/* <LockOutlinedIcon /> */}
            {/* <img src={logo} alt="logo com as palavras up carreiras, sendo que a palavra up tem um fundo azul claro" /> */}
          {/* </Avatar> */}
          
          <Typography component="h1" variant="h5">Entrar</Typography>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: '90%' }}>
            
            <Stack direction="column">
              <TextField
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }} 
                {...register("email", { required: 'true' })}
              />
              {errors.email ? <ErrorMessage variant={"caption"}>Por favor, preencha o email</ErrorMessage> : null}
            </Stack>

            {<Stack direction="column">
              <TextField
                id="password"
                type="password"
                label="Senha"
                variant="outlined"
                margin="normal"
                required={true} 
                sx={{ boxShadow: 1 }} 
                {...register("password", { required: 'true' })}
              />
              {errors.password ? <ErrorMessage variant={"caption"}>Por favor, preencha a senha</ErrorMessage> : null}
            </Stack>}


            <ErrorMessage>{error}</ErrorMessage>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {loading === true ? <SpinnerRoller /> : "Entrar" }
            </Button>
            


            <Grid container>
              <Grid item xs>
                <Link href="/forget-password" variant="body2">Esqueceu a senha?</Link>
              </Grid>
              <Grid item>
                <Link href="/new-user-form" variant="body2">
                  {"Não tem uma conta? Cadastre-se"}
                </Link>
              </Grid>
            </Grid>

          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}