import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from 'react-dom';
// import { Shimmer } from 'react-shimmer'
import * as S from "./CurriculumDefault.style";
import { Tooltip } from '@mui/material';
import { AsideMenu } from "../../../../components/Menu/AsideMenu/AsideMenu";
import { Modal } from '../../../../components/Modal/Modal';
import { FiHelpCircle, FiPlusCircle, FiEdit, FiTrash2 } from 'react-icons/fi';
// import { formatDateForTextYearMonth } from "../../../../utils/formatDateForTextYearMonth";
// import { getExperienceTimeWhenHaveOnlyOneExperience } from "../../../../utils/getExperienceTimeWhenHaveOnlyOneExperience";
// import { getRoleWhenHaveOnlyOneExperience } from "../../../../utils/getRoleWhenHaveOnlyOneExperience";
// import { createResumeEducation } from "../../../../utils/createResumeEducation";
import { formatDateForMonthYear } from "../../../../utils/formatDateForMonthYear";
import { transformStateUF } from "../../../../utils/transformStateUF";
import { handleItemName } from "../../../../utils/handleItemName";
import { capitalizeFirstLetterInEachWord } from "../../../../utils/capitalizeFirstLetterInEachWord";
// import { ajustArrayToCVText } from "../../../../utils/ajustArrayToCVText";
import { DELETE_PROPERTY, DELETE_ITEM } from "../../../../services/User.services";
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from 'uuid';
import { handleOnClickSection } from "../../Curriculum";
import { ConfirmDelete } from "../../../../components/ConfirmDelete/ConfirmDelete";
import { tooltip } from "../../../../utils/tooltips";

export const CurriculumDefault = ({ 
        dataAsideMenu,
        dataEditModal,
        setDataEditModal,
        openModal,
        setOpenModal,
        handleShowEditButton,
        handleOpenModal,
        showEditButton,
        showAsideMenu,
        setShowAsideMenu,
        props,
    }) => {
    const { data, loading, refetch } = props;
    const [user, setUser] = useState({});
    const [deleteItem] = useMutation(DELETE_ITEM);
    const [deleteProperty] = useMutation(DELETE_PROPERTY);

    useEffect(() => {
        const fetchUserData = () => {
            if (loading || !data) { 
                return; 
            };
            setUser({ ...data });
        
        };
        fetchUserData();
    }, [data, loading]);

    const {
        name,
        surname,
        email,
        dddPhone,
        phone,
        resume,
        experience,
        language,
        addressCity,
        addressState,
        socialMedia,
        socialMediaType,
        volunteerJob,
        education,
        course,
    } = user;

    const deleteSection = async (event, section) => {
        event.stopPropagation();

        const functionToBeConfirmed = async () => {
            await deleteProperty({
                variables: { 
                    userId: user?._id,
                    section: section,
                }
            });
            refetch()
        };

        setDataEditModal(
            <ConfirmDelete 
                handleOpenModal={handleOpenModal}
                section={section}
                functionToBeConfirmed={functionToBeConfirmed}
            />
        );

        handleOpenModal();
    };

    const handleDeleteItem = async (event, section, index) => {
        event.stopPropagation();

        const handleItem = handleItemName({ section });
        const itemToBeDeleted = user[section][index][handleItem]

        const functionToBeConfirmed = async () => {
            await deleteItem({
                variables: { 
                    userId: user?._id,
                    _id: user[section][index]["_id"],
                    section: section,
                }
            });
            refetch()
        };

        setDataEditModal(
            <ConfirmDelete 
                handleOpenModal={handleOpenModal}
                itemToBeDeleted={itemToBeDeleted}
                section={section}
                functionToBeConfirmed={functionToBeConfirmed}
            />
        );

        handleOpenModal();
    };

    const editItem = (event, section, index) => {
        event.stopPropagation()
        
        let selectedData = ''
        let selectedDataID = ''

        if(index !== undefined) {
            selectedData = user[section][index];
            selectedDataID = user[section][index]["_id"];
        }

        handleOnClickSection({ 
            section: section, 
            props, 
            openModal: true, 
            edit: "edit", 
            selectedData: selectedData, 
            index: selectedDataID 
        });
    };

    // function calcularAlturaTotal() {
    //     const secoes = document.querySelectorAll('section');
    //     let alturaTotal = 0;
    //     for (let i = 0; i < secoes.length; i++) {
    //         if (!secoes[i].classList.contains('non-count')) {
    //         alturaTotal += secoes[i].offsetHeight;
    //         }
    //     }
    //     return alturaTotal;
    // }

    return (
        <>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleOpenModal={handleOpenModal}
                maxWidth="80%"
                maxHeight="90%"
                zIndex="100"
                children={dataEditModal}
            />

            {showAsideMenu ? createPortal(<AsideMenu 
                initicalDirection={'open-right'} 
                children={dataAsideMenu}
                showAsideMenu={showAsideMenu}
                setShowAsideMenu={setShowAsideMenu}
            />, document.body) : null}

            <S.CV id={'curriculum'}>
                {/* {loading ? <Shimmer style={{ margin: "auto" }} /> :  */}
                    <S.SectionCV className="sheet container">
                    {/* <S.SectionCV className="paper container margin-bottom-large"> */}

                        <S.SectionGeneralData
                            onClick={() => handleOnClickSection({ section: "generalData", props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {/* {showEditButton ? 
                                    <S.ContainerButtons>
                                        <S.EditButton 
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                // adicionar a função de edição aqui, mas precisará ser customizada para esse caso.
                                            }}
                                        >
                                            <FiEdit size={16} color="#000" />
                                        </S.EditButton>
                                    </S.ContainerButtons>
                                : null} */}
                                <S.TitleFullName>
                                    <S.GSCapitalizeFirstLetter>{name + ' ' + surname}</S.GSCapitalizeFirstLetter>
                                </S.TitleFullName>
                                <S.HRDivider />
                                <S.GeneralData>
                                    <div>
                                        {addressCity ? 
                                        <>
                                            <S.GSCapitalizeFirstLetter>
                                                endereço: {addressCity}/{transformStateUF(addressState)}
                                            </S.GSCapitalizeFirstLetter>
                                            <span> - </span>
                                        </>
                                        : null}
                                        <S.GSCapitalizeFirstLetter>
                                            cel: ({dddPhone}) {phone}
                                        </S.GSCapitalizeFirstLetter>
                                        <span> - </span>
                                        <S.GSCapitalizeFirstLetter>
                                            email: <S.GSTextTransformNone>{email}</S.GSTextTransformNone>
                                        </S.GSCapitalizeFirstLetter>
                                    </div>
                                    {socialMedia ? <div>
                                        <S.GSTextTransformNone>
                                            {socialMediaType}: {socialMedia}
                                        </S.GSTextTransformNone>
                                    </div> : null}
                                </S.GeneralData>
                            </div>
                        </S.SectionGeneralData>

                        {resume?.length > 0 ? <S.SectionResume
                            onClick={() => handleOnClickSection({ section: 'resume', props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {showEditButton ? 
                                    <S.ContainerButtons>

                                        <Tooltip title={tooltip.tips}>
                                            <S.TipsButton onClick={() => {
                                                setShowAsideMenu(true)
                                                handleOnClickSection({ section: 'resume', props })
                                            }}>
                                                <FiHelpCircle size={16} color="#000" />
                                            </S.TipsButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.edit}>
                                            <S.EditButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    editItem(event, "resume");
                                                }}
                                            >
                                                    <FiEdit size={16} color="#000" />
                                            </S.EditButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.deleteSection}>
                                            <S.DeleteButton onClick={(event) => deleteSection(event, "resume")}>
                                                <FiTrash2 size={16} color="#000" />
                                            </S.DeleteButton>
                                        </Tooltip>

                                    </S.ContainerButtons>
                                : null}
                                <S.CVSubtitle>Resumo Profissional</S.CVSubtitle>

                                <S.HRDivider />

                                <S.ResumeParagraph
                                    value={resume}
                                    disabled={true}
                                />

                            </div>
                        </S.SectionResume> : null}

                        {education?.length > 0 ? <S.SectionEducation
                            onClick={() => handleOnClickSection({ section: "education", props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {showEditButton ? 
                                    <S.ContainerButtons>

                                        <Tooltip title={tooltip.tips}>
                                            <S.TipsButton onClick={() => {
                                                setShowAsideMenu(true);
                                                handleOnClickSection({ section: "education", props });
                                            }}>
                                                <FiHelpCircle size={16} color="#000" />
                                            </S.TipsButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.add}>
                                            <S.AddButton onClick={(event) => {
                                                event.stopPropagation();
                                                handleOnClickSection({ section: "education", props, openModal: true, edit: "edit" });
                                            }}>
                                                <FiPlusCircle size={16} color="#000" />
                                            </S.AddButton> 
                                        </Tooltip>

                                        <Tooltip title={tooltip.deleteSection}>
                                            <S.DeleteButton onClick={(event) => deleteSection(event, "education")}>
                                                <FiTrash2 size={16} color="#000" />
                                            </S.DeleteButton>
                                        </Tooltip>

                                    </S.ContainerButtons>
                                : null}
                                <S.CVSubtitle>Formação Acadêmica</S.CVSubtitle>
                                <S.HRDivider />
                                <S.Education>
                                    <ul>
                                        {education?.map((education, index) => <S.Li key={uuidv4()}>
                                            <S.ContainerItem>

                                                <span>
                                                    {education?.level} em {education?.course} na {capitalizeFirstLetterInEachWord(education?.institution)} - <S.Italic>{education?.status} em {education?.year}</S.Italic>
                                                </span>

                                                {showEditButton ? 
                                                    <S.ContainerButtons>

                                                        <Tooltip title={tooltip.edit}>
                                                            <S.EditItemButton onClick={(event) => editItem(event, "education", index)}>
                                                                <FiEdit size={16} color="#000" />
                                                            </S.EditItemButton>
                                                        </Tooltip>

                                                        <Tooltip title={tooltip.deleteItem}>
                                                            <S.DeleteItemButton onClick={(event) => handleDeleteItem(event, "education", index)}>
                                                                <FiTrash2 size={16} color="#000" />
                                                            </S.DeleteItemButton>
                                                        </Tooltip>

                                                    </S.ContainerButtons> : null
                                                }

                                            </S.ContainerItem>

                                        </S.Li>)}

                                        
                                    </ul>
                                </S.Education>
                            </div>
                        </S.SectionEducation> : null}

                        {experience?.length > 0 ? <S.SectionExperience 
                            onClick={() => handleOnClickSection({ section: "experience", props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {showEditButton ? 
                                    <S.ContainerButtons>

                                        <Tooltip title={tooltip.edit}>
                                            <S.TipsButton onClick={() => {
                                                setShowAsideMenu(true)
                                                handleOnClickSection({ section: "experience", props })
                                            }}>
                                                <FiHelpCircle size={16} color="#000" />
                                            </S.TipsButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.add}>
                                            <S.AddButton 
                                                onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleOnClickSection({ section: "experience", props, openModal: true, edit: "edit" })
                                                    }
                                                }
                                            >
                                                <FiPlusCircle size={16} color="#000" />
                                            </S.AddButton>
                                        </Tooltip>
                                        
                                        <Tooltip title={tooltip.deleteSection}>
                                            <S.DeleteButton onClick={(event) => deleteSection(event, "experience")}>
                                                <FiTrash2 size={16} color="#000" />
                                            </S.DeleteButton>
                                        </Tooltip>

                                    </S.ContainerButtons>
                                : null}
                                <S.CVSubtitle>Experiência Profissional</S.CVSubtitle>
                                <S.HRDivider />
                                {experience.map((experience, index) => {
                                    return (
                                        <S.ProfessionalExperience>

                                            <S.ContainerItem>
                                                <span>
                                                    <S.Bold>{experience?.role}</S.Bold> na <S.GSCapitalizeFirstLetter>{experience?.company}</S.GSCapitalizeFirstLetter>
                                                    {" - "}
                                                    <S.Italic>
                                                        período: {formatDateForMonthYear(experience?.initialDate)} até {experience?.untilPresent ? "o momento" : formatDateForMonthYear(experience?.finalDate)} 
                                                        {/* ({formatDateForTextYearMonth(experience?.initialDate, experience?.finalDate)}) */}
                                                    </S.Italic>
                                                </span>

                                                {showEditButton ? 
                                                    <S.ContainerButtons>

                                                        <Tooltip title={tooltip.edit}>
                                                            <S.EditItemButton onClick={(event) => editItem(event, "experience", index)}>
                                                                <FiEdit size={16} color="#000" />
                                                            </S.EditItemButton>
                                                        </Tooltip>

                                                        <Tooltip title={tooltip.deleteItem}>
                                                            <S.DeleteItemButton onClick={(event) => handleDeleteItem(event, "experience", index)}>
                                                                <FiTrash2 size={16} color="#000" />
                                                            </S.DeleteItemButton>
                                                        </Tooltip>

                                                    </S.ContainerButtons> : null
                                                }

                                            </S.ContainerItem>
                                            
                                            <S.Description>
                                                <ul>
                                                    {/* {experience?.description.map(description => <S.Li key={uuidv4()}>{description}</S.Li>)} */}
                                                    {experience?.description.split(', ').map((description) => <S.Li key={uuidv4()}>{description}</S.Li>)}
                                                </ul>
                                            </S.Description>
                                        </S.ProfessionalExperience>
                                    )
                                })}
                            </div>
                        </S.SectionExperience> : null}

                        {volunteerJob?.length > 0 ? <S.SectionVolunteerJob 
                            onClick={() => handleOnClickSection({ section: "volunteerJob", props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {showEditButton ? 
                                    <S.ContainerButtons>
                                        
                                        <Tooltip title={tooltip.tips}>
                                            <S.TipsButton onClick={() => {
                                                setShowAsideMenu(true)
                                                handleOnClickSection({ section: 'volunteerJob', props })
                                            }}>
                                                <FiHelpCircle size={16} color="#000" />
                                            </S.TipsButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.add}>
                                            <S.AddButton
                                                onClick={(event) => {
                                                    event.stopPropagation(); 
                                                    handleOnClickSection({ section: "volunteerJob", props, openModal: true, edit: "edit" })
                                                }}
                                            >
                                                <FiPlusCircle size={16} color="#000" />
                                            </S.AddButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.deleteSection}>
                                            <S.DeleteButton onClick={(event) => deleteSection(event, "volunteerJob")}>
                                                <FiTrash2 size={16} color="#000" />
                                            </S.DeleteButton> 
                                        </Tooltip>

                                    </S.ContainerButtons>
                                : null}
                                <S.CVSubtitle>Trabalho Voluntário</S.CVSubtitle>
                                <S.HRDivider />
                                {volunteerJob.map((volunteerJob, index) => {
                                    return (
                                        <S.VolunteerJob>
                                            
                                            <S.ContainerItem>
                                            
                                                <span>
                                                    <S.Bold>{volunteerJob?.role}</S.Bold> na {capitalizeFirstLetterInEachWord(volunteerJob?.company)}
                                                    {" - "} 
                                                    <S.Italic> 
                                                        período: {formatDateForMonthYear(volunteerJob?.initialDate)} até {volunteerJob?.untilPresent ? "o momento" : formatDateForMonthYear(volunteerJob?.finalDate)} 
                                                        {/* ({formatDateForTextYearMonth(volunteerJob?.initialDate, volunteerJob?.finalDate)}) */}
                                                    </S.Italic>
                                                </span>

                                                {showEditButton ? 
                                                    <S.ContainerButtons>

                                                        <Tooltip title={tooltip.edit}>
                                                            <S.EditItemButton onClick={(event) => editItem(event, "volunteerJob", index)}>
                                                                <FiEdit size={16} color="#000" />
                                                            </S.EditItemButton>
                                                        </Tooltip>

                                                        <Tooltip title={tooltip.deleteItem}>
                                                            <S.DeleteItemButton onClick={(event) => handleDeleteItem(event, "volunteerJob", index)}>
                                                                <FiTrash2 size={16} color="#000" />
                                                            </S.DeleteItemButton>
                                                        </Tooltip>

                                                    </S.ContainerButtons> : null
                                                }

                                            </S.ContainerItem>
                                            
                                            
                                            <S.Description>
                                                <ul>
                                                    {volunteerJob?.description.split(', ').map((description) => <S.Li key={uuidv4()}>{description}</S.Li>)}
                                                </ul>
                                            </S.Description>
                                        </S.VolunteerJob>
                                    )
                                })}
                            </div>
                        </S.SectionVolunteerJob> : null}

                        {course?.length > 0 ? <S.SectionCourse 
                            onClick={() => handleOnClickSection({ section: "course", props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {showEditButton ? 
                                    <S.ContainerButtons>
                                        
                                        <Tooltip title={tooltip.tips}>
                                            <S.TipsButton onClick={() => {
                                                setShowAsideMenu(true)
                                                handleOnClickSection({ section: 'course', props })
                                            }}>
                                                <FiHelpCircle size={16} color="#000" />
                                            </S.TipsButton>
                                        </Tooltip>
                                        
                                        <Tooltip title={tooltip.add}>
                                            <S.AddButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleOnClickSection({ section: "course", props, openModal: true, edit: "edit" });
                                                }}
                                            >
                                                <FiPlusCircle size={16} color="#000" />
                                            </S.AddButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.deleteSection}>
                                            <S.DeleteButton onClick={(event) => deleteSection(event, "course")}>
                                                <FiTrash2 size={16} color="#000" />
                                            </S.DeleteButton>
                                        </Tooltip>

                                    </S.ContainerButtons>
                                : null}
                                <S.CVSubtitle>Curso Livre</S.CVSubtitle>
                                <S.HRDivider />
                                <ul>
                                    {course?.map((course, index) => {
                                        return (
                                            <S.Li key={uuidv4()}>
                                                <S.ContainerItem>
                                                    {course?.course} - {course?.institution} ({course?.year})

                                                    {showEditButton ? 
                                                        <S.ContainerButtons>

                                                            <Tooltip title={tooltip.edit}>
                                                                <S.EditItemButton onClick={(event) => editItem(event, "course", index)}>
                                                                    <FiEdit size={16} color="#000" />
                                                                </S.EditItemButton>
                                                            </Tooltip>

                                                            <Tooltip title={tooltip.deleteItem}>
                                                                <S.DeleteItemButton onClick={(event) => handleDeleteItem(event, "course", index)}>
                                                                    <FiTrash2 size={16} color="#000" />
                                                                </S.DeleteItemButton>
                                                            </Tooltip>

                                                        </S.ContainerButtons> : null
                                                    }

                                                </S.ContainerItem>
                                            </S.Li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </S.SectionCourse> : null}

                        {language?.length > 0 ? <S.SectionLanguage
                            onClick={() => handleOnClickSection({ section: "language", props })}
                            // onMouseEnter={() => handleShowEditButton(true)}
                            // onMouseLeave={() => handleShowEditButton(false)}
                        >
                            <div>
                                {showEditButton ? 
                                    <S.ContainerButtons>

                                        <Tooltip title={tooltip.tips}>
                                            <S.TipsButton onClick={() => {
                                                setShowAsideMenu(true)
                                                handleOnClickSection({ section: 'language', props })
                                            }}>
                                                <FiHelpCircle size={16} color="#000" />
                                            </S.TipsButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.add}>
                                            <S.AddButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleOnClickSection({ section: "language", props, openModal: true, edit: "edit" });
                                                }}
                                            >
                                                <FiPlusCircle size={16} color="#000" />
                                            </S.AddButton>
                                        </Tooltip>

                                        <Tooltip title={tooltip.deleteSection}>
                                            <S.DeleteButton onClick={(event) => deleteSection(event, "language")}>
                                                <FiTrash2 size={16} color="#000" />
                                            </S.DeleteButton> 
                                        </Tooltip>

                                    </S.ContainerButtons>
                                : null}
                                <S.CVSubtitle>Idiomas</S.CVSubtitle>
                                <S.HRDivider />
                                <ul>
                                    {language?.map((language, index) => <S.Li key={uuidv4()}>
                                        <S.ContainerItem>

                                            {language?.language}: {language?.level}

                                            {showEditButton ? 
                                                <S.ContainerButtons>

                                                    <Tooltip title={tooltip.edit}>
                                                        <S.EditItemButton onClick={(event) => editItem(event, "language", index)}>
                                                            <FiEdit size={16} color="#000" />
                                                        </S.EditItemButton>

                                                    </Tooltip>

                                                    <Tooltip title={tooltip.deleteItem}>
                                                        <S.DeleteItemButton onClick={(event) => handleDeleteItem(event, "language", index)}>
                                                            <FiTrash2 size={16} color="#000" />
                                                        </S.DeleteItemButton>
                                                    </Tooltip>

                                                </S.ContainerButtons> : null
                                            }

                                        </S.ContainerItem>

                                    </S.Li>)}
                                </ul>
                            </div>
                        </S.SectionLanguage> : null}

                    </S.SectionCV>
                {/* } */}
            </S.CV>
        </>
    )
}
