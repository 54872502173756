import React from "react";
import * as S from './SpinnerRoller.style';

export const SpinnerRoller = ({ option }) => {
  
  // const type = option === "modal" : null;
  // color = "e342f538"
  // color={"#4baef5ff"}


  return (
    <>
      <S.Loading option={option} />
    </>
  );
}
