import { Grid, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { positions } from "../../staticData/positions";
import { company } from "../../staticData/company";
// import { roleDescription } from "../../staticData/roleDescription";
// import { achievementsAndHighlights } from '../../staticData/achievementsAndHighlights';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ErrorMessage } from "../../styles/globalStyles";
import { currentYearMonth } from "../../utils/currentYearMonth";

export const ProfessionalExperienceForm = ({ valueDescription, register, errors, control, checked, handleChange, watch }) => {
  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
          
          <Stack direction="column">
            <TextField 
              id="role"
              label="Cargo"
              variant="outlined"
              margin="normal"
              required={true}
              sx={{ mb: 1, boxShadow: 1 }}
              {...register("role", { required: 'true' })}
            />
            {errors.role ? <ErrorMessage variant={"caption"}>Por favor, preencha a Cargo</ErrorMessage> : null}
          </Stack>
          
          {/* <Stack direction="column">
            <Controller 
              name="role"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, data) => onChange(data)}
                  id="role"
                  options={positions.map((option) => option.title)}
                  sx={{ mb: 1, boxShadow: 1 }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      {...field}
                      inputRef={ref}
                      label="Cargo" 
                      {...register("role", { required: 'true' })} 
                    />}
                />
              )}
            />    
            {errors.role ? <ErrorMessage variant={"caption"}>Por favor, preencha o cargo</ErrorMessage> : null}
          </Stack> */}

        </Grid>

        <Grid item xs={12} sm={6}>

          <Stack direction="column">
            <TextField 
              id="company" 
              label="Instituição" 
              variant="outlined" 
              margin="normal"
              required={true}
              sx={{ mb: 1, boxShadow: 1 }}
              {...register("company", { required: 'true' })}
            />
            {errors.company ? <ErrorMessage variant={"caption"}>Por favor, preencha a Instituição</ErrorMessage> : null}
          </Stack>
          
          {/* <Stack direction="column">
            <Controller
              name="company"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, data) => onChange(data)}  
                  id="company"  
                  sx={{ mb: 1, boxShadow: 1 }}
                  options={company.map((option) => option.title)}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      {...field}
                      inputRef={ref}
                      label="Instituição" 
                      {...register("company", { required: 'true' })} 
                    />
                  }
                />
              )}
            />
            {errors.company ? <ErrorMessage variant={"caption"}>Por favor, preencha com o nome da instituição de ensino</ErrorMessage> : null}
          </Stack> */}

        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 1 }}>
        
        <Grid item xs={12} sm={6}>
          <Stack direction="column">
            <TextField
              id="initialDate"
              label="Data Inicial"
              type="month"
              required={true}
              sx={{ mb: 1, boxShadow: 1 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  min: "1900-01",
                  max: currentYearMonth
                }
              }}
              {...register("initialDate", { required: 'true' })}
            />
            {errors.initialDate ? <ErrorMessage variant={"caption"}>Por favor, preencha a data inicial</ErrorMessage> : null}
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack direction="column">
            <TextField
              id="finalDate"
              label="Data Final"
              type="month"
              sx={{ mb: 1, boxShadow: 1 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  min: "1900-01",
                  max: currentYearMonth
                }
              }}
              disabled={watch("untilPresent")}
              {...register("finalDate", { required: watch("untilPresent") === true ? false : true })}
            />
            {watch("untilPresent") ? null :
              errors.finalDate ? <ErrorMessage variant={"caption"}>Por favor, preencha a data final</ErrorMessage> : null
            }
          </Stack>
        </Grid>

      </Grid>

      <Controller
        name="untilPresent"
        control={control}
        defaultValue={false}
        {...register("untilPresent")}
        render={({ field: { value = checked, onChange = (e) => handleChange(e), ...field } }) => (
          <FormControlLabel
            label="Eu estou trabalhando nesse cargo no momento"
            control={
              <Checkbox
                {...field}
                checked={value}
                onChange={onChange}
              />
            }
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field: { ref, value = valueDescription, onChange, ...field } }) => (
          <TextField
            {...field}
            id="description"
            label="Descrição"
            // inputRef={ref}
            // value={valueDescription}
            value={watch("suggestion") === false ? '' : watch("suggestion")}
            // onChange={(_, data) => onChange(data)}
            variant="outlined"
            multiline
            minRows={6}
            maxRows={11}
            placeholder="Escreva suas funções e responsabilidades"
            fullWidth
            sx={{ mt: 2, boxShadow: 1 }}
            {...register("description")}
          />
        )}
      />

      {/* <TextField
        id="conquest"
        label="Conquistas e Destaques"
        variant="outlined"
        multiline
        minRows={6}
        maxRows={20}
        placeholder="Escreva suas conquistas e destaques"
        margin="normal"
        fullWidth
        sx={{ mt: 2, boxShadow: 1 }}
        value={valueTextAreaConquest}
        // onChange={handleChangeConquest}
        {...register("conquest")}
      /> */}
    </>
  )
};
