import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;

    font-family: inherit;   
    font-size: 1em;
`;

export const NavBar = styled.nav`
    padding: 0px 40px;
    width: 100%;
`

export const ContainerCards = styled.div`
    margin: 40px 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    align-content: center;
    gap: 50px;

    @media (max-width: 480px) {
        flex-direction: column;
    }
`
