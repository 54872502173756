import React from 'react';
import { FiMail } from 'react-icons/fi';
import { Typography } from "@mui/material";
import * as S from './FooterSecondary.style';

export const FooterSecondary = () => {
  return(
    <S.Footer id="footer">

      <Typography paragraph={true} sx={{ mt: 2 }}>

        <S.ContainerEmail>
          Caso tenha alguma dúvida ou sugestão envie um e-mail para: 
          
          <S.EmailLink href="mailto:contato@upcarreiras.com.br">
            <FiMail size={16} />
            contato@upcarreiras.com.br
          </S.EmailLink>

        </S.ContainerEmail>

      </Typography>
      
      <Typography paragraph={true} sx={{ mt: 3 }}>UP Carreiras © 2023. Todos os direitos reservados.</Typography>

    </S.Footer>
  )
};