import styled from 'styled-components';

export const Container = styled.div`
    font-family: monospace;
    font-size: 20px;
    text-align: center;

    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        color: rgb(169,169,169);
        margin-bottom: 30px;
    }
`
