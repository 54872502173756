import styled from 'styled-components';

export const Footer = styled.footer`  
  background-color: rgb(3, 4, 94);
  color: white;
  bottom: 0;
  line-height: 30px;
  padding: 20px;
  text-align: center;
  /* width: 100%; */
`

export const Title = styled.h1`
   margin: 40px 0px 20px 0px;
`

export const Paragraph = styled.p`
  margin-top: 10px!important;
  margin-bottom: 0px!important;
  font-size: 14px;
`

export const ContainerEmail = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;

  svg {
    margin: 0px 3px 0px 5px;
  }
`

export const EmailLink = styled.a`
  color: inherit;
  font-size: 16px;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 250px;

  @media screen and (max-width: 1500px) {
    margin: 0px 80px;
  };

  @media screen and (max-width: 1100px) {
    margin: 0px 80px;
    flex-wrap: wrap;
  };

  @media screen and (max-width: 800px) {
    margin: 0px 0px;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
`

export const CustomSection = styled.section`
  width: 300px;
  padding: 20px;

  @media screen and (max-width: 1500px) {
    /* margin: 0px 150px; */
    /* width: 100px;  */
  }
`

export const SectionAbout = styled(CustomSection)``
export const SectionContact = styled(CustomSection)``

export const SectionSocialMidia = styled(CustomSection)`
  svg {
    border-color: white;
    margin-right: 10px;

    fill: white;

    height: 24px;
    width: 24px;
  }
`

export const SectionPolicy = styled(CustomSection)`
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 20px;
    list-style-type: none;
  }

  a {
    color: white;
    text-decoration: none;
  }
`

export const SectionCopyright = styled(CustomSection)`
  display: flex;
  flex-direction: column;
  line-height: 20px;

  width: 100%;
  margin: 50px 0px 20px 0px;
`