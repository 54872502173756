import { createTheme } from '@mui/material/styles';
import { createGlobalStyle } from "styled-components";
import { Typography } from '@mui/material';
import styled from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, Arial, Helvetica, sans-serif;
  }
  
  @media print {
    font-size: 16px;

    #main-menu, #container-button, #footer, #aside-menu, #score {
      display: none;
    }
    #curriculum {
      padding: 0px;

      width: 210mm;
      height: 296mm;
      /* padding: 15mm; */

    }
  }

  body {
    background-color: #fff;
    /* overflow: hidden; */
  }
  
  a {
    text-decoration: none;
    color: white;
  }

  button {
    cursor: pointer;
  }

  ul {
    line-height: 25px;
  }

  p, span, li, div, table, td, th {
    :first-letter {
      text-transform: capitalize;
    }
  }
`;

export const CapitalizeFirstLetter = styled.span`
  text-transform: capitalize;
`

export const TextTransformNone = styled.span`
  text-transform: none;
`

export const UpperCase = styled.span`
  text-transform: uppercase;
`

export const LowerCase = styled.span`
  text-transform: lowercase;
`

export const FlexDirectionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexDirectionColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GridContainerTemplateColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(${ ({ columns }) => columns}, 1fr);
`;

export const ErrorMessage = styled(Typography)`
  color: red;
  font-size: 1rem;
`;

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: '2.5rem',
    },
  },
});

export const SectionA4 = styled.section`
  /* width: 50.69em;
  height: 73.06em;
  padding:  14.29em; */
  
  width: 210mm;
  height: 296mm;
  /* padding: 15mm; */

  font-size: 16px;

  @media screen and (max-width: 924px) {
    width: 95%;
    height: 296mm;
    /* padding: 3em; */

    @media (max-width: 885px) {
      font-size: 1.7vw;
    }

    @media (max-width: 665px) {
      font-size: 2.5vw;
    }

  }
`
