import { forwardRef } from "react";
import { Snackbar as SnackbarComponent } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Snackbar = ({ openSnackbar, setOpenSnackbar, vertical = "top", horizontal = "right", text }) => {
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div>
      <SnackbarComponent
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: '100%' }}>{text}</Alert>
      </SnackbarComponent>
    </div>
  );
} 