import styled from 'styled-components';

export const Carousel = styled.div`
    margin: 20px;

    .slick-next:before, .slick-prev:before {
        color: #fff;
    }

    section {
        padding: 20px;
    }
`

export const Frame = styled.div`
    background-color: rgb(242, 242, 242);
    text-align: center;
`

export const H3 = styled.h3`
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
`
