import styled from 'styled-components';

export const AvatarContainer = styled.div`
  background-color: rgb(242, 242, 242);
  background-size: cover;
  border-radius: 50%;
  height: 100px;
  width: 100px;
`

export const AvatarImage = styled.img`
  border-radius: 50%;
  background-size: cover;
  height: 100%;
  width: 100%;
`
