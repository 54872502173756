import React from 'react';
import { FiMail } from 'react-icons/fi';
import { ReactComponent as Facebook } from '../../../assets/icons/socialMedia/facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/socialMedia/instagram.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/socialMedia/linkedin.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/socialMedia/youtube.svg';
import { ReactComponent as WhatsApp } from '../../../assets/icons/socialMedia/whatsapp.svg';
import * as S from './Footer.style';

export const Footer = ({ id = "footer" }) => {
  return(
    <S.Footer id={id}>

      <S.Container>

        <S.SectionAbout>

          <S.Title>Sobre</S.Title>

          <p style={{ textAlign: "left" }}>Somos uma startup especializada em carreira com soluções personalizadas para otimizar seu currículo e aprimorar seu perfil profissional no LinkedIn.</p>

        </S.SectionAbout>

        <S.SectionContact>

          <S.Title>Contato</S.Title>

          <S.Paragraph>

            <S.ContainerEmail>
              {/* <p>Caso tenha alguma dúvida ou sugestão envie um e-mail para: </p> */}
              
              <S.EmailLink href="mailto:contato@upcarreiras.com.br">
                <FiMail size={16} />
                contato@upcarreiras.com.br
              </S.EmailLink>

            </S.ContainerEmail>

          </S.Paragraph>

        </S.SectionContact>

        <S.SectionSocialMidia>

          <S.Title>Redes Sociais</S.Title>
          
          <a target="_blank" href=""><Linkedin /></a>
          {/* <a target="_blank" href=""><WhatsApp /></a> */}
          <a target="_blank" href="https://www.instagram.com/up.carreiras"><Instagram /></a>
          {/* <a target="_blank" href=""><Youtube /></a> */}
          {/* <a target="_blank" href=""><Facebook /></a> */}

        </S.SectionSocialMidia>

        <S.SectionPolicy>

          <S.Title>Políticas</S.Title>

          <ul>
            <li><a target="_blank" href="">Termos de Uso</a></li>
            <li><a target="_blank" href="">Política de Cookies</a></li>
            <li><a target="_blank" href="">Política de Privacidade</a></li>
          </ul>

        </S.SectionPolicy>

      </S.Container>

      <S.SectionCopyright>
        <S.Paragraph>CNPJ: 44.065.912/0001-17 - Yamashiro Tecnologia</S.Paragraph>
        <S.Paragraph>© 2023 UP Carreiras. Todos os direitos reservados.</S.Paragraph>
      </S.SectionCopyright>

    </S.Footer>
  )
};
