import React, { useCallback } from 'react';
import { Button } from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import { isSmallScreen } from '../../../utils/isSmallScreen';
import * as S from './ToolButtons.style';

export const ToolButtons = ({ activePainel, flexDirection = "column", buttonsStyle = "navBar", setShowHamburguerMenu }) => {
  const { signOff } = useAuth();
  const navigate = useNavigate();

  const handleSignOff = useCallback(() => {
    signOff();
    navigate("/");
  }, [navigate, signOff]);

  const handleCloseMenu = () => {
    activePainel();
    setShowHamburguerMenu(prevState => !prevState);
  }

  return (
    <>
      <S.Container id="container-button" flexDirection={flexDirection} buttonsStyle={buttonsStyle}>
        <Button variant="contained" color="primary" onClick={() => isSmallScreen() ? handleCloseMenu() : activePainel()}>Painel</Button>
        <Button variant="contained" color="primary" onClick={() => window.print()}>Imprimir</Button>
        <Button variant="contained" color="error" onClick={() => navigate("/premium")}>Premium</Button>
        <Button variant="contained" color="primary" onClick={() => handleSignOff()}>Sair</Button>
      </S.Container> 
    </>
  );
};
