import { Grid } from '@mui/material';
import { Navbar } from "../../components/NavBar/Navbar";
import { MainSection } from "../../components/MainSection/MainSection";
import { AccordionComponent } from "../../components/Accordion/Accordion";
import { CardWithIconAndText } from "../../components/Cards/CardWithIconAndText/CardWithIconAndText";
import { GridContainerTemplateColumns } from "../../styles/globalStyles";
import { Testimonials } from "../../components/Testimonials/Testimonials";
import { Footer } from "../../components/Footer/Footer/Footer";
import { Image } from "../../atoms/Image/Image";
import gifExample from "../../assets/gifs/video-demo.gif";
import { Carousel } from "../../components/Carousel/Carousel";
import { cardsContent, listContent, questionsAndAnswers, testimonials } from "./dataHomePage";

import * as S from "./HomePage.style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const HomePage = () => {  
  return (
    <S.Container>

      <Navbar />

      <MainSection />

      {/* <S.ContainerFrame id={"demo"}>

        <S.ContainerTitle>
          <S.Title>Como Funciona</S.Title>
        </S.ContainerTitle>

        <S.ContainerShortText>
          <S.Text>
            Produto inovador e eficiente que vai revolucionar o seu dia a dia, simplificando tarefas e otimizando seu tempo. Com design moderno e funcional, é perfeito para quem busca praticidade sem abrir mão da qualidade. Experimente agora mesmo e veja a diferença!
          </S.Text>
        </S.ContainerShortText>

        <S.ContainerContent>
          <S.ContainerDemo>
            <Image 
              alt={""}
              src={gifExample}
              width={"280px"}
              />
          </S.ContainerDemo>

          <S.ContainerHowWorksText>
            <ul>{listContent.map((item) => <li>{item}</li>)}</ul>
          </S.ContainerHowWorksText>
        </S.ContainerContent>
        
      </S.ContainerFrame> */}

      <S.ContainerFrame id={"features"}>
        
        <S.ContainerTitle>
          <S.Title>Como funciona ?</S.Title>
        </S.ContainerTitle>
        
        <S.ContainerShortText>
          <S.Text>
            Nosso processo de análise consistem em:
          </S.Text>
        </S.ContainerShortText>

        <S.ContainerContent>
          <S.ContainerFeatures>
              <Grid container rowSpacing={1} columnSpacing={25}>
                {cardsContent.map(({ title, text, icon }) => 
                  <Grid item xs={12} sm={6} md={4}>
                    <CardWithIconAndText
                      // iconWidth="35px"
                      // iconHeight="35px"
                      title={title}
                      text={text}
                      height="200px"
                      width="230px"
                      icon={icon}
                    />
                  </Grid>
                )}
              </Grid>

          </S.ContainerFeatures>
        </S.ContainerContent>

      </S.ContainerFrame>

      {/* <S.ContainerFrame id={"testimonials"}>

        <S.ContainerTitle>
          <S.Title>Depoimentos</S.Title>
        </S.ContainerTitle>

        <S.ContainerShortText>
          <S.Text>
            Veja o que nossos clientes têm a dizer sobre sua experiência conosco!
          </S.Text>
        </S.ContainerShortText>

        <S.ContainerContent>

          <S.ContainerTestimonials>
            
            <div>

              <Carousel
                slidesToShow={3}
                slidesToScroll={3}
                autoplay={false}
                autoplaySpeed={4000}
                width="1800px"
                className="custom-carousel"
              >
                {testimonials.map(({ image, text, role, author }) => 
                  <section>
                    <Testimonials
                      image={image}
                      text={text}
                      author={author}
                      role={role}
                    />
                  </section>
                )}
              </Carousel>

            </div>

          </S.ContainerTestimonials>

        </S.ContainerContent>

      </S.ContainerFrame> */}

      {/* <S.ContainerFrame id={"faq"}>
        
        <S.ContainerTitle>
          <S.Title>Perguntas Frequentes</S.Title>
        </S.ContainerTitle>

        <S.ContainerShortText>
          <S.Text>
            Abaixo as principais dúvidas de nossos clientes, caso você tenha alguma dúvida que não esteja abaixo entre em contato por nosso e-mail.
          </S.Text>
        </S.ContainerShortText>
        
        <S.ContainerContentAccordion>

          <S.ContainerAccordion>
            
            <AccordionComponent
              questionsAndAnswers={questionsAndAnswers}
              expandeFirstQuestion={true}
            />

          </S.ContainerAccordion>

        </S.ContainerContentAccordion>

      </S.ContainerFrame> */}

      <Footer id={"contact"} />

    </S.Container>
  );
};
