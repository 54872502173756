export const score = ({experience, education, course}) => {

  let maxScore = 100
  
  const experienceIsEmpty = experience?.length === 0 ? true : experience === undefined ? true : false;
  const educationIsEmpty = education?.length === 0 ? true : education === undefined ? true : false;
  const courseIsEmpty = course?.length === 0 ? true : course === undefined ? true : false;

  if(experienceIsEmpty && educationIsEmpty && courseIsEmpty) return maxScore - 60;
  if(experienceIsEmpty && educationIsEmpty) return maxScore - 40;
  if(experienceIsEmpty && courseIsEmpty) return maxScore - 40;
  if(educationIsEmpty && courseIsEmpty) return maxScore - 40;
  if(experienceIsEmpty) return maxScore - 20;
  if(educationIsEmpty) return maxScore - 20;
  if(courseIsEmpty) return maxScore - 20;
  else return maxScore;

};