import { FiCheck, FiMessageCircle, FiSearch, FiEdit, FiLinkedin, FiFilter } from "react-icons/fi";
import imageExample1 from "../../assets/images/peoples/foto-fer.jpg";
import imageExample2 from "../../assets/images/peoples/foto-d.jpeg";
import imageExample3 from "../../assets/images/peoples/foto-j.jpeg";

export const cardsContent = [
  {
    "title": "Análise Prévia",
    "text": "Analisamos seu currículo e LinkedIn para melhor compreender sua experiência e habilidades",
    "icon": <FiSearch size={16} color="#fff" />
  },
  {
    "title": "Entrevista",
    "text": "Entrevistamos você para obter mais detalhes e esclarecer dúvidas",
    "icon": <FiMessageCircle size={16} color="#fff" />
  },
  {
    "title": "Otimização de Currículo",
    "text": "Com todas as informações coletadas resumimos tudo em um currículo otimizado de acordo com o seu objetivo",
    "icon": <FiEdit size={16} color="#fff" />
  },
  {
    "title": "Otimização de Linkedin",
    "text": "Seu Linkedin é analisado e criamos um relatório de tudo o que deve ser alterado para gerar o melhor resultado",
    "icon": <FiLinkedin size={16} color="#fff" />
  },
  {
    "title": "Revisão do Trabalho",
    "text": "Nosso time faz um revisão interna para garantir que você terá o melhor resultado",
    "icon": <FiFilter size={16} color="#fff" />
  },
  {
    "title": "Reunião de Entrega",
    "text": "Após a conclusão do trabalho, realizamos uma reunião para apresentar o resultado e esclarecer quaisquer dúvidas que você possa ter",
    "icon": <FiCheck size={16} color="#fff" />
  },
]

export const listContent = [
  "Ferramenta de criação gratuita",
  "Criação de currículo fácil e rápida",
  "Sabia tudo sobre cada seção do seu currículo",
  "Preencha seu currículo com o auxílio de nossa inteligência que te ajuda a escolher os melhores termos",
  "Dicas customizadas de acordo com a sua profissão e momento profissional"
]

export const questionsAndAnswers = [
  {
    question: "Como funciona ?",
    answer: ""
  },
  {
    question: "Como minhas informações serão usadas ?",
    answer: ""
  },
  {
    question: "A ferramenta é gratuita ?",
    answer: ""
  },
  {
    question: "Por quê devo usar a ferramenta ?",
    answer: "É a maneira mais fácil e rápida de criar seu currículo, nossa ferramenta facilita o trabalho de escrever um currículo bem escrito e estruturado com as melhores informações de acordo com o seu cargo, priorizando a sua experiência."
  },
  {
    question: "Desistência ou arrependimento",
    answer: "Caso você adquira o serviço por engano você pode cancelar o serviço em até 7 dias "
  },
  {
    question: "Processo de qualidade e garantia",
    answer: "Nós queremos que você tenha a melhor experiência possível e que nosso serviço possa ser útil para você, por isso temos um controle de qualidade que analisa o seu caso previamente e caso possamos agregar valor para você seguimos com o trabalho."
  },
]

export const testimonials = [
  {
    image: imageExample1,
    text: "Sinto que estou finalmente conseguindo alcançar meus objetivos profissionais",
    author: "Fernanda Cukier",
    role: "Compliance",
  },
  {
    image: imageExample2,
    text: "Gostei muito da facilidade e rapidez que consegui criar meu currículo",
    author: "David Araújo Souza",
    role: "Engenheiro de Software",
  },
  {
    image: imageExample3,
    text: "Estou mais preparado para os processos seletivos",
    author: "Julio Cesar Rios",
    role: "Engenheiro Civil",
  },
  // {
  //   image: imageExample1,
  //   text: "texto 4",
  //   author: "abc",
  //   role: "",
  // },
  // {
  //   image: imageExample2,
  //   text: "texto 5",
  //   author: "abc",
  //   role: "",
  // },
  // {
  //   image: imageExample3,
  //   text: "texto 6",
  //   author: "abc",
  //   role: "",
  // },
  // {
  //   image: imageExample1,
  //   text: "texto 7",
  //   author: "abc",
  //   role: "",
  // },
  // {
  //   image: imageExample2,
  //   text: "texto 8",
  //   author: "abc",
  //   role: "",
  // },
  // {
  //   image: imageExample3,
  //   text: "texto 9",
  //   author: "abc",
  //   role: "",
  // },
];
