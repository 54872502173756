import React, { useState } from 'react';
import { Aside, Section, Wrapper } from './AsideMenu.styles';
import { FiX } from "react-icons/fi";
import { isSmallScreen } from "../../../utils/isSmallScreen";

export const AsideMenu = ({ children, initicalDirection, showAsideMenu, setShowAsideMenu }) => {
  const initialState = showAsideMenu ? initicalDirection : "close";
  const [direction, setDirection] = useState(initialState);

  // const handleCloseAsideMenu = () => { setDirection("right") }

  return(
    <Aside id={'aside-menu'} direction={direction}>
  
      <Wrapper>
        <FiX size={24} color="black" onClick={() => setShowAsideMenu(false)}/>
      </Wrapper>
      
      <Section>
        {children}
      </Section>

    </Aside>
  )
};
