import styled from 'styled-components';

export const Loading = styled.div`
    height: ${({option}) => option === "modal" ? "50px" : "25px"};
    width: ${({option}) => option === "modal" ? "50px" : "25px"};
    border: ${({option}) => option === "modal" ? `5px solid #4baef5ff` : `3px solid #e342f538`};
    border-top-color: white;
    border-radius: 50%;
    margin: auto auto;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`
