import styled from 'styled-components';

export const ContainerHamburguer = styled.div`
  margin: 0px 20px;
  display: none;
  /* width: 100%; */

  @media screen and (max-width: 1150px) {
    display: ${({ direction }) => direction === 'left' ? 'block' : 'flex'};
    justify-content: flex-end;
  }

`

export const Button = styled.button`
  padding: 5px;
  background: transparent;
  border: transparent;
`