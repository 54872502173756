import React from 'react';
import { ParagraphTag } from './Paragraph.style';

export const Paragraph = ({ id, className, children, margin, fontSize = "14px", textAlign }) => {
  return (
    <ParagraphTag 
      id={id} 
      className={className} 
      style={
        { 
          textAlign: textAlign,
          fontSize: fontSize,
          margin: margin,
        }
      }
    >
      {children}
    </ParagraphTag>
  );
};
