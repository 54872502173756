import { Typography } from "@mui/material";
import styled from 'styled-components';

export const Title = styled(Typography)`
  text-align: center;
`

export const Paragraph = styled(Typography)`
  margin: 30px 0 30px 0!important;
  text-align: center;
  max-width: 450px;
`

export const Bold = styled.span`
  font-weight: bold;
`
