import { gql } from "@apollo/client";

export const AUTH_USER = gql`
  mutation authUser($email: String, $password: String) {
    authUser(email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;
