import styled from 'styled-components';
import { H2 } from '../../../atoms/Headling/H2';
import { Paragraph } from '../../../atoms/Paragraph/Paragraph';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    border-radius: 5px;

    font-size: 18px;
    font-family: sans-serif;
    height: 150px;
    margin: 20px auto;
    width: 300px;
    padding: 20px;
`;

export const Title = styled(H2)`
    font-size: 18px;

    background-color: rgb(3,4,94);
    border-radius: 5px;
    color: white;
    padding: 10px;
    width: 300px;
`

export const Text = styled(Paragraph)`
    font-size: 12px;
`

// export const Header = styled.header`
//     align-items: center;
//     display: flex;
//     flex-direction: row;
//     height: 25px;
//     justify-content: space-between;
//     margin-bottom: 20px;
//     margin-top: 10px;
// `;
