import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;  
  gap: 20px;
  justify-content: center;
  margin: 20px;
`

export const ContainerScore = styled.div`
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  margin-top: 10px;

  font-size: 22px;
  position: absolute;
  top: 10px;
  right: 75px;

  border-radius: 50%;
  height: 80px;
  width: 80px;

  @media screen and (max-width: 768px) {
    right: 30px; 
    height: 60px;
    width: 65px;
  }
`