import React, { useEffect, useState } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { useNavigate } from "react-router";
import * as S from './NavBarButtons.style';

export const NavBarButtons = ({ setShowHamburguerMenu }) => {
  const navigate = useNavigate();

  return(
    <>
      <S.ContainerButtons id="container-buttons">

        <S.Ul onClick={() => setShowHamburguerMenu(prevState => !prevState)}>
          <S.Li><a href="#">Início</a></S.Li>
          {/* <S.Li><a href="#demo">Demo</a></S.Li> */}
          <S.Li><a href="#features">Funcionalidades</a></S.Li>
          {/* <S.Li><a href="#testimonials">Depoimentos</a></S.Li> */}
          {/* <S.Li><a href="#faq">FAQ</a></S.Li> */}
          <S.Li><a href="#contact">Contato</a></S.Li>   
          {/* <S.Li>
            <FiLogIn size={16} color="#fff"/>
            <S.Button onClick={() => navigate("/login")}>Entrar</S.Button>
          </S.Li>
          <S.Li>
              <S.HighlightedButton onClick={() => navigate("/new-user-form")}>Criar conta</S.HighlightedButton>
          </S.Li> */}
        </S.Ul>

      </S.ContainerButtons>
    </>
  )
};
