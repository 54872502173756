import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Box, Button, Container, CssBaseline, Link, Stack, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SpinnerRoller } from '../../components/Loading/SpinnerRoller/SpinnerRoller';
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../services/ForgetPassword";
import { ErrorMessage } from '../../styles/globalStyles';

const theme = createTheme();

export const ForgetPassword = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [resetPassword, { loading, data: response }] = useMutation(RESET_PASSWORD);
  const [error , setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if(response) {
      setMessage("E-mail de alteração de senha enviado. Por favor, verifique sua caixa de entrada.")
    }
  }, [response]);

  const onSubmit = (async (data) => {
    try {
      await resetPassword({ variables: { email: data.email } });
    } catch (error) {
      setError('E-mail não registrado na base de dados');
    }
  });

  return (    
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Typography component="h1" variant="h5">Recuperar senha</Typography>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ my: 5, width: '90%' }}>

            <Typography paragraph={true}>Insira abaixo o endereço de e-mail que você usa para fazer login na plataforma.</Typography>
            
            <Stack direction="column">
              <TextField
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }} 
                {...register("email", { required: 'true' })}
              />
              {errors.email ? <ErrorMessage variant={"caption"}>Por favor, preencha o email</ErrorMessage> : null}
            </Stack>

            <Stack direction="column" sx={{ my: 1 }}>
              <Link href="/login" variant="body2">Voltar para o login</Link>
            </Stack>

            {error ? error : null}
            {message ? message : null}
              
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {loading === true ? <SpinnerRoller /> : "Recuperar senha" }
            </Button>

          </Box>
        </Box>
      
      </Container>
    </ThemeProvider>
  );
}