import styled from 'styled-components';

export const Container = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ContainerAvatar = styled.div`
  position: relative;
  top: 30px;
`

export const ContainerTestimonialText = styled.div`
  margin-top: 20px;
  text-align: center;
  justify-content: center;
`

export const TestimonialText = styled.span`
  font-size: 13px;
  font-style: italic;
`

export const Author = styled.div`
  margin: 20px 0px 0px 0px;
`
