import styled from 'styled-components';
import { H2 } from '../../../atoms/Headling/H2';

export const Container = styled.div`
    background-color: rgb(30, 167, 253);
    /* border-radius: 10px; */
    box-shadow: 2px 2px 1px 0 rgb(26 57 144 / 25%);
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    color: white;
    font-size: 18px;

    font-family: sans-serif;
    height: 150px;
    margin: 20px auto;
    width: 300px;
    padding: 20px;
`;

export const Title = styled(H2)`
    font-size: 18px;    
`

