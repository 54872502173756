import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { router } from './Routes';
import { theme } from './styles/globalStyles';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
