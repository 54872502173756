import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { Button, Container, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { VolunteerJobForm } from "./VolunteerJobForm/VolunteerJobForm";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_VOLUNTEERJOB } from "../../services/User.services";
import { SpinnerRoller } from "../../components/Loading/SpinnerRoller/SpinnerRoller";

export const VolunteerJob = ({ handleOpenModal, selectedData, index, context }) => {  
  let startDateMonthString = null;

  if(selectedData?.initialDate != null) {
    const date = new Date(parseInt(selectedData.initialDate));
    startDateMonthString = date.toISOString().slice(0, 7);
  }

  let finalDateMonthString = null;

  if(selectedData?.finalDate != null) {
    const date = new Date(parseInt(selectedData.finalDate));
    finalDateMonthString = date.toISOString().slice(0, 7);
  }

  const newSelectedData = {
    ...selectedData,
    initialDate: startDateMonthString,
    finalDate: finalDateMonthString,
  }

  const { register, control, handleSubmit, watch, formState: { errors } } = useForm({ defaultValues: newSelectedData });
  const [updateUser, { loading }] = useMutation(UPDATE_USER_VOLUNTEERJOB);
  const [spinner, setSpinner] = useState(false);
  
  const { data, refetch } = context;
  const { _id } = data;
  
  const [checked, setChecked] = useState(false);

  useEffect(() => { setSpinner(loading) }, [loading]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmit = ((data) => {

    const updateVolunteerJob = async () => {
      await updateUser({
        variables: {
          userId: _id,
          _id: data._id,
          role: data.role,
          company: data.company,
          initialDate: data.initialDate,
          finalDate: data.untilPresent ? "" : data.finalDate,
          untilPresent: data.untilPresent,
          description: data.description,
        }
      });      
      refetch()
      setSpinner(loading)
      handleOpenModal();
    }
    updateVolunteerJob()
  });
    

  return (
    <Container maxWidth="md">
      {spinner ? <SpinnerRoller option={"modal"} /> : 
        <>

          <Typography variant="h1" align="center" sx={{ margin: 2 }}>Trabalho Voluntário</Typography>
          
          <Stack
            direction="row"
            spacing={2}
            justifyContent
          >

            <Box
              component="form"
              sx={{ m: 2 }}
              onSubmit={handleSubmit(onSubmit)}
            >

              <VolunteerJobForm
                register={register}
                errors={errors}
                control={control}
                checked={checked}
                handleChange={handleChange}
                watch={watch}
              />

              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                sx={{ mt: 3 }}
              >
                
                <Button type="submit" variant="contained">Salvar</Button>

              </Stack>
            
            </Box>

          </Stack>

        </>
      }

    </Container>
  );
};
