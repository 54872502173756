export const priceCardText = [
  {
    title: "Basic",
    bestOffer: false,
    installment: "",
    installmentValue: "Gratuito",
    price: "R$250,00",
    paymentTerms: "-",
    services: [
      {"description": "Construtor de currículo online", "serviceIsPartOfPackage": true},
      {"description": "Criação/revisão de currículo", "serviceIsPartOfPackage": false},
      {"description": "Criação/revisão de Linkedin", "serviceIsPartOfPackage": false}
    ]
  },
  {
    title: "Premium",
    bestOffer: true,
    installment: "12x",
    installmentValue: "R$25,00",
    price: "R$250,00",
    paymentTerms: "à vista R$250,00",
    services: [
      {"description": "Construtor de currículo online", "serviceIsPartOfPackage": true},
      {"description": "Criação/revisão de currículo", "serviceIsPartOfPackage": true},
      {"description": "Criação/revisão de Linkedin", "serviceIsPartOfPackage": true}
    ]
  },
];