import * as S from "./MainSection.style";
import { ButtonPrimary } from "../../atoms/Buttons/ButtonPrimary/ButtonPrimary";
import { CardAttached } from "../../components/Cards/CardAttached/CardAttached";
import { useNavigate } from "react-router";

const text = <div>
  <p>Envie seu currículo e Linkedin para uma avaliação gratuita.</p><br/>
  <p>Receba insights valiosos para aprimorar sua imagem no mercado de trabalho</p>.
</div>

export const MainSection = () => {
  const navigate = useNavigate();

  return (
    <S.Container>

      <S.ContainerElement>
        <S.Title>
          A <S.HighlightedText>solução</S.HighlightedText> para a sua carreira <br />está aqui!
        </S.Title>
        
        <S.SubTitle>
          Tenha seu currículo e Linkedin otimizados por profissionais qualificados.
        </S.SubTitle>

        {/* <ButtonPrimary
          height={"50px"}
          text={"Criar conta"}
          width={"200px"}
          margin={"60px 0px 60px 0px"}
          onClick={() => navigate("/new-user-form")}
        /> */}
      </S.ContainerElement>

      <S.ContainerElement>
        <CardAttached 
          title="Avaliação gratuita!"
          text={text}
          iconWidth="35px"
          iconHeight="35px"
          height="450px"
          width="350px"
          backgroundColor="#f8f9fa"
          borderRadius="none"
          border="none"
          boxShadow="2px 2px 1px 0 rgb(26 57 144 / 25%)"
          color="black"
          withIcon="false"
        />
      </S.ContainerElement>

    </S.Container>
  );
};
