import React, { useState, useEffect, useContext } from 'react';
import * as S from './DataCVAsideMenu.style';
import { Button } from '@mui/material';
import { handleOnClickSection } from "../../pages/Curriculum/Curriculum";
import { Context } from "../../../src/pages/Curriculum/Curriculum";

const subtitle1 = "explicação"
const subtitle2 = "dicas"

const ButtonsPainel = () => {
  const context = useContext(Context);
  const { props } = context;
  const { resume, experience, volunteerJob, education, course, language } = props?.data || '';

  return (
    <section style={{ marginTop: "20px" }}>

      {resume === null ? <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px", width: "100%" }}
        onClick={() => handleOnClickSection({ section: "resume", props, openModal: true, edit: "edit" })}
      >Adicionar Resumo Profissional</Button> : null}

      {education?.length === 0 ? <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px", width: "100%" }}
        onClick={() => handleOnClickSection({ section: "education", props, openModal: true, edit: "edit" })}
      >Adicionar Formação Acadêmica</Button> : null}

      {experience?.length === 0 ? <Button 
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px", width: "100%" }}
        onClick={() => handleOnClickSection({ section: "experience", props, openModal: true, edit: "edit" })}
      >Adicionar Experiência Profissional</Button> : null}

      {volunteerJob?.length === 0 ? <Button 
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px", width: "100%" }}
        onClick={() => handleOnClickSection({ section: "volunteerJob", props, openModal: true, edit: "edit" })}
      >Adicionar Trabalho Voluntário</Button> : null} 
      
      {course?.length === 0 ? <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px", width: "100%" }}
        onClick={() => handleOnClickSection({ section: "course", props, openModal: true, edit: "edit" })}
      >Adicionar Curso Livre</Button> : null}

      {language?.length === 0 ? <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px", width: "100%" }}
        onClick={() => handleOnClickSection({ section: "language", props, openModal: true, edit: "edit" })}
      >Adicionar Idioma</Button> : null}

    </section>
  )
};

export const DataCVAsideMenu = ({ section }) => {
  const context = useContext(Context);
  const { props } = context;
  const { name, experience, volunteerJob, education, course, language } = props?.data || '';

  const [state, setState] = useState({
    initialMessage: false,
    generalData: false,
    resume: false,
    education: false,
    experience: false,
    volunteerJob: false,
    course: false,
    language: false,
  });

  useEffect(() => { setState({ [section]: true }) }, [section]);
  
  return (
    <>

      {state.initialMessage ?
        <>
          <S.Title variant={'h5'} sx={{ marginBottom: "20px" }}>Painel Principal</S.Title>
  
          <S.Subtitle sx={{ marginBottom: "20px" }}>olá, {name}!</S.Subtitle>

          <S.Paragraph>esse é o painel principal, onde você pode gerenciar o seu currículo e receber dicas personizadas para cada seção, clique em cada uma das seções do seu currículo para ver dicas personalizadas.</S.Paragraph>
  
          <S.Subtitle sx={{ marginBottom: "20px" }}>Pontuação</S.Subtitle>

          <S.Paragraph>A pontuação localizado no canto superior direito representa a pontuação do seu currículo</S.Paragraph>

          {(experience?.length === 0 || volunteerJob?.length === 0 || education?.length === 0 || course?.length === 0 || language?.length === 0) ?
            <>
              <S.Paragraph>Aumente o pontuação do seu currículo adicionando mais informações no seu currículo! Veja abaixo:</S.Paragraph>
            
              <ButtonsPainel />
            </>
          : null}
        </>
      : null}

      {state.generalData ?
        <>
          <S.Title variant={'h5'}>dados gerais</S.Title>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>            
          <S.Paragraph>aqui é onde ficam os seus dados gerais, como:</S.Paragraph>
          
          <S.Ul>
              <li>nome completo;</li>
              <li>contato (email, telefone e etc);</li>
              <li>endereço (somente a cidade)</li>
          </S.Ul>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle2}</S.Subtitle>
          
          <S.Paragraph>não coloque números de documentos como CPF ou RG.</S.Paragraph>
          
          <S.Paragraph>não coloque o seu endereço completo para que seus dados não sejam utilizados de maneira indevida, apenas a cidade já é o suficiente.</S.Paragraph>
    
        </>
      : null}

      {state.resume ?
        <>
          <S.Title variant={'h5'}>resumo Profissional</S.Title>
          
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>
          <S.Paragraph>essa seção é como o próprio nome diz um "resumo" do seu currículo, essa é a seção mais importante e normalmente é a última parte que se escreve em um currículo, exatamente para resumir todas as informações com os seus pontos mais fortes.</S.Paragraph>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle2}</S.Subtitle>
          <S.Paragraph>alguns tópicos que não podem falar em seu resumo são:</S.Paragraph>
    
          <S.Ul>
              <li>inicie com o seu tempo de experiência;</li>
              <li>reforce seus conhecimentos através das palavras-chaves;</li>
              <li>ressalte seus principais resultados;</li>
              <li>coloque sua formação;</li>
              <li>insira suas competências comportamentais</li>
          </S.Ul>
          
          {/* <br />
          <h4>Exemplo</h4>
          <p>Profissional com XXX anos de atuação na área XXX, com conhecimentos em XXX (explore as palavras-chave).</p>
          <br />
          <p>Participei de vários projetos XXX ao longo de minha trajetória (use seus principais destaques e evoluções).</p>
          <br />
          <p>Tenho conhecimento nos sistemas, licenças ou idiomas XXX.</p>
          <br />
          <p>Sou formado em XXX, com pós, mestrado, doutorado na faculdade XXX.</p>
          <br />
          <p>Possuo forte habilidade nas competências comportamentais XXX.</p> */}
        </>
      : null}

      {state.education ?
        <>
          <S.Title variant={'h5'}>formação acadêmica</S.Title>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>
          <S.Paragraph>informe nessa parte a sua escolaridade, não é necessário informar todos os seus níveis de formação acadêmica, por exemplo, se você tem nível superior não precisa informar onde fez o ensino fundamental e médio, mas é interessante informar todos os seus níveis de ensino superior.</S.Paragraph>
        </>
      : null}

      {state.experience ?
        <>
          <S.Title variant={'h5'}>experiência profissional</S.Title>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>
          <S.Paragraph>na experiência profissional é importante que façamos uma grande reflexão sobre tudo o que fizemos ao longo em cada emprego e destacarmos as principais funções que tivemos</S.Paragraph>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle2}</S.Subtitle>
          <S.Paragraph>algumas dicas para preencher essa seção são:</S.Paragraph>
    
          <S.Ul>
              <li>principais responsabilidades;</li>
              <li>exemplo de desafios que foram enfretados e como foram resolvidos;</li>
              <li>principais tarefas executadas;</li>
              <li>resultados alcançados;</li>
              <li>premiações e destaques.</li>
          </S.Ul>
    
        </>
      : null}

      {state.volunteerJob ?
        <>
          <S.Title variant={'h5'}>trabalho voluntário</S.Title>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>
          <S.Paragraph>aqui é onde você pode registrar trabalhos não remunerados em benefício da sociedade como trabalho em ONGs.</S.Paragraph>

          <S.Paragraph>apesar de não ser um trabalho remunerado muitas vezes um trabalho voluntário também é um oportunidade de utilizarmos e desenvolvermos ainda mais as nossas habilidades ou simplesmente fazer uma atividade que ajude a sociedade.</S.Paragraph>

          <S.Subtitle variant={'subtitle1'}>{subtitle2}</S.Subtitle>
          <S.Paragraph>algumas dicas para preencher essa seção são:</S.Paragraph>
    
          <S.Ul>
              <li>principais responsabilidades e/ou tarefas;</li>
              <li>breve descrição sobre a instituição e a causa apoiada (veja no site da instituição)</li>
          </S.Ul>
    
        </>
      : null}

      {state.course ?
        <>
          <S.Title variant={'h5'}>Cursos Livres</S.Title>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>
          <S.Paragraph>essa é a seção onde ficam registrados os cursos livres que você fez, ou seja, os cursos que não sejam acadêmicos, por exemplo, curso de Excel, AutoCad, Lógica de programação e etc</S.Paragraph>
    
          <S.Subtitle variant={"subtitle1"}>{subtitle2}</S.Subtitle>
          <S.Paragraph>escreva apenas os principais cursos que tenham correlação com com a vaga que você planeja aplicar.</S.Paragraph>
          <S.Paragraph>procure sempre se atualizar, além de olhar a relevância dos cursos, o recrutador pode olhar a data dos cursos para saber se você é um profissional que se atualiza constantemente.</S.Paragraph>
        </>
      : null}

      {state.language ?
        <>
          <S.Title variant={'h5'}>Idiomas</S.Title>
    
          <S.Subtitle variant={'subtitle1'}>{subtitle1}</S.Subtitle>
          <S.Paragraph>informe os idiomas que você sabe por nível de proeficiência.</S.Paragraph>
    
        </>
      : null}

    </>
  );
}