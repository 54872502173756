import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Button, Stack } from '@mui/material';
import { GeneralInformation } from "./Steps/GeneralInformation";
import { BehaviourSkillsPage } from "./Steps/BehaviourSkillsPage";
import { CREATE_USER } from '../../services/User.services';
import { useNavigate } from "react-router";
import { useAuth } from '../../context/AuthContext';
import { SpinnerRoller } from '../../components/Loading/SpinnerRoller/SpinnerRoller';
import * as S from "./NewUserForm.styles";

export const NewUserForm = ({ handleOpenModal, selectedData, index }) => {
  const { register, control, handleSubmit, watch, getValues, formState: { errors } } = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState();
  const [createUser] = useMutation(CREATE_USER);
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => setChecked(event.target.checked);

  const props = {
    register: register,
    control: control,
    watch: watch,
    errors: errors,
    getValues: getValues,
    emailAlreadyUsed: emailAlreadyUsed,
    checked: checked,
    handleChange: handleChange,
  }

  const form = [
    <GeneralInformation props={props} />,
    // <BehaviourSkillsPage props={props} value={value} setValue={setValue} />,
  ];

  const onSubmit = (async (data) => {
    setLoading(true);
    setEmailAlreadyUsed('')

    if(currentStep === form.length - 1) {

      // const showSkills = Object.entries(value).map((object) => object[1] === true ? object[0] : null).filter((item) => item !== null);
  
      try {
        const userData = {
          name: data.name.toLowerCase(),
          surname: data.surname.toLowerCase(),
          password: data.password,
          email: data.email.toLowerCase(),
          dddPhone: data.dddPhone,
          phone: data.phone,
          acceptPolicies: data.acceptPolicies,
          acceptEmailMarketing: data.acceptEmailMarketing,
        };
  
        await createUser({
          variables: userData,
        });
  
        signIn({data: userData})
  
        setLoading(false);
        navigate('/cv');
      } catch {
        setEmailAlreadyUsed('Esse endereço de e-mail já está sendo usado')
        setLoading(false);
      }

    } else {
      setCurrentStep(currentStep + 1) 
    }

  });

  // const handleBack = () => currentStep === 0 ? navigate('/') : setCurrentStep(currentStep - 1);

  return (
   <>

    <S.Form onSubmit={handleSubmit(onSubmit)}>

      {form[currentStep]}

      <Stack direction="row" spacing={2} sx={{ m: 2 }} justifyContent={"center"}>
        <Button type="submit" variant="contained" sx={{ width: "250px", height: "40px" }}>
          {loading === true ? <SpinnerRoller /> : "Criar Currículo" }
        </Button>
      </Stack>

    </S.Form>

   </>
  );
};
