import React, { useEffect } from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, useLocation, redirect } from 'react-router-dom';
import { NewUserForm } from "../src/pages/NewUserForm/NewUserForm";
import { Login } from './pages/Login/Login';
import { HomePage } from './pages/HomePage/HomePage';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { Curriculum } from './pages/Curriculum/Curriculum';
// import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
// import { TermsOfUsePage } from "./pages/TermsOfUsePage/TermsOfUsePage";
import { ForgetPassword } from "./pages/ForgetPassword/ForgetPassword";
import { ChangePassword } from "./pages/ChangePassword/ChangePassword";
import { PremiumPage } from "./pages/PremiumPage/PremiumPage";
import { CareerManagement } from "./pages/CareerManagement/CareerManagement";
import { useAuth } from './context/AuthContext';

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!auth.user) {
      redirect('/login', { state: { from: location } });
    }
  }, [auth, location]);

  if (!auth.user) {
    return null;
  } else {
    return children;
  }
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Rotas Públicas */}
      <Route path="/" element={<HomePage />} errorElement={<PageNotFound />} />
      <Route path="/login" element={<Login />} errorElement={<PageNotFound />} />
      <Route path="/new-user-form" element={<NewUserForm />} errorElement={<PageNotFound />} />
      <Route path="/forget-password" element={<ForgetPassword />} errorElement={<PageNotFound />} />
      <Route path="/change-password/:password_code" element={<ChangePassword />} errorElement={<PageNotFound />} />
      <Route path="/premium" element={<PremiumPage />} errorElement={<PageNotFound />} />

      {/* <Route path="/privacy-policy" element={<PrivacyPolicyPage />} errorElement={<PageNotFound />} /> */}
      {/* <Route path="/terms-of-use" element={<TermsOfUsePage />} errorElement={<PageNotFound />} /> */}

      {/* Rotas Privadas */}
      <Route path="/cv" element={<PrivateRoute><Curriculum /></PrivateRoute>} errorElement={<PageNotFound />} />
      <Route path="/career-management" element={<PrivateRoute><CareerManagement /></PrivateRoute>} errorElement={<PageNotFound />} />
      
    </>
  )
);
