import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      _id
      name
      surname
      email
      dddPhone
      phone
      resume
      experience {
        _id
        role
        company
        initialDate
        finalDate
        untilPresent
        description
      }
      education {
        _id
        course
        institution
        level
        status
        year
      }
      volunteerJob {
        _id
        role
        company
        initialDate
        finalDate
        untilPresent
        description
      }
      course {
        _id
        course
        institution
        status
        year
      }
      language {
        _id
        language
        level
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: ID!){
    getUser(userId: $userId){
      _id
      name
      surname
      email
      dddPhone
      phone
      resume
      experience {
        _id
        role
        company
        initialDate
        finalDate
        untilPresent
        description
      }
      education {
        _id
        course
        institution
        level
        status
        year
      }
      volunteerJob {
        _id
        role
        company
        initialDate
        finalDate
        untilPresent
        description
      }
      course {
        _id
        course
        institution
        status
        year
      }
      language {
        _id
        language
        level
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $name: String!
    $surname: String!
    $password: String!,
    $email: String!,
    $dddPhone: String,
    $phone: String,
    $acceptPolicies: Boolean!,
    $acceptEmailMarketing: Boolean,
  ){
    createUser(
      user: {
        name: $name
        surname: $surname
        password: $password
        email: $email
        dddPhone: $dddPhone
        phone: $phone
        acceptPolicies: $acceptPolicies
        acceptEmailMarketing: $acceptEmailMarketing
      }
    ){
      ok
    }
  }
`;

export const UPDATE_USER_RESUME = gql`
  mutation updateUser(
    $userId: ID!,
    $resume: String,
  ){
    updateUser(
      userId: $userId
      user: {
        resume: $resume,
      }
    ){
      ok
    }
  }
`;

export const UPDATE_USER_EXPERIENCE = gql`
  mutation updateUser(
    $userId: ID!,
    $_id: ID,
    $role: String,
    $company: String,
    $initialDate: String,
    $finalDate: String,
    $untilPresent: Boolean,
    $description: String,
  ){
    updateUser(
      userId: $userId
      user: {
        experience: {
          _id: $_id,
          role: $role,
          company: $company,
          initialDate: $initialDate,
          finalDate: $finalDate,
          untilPresent: $untilPresent,
          description: $description,
        }
      }
    ){
      ok
    }
  }
`;

export const UPDATE_USER_VOLUNTEERJOB = gql`
  mutation updateUser(
    $userId: ID!,
    $_id: ID,
    $role: String,
    $company: String,
    $initialDate: String,
    $finalDate: String,
    $untilPresent: Boolean,
    $description: String,
  ){
    updateUser(
      userId: $userId
      user: {
        volunteerJob: {
          _id: $_id,
          role: $role,
          company: $company,
          initialDate: $initialDate,
          finalDate: $finalDate,
          untilPresent: $untilPresent,
          description: $description,
        }
      }
    ){
      ok
    }
  }
`;

export const UPDATE_USER_EDUCATION = gql`
  mutation updateUser(
    $userId: ID!,
    $_id: ID,
    $course: String,
    $institution: String,
    $level: String,
    $status: String,
    $year: String,
  ){
    updateUser(
      userId: $userId
      user: {
        education: {
          _id: $_id,
          course: $course,
          institution: $institution,
          level: $level,
          status: $status,
          year: $year,  
        }
      }
    ){
      ok
    }
  }
`;

export const UPDATE_USER_COURSE = gql`
  mutation updateUser(
    $userId: ID!,
    $_id: ID,
    $course: String,
    $institution: String,
    $status: String,
    $year: String,
  ){
    updateUser(
      userId: $userId
      user: {
        course: {
          _id: $_id,
          course: $course,
          institution: $institution,
          status: $status,
          year: $year,
        }
      }
    ){
      ok
    }
  }
`;

export const UPDATE_USER_LANGUAGE = gql`
  mutation updateUser(
    $userId: ID!,
    $_id: ID,
    $language: String,
    $level: String,
  ){
    updateUser(
      userId: $userId
      user: {
        language: {
          _id: $_id,
          language: $language,
          level: $level,
        }
      }
    ){
      ok
    }
  }
`;

// export const DELETE_USER = gql`
//   mutation deleteUser($userId: ID!){
//     deleteUser(userId: $userId){
//       ok
//     }
//   }
// `;

export const DELETE_PROPERTY = gql`
  mutation deleteProperty(
      $userId: ID!,
      $section: String,
    ){
    deleteProperty(
      userId: $userId,
      section: $section,
    ){
      ok
    }
  }
`;


export const DELETE_ITEM = gql`
  mutation deleteItem(
      $userId: ID!,
      $_id: ID!,
      $section: String,
    ){
    deleteItem(
      userId: $userId,
      _id: $_id,
      section: $section,
    ){
      ok
    }
  }
`;
