import React from 'react';
import * as S from './ButtonPrimary.style';

export const ButtonPrimary = ({ backgroundColor, className, height, margin, text, width, onClick }) => {
  return (
    <S.Button
      className={className}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
      margin={margin}
      onClick={onClick}
    >
      {text}
    </S.Button>
  );
};
