export const capitalizeFirstLetterInEachWord = string => {
  const artigos_preposicoes = ['a', 'ao', 'aos', 'à', 'às', 'ante', 'após', 'até', 'com', 'contra', 'da', 'de', 'do', 'desde', 'e' , 'em', 'entre', 'para', 'perante', 'por', 'sem', 'sob', 'sobre', 'trás', 'o', 'os', 'a', 'as'];
  const words = string.split(' ');
  const capitalizedWords = words.map(word => {
    if (artigos_preposicoes.includes(word.toLowerCase())) {
      return word.toLowerCase(); // mantém a palavra em minúsculo
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // capitaliza a primeira letra e mantém o resto em minúsculo
    }
  });
  return capitalizedWords.join(' ');
};