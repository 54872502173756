import React from 'react';
import { Container, Title } from './CardAttached.styles';
import { Paragraph } from '../../../atoms/Paragraph/Paragraph';
import { ButtonPrimary } from '../../../atoms/Buttons/ButtonPrimary/ButtonPrimary';

export const CardAttached = ({ backgroundColor, borderRadius, border, boxShadow, color, withIcon, iconWidth, iconHeight, text, title, width, height }) => {
  return(
    <Container 
      style={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        border: border,
        boxShadow: boxShadow,
        color: color,
        height: height,
        width: width,
      }}
    >

      <Title textAlign="center">{title}</Title>

      <Paragraph 
        textAlign="center" 
        fontSize="18px"
        margin={"0px 40px"}
      >{text}</Paragraph>

      <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSf_fmYOLXyu-cpN48ZuYvM-vclj9N2Qiy7V13fJlmPnbVqcQg/viewform">
        <ButtonPrimary
          backgroundColor="#4baef5ff"
          className=""
          text="Anexar" 
          height="50px"
          margin="10px"
          width="200px"
          // onClick={() => console.log('ok')}
        />
      </a>

    </Container>
  );
};
