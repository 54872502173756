import styled from 'styled-components';

export const NavBar = styled.nav`
  background-color: rgb(3, 4, 94);
  font-family: sans-serif;
  font-size: 16px;
  position: sticky;
  z-index: 1000;
`

export const ContainerLogo = styled.div`
  font-size: 24px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 100px;
  padding: 20px;

  @media screen and (max-width: 1100px) {
    margin: 0px 20px;
  }
`;

export const ContainerButtons = styled.div`
  text-transform: uppercase;

  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media screen and (max-width: 1150px) {
    display: none;
  }

`

export const Ul = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
`

export const Li = styled.li`
  list-style: none;
  margin-left: 40px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`
export const Button = styled.button`
  border: none;
  background-color: inherit;
  color: inherit;
  
  font-size: inherit;
  font-weight: inherit;
  padding: 12px;
  text-transform: inherit;

  &:hover {
    color: rgba(255,255,255,.5);
  }
`

export const HighlightedButton = styled.button`
  border: 1px solid white;
  background-color: inherit;
  color: inherit;
  
  font-size: inherit;
  font-weight: inherit;
  padding: 12px;
  text-transform: inherit;

  &:hover {
    border: 1px solid rgba(255,255,255,.5);
    color: rgba(255,255,255,.5);
  }
`
