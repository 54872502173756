export const translateSection = (section) => {
  switch (section) {
    case "resume":
      return "Resumo Profissional"
    case "education":
        return "Formação Acadêmica"
    case "experience":
      return "Experiência Profissional"
    case "volunteerJob":
      return "Trabalho Voluntário"
    case "course":
      return "Curso Livre"
    case "language":
      return "Idiomas"
    default:
      return null;
  }
}