import React, { useState, createContext, useCallback, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { HamburguerButton } from "../../components/Buttons/HamburguerButton/HamburguerButton";
import Circle from 'react-circle';
import { useNavigate } from "react-router";
import { CurriculumDefault } from "./CurriculumModels/DefaultModel/CurriculumDefault";
import { ToolButtons } from "../../components/Buttons/ToolButtons/ToolButtons";
import * as S from "./Curriculum.styles";
import { useQuery } from "@apollo/client";
import { GET_USER } from '../../services/User.services';
import { DataCVAsideMenu } from '../../staticData/DataCVAsideMenu/DataCVAsideMenu';
import { EducationForm } from '../../components/Forms/EducationForm';
import { Course } from '../../components/Forms/Course';
import { Language } from '../../components/Forms/Language';
import { Resume } from '../../components/Resume/Resume';
import { ProfessionalExperiencePage } from "../NewUserForm/Steps/ProfessionalExperiencePage";
import { ButtonSwitch } from "../../components/Buttons/ButtonSwitch/ButtonSwitch";
import { VolunteerJob } from "../../components/Forms/VolunteerJob";
import { GeneralInformationForm } from "../../components/Forms/GeneralInformationForm/GeneralInformationForm";
import { useAuth } from "../../context/AuthContext";
import { Footer } from "../../components/Footer/Footer/Footer";
import { score } from "../../utils/score";
import { HamburguerMenu } from "../../components/Menu/HamburguerMenu/HamburguerMenu";
import { isSmallScreen } from "../../utils/isSmallScreen";
import { tooltip } from "../../utils/tooltips";

export const Context = createContext();

export const handleOnClickSection = ({ section, props, openModal, edit, selectedData, index }) => {
  const { setDataAsideMenu, setDataEditModal, handleOpenModal, ...context } = props;

  if(openModal) { handleOpenModal() };

  if(section === 'generalData') {
    setDataAsideMenu(<DataCVAsideMenu section="generalData" />);
    setDataEditModal(<GeneralInformationForm handleOpenModal={handleOpenModal} edit={edit} />);
  }
  if(section === 'resume') {
    setDataAsideMenu(<DataCVAsideMenu section="resume" />);
    setDataEditModal(<Resume handleOpenModal={handleOpenModal} selectedData={selectedData} context={context} />);
  }
  if(section === 'education') {
    setDataAsideMenu(<DataCVAsideMenu section="education" />);
    setDataEditModal(<EducationForm handleOpenModal={handleOpenModal} selectedData={selectedData} index={index} context={context} />);
  }
  if(section === 'experience') {
    setDataAsideMenu(<DataCVAsideMenu section="experience" />);
    setDataEditModal(<ProfessionalExperiencePage handleOpenModal={handleOpenModal} selectedData={selectedData} index={index} context={context} edit={edit} />);
  }
  if(section === 'volunteerJob') {
    setDataAsideMenu(<DataCVAsideMenu section="volunteerJob" />);
    setDataEditModal(<VolunteerJob handleOpenModal={handleOpenModal} selectedData={selectedData} index={index} context={context} />);
  }
  if(section === 'course') {
    setDataAsideMenu(<DataCVAsideMenu section="course" />);
    setDataEditModal(<Course handleOpenModal={handleOpenModal} selectedData={selectedData} index={index} context={context} />);
  }
  if(section === 'language') {
    setDataAsideMenu(<DataCVAsideMenu section="language" />);
    setDataEditModal(<Language handleOpenModal={handleOpenModal} selectedData={selectedData} index={index} context={context} />);
  }
};

export const Curriculum = () => {
  const [dataEditModal, setDataEditModal] = useState(false);
  const [dataAsideMenu, setDataAsideMenu] = useState(<DataCVAsideMenu section="initialMessage" />);
  const [openModal, setOpenModal] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const { signOff } = useAuth();
  const navigate = useNavigate();
  const userLocalStorage = JSON.parse(localStorage.getItem('@UP/User'));
  const [showAsideMenu, setShowAsideMenu] = useState(isSmallScreen() ? false : true);
  const [showHamburguerButton, setShowHamburguerButton] = useState(false);
  const [showHamburguerMenu, setShowHamburguerMenu] = useState(false);

  useEffect(() => {
    setShowHamburguerButton(isSmallScreen() ? true : false);
  }, [])

  const userId = userLocalStorage?._id

  const { data = {}, loading, refetch } = useQuery(GET_USER, { variables: { userId } });
  
  const userData = data?.getUser

  const activePainel = () => {
    setDataAsideMenu(<DataCVAsideMenu section="initialMessage" />);
    setShowAsideMenu(true)
  };
  const handleShowEditButton = useCallback((boolean) => setShowEditButton(boolean), [setShowEditButton]);
  const handleOpenModal = useCallback(() => setOpenModal(prevState => !prevState), [setOpenModal]);

  const props = {
    setDataAsideMenu,
    setDataEditModal,
    handleOpenModal,
    data: userData,
    loading,
    refetch,
  };

  const scoreData = score({
    experience: userData?.experience,
    education: userData?.education,
    course: userData?.course,
  });

  return (
    <>
      <S.Container id="main-menu">

        {showHamburguerButton ? 
          <HamburguerButton 
            color={"black"} 
            direction={"left"}
            onClick={() => setShowHamburguerMenu(prevState => !prevState)}
            handleOpenClose={() => showHamburguerMenu}
          />
        : null}

        {showHamburguerMenu ? 
          <HamburguerMenu
            backgroundColor={"white"}
            children={<ToolButtons activePainel={activePainel} setShowHamburguerMenu={setShowHamburguerMenu} />}
            direction="left"
          /> 
        : null}

        {!showHamburguerButton ? 
          <ToolButtons 
            activePainel={activePainel}
            flexDirection="row"
            buttonsStyle="asideMenu"
          />
        : null}

        <ButtonSwitch onClick={() => handleShowEditButton(prevState => !prevState)} labelText={"Modo de edição"} />

        <S.Container>
          <Tooltip title={tooltip.score}>
            <S.ContainerScore id="score">
              <Circle 
                progress={scoreData} 
                lineWidth="50"
                textStyle={{ font: 'bold 5rem Inter,Arial,Helvetica,sans-serif' }}
              />
            </S.ContainerScore>
          </Tooltip>
        </S.Container>

      </S.Container>

      <Context.Provider value={{ props }}>
        <CurriculumDefault 
          dataAsideMenu={dataAsideMenu} 
          dataEditModal={dataEditModal}
          setDataEditModal={setDataEditModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleShowEditButton={handleShowEditButton}
          handleOpenModal={handleOpenModal}
          showEditButton={showEditButton}
          props={props}
          showAsideMenu={showAsideMenu}
          setShowAsideMenu={setShowAsideMenu}
        />
      </Context.Provider>

      <Footer />
      
    </>
  );
};
