import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import { Autocomplete, Button, TextField, Typography, Stack } from '@mui/material';
import { educationLevel } from '../../staticData/educationLevel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { highEducationInstitutions } from '../../staticData/highEducationInstitutions';
import { ErrorMessage } from '../../styles/globalStyles';
import { UPDATE_USER_EDUCATION } from "../../services/User.services";
import { useMutation } from "@apollo/client";
import { capitalizeFirstLetterOnlyInFirstWord } from "../../utils/capitalizeFirstLetterOnlyInFirstWord";
import { capitalizeFirstLetterInEachWord } from "../../utils/capitalizeFirstLetterInEachWord";
import { status } from "../../staticData/status";
import { SpinnerRoller } from "../../components/Loading/SpinnerRoller/SpinnerRoller";
import dayjs from "dayjs";
  
export const EducationForm = ({ handleOpenModal, selectedData, index, context }) => {
  const { register, handleSubmit, control, formState: { errors } } = useForm({ defaultValues: selectedData || { "year": null } });
  const [updateUser, { loading }] = useMutation(UPDATE_USER_EDUCATION);
  const [spinner, setSpinner] = useState(false);
  
  const { data, refetch } = context;
  const { _id } = data;
  
  useEffect(() => { setSpinner(loading) }, [loading]);

  // const handleInstitutionName = () => {
  //   return highEducationInstitutions?.map((option) => 
  //     capitalizeFirstLetterInEachWord(option?.institution) + (option?.acronym === "null" ? "" : ` (${option.acronym})`)
  //   )
  // };

  const onSubmit = ((data) => {
    const updateEducation = async () => {
      await updateUser({
        variables: { 
          userId: _id,
          _id: data._id,
          course: data.course,
          institution: data.institution,
          level: data.level,
          status: data.status,
          year: String(data.year.$y)
        }
      });
      refetch()
      setSpinner(loading)
      handleOpenModal();
    }
    updateEducation()      
  });

  return (
    <>

      <Box
        component="form"
        sx={{ m: 2 }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {spinner ? <SpinnerRoller option={"modal"} /> : 
          <>
            <Typography variant={'h5'} sx={{ marginBottom: "20px" }}>Formação Acadêmica</Typography>

            <Stack direction="column" justifyContent>
              <Controller
                name="level"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, data) => onChange(data)}
                    options={educationLevel.map((option) => capitalizeFirstLetterInEachWord(option))}
                    renderInput={( params ) => <TextField
                      {...params}
                      {...field}
                      id="level"
                      label="Nível"
                      inputRef={ref}
                      required={true}
                      {...register("level", { required: 'true' })}
                    />}
                    margin="normal"
                    fullWidth
                    sx={{ boxShadow: 1 }}
                  />
                )}
              />
              {errors.level ? <ErrorMessage variant={"caption"}>Por favor, preencha o nível do curso</ErrorMessage> : null}
            </Stack>

            <Stack direction="column" justifyContent>
              <Controller
                name="course"
                control={control}
                render={({ field }) => <TextField 
                    {...field}
                    id="course"
                    label="Curso"
                    variant="outlined"
                    placeholder="Escreva o nome do curso"
                    fullWidth
                    sx={{ mt: 2, boxShadow: 1 }}
                    required={true}
                    {...register("course", { required: 'true' })}
                  />
                }
              />
              {errors.course ? <ErrorMessage variant={"caption"}>Por favor, preencha o nome do curso</ErrorMessage> : null}
            </Stack>

            <Stack direction="column" justifyContent>
              <TextField
                  id="institution"
                  label="Instituição"
                  variant="outlined"
                  placeholder="Escreva o nome da Instituição"
                  required={true}
                  fullWidth
                  sx={{ mt: 2, boxShadow: 1 }}
                  {...register("institution", { required: 'true' })}
                />
              {/* <Controller
                name="institution"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, data) => onChange(data)}
                    options={handleInstitutionName()}
                    renderInput={( params ) => <TextField
                      {...params}
                      {...field}
                      id="institution"
                      label="Instituição"
                      inputRef={ref}
                      required={true}
                      {...register("institution", { required: 'true' })}
                    />}
                    margin="normal"
                    fullWidth
                    sx={{ mt: 2, boxShadow: 1 }}
                  />
                )}
              /> */}
              {errors.institution ? <ErrorMessage variant={"caption"}>Por favor, preencha o nome da instituição</ErrorMessage> : null}
            </Stack>

            <Stack direction="column" justifyContent>
              <Controller
                name="status"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, data) => onChange(data)}
                    options={status?.map((option) => capitalizeFirstLetterOnlyInFirstWord(option))}
                    renderInput={(params) => <TextField
                      {...params}
                      {...field}
                      id="status"
                      label="Situação"
                      inputRef={ref}
                      required={true}
                      {...register("status", { required: 'true' })}
                    />}
                    margin="normal"
                    fullWidth
                    sx={{ mt: 2, boxShadow: 1 }}
                  />
                )}
              />
              {errors.status ? <ErrorMessage variant={"caption"}>Por favor, preencha a situação do curso</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="year"
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MobileDatePicker
                    {...field}
                    label="Ano"
                    openTo="year"
                    views={['year']}
                    sx={{ mt: 2, boxShadow: 1 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required={true}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      openTo="year"
                      minDate={dayjs("1900-01-01")}
                      maxDate={dayjs()}
                      label="Ano"
                      {...field}
                      renderInput={(params) => 
                        <TextField
                          {...params}
                          {...register("year", { required: "true" })}
                          required={true}
                          fullWidth
                          sx={{ mt: 2, boxShadow: 1 }}
                        />
                      }
                    />
                  </LocalizationProvider>
                )}
              /> */}
              {errors.year ? <ErrorMessage variant={"caption"}>Por favor, preencha o ano de conclusão (ou término previsto) do curso</ErrorMessage> : null}
            </Stack>
        
            <Stack direction="row" justifyContent={'flex-end'} sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>Salvar</Button>
            </Stack>
            
          </>
        }


      </Box>

    </>
  );
}
