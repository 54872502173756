// Fonte: Site do MEC

export const highEducationInstitutions = [
  { "institution": "faculdade de tecnologia senac curitiba", "acronym": "null" },
  { "institution": "faculdade regional serrana", "acronym": "FUNPAC" },
  { "institution": "universidade do estado do amapá", "acronym": "UEAP" },
  { "institution": "faculdade são judas de são bernardo do campo", "acronym": "null" },
  { "institution": "faculdade de ciências gerenciais alves fortes (juiz de fora)", "acronym": "FACE ALFOR JF" },
  { "institution": "centro universitário cenecista de osório", "acronym": "UNICNEC" },
  { "institution": "faculdade de ciências e tecnologias de campos gerais", "acronym": "FACICA" },
  { "institution": "centro universitário curitiba", "acronym": "UNICURITIBA" },
  { "institution": "faculdade venda nova do imigrante", "acronym": "FAVENI" },
  { "institution": "universidade do vale do paraíba", "acronym": "UNIVAP" },
  { "institution": "faculdade presidente antônio carlos de bom sucesso", "acronym": "FUNEES Bom Sucesso" },
  { "institution": "faculdade fleming cerquilho", "acronym": "Fac Cerquilho" },
  { "institution": "instituto federal de educação, ciência e tecnologia do norte de minas gerais", "acronym": "IFNMG" },
  { "institution": "faculdade sigma", "acronym": "SIGMA EAD" },
  { "institution": "faculdade de tecnologia de jacareí", "acronym": "FATEC Jacareí" },
  { "institution": "faculdade metodista do sul paulista", "acronym": "FMSP" },
  { "institution": "centro universitário multivix vitória", "acronym": "null" },
  { "institution": "centro universitário salesiano", "acronym": "UniSales" },
  { "institution": "faculdade integrada da amazônia", "acronym": "FINAMA" },
  { "institution": "faculdade metodista granbery", "acronym": "FMG" },
  { "institution": "faculdade unirb ceará", "acronym": "F.CEARÁ" },
  { "institution": "faculdade serra da mesa", "acronym": "FASEM" },
  { "institution": "faculdade guaraí", "acronym": "FAG" },
  { "institution": "faculdade integrada cete", "acronym": "FIC" },
  { "institution": "pontifícia universidade católica de minas gerais", "acronym": "PUC MINAS" },
  { "institution": "faculdade pitagoras anhanguera de santa cruz do sul", "acronym": "null" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro", "acronym": "FAETERJ PARACAMBI" },
  { "institution": "faculdade de itápolis - facita", "acronym": "FACITA" },
  { "institution": "faculdade nove de julho de bauru", "acronym": "NOVE-BAURU" },
  { "institution": "faculdade marechal rondon", "acronym": "FMR" },
  { "institution": "academia de polícia militar de minas gerais", "acronym": "APM" },
  { "institution": "faculdade anhanguera pitágoras de jundiaí", "acronym": "null" },
  { "institution": "instituto federal de educação, ciência e tecnologia do paraná", "acronym": "IFPR" },
  { "institution": "faculdade de ciências humanas de curvelo", "acronym": "FACIC" },
  { "institution": "instituto goiano de direito", "acronym": "IGD" },
  { "institution": "faciten - faculdade de ciências e tecnologias de natal", "acronym": "FACITEN" },
  { "institution": "faculdade européia de vitória", "acronym": "FAEV" },
  { "institution": "faculdade de tecnologia ipuc", "acronym": "FATIPUC" },
  { "institution": "faculdade uniavan joinville", "acronym": "UNIAVAN" },
  { "institution": "instituto união das faculdades americanas", "acronym": "IUFA" },
  { "institution": "faculdade de tecnologia de diadema luigi papaiz", "acronym": "FATEC DIADEMA" },
  { "institution": "faculdade uniftb", "acronym": "null" },
  { "institution": "faculdade pitágoras unopar de chapecó", "acronym": "null" },
  { "institution": "faculdade ages de lagarto", "acronym": "Faculdade AGES" },
  { "institution": "faculdade santo antônio", "acronym": "FSA" },
  { "institution": "centro universitário de ensino superior do amazonas", "acronym": "CIESA" },
  { "institution": "faculdade maurício de nassau de niterói", "acronym": "FMN NITERÓI" },
  { "institution": "instituto superior de educação vale do salgado", "acronym": "IVS" },
  { "institution": "faculdade de colinas do tocantins", "acronym": "null" },
  { "institution": "centro universitário de santa fé do sul", "acronym": "UNIFUNEC" },
  { "institution": "escola superior de tecnologia & gestão de santa catarina", "acronym": "EST&G" },
  { "institution": "faculdade de presidente epitácio - fape", "acronym": "FAPE" },
  { "institution": "fai - centro de ensino superior em gestão, tecnologia e educação", "acronym": "null" },
  { "institution": "faculdade de administração, ciências, educação e letras", "acronym": "FACEL" },
  { "institution": "faculdade abrange abc", "acronym": "FABRANGE" },
  { "institution": "centro universitário assis gurgacz", "acronym": "FAG" },
  { "institution": "faculdades integradas de saúde e educação do brasil", "acronym": "FISEB" },
  { "institution": "faculdade metropolitana de itacoatiara", "acronym": "FAMETRO" },
  { "institution": "faculdade estadual de filosofia ciências e letras de jacarezinho", "acronym": "FAFIJA" },
  { "institution": "faculdade madalena sofia", "acronym": "FMS" },
  { "institution": "faculdade de ciências contábeis de afonso cláudio", "acronym": "ISEAC" },
  { "institution": "faculdade de ciências contábeis", "acronym": "FACIC" },
  { "institution": "faculdade de administração e marketing de brasília", "acronym": "FAMA" },
  { "institution": "faculdade cnec varginha", "acronym": "null" },
  { "institution": "faculdade adjetivo cetep", "acronym": "ADJETIVO-CETEP" },
  { "institution": "faculdade mafrense de turismo e ciências humanas de teresina", "acronym": "FAMA" },
  { "institution": "faculdade de ciências, educação e teologia do norte do brasil", "acronym": "FACETEN" },
  { "institution": "faculdade de ciências e tecnologia de janaúba", "acronym": "FACITEC" },
  { "institution": "faculdades integradas silva e souza", "acronym": "FAU" },
  { "institution": "faculdade anhangüera de são josé", "acronym": "null" },
  { "institution": "centro universitário opet", "acronym": "Uniopet" },
  { "institution": "faculdade einstein", "acronym": "null" },
  { "institution": "centro universitário unidade de ensino superior dom bosco", "acronym": "UNDB" },
  { "institution": "faculdade união de campo mourão", "acronym": "null" },
  { "institution": "inside - instituto superior para integração, desenvolvimento e educação", "acronym": "INSIDE" },
  { "institution": "faculdade estácio de teresina", "acronym": "ESTÁCIO TERESINA" },
  { "institution": "faculdade de ciência e tecnologia", "acronym": "FACITEC" },
  { "institution": "faculdade anhanguera de itabuna", "acronym": "null" },
  { "institution": "faculdade de martinópolis", "acronym": "FAMA" },
  { "institution": "faculdade de odontologia da apcd", "acronym": "FAOA" },
  { "institution": "faculdades integradas soares de oliveira", "acronym": "FISO" },
  { "institution": "faculdade lusitana de pernambuco", "acronym": "FALUP" },
  { "institution": "faculdade de educação superior de catalão", "acronym": "null" },
  { "institution": "faculdades de ciências e tecnologia do nordeste ltda", "acronym": "FACINE" },
  { "institution": "escola agrotécnica federal de salinas", "acronym": "EAFSALINAS" },
  { "institution": "faculdade anhanguera itabira", "acronym": "null" },
  { "institution": "faculdade de tecnologia de são paulo", "acronym": "FATEC-SP" },
  { "institution": "faculdade esamc franca", "acronym": "ESAMC FRANCA" },
  { "institution": "centro universitário são josé de itaperuna", "acronym": "null" },
  { "institution": "faculdade de tecnologia de mococa", "acronym": "FATEC" },
  { "institution": "faculdade de direito de ipatinga", "acronym": "FADIPA" },
  { "institution": "instituto do sul de santa catarina", "acronym": "null" },
  { "institution": "universidade católica de brasília", "acronym": "UCB" },
  { "institution": "faculdade de ampére", "acronym": "FAMPER" },
  { "institution": "faculdade de palmas", "acronym": "FAPAL" },
  { "institution": "faculdade de nova mutum", "acronym": "null" },
  { "institution": "faculdade de tecnologia, capacitação e gestão integral", "acronym": "FAINTEC" },
  { "institution": "instituto plenitude educação", "acronym": "IPLENI" },
  { "institution": "faculdade ismd", "acronym": "ISMD" },
  { "institution": "instituto superior de filosofia berthier", "acronym": "IFIBE" },
  { "institution": "escola superior de aviação civil", "acronym": "ESAC" },
  { "institution": "faculdade liber de porangatu", "acronym": "FacLiber" },
  { "institution": "instituto de desenvolvimento educacional", "acronym": "IDE" },
  { "institution": "faculdade presidente antônio carlos de campo belo", "acronym": "FUNEES Campo Belo" },
  { "institution": "faculdade presidente antônio carlos de itabirito", "acronym": "null" },
  { "institution": "instituto aphonsiano de ensino superior", "acronym": "IAESUP" },
  { "institution": "faculdade guerra", "acronym": "FAG" },
  { "institution": "faculdade de enfermagem nova esperança", "acronym": "FACENE" },
  { "institution": "faculdade bezerra de araújo", "acronym": "FABA" },
  { "institution": "faculdade paulista", "acronym": "null" },
  { "institution": "faculdade de tecnologia ftec de porto alegre", "acronym": "FTEC Porto Alegre" },
  { "institution": "universidade do oeste paulista", "acronym": "UNOESTE" },
  { "institution": "faculdade teológica batista de são paulo", "acronym": "FTBSP" },
  { "institution": "faculdade politécnica de taubaté", "acronym": "null" },
  { "institution": "faculdade unirio", "acronym": "null" },
  { "institution": "centro universitário ibmec", "acronym": "IBMEC" },
  { "institution": "centro universitário da amazônia", "acronym": "Unama" },
  { "institution": "faculdade de colider", "acronym": "FACIDER" },
  { "institution": "universidade anhembi morumbi", "acronym": "UAM" },
  { "institution": "faculdade de tecnologia de osasco - prefeito hirant sanazar", "acronym": "Fatec Osasco" },
  { "institution": "faculdade enau", "acronym": "FacEnau" },
  { "institution": "faculdade cnec farroupilha", "acronym": "null" },
  { "institution": "faculdade de ciências médicas de são josé dos campos", "acronym": "null" },
  { "institution": "faculdade cnec unaí", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai florianópolis", "acronym": "SENAI -SC - CTAI" },
  { "institution": "faculdades coc de são paulo", "acronym": "FACOCSP" },
  { "institution": "faculdades integradas tibiriçá", "acronym": "FATI" },
  { "institution": "faculdade de tupã", "acronym": "null" },
  { "institution": "faculdade ágape de são félix", "acronym": "null" },
  { "institution": "centro universitário estácio meta de rio branco estácio", "acronym": "UNIMETA" },
  { "institution": "faculdade curitibana", "acronym": "FAC" },
  { "institution": "faculdade de cascavel", "acronym": "FADEC" },
  { "institution": "instituto de ensino superior de olinda", "acronym": "IESO" },
  { "institution": "faculdade anhanguera de tangará da serra", "acronym": "FITS" },
  { "institution": "faculdade de bragança", "acronym": "FABRA" },
  { "institution": "faculdade anhanguera de rondonópolis", "acronym": "FAR" },
  { "institution": "faculdade espírito santense", "acronym": "UNICAPE" },
  { "institution": "faculdade setelagoana de ciências gerenciais", "acronym": "FASCIG" },
  { "institution": "faculdade bookplay", "acronym": "null" },
  { "institution": "faculdade hsm", "acronym": "null" },
  { "institution": "faculdade uninta tiangua", "acronym": "null" },
  { "institution": "faculdade machado de assis", "acronym": "FAMA" },
  { "institution": "universidade federal da integração latino-americana", "acronym": "UNILA" },
  { "institution": "instituto superior de educação de santa catarina", "acronym": "ISESC" },
  { "institution": "trevisan escola superior de negócios", "acronym": "null" },
  { "institution": "faculdade do sul da bahia", "acronym": "FASB" },
  { "institution": "faculdade microlins", "acronym": "FAMIC" },
  { "institution": "faculdade de boituva", "acronym": "FIB" },
  { "institution": "faculdade 05 de julho", "acronym": "F5" },
  { "institution": "faculdade de tecnologia de campinas", "acronym": "FATEC CAMPINAS" },
  { "institution": "faculdade anhanguera de erechim", "acronym": "null" },
  { "institution": "faculdade de ciências contábeis de ponte nova", "acronym": "FACE" },
  { "institution": "centro universitário satc", "acronym": "UniSATC" },
  { "institution": "faculdade cecape", "acronym": "null" },
  { "institution": "faculdade boas novas de ciências teológicas, sociais e biotecnológicas", "acronym": "FBNCTSB" },
  { "institution": "faculdade de engenharia e agrimensura de são paulo", "acronym": "FEASP" },
  { "institution": "faculdade anhanguera de belo horizonte", "acronym": "null" },
  { "institution": "faculdade de ciências sociais aplicadas de extrema", "acronym": "FAEX" },
  { "institution": "faculdade de administração de nova andradina - fanova", "acronym": "FANOVA" },
  { "institution": "faculdade anhanguera franca", "acronym": "FAF" },
  { "institution": "faculdade católica de belém", "acronym": "FACBEL" },
  { "institution": "faculdade teológica betânia", "acronym": "FATEBE" },
  { "institution": "centro universitário das faculdades metropolitanas unidas", "acronym": "FMU" },
  { "institution": "faculdade evangélica de taguatinga", "acronym": "FE TAGUATINGA" },
  { "institution": "instituto brasil de ensino superior", "acronym": "IBRAS" },
  { "institution": "faculdades integradas de três lagoas", "acronym": "AEMS" },
  { "institution": "faculdade de filosofia ciências e letras de alegre", "acronym": "FAFIA" },
  { "institution": "faculdade impacto", "acronym": "null" },
  { "institution": "faculdade cnec alberto torres", "acronym": "null" },
  { "institution": "união das faculdades dos grandes lagos", "acronym": "UNILAGO" },
  { "institution": "faculdade montenegro", "acronym": "FAM" },
  { "institution": "faculdade de joão pessoa (santa emília do rodat)", "acronym": "null" },
  { "institution": "faculdade anhanguera de maceió", "acronym": "null" },
  { "institution": "centro universitário metodista", "acronym": "IPA" },
  { "institution": "faculdade de ciências médicas da bahia", "acronym": "CIENCIAS MEDICAS" },
  { "institution": "faculdade presidente antônio carlos de nazareno", "acronym": "FUNEES" },
  { "institution": "faculdade de tecnologia senai maringá", "acronym": "null" },
  { "institution": "faculdade de odontologia do recife", "acronym": "FOR" },
  { "institution": "faculdades integradas de fátima do sul", "acronym": "FIFASUL" },
  { "institution": "faculdade estácio de jataí estácio jataí", "acronym": "Estácio Jataí" },
  { "institution": "faculdade dom bosco de porto alegre", "acronym": "FDB" },
  { "institution": "universidade de caxias do sul", "acronym": "UCS" },
  { "institution": "universidade federal de roraima", "acronym": "UFRR" },
  { "institution": "faculdade sudoeste paulista - tatuí - fsp", "acronym": "TATUÍ - FSP" },
  { "institution": "faculdade norte e nordeste do brasil", "acronym": "FANOR - BRASIL" },
  { "institution": "faculdade fasipe de rondonópolis", "acronym": "FFR" },
  { "institution": "faculdade presidente antônio carlos de leopoldina", "acronym": "null" },
  { "institution": "favenorte de porteirinha", "acronym": "FAVEPORT" },
  { "institution": "faculdade de medicina nova esperança", "acronym": "FAMENE" },
  { "institution": "faculdade prudente de moraes", "acronym": "FPM" },
  { "institution": "faculdade fecomercio roraima", "acronym": "FACORR" },
  { "institution": "faculdades unificadas de iúna", "acronym": "FUI" },
  { "institution": "universidade estadual do oeste do paraná", "acronym": "UNIOESTE" },
  { "institution": "faculdade irecê", "acronym": "FAI" },
  { "institution": "faculdade dinâmica", "acronym": "FACDIN" },
  { "institution": "unicorp faculdades", "acronym": "UNICORP" },
  { "institution": "escola de medicina souza marques da fundação técnico-educacional souza marques", "acronym": "EMSM" },
  { "institution": "faculdade de tecnologia senai nadir dias de figueiredo", "acronym": "null" },
  { "institution": "universidade federal do amazonas", "acronym": "UFAM" },
  { "institution": "faculdade senac florianópolis", "acronym": "SENAC FLORIANÓPOLIS" },
  { "institution": "faculdade de educação de itaboraí", "acronym": "FEITA" },
  { "institution": "faculdade prisma", "acronym": "FAP" },
  { "institution": "faculdade de ciências humanas do sul paulista", "acronym": "FACHI" },
  { "institution": "faculdade olga mettig", "acronym": "FAMETTIG" },
  { "institution": "faculdade de ciências e tecnologia de natal", "acronym": "FACITEN" },
  { "institution": "faculdade de medicina estácio de castanhal", "acronym": "ESTÁCIO FMEC" },
  { "institution": "centro universitário luterano de manaus", "acronym": "CEULM/ULBRA" },
  { "institution": "faculdade vale do gorutuba", "acronym": "FAVAG" },
  { "institution": "escola superior do ministério público da união", "acronym": "ESMPU" },
  { "institution": "faculdade de são vicente", "acronym": "FSV" },
  { "institution": "faculdade de ensino jurídico de vitória", "acronym": "FEJ" },
  { "institution": "universidade do extremo sul catarinense", "acronym": "UNESC" },
  { "institution": "faculdade unità", "acronym": "UNITÀ" },
  { "institution": "faculdade pitágoras belo jardim", "acronym": "null" },
  { "institution": "faculdade de tecnologia de barueri", "acronym": "Fatec Barueri" },
  { "institution": "faculdade redentor de campos", "acronym": "FACREDENTOR" },
  { "institution": "centro universitário de adamantina", "acronym": "FAI" },
  { "institution": "faculdade evangélica de tecnologia, ciências e biotecnologia da cgadb", "acronym": "FAECAD" },
  { "institution": "faculdade de santo ângelo", "acronym": "FASA" },
  { "institution": "faculdade centro oeste catanduvas", "acronym": "FACOC" },
  { "institution": "faculdades integradas de fernandópolis", "acronym": "FIFE" },
  { "institution": "faculdade ensin.e", "acronym": "ENSIN.E" },
  { "institution": "faculdade de ciências sociais e aplicadas do paraná", "acronym": "FACET" },
  { "institution": "centro universitário univinte", "acronym": "null" },
  { "institution": "faculdade faciencia", "acronym": "FaCiencia" },
  { "institution": "centro de ensino superior riograndense", "acronym": "CESURG" },
  { "institution": "faculdade cidade de coromandel", "acronym": "FCC" },
  { "institution": "faculdades integradas ipitanga", "acronym": "FACIIP" },
  { "institution": "faculdade unida de campinas goiânia - facunicamps goiânia", "acronym": "FACUNICAMPS" },
  { "institution": "faculdade tecnológica latino americana", "acronym": "FATLA" },
  { "institution": "faculdade cidade de guanhães - facig", "acronym": "FACIG" },
  { "institution": "faculdade de teologia, filosofia e ciências humanas gamaliel", "acronym": "FATEFIG" },
  { "institution": "faculdade innovare", "acronym": "FACINN" },
  { "institution": "conservatório dramático musical são paulo", "acronym": "CDMSP" },
  { "institution": "faculdades integradas camões", "acronym": "FICA" },
  { "institution": "faculdade cnec rio das ostras", "acronym": "null" },
  { "institution": "faculdade de tecnologia oswaldo cruz", "acronym": "FATEC OSWALDO CRUZ" },
  { "institution": "instituto leya de educação superior", "acronym": "Leya" },
  { "institution": "centro universitário planalto do distrito federal - uniplan", "acronym": "UNIPLAN" },
  { "institution": "faculdade de ciências da saúde de barretos dr. paulo prata", "acronym": "FACISB" },
  { "institution": "faculdade politécnica de campinas", "acronym": "POLICAMP" },
  { "institution": "faculdade pitágoras de bom jesus da lapa", "acronym": "null" },
  { "institution": "faculdade de ensino superior de marechal cândido rondon", "acronym": "ISEPE  RONDON" },
  { "institution": "faculdade universalis", "acronym": "FACSALIS" },
  { "institution": "faculdade do maciço do baturité", "acronym": "FMB" },
  { "institution": "faculdade de comunicação social santa efigênia", "acronym": "FACULDADE VEREDAS" },
  { "institution": "faculdade alis de itabirito", "acronym": "null" },
  { "institution": "instituto de ensino superior de teresina", "acronym": "IEST" },
  { "institution": "instituto são paulo de estudos superiores", "acronym": "ITESP" },
  { "institution": "escola da cidade - faculdade de arquitetura e urbanismo", "acronym": "ESCOLA DA CIDADE" },
  { "institution": "faculdade cnec santo ângelo", "acronym": "null" },
  { "institution": "centro universitário una de uberlândia", "acronym": "null" },
  { "institution": "faculdade centro oeste do paraná", "acronym": "FACEOPAR" },
  { "institution": "instituto federal de educação, ciência e tecnologia  de santa catarina", "acronym": "IFSC" },
  { "institution": "faculdade anhanguera unidade guarapari", "acronym": "null" },
  { "institution": "faculdade de nova mutum", "acronym": "UNIFAMA" },
  { "institution": "escola superior de pouso alegre", "acronym": "null" },
  { "institution": "centro universitário alves faria", "acronym": "UNIALFA" },
  { "institution": "faculdade de tecnologia e ciências - ftc caucaia", "acronym": "FTC Caucaia" },
  { "institution": "faculdade integração", "acronym": "FI" },
  { "institution": "faculdade planalto de filosofia, ciências e letras", "acronym": "FACPLAN" },
  { "institution": "faculdade senai-cetiqt", "acronym": "SENAI-CETIQT" },
  { "institution": "faculdades idaam", "acronym": "IDAAM" },
  { "institution": "faculdade maurício de nassau de divinópolis", "acronym": "FMN DIVINÓPOLIS" },
  { "institution": "instituto logos de educação superior", "acronym": "ILES" },
  { "institution": "faculdade de sistemas de informação de paraíso do tocantins", "acronym": "FSIP" },
  { "institution": "faculdade de medicina de petrópolis", "acronym": "FMP" },
  { "institution": "faculdade católica de feira de santana", "acronym": "null" },
  { "institution": "instituto de desenvolvimento tecnológico da fgv", "acronym": "FGV/IDT" },
  { "institution": "faculdades coc de brasília", "acronym": "FACOCBR" },
  { "institution": "faculdade meridional de ijuí", "acronym": "IMED" },
  { "institution": "faculdade conhecer", "acronym": "FC" },
  { "institution": "faculdade santíssimo sacramento", "acronym": "FSSS" },
  { "institution": "faculdade cleber leite - ead", "acronym": "FCL - EAD" },
  { "institution": "faculdade garça branca pantanal", "acronym": "FGB" },
  { "institution": "faculdade de ciências aplicadas e tecnológicas do litoral sul", "acronym": "null" },
  { "institution": "faculdades famep", "acronym": "FAMEP" },
  { "institution": "faculdade de brusque", "acronym": "null" },
  { "institution": "faculdade 2001", "acronym": "null" },
  { "institution": "faculdade menino deus", "acronym": "FAMED" },
  { "institution": "faculdade de assuntos acadêmicos e científicos monsenhor edisson vieira lício", "acronym": "FAACI" },
  { "institution": "faculdade maringá", "acronym": "CESPAR" },
  { "institution": "faculdade unirb - natal", "acronym": "F. UNIRB" },
  { "institution": "faculdade santa maria", "acronym": "FASAMP" },
  { "institution": "emge - escola de engenharia", "acronym": "null" },
  { "institution": "universidade virtual do estado do maranhão", "acronym": "UNIVIMA" },
  { "institution": "centro universitário arnaldo horácio ferreira", "acronym": "null" },
  { "institution": "faculdade cta", "acronym": "CTA" },
  { "institution": "centro universitário senai blumenau", "acronym": "null" },
  { "institution": "universidade de taubaté", "acronym": "UNITAU" },
  { "institution": "faculdade humboldt", "acronym": "FH" },
  { "institution": "faculdade da serra gaúcha de bento gonçalves", "acronym": "null" },
  { "institution": "fundação universidade do estado de santa catarina", "acronym": "null" },
  { "institution": "faculdade paranaense", "acronym": "FACCAR" },
  { "institution": "faculdade de tecnologia e ciências", "acronym": "FTC Juazeiro" },
  { "institution": "centro universitário unifecaf", "acronym": "null" },
  { "institution": "faculdade centro sul do paraná", "acronym": "FACSPAR" },
  { "institution": "escola superior de educação e negócios", "acronym": "ESN" },
  { "institution": "faculdade terra brasilis", "acronym": "null" },
  { "institution": "faculdade de tecnologia de ampére", "acronym": "FAMPERTEC" },
  { "institution": "faculdades de dracena", "acronym": "null" },
  { "institution": "faculdade unirb - cidade de fortaleza", "acronym": "F. UNIRB" },
  { "institution": "faculdade de tecnologia e inovação senac df", "acronym": "null" },
  { "institution": "faculdade de ciências médicas da paraíba", "acronym": "FCM-PB" },
  { "institution": "faculdade cathedral", "acronym": "FACES" },
  { "institution": "faculdade estácio de belém - estácio belém", "acronym": "ESTÁCIO BELÉM" },
  { "institution": "universidade de cruz alta", "acronym": "UNICRUZ" },
  { "institution": "faculdade presidente antônio carlos de lagoa dos patos", "acronym": "FUNEES" },
  { "institution": "faculdade de bauru", "acronym": "null" },
  { "institution": "istituto europeo di design", "acronym": "IED SP" },
  { "institution": "faculdade cespu europa brasil", "acronym": "FACCE" },
  { "institution": "faculdade paulista de bem estar e saúde", "acronym": "null" },
  { "institution": "faculdade ibero americana de são paulo", "acronym": "FIASP" },
  { "institution": "instituto superior de educação coração de jesus", "acronym": "ISECJ" },
  { "institution": "faculdade ibra de tecnologia", "acronym": "FACULDADE FITEC" },
  { "institution": "faculdade de direito tech de são paulo", "acronym": "FADITECH" },
  { "institution": "faculdades londrina", "acronym": "null" },
  { "institution": "centro universitário estácio de são paulo", "acronym": "ESTÁCIO SÃO PAULO" },
  { "institution": "faculdade presidente antônio carlos de almenara", "acronym": "FUNEC Almenara" },
  { "institution": "faculdade paraibana de processamento de dados", "acronym": "FPPD" },
  { "institution": "centro universitário estácio de santa catarina - estácio santa catarina", "acronym": "null" },
  { "institution": "faculdade facuminas de pós-graduação ead", "acronym": "FACUMINAS EAD" },
  { "institution": "faculdade pitágoras unopar de guanambi", "acronym": "FPG" },
  { "institution": "faculdade de tecnologia de araraquara", "acronym": "FATEC ARQ" },
  { "institution": "faculdade fasipe de primavera", "acronym": "FFP" },
  { "institution": "faculdade pitágoras de luis eduardo magalhães", "acronym": "null" },
  { "institution": "faculdade dama", "acronym": "null" },
  { "institution": "faculdade dom alberto", "acronym": "FDA" },
  { "institution": "faculdade cesmac do sertão", "acronym": "CESMAC Sertão" },
  { "institution": "faculdade refidim", "acronym": "Refidim" },
  { "institution": "centro universitário de mineiros", "acronym": "UNIFIMES" },
  { "institution": "faculdade anhanguera de tecnologia de belo horizonte", "acronym": "null" },
  { "institution": "faculdade de tecnologia inesul do paraná", "acronym": "FIPAR" },
  { "institution": "faculdade censupeg", "acronym": "null" },
  { "institution": "universidade universus veritas guarulhos", "acronym": "Univeritas UNG" },
  { "institution": "cisne - faculdade de quixadá", "acronym": "CFQ" },
  { "institution": "faculdade de filosofia ciências e letras de são josé do rio pardo", "acronym": "FFCL" },
  { "institution": "faculdade da alta paulista", "acronym": "FAP" },
  { "institution": "centro universitário presidente antônio carlos", "acronym": "UNIPAC" },
  { "institution": "faculdade presidente antônio carlos de nova lima", "acronym": "null" },
  { "institution": "faculdade iseib de belo horizonte", "acronym": "FIBH" },
  { "institution": "faculdade luterana são marcos", "acronym": "FALSM" },
  { "institution": "centro universitário univel", "acronym": "null" },
  { "institution": "faculdade de goiana", "acronym": "FAG" },
  { "institution": "faculdade do sertão baiano", "acronym": "FASBE" },
  { "institution": "faculdade de formação de professores e especialistas de educação", "acronym": "FAFOPEE" },
  { "institution": "faculdade presidente antônio carlos de luminárias", "acronym": "FUNEES" },
  { "institution": "faculdade presidente antônio carlos de poté", "acronym": "FUNEES Poté" },
  { "institution": "centro universitário estácio do ceará", "acronym": "Estácio Ceará" },
  { "institution": "faculdade diocesana são josé", "acronym": "FADISI" },
  { "institution": "instituto superior e centro educacional luterano - bom jesus - ielusc", "acronym": "BOM JESUS/IELUSC" },
  { "institution": "faculdade de tecnologia futuro", "acronym": "Fatec Futuro" },
  { "institution": "faculdade de tecnologia e ciências de campina grande", "acronym": "FTC Camp Grande" },
  { "institution": "faculdade unibras do mato grosso", "acronym": "FACBRAS" },
  { "institution": "faculdade do vale do rio arinos", "acronym": "AJES" },
  { "institution": "faculdade itaquá", "acronym": "UNEITAQUÁ" },
  { "institution": "instituto federal de educação, ciência e tecnologia de goiás", "acronym": "IFG" },
  { "institution": "faculdade de medicina de ji-paraná", "acronym": "null" },
  { "institution": "faculdade do instituto nacional de pós-graduação de campinas", "acronym": "FAC. INPG - CAMPINAS" },
  { "institution": "faculdades integradas de cataguases", "acronym": "FIC" },
  { "institution": "faculdade espírito santense de ciências jurídicas", "acronym": "PIO XII - DIR" },
  { "institution": "faculdade goyazes do distrito federal", "acronym": "FG-DF" },
  { "institution": "centro universitário uniftec bento gonçalves", "acronym": "null" },
  { "institution": "faculdade jk brasília - recanto das emas ii", "acronym": "null" },
  { "institution": "faculdade de tecnologia e ciências de teresina", "acronym": "FTC TERESINA" },
  { "institution": "instituto salesiano de filosofia", "acronym": "INSAF" },
  { "institution": "universidade regional integrada do alto uruguai e das missões", "acronym": "URI" },
  { "institution": "centro universitário uniasselvi de brusque", "acronym": "null" },
  { "institution": "faculdade santa lúcia", "acronym": "FCACSL" },
  { "institution": "centro universitário estácio da bahia", "acronym": "Estácio Bahia" },
  { "institution": "faculdade de filosofia ciências e letras de congonhas", "acronym": "FAFIC" },
  { "institution": "instituto federal de educação, ciência e tecnologia do rio grande do norte", "acronym": "IFRN" },
  { "institution": "centro universitário são lucas ji-paraná", "acronym": "UniSL" },
  { "institution": "faculdade maurício de nassau de blumenau", "acronym": "FMN BLUMENAU" },
  { "institution": "faculdade de jandira", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de rio verde", "acronym": "null" },
  { "institution": "faculdade de tecnologia senac rio", "acronym": "FATEC" },
  { "institution": "faculdade multivix vila velha", "acronym": "MULTIVIX VILA V" },
  { "institution": "faculdade presidente antônio carlos de conselheiro pena", "acronym": "null" },
  { "institution": "faculdade brasília", "acronym": "FBr" },
  { "institution": "faculdade senai da paraíba", "acronym": "null" },
  { "institution": "faculdade de miguel pereira", "acronym": "FAMIPE" },
  { "institution": "faculdade de educação de são mateus", "acronym": "null" },
  { "institution": "instituto de ensino superior agostiniano casa gaia", "acronym": "null" },
  { "institution": "instituto superior de educação do município de itaperuna", "acronym": "ISEMI" },
  { "institution": "faculdade do vale do jaguaribe mossoró", "acronym": "FVJ Mossoró" },
  { "institution": "escola superior nacional de seguros de são paulo", "acronym": "ESNS-SP" },
  { "institution": "instituto federal de educação, ciência e tecnologia de alagoas", "acronym": "IFAL" },
  { "institution": "centro universitário una de contagem", "acronym": "null" },
  { "institution": "faculdade interamericana de porto velho", "acronym": "UNIRON" },
  { "institution": "faculdade de educação memorial adelaide franco", "acronym": "FEMAF" },
  { "institution": "faculdade de medicina de açailândia", "acronym": "null" },
  { "institution": "centro universitário ritter dos reis", "acronym": "UNIRITTER" },
  { "institution": "universidade católica de pernambuco", "acronym": "UNICAP" },
  { "institution": "fatece - faculdade de tecnologia, ciências e educação", "acronym": "FATECE" },
  { "institution": "faculdade cnec capitão lemos cunha", "acronym": "null" },
  { "institution": "faculdade de tecnologia de mauá", "acronym": "FATEC MAUÁ" },
  { "institution": "faculdade de tecnologia senai felix guisard", "acronym": "null" },
  { "institution": "faculdade de tecnologia tecmed", "acronym": "TECMED" },
  { "institution": "faculdade damásio", "acronym": "Damasio" },
  { "institution": "faculdade cesumar de campo grande", "acronym": "FACCESUMAR" },
  { "institution": "centro universitário maurício de nassau de cacoal", "acronym": "UNINASSAU" },
  { "institution": "universidade federal fluminense", "acronym": "UFF" },
  { "institution": "faculdade estácio de passos", "acronym": "ESTÁCIO PASSOS" },
  { "institution": "faculdade ibs", "acronym": "IBS" },
  { "institution": "faculdade invictus", "acronym": "FIRP" },
  { "institution": "faculdade anhanguera jaraguá do sul", "acronym": "null" },
  { "institution": "faculdade de educação paulistana", "acronym": "FAEP" },
  { "institution": "faculdade de ciências médicas de três rios", "acronym": "FCM/TR" },
  { "institution": "universidade estadual de alagoas - uneal", "acronym": "UNEAL" },
  { "institution": "centro universitário newton paiva", "acronym": "NEWTON PAIVA" },
  { "institution": "faculdade de quixeramobim", "acronym": "UniQ" },
  { "institution": "faculdade da associação médica paulista", "acronym": "APM" },
  { "institution": "universidade do vale do itajaí", "acronym": "UNIVALI" },
  { "institution": "faculdade de guarantã do norte", "acronym": "UNIFAMA" },
  { "institution": "faculdade resultados", "acronym": "FR" },
  { "institution": "faculdade metropolitana da grande recife", "acronym": "UNESJ" },
  { "institution": "faculdade sinop", "acronym": "FASIP" },
  { "institution": "faculdade ceafi", "acronym": "null" },
  { "institution": "faculdade de mato grosso", "acronym": "FAMAT" },
  { "institution": "centro universitário das américas", "acronym": "CAM" },
  { "institution": "faculdade pitágoras unopar de joão pessoa", "acronym": "null" },
  { "institution": "faculdade promove de são paulo", "acronym": "FPSP" },
  { "institution": "faculdade são sebastião", "acronym": "null" },
  { "institution": "faculdade evolução", "acronym": "FAEV" },
  { "institution": "faculdade iseib de betim", "acronym": "FISBE" },
  { "institution": "faculdade alvorada de informática e processamento de dados", "acronym": "FAIPD" },
  { "institution": "faculdade estácio de joão pessoa - estácio de joão pessoa", "acronym": "null" },
  { "institution": "faculdade padre anchieta de cajamar", "acronym": "null" },
  { "institution": "universidade estadual de ciências da saúde de alagoas - uncisal", "acronym": "UNCISAL" },
  { "institution": "universidade federal de mato grosso", "acronym": "UFMT" },
  { "institution": "centro universitário tocantinense presidente antônio carlos", "acronym": "UNITPAC" },
  { "institution": "faculdade profissional", "acronym": "FAPRO" },
  { "institution": "instituto itapetiningano de ensino superior", "acronym": "IIES" },
  { "institution": "faculdade adelina moura - faadema", "acronym": "FAADEMA" },
  { "institution": "faculdade dynamus de campinas", "acronym": "FADYC" },
  { "institution": "centro de ensino superior de contagem", "acronym": "null" },
  { "institution": "faculdade de ensino superior de floriano", "acronym": "FAESF" },
  { "institution": "faculdade fasipe pantanal", "acronym": "FASIPE" },
  { "institution": "faculdade hélio rocha", "acronym": "FHR" },
  { "institution": "faculdade sinergia", "acronym": "SINERGIA" },
  { "institution": "centro universitário são josé", "acronym": "UNISJ" },
  { "institution": "faculdade unirb - maceio", "acronym": "Faculdade Unirb" },
  { "institution": "faculdade do amazonas de ensino, pesquisa e inovação", "acronym": "FAMEPI" },
  { "institution": "faculdade ictq/pge", "acronym": "ICTQ/PGE" },
  { "institution": "instituto federal de educação, ciência e tecnologia fluminense", "acronym": "IF Fluminense" },
  { "institution": "centro universitario do recife", "acronym": "UNIPESU" },
  { "institution": "faculdade georgina", "acronym": "FAGEO" },
  { "institution": "ugv centro universitário", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de itambacuri", "acronym": "null" },
  { "institution": "faculdade jesuíta de filosofia e teologia", "acronym": "FAJE" },
  { "institution": "faculdade de tecnologia e ciências - ftc n. sra. do socorro", "acronym": "FTC N S Socorro" },
  { "institution": "faculdade de sistema de informação", "acronym": "ITPAC" },
  { "institution": "universidade federal rural do semi-árido", "acronym": "UFERSA" },
  { "institution": "faculdade de ciências contábeis de araguaína", "acronym": "ITPAC" },
  { "institution": "universidade federal do oeste da bahia", "acronym": "UFOB" },
  { "institution": "faculdade cesumar de londrina", "acronym": "FAC-CESUMAR" },
  { "institution": "faculdade de tecnologia de botucatu", "acronym": "FATEC BT" },
  { "institution": "faculdade anhanguera de matão", "acronym": "null" },
  { "institution": "faculdade promove de curvelo", "acronym": "FACURVELO" },
  { "institution": "instituto federal de educação, ciência e tecnologia catarinense", "acronym": "IF Catarinense" },
  { "institution": "faculdade pinhalzinho", "acronym": "HORUS" },
  { "institution": "faculdade sete lagoas", "acronym": "FACSETE" },
  { "institution": "centro universitário da grande fortaleza", "acronym": "UNIGRANDE" },
  { "institution": "centro universitário salesiano de são paulo", "acronym": "UNISAL" },
  { "institution": "faculdade santo augusto", "acronym": "FAISA" },
  { "institution": "nova faculdade", "acronym": "NF" },
  { "institution": "faculdade docktor", "acronym": "FADOCKTOR" },
  { "institution": "faculdade de tecnologia de são vicente", "acronym": "FATEF" },
  { "institution": "faculdade de ciências humanas do sertão central", "acronym": "FACHUSC" },
  { "institution": "faculdade paulista de artes", "acronym": "FPA" },
  { "institution": "faculdade soberana de jacobina", "acronym": "Soberana" },
  { "institution": "universidade do oeste de santa catarina", "acronym": "UNOESC" },
  { "institution": "faculdade de direito atame", "acronym": "ATAME" },
  { "institution": "instituto de ensino superior do nordeste", "acronym": "IESNE" },
  { "institution": "faculdade kennedy de belo horizonte", "acronym": "FKBH" },
  { "institution": "faculdade luterana de teologia", "acronym": "FLT" },
  { "institution": "centro universitário de joão pessoa", "acronym": "UNIPÊ" },
  { "institution": "faculdade estácio de manaus", "acronym": "Estácio Manaus" },
  { "institution": "instituto superior de educação pró-saber", "acronym": "ISEPS" },
  { "institution": "faculdade peruibe", "acronym": "FPBE" },
  { "institution": "faculdade pernambucana de saúde", "acronym": "FPS" },
  { "institution": "faculdade anhanguera do maranhão", "acronym": "null" },
  { "institution": "faculdade de estudos superiores do maranhão", "acronym": "FESCEMP" },
  { "institution": "faculdade xp educação - igti", "acronym": "XPE" },
  { "institution": "centro universitário unirb", "acronym": "UNIRB" },
  { "institution": "faculdade facmil", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de mariana", "acronym": "FAPAM" },
  { "institution": "faculdade estácio de são josé dos pinhais", "acronym": "null" },
  { "institution": "faculdades integradas de bauru", "acronym": "FIB" },
  { "institution": "faculdade espírito santense de ensino tecnológico", "acronym": "FAESTE" },
  { "institution": "faculdade pan amazônica", "acronym": "FAPAN" },
  { "institution": "faculdade anhanguera de parauapebas", "acronym": "null" },
  { "institution": "faculdade cdl", "acronym": "null" },
  { "institution": "universidade federal do oeste do pará", "acronym": "UFOPA" },
  { "institution": "faculdade endex", "acronym": "Endex" },
  { "institution": "faculdade paulista de comunicação", "acronym": "FEPAC" },
  { "institution": "faculdade américa", "acronym": "América" },
  { "institution": "faculdade de duque de caxias", "acronym": "FDC" },
  { "institution": "faculdade anhanguera de feira de santana", "acronym": "null" },
  { "institution": "faculdade de tecnologia de jales", "acronym": "Fatec Jales" },
  { "institution": "faculdade de tecnologia jardim", "acronym": "FATEJ" },
  { "institution": "centro universitário facvest", "acronym": "UNIFACVEST" },
  { "institution": "faculdade de tecnologia alpha channel", "acronym": "FATAC" },
  { "institution": "centro universitário de araras -", "acronym": "UNAR" },
  { "institution": "universidade federal de sergipe", "acronym": "UFS" },
  { "institution": "faculdade santana", "acronym": "null" },
  { "institution": "faculdade de educação superior iesla", "acronym": "IESLA" },
  { "institution": "faculdade de filosofia ciências e letras dom bosco", "acronym": "FFCLDB" },
  { "institution": "instituto de educação morumbi", "acronym": "IEM" },
  { "institution": "faculdade futura", "acronym": "null" },
  { "institution": "academia nacional de polícia - anp", "acronym": "ANP" },
  { "institution": "faculdade brasileira do recôncavo", "acronym": "FBBR" },
  { "institution": "centro federal de educação tecnológica celso suckow da fonseca", "acronym": "CEFET/RJ" },
  { "institution": "escola superior paulista de administração - espa", "acronym": "ESPA" },
  { "institution": "instituto superior do ministerio publico", "acronym": "ISMP" },
  { "institution": "universidade estadual do rio grande do sul", "acronym": "UERGS" },
  { "institution": "faculdade de ciências da saúde archimedes theodoro", "acronym": "FAC - SAUDE ARTHE" },
  { "institution": "faculdades itecne de cascavel", "acronym": "ITECNE" },
  { "institution": "faculdade anhanguera de vitória da conquista", "acronym": "null" },
  { "institution": "faculdade de tecnologia martinus", "acronym": "FACULDADES MARTINUS" },
  { "institution": "faculdade de tecnologia centec - cariri", "acronym": "CENTEC" },
  { "institution": "faculdade de ciências econômicas de jequié", "acronym": "FCEJ" },
  { "institution": "faculdade anhanguera de valinhos", "acronym": "null" },
  { "institution": "faculdade metropolitana de ciencias e tecnologia", "acronym": "FAMEC" },
  { "institution": "faculdade vitória em cristo", "acronym": "FVC" },
  { "institution": "faculdades est", "acronym": "EST" },
  { "institution": "faculdade associada brasil", "acronym": "FAB" },
  { "institution": "faculdade de educação  acriana euclides da cunha", "acronym": "INEC" },
  { "institution": "centro universitário de brusque", "acronym": "UNIFEBE" },
  { "institution": "faculdade educacional araucária", "acronym": "Facear" },
  { "institution": "faculdade supremo", "acronym": "Supremo" },
  { "institution": "fiam-faam - centro universitário", "acronym": "UNIFIAM-FAAM" },
  { "institution": "centro universitário governador ozanam coelho", "acronym": "UNIFAGOC" },
  { "institution": "faculdade supremo redentor", "acronym": "FACSUR" },
  { "institution": "faculdade do centro oeste paulista", "acronym": "FACOP" },
  { "institution": "fatec cruzeiro - prof. waldomiro may", "acronym": "null" },
  { "institution": "centro universitário da indústria", "acronym": "Unindus" },
  { "institution": "faculdade católica salesiana de macaé", "acronym": "FCSMA" },
  { "institution": "centro universitário de patos de minas", "acronym": "UNIPAM" },
  { "institution": "faculdade de educação de santa catarina", "acronym": "FAESC" },
  { "institution": "faculdade de ciências gerenciais de bicas", "acronym": "FCGB" },
  { "institution": "faculdade evangélica mackenzie do paraná", "acronym": "FEMPAR" },
  { "institution": "faculdade da saúde e ecologia humana", "acronym": "FASEH" },
  { "institution": "faculdade fadam", "acronym": "FADAM" },
  { "institution": "faculdade de educação, tecnologia e administração de caarapó", "acronym": "null" },
  { "institution": "centro universitário - católica de santa catarina em joinville", "acronym": "Católica em Joinvile" },
  { "institution": "faculdade de ciências da saúde de serra talhada", "acronym": "FACISST" },
  { "institution": "escola de comando e estado-maior do exército", "acronym": "ECEME" },
  { "institution": "faculdade jk - guará", "acronym": "JK" },
  { "institution": "centro universitário de ciências e empreendedorismo", "acronym": "UNIFACEMP" },
  { "institution": "faculdade santista - fasan", "acronym": "null" },
  { "institution": "centro universitário de viçosa", "acronym": "UNIVIÇOSA" },
  { "institution": "instituto de ciências sociais e humanas", "acronym": "ICSH" },
  { "institution": "escola de administração e negócios", "acronym": "ESAD" },
  { "institution": "faculdade una de itabira", "acronym": "Una" },
  { "institution": "centro universitário central paulista", "acronym": "UNICEP" },
  { "institution": "universidade estadual da região tocantina do maranhão", "acronym": "UEMASUL" },
  { "institution": "faculdade de administração", "acronym": "ITPAC" },
  { "institution": "faculdade do sertão do araripe", "acronym": "FASA" },
  { "institution": "faculdade network", "acronym": "NWK" },
  { "institution": "faculdade estácio macapá - estácio macapá", "acronym": "ESTÁCIO MACAPÁ" },
  { "institution": "faculdade aliança educacional do estado de são paulo", "acronym": "null" },
  { "institution": "faculdade da seune", "acronym": "SEUNE" },
  { "institution": "faculdade de tecnologia senai porto alegre", "acronym": "FATEC SENAI" },
  { "institution": "faculdade metropolitana de tefé", "acronym": "FAMETRO" },
  { "institution": "faculdade do seridó", "acronym": "FAS" },
  { "institution": "faculdade pitagoras de sete lagoas", "acronym": "null" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro - faeterj", "acronym": "FAETERJ S ANT. PÁDUA" },
  { "institution": "faculdade eduvale de avaré", "acronym": "EDUVALE" },
  { "institution": "faculdade de santa luzia", "acronym": "FACSAL" },
  { "institution": "l2 labor", "acronym": "L2 LABOR" },
  { "institution": "faculdade faipe", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai de desenvolvimento gerencial", "acronym": "FATESG" },
  { "institution": "sistema educacional corporativo da petrobras", "acronym": "null" },
  { "institution": "faculdade lusófona de são paulo", "acronym": "FL-SP" },
  { "institution": "universidade federal de rondonópolis", "acronym": "UFR" },
  { "institution": "faculdade de tecnologia de minas gerais", "acronym": "FATEMG" },
  { "institution": "faculdade católica da paraíba", "acronym": "null" },
  { "institution": "faculdades reunidas da asce", "acronym": "FRASCE" },
  { "institution": "faculdade unesc-unama", "acronym": "null" },
  { "institution": "faculdade conceito educacional", "acronym": "FACCON" },
  { "institution": "faculdade de tecnologia de tatuí - prof. wilson roberto ribeiro de camargo", "acronym": "FATEC TATUÍ" },
  { "institution": "universidade cândido mendes", "acronym": "UCAM" },
  { "institution": "faculdade uninta fortaleza", "acronym": "null" },
  { "institution": "escola superior una de itumbiara", "acronym": "null" },
  { "institution": "faculdade soberana de cachoeiro do itapemirim", "acronym": "SOBERANA" },
  { "institution": "faculdade fortium de brasília", "acronym": "FFB" },
  { "institution": "svt faculdade de ensino superior", "acronym": "SVT" },
  { "institution": "centro universitário ruy barbosa wyden", "acronym": "UniRuy Wyden" },
  { "institution": "faculdade senac são miguel do oeste", "acronym": "null" },
  { "institution": "ensino superior albert sabin jk", "acronym": "ESAS JK" },
  { "institution": "faculdade unicpo", "acronym": "FACULDADE UNICPO" },
  { "institution": "faculdade de ciências humanas e sociais", "acronym": "FCHS" },
  { "institution": "faculdade pitágoras de tecnologia de guarapari", "acronym": "null" },
  { "institution": "faculdade unidas de tatui", "acronym": "FDT" },
  { "institution": "faculdade pitágoras de altamira", "acronym": "null" },
  { "institution": "faculdade de josé bonifácio", "acronym": "FJB" },
  { "institution": "faculdade paraíso araripina", "acronym": "FAP" },
  { "institution": "universidade de uberaba", "acronym": "UNIUBE" },
  { "institution": "faculdade pitágoras unopar de canindé", "acronym": "null" },
  { "institution": "faculdade do centro leste", "acronym": "UCL" },
  { "institution": "centro universitário braz cubas", "acronym": "null" },
  { "institution": "centro universitário aparício carvalho", "acronym": "null" },
  { "institution": "faculdade unime de ciências sociais", "acronym": "FCS" },
  { "institution": "centro universitário tabosa de almeida", "acronym": "ASCES-UNITA" },
  { "institution": "faculdade da aldeia de carapicuíba", "acronym": "FALC" },
  { "institution": "faculdade maranhense são josé dos cocais", "acronym": "FSJ" },
  { "institution": "faculdade paulista de serviço social de são caetano do sul", "acronym": "FAPSS" },
  { "institution": "faculdade castro alves", "acronym": "FCA" },
  { "institution": "faculdade murialdo", "acronym": "FAMUR" },
  { "institution": "faculdade anhanguera de palmas", "acronym": "null" },
  { "institution": "faculdade de frutal", "acronym": "FAF" },
  { "institution": "centro universitário de talentos humanos", "acronym": "Facthus" },
  { "institution": "centro superior de ensino e pesquisa de machado", "acronym": "CESEP" },
  { "institution": "escola superior são judas de guarulhos", "acronym": "null" },
  { "institution": "faculdades integradas do rio grande do norte - fanortes", "acronym": "null" },
  { "institution": "faculdade metropolitana de camaquã", "acronym": "FAMECA" },
  { "institution": "centro universitário do triângulo", "acronym": "UNITRI" },
  { "institution": "escola nacional de administração pública", "acronym": "ENAP" },
  { "institution": "faculdade de administração milton campos", "acronym": "FAMC" },
  { "institution": "instituto superior de educação ceres", "acronym": "ISE-CERES" },
  { "institution": "faculdade pitágoras de cachoeiro de itapemirim", "acronym": "null" },
  { "institution": "strong business school", "acronym": "null" },
  { "institution": "faculdade mais de ituiutaba", "acronym": "FacMais" },
  { "institution": "faculdade anhanguera do amazonas", "acronym": "null" },
  { "institution": "instituto superior de educação do cecap", "acronym": "ISCECAP" },
  { "institution": "faculdade de ciências contábeis de nova andradina - facinan", "acronym": "FACINAN" },
  { "institution": "faculdade anhanguera de teixeira de freitas", "acronym": "null" },
  { "institution": "faculdade de ciências administrativas e contábeis costa braga", "acronym": "FCB" },
  { "institution": "faculdade de tecnologia senai cetind", "acronym": "null" },
  { "institution": "instituto superior de educação manhumirim", "acronym": "FACULDADES DOCTUM" },
  { "institution": "faculdade senac ceara", "acronym": "SENACCE" },
  { "institution": "faculdade iteana de botucatu", "acronym": "FITB" },
  { "institution": "faculdade de tecnologia de joão pessoa", "acronym": "FATEC" },
  { "institution": "centro universitário faveni", "acronym": "UNIFAVENI" },
  { "institution": "faculdade edufor de salvador", "acronym": "EDUFOR" },
  { "institution": "faculdade de tecnologia senai belo horizonte", "acronym": "FATEC SENAI BH" },
  { "institution": "faculdade physio cursos", "acronym": "FACPHYSIO" },
  { "institution": "faculdade de tecnologia senai toledo", "acronym": "null" },
  { "institution": "faculdade de ibiúna", "acronym": "null" },
  { "institution": "faculdade espírito santo", "acronym": "FAES" },
  { "institution": "faculdade de ensino portal", "acronym": "FEP" },
  { "institution": "faculdade g8", "acronym": "FG8" },
  { "institution": "faculdade rio guaribas", "acronym": "FaRG" },
  { "institution": "instituto brasileiro de educacao superior continuada", "acronym": "IBEC" },
  { "institution": "faculdade kennedy de minas gerais - fkmg", "acronym": "FKMG" },
  { "institution": "faculdade de tecnologia fatep", "acronym": "FATEP" },
  { "institution": "faculdades integradas de são carlos", "acronym": "FADISC" },
  { "institution": "faculdade autônoma do brasil - maceió", "acronym": "FBR Maceió" },
  { "institution": "faculdade frassinetti do recife", "acronym": "FAFIRE" },
  { "institution": "faculdade de tecnologia iapec", "acronym": "IAPEC" },
  { "institution": "faculdade especializada na área de saúde do rio grande do sul", "acronym": "FASURGS" },
  { "institution": "faculdade de nova serrana", "acronym": "FANS" },
  { "institution": "faculdade da amazônia legal", "acronym": "FAMA" },
  { "institution": "faculdade de tecnologia de pindamonhangaba", "acronym": "FATEC PI" },
  { "institution": "faculdade de tecnologia padre anchieta", "acronym": "CETEPA" },
  { "institution": "faculdade nacional de rio do sul", "acronym": "FANRIOS" },
  { "institution": "faculdade uniguaçu", "acronym": "FAC UNIGUAÇU" },
  { "institution": "faculdade de tecnologia senai são josé", "acronym": "SENAISC" },
  { "institution": "fundação escola lince kempim", "acronym": "FELK" },
  { "institution": "centro universitário belas artes de são paulo", "acronym": "FEBASP" },
  { "institution": "faculdade de ciências jurídicas de campina grande", "acronym": "null" },
  { "institution": "faculdade j. simões ensino superior", "acronym": "FABAVI" },
  { "institution": "faculdade doctum de sete lagoas", "acronym": "DOCTUM" },
  { "institution": "faculdade ariano suassuna - univas", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de setubinha", "acronym": "FUNEES" },
  { "institution": "universidade federal do agreste de pernambuco", "acronym": "UFAPE" },
  { "institution": "faculdade evangélica de salvador", "acronym": "FACESA" },
  { "institution": "faculdades evangélicas integradas cantares de salomão", "acronym": "FEICS" },
  { "institution": "faculdade unirb - juazeiro", "acronym": "FACULDADE UNIRB" },
  { "institution": "centro de ensino superior de conselheiro lafaiete", "acronym": "CES-CL" },
  { "institution": "faculdade de educação de colorado do oeste", "acronym": "FAEC" },
  { "institution": "faculdade estácio do pará - estácio fap", "acronym": "ESTÁCIO FAP" },
  { "institution": "faculdade de tecnologia ftec", "acronym": "FTEC Novo Hamburgo" },
  { "institution": "centro universitário mauricio de nassau de aracaju", "acronym": "null" },
  { "institution": "faculdade atenas centro de mato grosso", "acronym": "null" },
  { "institution": "faculdade de são carlos", "acronym": "FASC" },
  { "institution": "faculdade tiradentes de goiana", "acronym": "FITS" },
  { "institution": "universidade federal de goiás", "acronym": "UFG" },
  { "institution": "faculdade cognitivo", "acronym": "COGNITIVO" },
  { "institution": "centro universitário da vitória de santo antão", "acronym": "UNIVISA" },
  { "institution": "centro universitário una", "acronym": "UNA" },
  { "institution": "faculdade unida de são paulo - ead", "acronym": "FAUSP" },
  { "institution": "faculdade isaac newton", "acronym": "FACINE" },
  { "institution": "faculdade de formação de professores de serra talhada", "acronym": "FAFOPST" },
  { "institution": "faculdade anglicana de erechim", "acronym": "FAE" },
  { "institution": "faculdade municipal de educação e meio ambiente", "acronym": "FAMA" },
  { "institution": "faculdade intermunicipal do noroeste do paraná", "acronym": "FACINOR" },
  { "institution": "faculdade uninorte tailândia", "acronym": "UNINORTE" },
  { "institution": "faculdade sudamérica", "acronym": "SUDAMÉRICA" },
  { "institution": "faculdade metropolitana são carlos", "acronym": "null" },
  { "institution": "faculdade metropolitana norte riograndense", "acronym": "FAMEN" },
  { "institution": "faculdade uniavan rio do sul", "acronym": "Uniavan" },
  { "institution": "faculdade ana carolina puga", "acronym": "FAPUGA" },
  { "institution": "faculdade pitágoras de concórdia", "acronym": "null" },
  { "institution": "faculdade ceam", "acronym": "CEAM" },
  { "institution": "unama faculdade da amazônia de macapá", "acronym": "null" },
  { "institution": "faculdade de vitória", "acronym": "null" },
  { "institution": "universidade federal de são joão del rei", "acronym": "UFSJ" },
  { "institution": "faculdade de ilha solteira", "acronym": "FAISA" },
  { "institution": "instituto federal de educação, ciência e tecnologia do sudeste de minas gerais", "acronym": "IFSEMG" },
  { "institution": "faculdade monsenhor hipolito", "acronym": "FMH" },
  { "institution": "faculdade anhanguera de assis", "acronym": "null" },
  { "institution": "faculdade anhanguera de taguatinga", "acronym": "null" },
  { "institution": "faculdade pan-americana de administração e direito", "acronym": "FAPAD" },
  { "institution": "faculdades integradas de várzea grande", "acronym": "FIAVEC" },
  { "institution": "centro universitário de sete lagoas", "acronym": "UNIFEMM" },
  { "institution": "faculdade de estudos sociais aplicados de viana", "acronym": "FESAV" },
  { "institution": "escola superior de propaganda e marketing do rio de janeiro", "acronym": "ESPM" },
  { "institution": "centro universitário processus", "acronym": "UNIPROCESSUS" },
  { "institution": "faculdade de administração de mariana", "acronym": "FAMA" },
  { "institution": "faculdade leonardo da vinci de belo horizonte", "acronym": "null" },
  { "institution": "faculdade de tecnologia do rio de janeiro", "acronym": "FATERJ" },
  { "institution": "faculdade de ciências e tecnologia da bahia", "acronym": "FACITE" },
  { "institution": "faculdade católica de abaetetuba", "acronym": "FCAB" },
  { "institution": "faculdade de ciências jurídicas de erechim", "acronym": "null" },
  { "institution": "grupo de nutrição humana", "acronym": "GANEP" },
  { "institution": "faculdade de ensino unificado de belo horizonte", "acronym": "FEUBH" },
  { "institution": "centro universitário tiradentes de pernambuco", "acronym": "UNIT PE" },
  { "institution": "faculdade regional jaguaribana", "acronym": "FRJ" },
  { "institution": "faculdade alphaville", "acronym": "FAVI" },
  { "institution": "centro universitário padre anchieta", "acronym": "UNIANCHIETA" },
  { "institution": "faculdade presidente antônio carlos de vazante", "acronym": "FEES Vazante" },
  { "institution": "faculdade villa-lobos do cone-leste paulista", "acronym": "FAV-COLESP" },
  { "institution": "escola de direito fucape", "acronym": "FUCAPE DIREITO" },
  { "institution": "universidade são marcos", "acronym": "USM" },
  { "institution": "faculdade batista do cariri", "acronym": "FBC" },
  { "institution": "universidade de cuiabá", "acronym": "UNIC/UNIME" },
  { "institution": "faculdade sartre coc", "acronym": "FACOC SALVADOR" },
  { "institution": "centro universitário são lucas", "acronym": "UniSL" },
  { "institution": "faculdade soberana de arapiraca", "acronym": "Soberana" },
  { "institution": "faculdade anhanguera de são bernardo", "acronym": "null" },
  { "institution": "faculdade rio sono", "acronym": "riso" },
  { "institution": "faculdade de enfermagem nova esperança de mossoró - facene/rn", "acronym": "FACENE/RN" },
  { "institution": "faculdade acesita", "acronym": "FACESITA" },
  { "institution": "centro universitário tiradentes", "acronym": "UNIT" },
  { "institution": "faculdade brasileira de tecnologia", "acronym": "FBT" },
  { "institution": "faculdade regional do amazonas", "acronym": "FAM" },
  { "institution": "faculdade ari de sá", "acronym": "FAS" },
  { "institution": "faculdade marista", "acronym": "FM" },
  { "institution": "faculdade metropolitana de anápolis", "acronym": "FAMA" },
  { "institution": "centro universitário integrado de campo mourão", "acronym": "null" },
  { "institution": "faculdade de tecnologia são gonçalo", "acronym": "FATESG" },
  { "institution": "faculdade sanar", "acronym": "SANAR" },
  { "institution": "faculdade santo antônio de pádua", "acronym": "FASAP" },
  { "institution": "faculdade nova roma de campo grande", "acronym": "FNR - Campo Grande" },
  { "institution": "faculdade de educação são francisco", "acronym": "FAESF" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro - faeterj", "acronym": "FAETERJ TRÊS RIOS" },
  { "institution": "faculdade atenas sorriso", "acronym": "null" },
  { "institution": "faculdade joão paulo ii", "acronym": "FAJOP" },
  { "institution": "centro universitário fmabc", "acronym": "FMABC" },
  { "institution": "faculdade de tecnologia do tatuapé", "acronym": "FATEC-TT" },
  { "institution": "faculdade univeritas universus veritas de piracicaba", "acronym": "UNIVERITAS PCBA" },
  { "institution": "faculdade anhanguera de caxias do sul", "acronym": "null" },
  { "institution": "centro universitário sant´anna", "acronym": "UNISANT'ANNA" },
  { "institution": "faculdade estácio do rio grande do sul - estácio fargs", "acronym": "ESTÁCIO FARGS" },
  { "institution": "faculdades unidas de pesquisa, ciências e saúde ltda", "acronym": "FAPEC" },
  { "institution": "famac - faculdade de machadinho do oeste", "acronym": "FAMAC" },
  { "institution": "faculdade gran tietê", "acronym": "FGT" },
  { "institution": "universidade federal de juiz de fora", "acronym": "UFJF" },
  { "institution": "faculdade de ensino superior do interior paulista", "acronym": "FAIP" },
  { "institution": "escola superior de catalão", "acronym": "null" },
  { "institution": "universidade federal do delta do parnaiba", "acronym": "UFDPAR" },
  { "institution": "universidade estadual de feira de santana", "acronym": "UEFS" },
  { "institution": "faculdade presidente antônio carlos de piraúba", "acronym": "FUNEES" },
  { "institution": "faculdade capital federal de itapecerica da serra", "acronym": "FECAF" },
  { "institution": "faculdade pitágoras de cruz das almas", "acronym": "null" },
  { "institution": "faculdade cnec gravataí", "acronym": "null" },
  { "institution": "universidade estadual do norte fluminense darcy ribeiro", "acronym": "UENF" },
  { "institution": "instituto de desenvolvimento e aprendizagem - idea são luiz", "acronym": "IDEA São Luiz" },
  { "institution": "faculdades de campinas", "acronym": "FACAMP" },
  { "institution": "faculdade senac caçador", "acronym": "SENAC" },
  { "institution": "faculdade dom pedro ii de sergipe", "acronym": "DP II Sergipe" },
  { "institution": "instituto superior de ciências humanas e sociais anísio teixeira", "acronym": "ISAT" },
  { "institution": "faculdade de ciências humanas", "acronym": "IMENSU" },
  { "institution": "faculdade de salvador", "acronym": "null" },
  { "institution": "faculdade anhanguera de negócios e tecnologias da informação", "acronym": "null" },
  { "institution": "universidade católica de santos", "acronym": "UNISANTOS" },
  { "institution": "faculdade phorte de educação e tecnologia", "acronym": "null" },
  { "institution": "faculdade pitágoras de paranaguá", "acronym": "null" },
  { "institution": "faculdade de tecnologia e gestão", "acronym": "FATEG" },
  { "institution": "faculdade superior de ribas do rio pardo", "acronym": "FASURP" },
  { "institution": "faculdade universo brasília", "acronym": "null" },
  { "institution": "faculdade são leopoldo mandic de araras", "acronym": "SLMANDIC-ARARAS" },
  { "institution": "instituto nacional de telecomunicações", "acronym": "INATEL" },
  { "institution": "faculdade evangélica de rubiataba", "acronym": "FER" },
  { "institution": "faculdade master", "acronym": "FAC MASTER" },
  { "institution": "centro universitário de belo horizonte", "acronym": "UNI-BH" },
  { "institution": "universidade metropolitana de santos", "acronym": "UNIMES" },
  { "institution": "centro de ensino superior de ilhéus", "acronym": "CESUPI" },
  { "institution": "escola superior de propaganda e marketing", "acronym": "ESPM" },
  { "institution": "instituto tocantinense presidente antonio carlos", "acronym": "ITPAC" },
  { "institution": "faculdade cetrus sanar", "acronym": "CETRUS" },
  { "institution": "faculdade fidelis", "acronym": "FF" },
  { "institution": "faculdade salesiana dom bosco", "acronym": "FSDB" },
  { "institution": "faculdade unypublica", "acronym": "null" },
  { "institution": "faculdade de assis", "acronym": "FASS" },
  { "institution": "faculdade de administração, comércio e empreendedorismo", "acronym": "FACEM" },
  { "institution": "faculdade finaci", "acronym": "FINACI" },
  { "institution": "instituto de estudos superiores do maranhão", "acronym": "IESMA" },
  { "institution": "faculdade anhanguera de betim", "acronym": "null" },
  { "institution": "faculdades pequeno príncipe", "acronym": "FPP" },
  { "institution": "instituto de ensino superior de piedade", "acronym": "IESP" },
  { "institution": "instituto universitário são judas de guarulhos", "acronym": "null" },
  { "institution": "faculdade atenas porto seguro", "acronym": "null" },
  { "institution": "pontifícia universidade católica do rio grande do sul", "acronym": "PUCRS" },
  { "institution": "universidade federal do pará", "acronym": "UFPA" },
  { "institution": "faculdade de tecnologia faesa - unidade guarapari", "acronym": "null" },
  { "institution": "faculdade de são paulo", "acronym": "FASP" },
  { "institution": "centro universitário moura lacerda", "acronym": "CUML" },
  { "institution": "faculdade de administração ciências econ e contábeis de guaratinguetá", "acronym": "FACEAG" },
  { "institution": "instituto nacional de educação de surdos", "acronym": "INES" },
  { "institution": "faculdade da escada", "acronym": "FAESC" },
  { "institution": "faculdade metropolitana de rio do sul", "acronym": "FAMESUL" },
  { "institution": "faculdade unibras do pará", "acronym": "FACBRAS" },
  { "institution": "faculdade doctum de joão monlevade", "acronym": "DOCTUM" },
  { "institution": "instituto federal de educação, ciência e tecnologia do pará", "acronym": "IFPA" },
  { "institution": "faculdade presidente antônio carlos de são francisco", "acronym": "FUNEES São Francisco" },
  { "institution": "escola superior do ministério público", "acronym": "ESCOLAMP" },
  { "institution": "faculdade múltipla", "acronym": "null" },
  { "institution": "faculdade ibcmed", "acronym": "IBCMED" },
  { "institution": "faculdade de tecnologia de araras", "acronym": "null" },
  { "institution": "faculdade de filosofia ciências e letras de tatuí", "acronym": "FAFICILE" },
  { "institution": "faculdade rodolfo teófilo", "acronym": "FRT" },
  { "institution": "instituto de ensino superior blauro cardoso de mattos", "acronym": "FASERRA" },
  { "institution": "faculdade pitagoras de eunapolis", "acronym": "FPE" },
  { "institution": "faculdades resende de freitas", "acronym": "UESRF" },
  { "institution": "faculdade doctum da zona norte de juiz de fora", "acronym": "Doctum" },
  { "institution": "instituto superior de educação carlos chagas", "acronym": "ISECC" },
  { "institution": "faculdade de tecnologia ícone", "acronym": "FACTI" },
  { "institution": "faculdade anhangüera de ribeirão preto", "acronym": "null" },
  { "institution": "faculdade cenecista de fortaleza", "acronym": "FACEFOR" },
  { "institution": "instituto una de itabira", "acronym": "Una" },
  { "institution": "faculdade uníntese", "acronym": "null" },
  { "institution": "instituto federal de educação, ciência e tecnologia do sul de minas gerais", "acronym": "IF SUL DE MINAS" },
  { "institution": "universidade federal do triângulo mineiro", "acronym": "UFTM" },
  { "institution": "faculdade unifametro maracanaú", "acronym": "null" },
  { "institution": "faculdade senac chapecó", "acronym": "SENAC" },
  { "institution": "faculdade do futuro", "acronym": "FAF" },
  { "institution": "faculdade tecsoma", "acronym": "FATEC" },
  { "institution": "faculdade anhanguera de osasco", "acronym": "null" },
  { "institution": "faculdade de ensino superior pelegrino cipriani", "acronym": "FASPEC" },
  { "institution": "centro universitário metropolitano de são paulo", "acronym": "UNIMESP" },
  { "institution": "faculdade nossa senhora da conceição", "acronym": "FSC" },
  { "institution": "faculdade aliança de itaberai", "acronym": "null" },
  { "institution": "faculdade de vargem grande paulista", "acronym": "null" },
  { "institution": "faculdades integradas da união de ensino superior certo", "acronym": "UNICERTO" },
  { "institution": "faculdade única de timóteo", "acronym": "FUNIT" },
  { "institution": "universidade nilton lins", "acronym": "UNINILTONLINS" },
  { "institution": "faculdade integrada de santa maria", "acronym": "FISMA" },
  { "institution": "faculdade cândido rondon de rondonópolis", "acronym": "FCRROO" },
  { "institution": "faculdade ease brasil", "acronym": "EASE" },
  { "institution": "faculdade machado sobrinho", "acronym": "FMS" },
  { "institution": "faculdade unime de ciências jurídicas", "acronym": "FCJ" },
  { "institution": "faculdade m-educar", "acronym": "FAMED" },
  { "institution": "instituto de educação superior unyahna de salvador", "acronym": "IESUS" },
  { "institution": "faculdade canadenses no brasil", "acronym": "null" },
  { "institution": "faculdade una de conselheiro lafaiete", "acronym": "null" },
  { "institution": "faculdade unibf", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de bambuí", "acronym": "FUNEES" },
  { "institution": "universidade do estado da bahia", "acronym": "UNEB" },
  { "institution": "icev - instituto de ensino superior", "acronym": "null" },
  { "institution": "faculdade de tecnologia do instituto bandeirante de educação e cultura", "acronym": "IBEC" },
  { "institution": "centro universitário gama e souza", "acronym": "null" },
  { "institution": "centro universitário de bauru", "acronym": "null" },
  { "institution": "faculdade do vale do caju", "acronym": "FVC" },
  { "institution": "faculdades integradas campos salles", "acronym": "FICS" },
  { "institution": "faculdade terra nordeste", "acronym": "FATENE" },
  { "institution": "instituto superior de ciências da saúde carlos chagas", "acronym": "IPGMCC" },
  { "institution": "faculdade ubs", "acronym": "FACULDADE UBS" },
  { "institution": "faculdade santa rita", "acronym": "FASAR" },
  { "institution": "faculdade de tecnologia senai mato grosso", "acronym": "FATEC SENAI MT" },
  { "institution": "faculdades batista do paraná", "acronym": "FABAPAR" },
  { "institution": "faculdade univeritas universus veritas de campo grande", "acronym": "null" },
  { "institution": "faculdade verbo educacional", "acronym": "VERBOEDU" },
  { "institution": "faculdade anhanguera de jaú", "acronym": "FAJ" },
  { "institution": "faculdade nove de julho de botucatu", "acronym": "NOVE-BOTUCATU" },
  { "institution": "faculdade arquidiocesana de curvelo", "acronym": "FAC" },
  { "institution": "centro universitário facid wyden", "acronym": "UNIFACID WYDEN" },
  { "institution": "instituto de ensino superior integrado-iesi", "acronym": "FENORD" },
  { "institution": "faculdade iteana de ibitinga", "acronym": "FITI" },
  { "institution": "faculdade faveni guarulhos", "acronym": "FAVENI - GUARULHOS" },
  { "institution": "faculdade de tecnologia senac minas - unidade barbacena", "acronym": "null" },
  { "institution": "faculdade de tecnologia de sorocaba", "acronym": "FATEC SO" },
  { "institution": "faculdade de direito de conselheiro lafaiete", "acronym": "FDCL" },
  { "institution": "faculdade montessoriano de salvador", "acronym": "FAMA" },
  { "institution": "faculdade cândido rondon", "acronym": "FCR" },
  { "institution": "faculdade santos dumont", "acronym": "FSD" },
  { "institution": "faculdade de ensino paschoal dantas", "acronym": "FEPD" },
  { "institution": "centro universitário adventista de são paulo", "acronym": "UNASP" },
  { "institution": "faculdade método de são paulo", "acronym": "FAMESP" },
  { "institution": "centro universitário carlos drummond de andrade", "acronym": "UniDrummond" },
  { "institution": "universidade do estado de minas gerais", "acronym": "UEMG" },
  { "institution": "fundação universidade virtual do estado de são paulo", "acronym": "UNIVESP" },
  { "institution": "escola de sargentos das armas", "acronym": "ESA" },
  { "institution": "faculdade teológica batista ana wollerman", "acronym": "FTBAW" },
  { "institution": "centro universitário - uniesp", "acronym": "UNIESP" },
  { "institution": "faculdade cesumar de maringá", "acronym": "FAC-CESUMAR" },
  { "institution": "faculdade de tecnologia senai ítalo bologna", "acronym": "FATECIB" },
  { "institution": "faculdade santo andré", "acronym": "FASA" },
  { "institution": "faculdade de presidente prudente", "acronym": "FAPEPE" },
  { "institution": "instituto matão de ensino superior", "acronym": "IMES" },
  { "institution": "fiap - centro universitário", "acronym": "null" },
  { "institution": "faculdade de viçosa", "acronym": "FDV" },
  { "institution": "faculdade estácio de jaraguá do sul", "acronym": "Estácio Jaraguá" },
  { "institution": "faculdade de jaú", "acronym": "null" },
  { "institution": "universidade católica de pelotas", "acronym": "UCPEL" },
  { "institution": "colégio pedro ii", "acronym": "CP II" },
  { "institution": "centro universitário santa amélia", "acronym": "SECAL" },
  { "institution": "faculdade alvorada de saúde", "acronym": "null" },
  { "institution": "faculdade de educação ciências e letras de paraíso", "acronym": "FECIPAR" },
  { "institution": "centro universitário estácio de ji-paraná", "acronym": "ESTÁCIO UNIJIPA" },
  { "institution": "faculdade uninorte marabá", "acronym": "Uninorte Marabá" },
  { "institution": "faculdade lusocapixaba", "acronym": "FLC" },
  { "institution": "centro universitário piaget", "acronym": "UniPiaget" },
  { "institution": "faculdade presidente antônio carlos de são joão del rei", "acronym": "FUNEES SJDR" },
  { "institution": "escola de aperfeiçoamento de oficiais", "acronym": "EsAO" },
  { "institution": "faculdade presidente antônio carlos de carmo do paranaíba", "acronym": "FUNEES C. Paranaíba" },
  { "institution": "faculdade católica santa teresinha", "acronym": "FCST" },
  { "institution": "faculdade batista do estado do rio de janeiro - faberj", "acronym": "FABERJ" },
  { "institution": "faculdade de tecnologia e ciências - ftc camaçari", "acronym": "FTC CAMAÇARI" },
  { "institution": "faculdade de ciências e tecnologia do paraná", "acronym": "FACET-PR" },
  { "institution": "faculdade antônio meneghetti", "acronym": "AMF" },
  { "institution": "faculdade de penedo", "acronym": "FAC-PENEDO" },
  { "institution": "faculdade vale do pajeu", "acronym": "FVP" },
  { "institution": "instituto de educação superior pio xii", "acronym": "PIO XII" },
  { "institution": "centro universitário de pato branco", "acronym": "UNIDEP" },
  { "institution": "faculdade fae blumenau", "acronym": "null" },
  { "institution": "escola superior de marketing", "acronym": "ESM" },
  { "institution": "fap-faculdade de pinheiros", "acronym": "FAP" },
  { "institution": "escola de inteligência militar do exército", "acronym": "EsIMEx" },
  { "institution": "faculdade sul paulista de itanhaém", "acronym": "FASUPI" },
  { "institution": "centro universitário la salle", "acronym": "UNILASALLE - LUCAS" },
  { "institution": "faculdade damas da instrução cristã", "acronym": "FADIC" },
  { "institution": "universidade federal de uberlândia", "acronym": "UFU" },
  { "institution": "centro universitário são miguel", "acronym": "UNISÃOMIGUEL" },
  { "institution": "faculdade uninassau lauro de freitas", "acronym": "null" },
  { "institution": "faculdade pitágoras de penápolis", "acronym": "null" },
  { "institution": "faculdade do oeste potiguar", "acronym": "FAOP" },
  { "institution": "universidade federal de minas gerais", "acronym": "UFMG" },
  { "institution": "faculdade estácio de goiânia", "acronym": "Estácio Goiânia" },
  { "institution": "faculdade presidente antônio carlos de campina verde", "acronym": "null" },
  { "institution": "faculdade interativa de são paulo", "acronym": "FAISP" },
  { "institution": "faculdade cmb", "acronym": "CMB" },
  { "institution": "centro universitário do distrito federal", "acronym": "UDF" },
  { "institution": "faculdade de educação eliâ", "acronym": "FACEEL" },
  { "institution": "centro universitário da fundação educacional guaxupé", "acronym": "UNIFEG" },
  { "institution": "universidade anhanguera", "acronym": "UNIDERP" },
  { "institution": "escola superior de ciências da saúde", "acronym": "ESCS" },
  { "institution": "faculdade do centro maranhense", "acronym": "FCMA" },
  { "institution": "faculdade nacional de naturopatia  administração e pedagogia", "acronym": "FANNAP" },
  { "institution": "faculdade essa educacao profissional", "acronym": "UNIESSA" },
  { "institution": "centro universitário natalense", "acronym": "UNICEUNA" },
  { "institution": "instituto superior de educação de pesqueira", "acronym": "ISEP" },
  { "institution": "faculdade presidente antônio carlos de teófilo otoni", "acronym": "null" },
  { "institution": "faculdade metropolitana do ceará", "acronym": "FAMEC" },
  { "institution": "faculdade pio décimo", "acronym": "FPD" },
  { "institution": "centro universitário alfredo nasser", "acronym": "UNIFAN" },
  { "institution": "centro de ensino unificado do piauí", "acronym": "CEUPI" },
  { "institution": "centro universitário euro-americano", "acronym": "UNIEURO" },
  { "institution": "faculdade unirb joão pessoa", "acronym": "F.UNIRB" },
  { "institution": "faculdade pan americana", "acronym": "FPA" },
  { "institution": "faculdade presidente antônio carlos de buritis", "acronym": "FUNEES Buritis" },
  { "institution": "centro universitário maurício de nassau", "acronym": "UNINASSAU" },
  { "institution": "faculdades do vale do ararangua - fva", "acronym": "FVA" },
  { "institution": "centro universitário maurício de nassau de fortaleza", "acronym": "UNINASSAU FORTALEZA" },
  { "institution": "faculdade rachel de queiroz", "acronym": "FAQ" },
  { "institution": "centro universitário anhanguera pitágoras unopar de niterói", "acronym": "UNIAN-RJ" },
  { "institution": "faculdade de educação sul do piauí", "acronym": "null" },
  { "institution": "universidade do contestado", "acronym": "UNC" },
  { "institution": "faculdade de umuarama", "acronym": "null" },
  { "institution": "faculdade de belo horizonte", "acronym": "FDR" },
  { "institution": "faculdade vincit", "acronym": "VINCIT" },
  { "institution": "faculdade vidal", "acronym": "FAVILI" },
  { "institution": "faculdade cristo rei", "acronym": "FACCREI" },
  { "institution": "faculdade sudoeste paulistano", "acronym": "FASUP" },
  { "institution": "faculdade instituto de ensino superior", "acronym": "FACIES" },
  { "institution": "faculdade anhanguera de paragominas", "acronym": "FPP" },
  { "institution": "faculdade alfa américa", "acronym": "null" },
  { "institution": "faculdade abc de goiânia", "acronym": "FABC" },
  { "institution": "faculdade casa branca", "acronym": "FACAB" },
  { "institution": "faculdade presidente antônio carlos de felixlândia", "acronym": "FUNEES" },
  { "institution": "faculdade marechal rondon", "acronym": "null" },
  { "institution": "faculdade de tecnologia santo andré", "acronym": "FATEC-SA" },
  { "institution": "faculdade cristo rei", "acronym": "FACCREI" },
  { "institution": "faculdade anhanguera de passo fundo", "acronym": "null" },
  { "institution": "universidade estadual de maringá", "acronym": "UEM" },
  { "institution": "faculdade presidente antônio carlos de piranguinho", "acronym": "FUNEES Piranguinho" },
  { "institution": "faculdade dos carajás", "acronym": "null" },
  { "institution": "epge escola brasileira de economia e finanças", "acronym": "FGV EPGE" },
  { "institution": "fundação dom cabral", "acronym": "null" },
  { "institution": "faculdade de tecnologia zona leste", "acronym": "FATEC-ZL" },
  { "institution": "faculdade integrada de goiás", "acronym": "FIG" },
  { "institution": "faculdade maurício de nassau de ponta grossa", "acronym": "FMN PONTAGROSSA" },
  { "institution": "escola da advocacia-geral da união", "acronym": "EAGU" },
  { "institution": "faculdade alfredo nasser de pontalina", "acronym": "null" },
  { "institution": "faculdade do povo", "acronym": "FAP" },
  { "institution": "instituto de educação superior de pouso alegre", "acronym": "null" },
  { "institution": "faculdade de tecnologia do brasil", "acronym": "FAT Brasil" },
  { "institution": "faculdades famep -  unidade uruçui - pi", "acronym": "FAMEP" },
  { "institution": "faculdade de ensino superior santa barbara", "acronym": "FAESB" },
  { "institution": "escola de saúde do exército", "acronym": "EsSEx" },
  { "institution": "faculdades famep unidade parnaíba - pi", "acronym": "FAMEP" },
  { "institution": "faculdade de olímpia", "acronym": "null" },
  { "institution": "universidade estadual da paraíba", "acronym": "UEPB" },
  { "institution": "faculdade estácio de rio preto", "acronym": "Estácio Rio Preto" },
  { "institution": "faculdade metropolitana de palhoça", "acronym": "FAMEPALHOÇA" },
  { "institution": "faculdade sul fluminense", "acronym": "FASF" },
  { "institution": "faculdade de teologia de hokemãh", "acronym": "FATEH" },
  { "institution": "fae centro universitário", "acronym": "null" },
  { "institution": "universidade brasil", "acronym": "UB" },
  { "institution": "universidade do estado do pará", "acronym": "UEPA" },
  { "institution": "faculdade bi social quaresma", "acronym": "null" },
  { "institution": "faculdade afonso schmidt", "acronym": "FAS" },
  { "institution": "faculdade nova roma", "acronym": "FNR" },
  { "institution": "faculdade são camilo", "acronym": "FASC" },
  { "institution": "instituto superior de educação do alto são francisco", "acronym": "ISEASF" },
  { "institution": "faculdade de tecnologia cetep", "acronym": "CETEP" },
  { "institution": "faculdade anhanguera de anápolis", "acronym": "null" },
  { "institution": "centro universitário sul-americana", "acronym": "UNIFASAM" },
  { "institution": "faculdade de ciências sociais aplicadas do vale do são lourenço", "acronym": "EDUVALE" },
  { "institution": "faculdade unirb - aracaju", "acronym": "F. UNIRB" },
  { "institution": "faculdade interlagos de educação e cultura", "acronym": "FINTEC" },
  { "institution": "centro universitário de goiânia", "acronym": "UNICEUG" },
  { "institution": "escola de políticas públicas e governo da fundação getulio vargas", "acronym": "FGV EPPG" },
  { "institution": "centro universitário de formiga", "acronym": "UNIFORMG" },
  { "institution": "faculdade fae sévigné porto alegre", "acronym": "FAE SÉVIGNÉ" },
  { "institution": "faculdade unirb - barreiras", "acronym": "F. UNIRB" },
  { "institution": "faculdades oswaldo cruz", "acronym": "FOC" },
  { "institution": "faculdade inspirar", "acronym": "Inspirar" },
  { "institution": "instituto educacional das américas", "acronym": "IEA Educacional" },
  { "institution": "faculdade de tecnologia cna", "acronym": "FATECNA" },
  { "institution": "faculdade afirmativo", "acronym": "FAFI" },
  { "institution": "faculdade senac tubarão", "acronym": "null" },
  { "institution": "faculdades integradas simonsen", "acronym": "FIS" },
  { "institution": "faculdade ibmec de brasília", "acronym": "Ibmec Brasília" },
  { "institution": "universidade federal de alfenas", "acronym": "UNIFAL-MG" },
  { "institution": "faculdade do cariri paraibano", "acronym": "UNICIR" },
  { "institution": "faculdade skema business school", "acronym": "SKEMA" },
  { "institution": "faculdade de cariacica", "acronym": "FAC DE CARIACICA" },
  { "institution": "faculdade de ciências humanas de vitória", "acronym": "FAVIX" },
  { "institution": "faculdade são tomás de aquino de nova lima", "acronym": "FACTAN" },
  { "institution": "faculdade de ciências educacionais e empresariais de natal", "acronym": "FACEN" },
  { "institution": "faculdade fisepe", "acronym": "FISEPE" },
  { "institution": "faculdade de desenho industrial de mauá", "acronym": "FADIM" },
  { "institution": "faculdade presidente antônio carlos de jaboticatubas", "acronym": "FUNEES" },
  { "institution": "faculdade  do sertão central  ead", "acronym": "FASEC EAD" },
  { "institution": "faculdade católica de são josé dos campos", "acronym": "CATÓLICA-SJC" },
  { "institution": "faculdade de taquaritinga", "acronym": "FTGA" },
  { "institution": "universidade santa cecília", "acronym": "UNISANTA" },
  { "institution": "faculdade uninassau cabo", "acronym": "null" },
  { "institution": "universidade estadual paulista júlio de mesquita filho", "acronym": "UNESP" },
  { "institution": "faculdade norte capixaba de são mateus - famat", "acronym": "FAMAT" },
  { "institution": "faculdade bravium", "acronym": "BRAVIUM" },
  { "institution": "faculdade presidente antônio carlos de senhora dos remédios", "acronym": "null" },
  { "institution": "faculdade de tecnologia interamérica", "acronym": "INTERAMERICA" },
  { "institution": "universidade estadual de mato grosso do sul", "acronym": "UEMS" },
  { "institution": "faculdade anhanguera de ponta grossa", "acronym": "null" },
  { "institution": "faculdade cerrado", "acronym": "FACE" },
  { "institution": "faculdade da polícia militar de santa catarina", "acronym": "FAPOM" },
  { "institution": "faculdade são francisco de cajazeiras", "acronym": "FSF" },
  { "institution": "centro universitário guairacá", "acronym": "UNIGUAIRACÁ" },
  { "institution": "universidade federal rural de pernambuco", "acronym": "UFRPE" },
  { "institution": "faculdade cesar", "acronym": "FCE" },
  { "institution": "faculdade de agudos", "acronym": "FAAG" },
  { "institution": "centro universitário para o desenvolvimento do alto vale do itajaí", "acronym": "UNIDAVI" },
  { "institution": "faculdade são luís", "acronym": "FSL" },
  { "institution": "faculdade do norte novo de apucarana", "acronym": "FACNOPAR" },
  { "institution": "instituto de ensino superior do oeste paulista", "acronym": "IESOP" },
  { "institution": "faculdade de tecnologia fundação universitária de cardiologia", "acronym": "null" },
  { "institution": "faculdade uninassau olinda - nassau olinda", "acronym": "null" },
  { "institution": "faculdades unificadas de foz do iguaçu", "acronym": "UNIFOZ" },
  { "institution": "faculdade pitágoras de  caruaru", "acronym": "null" },
  { "institution": "faculdade de campina grande", "acronym": "FAC-CG" },
  { "institution": "instituto de ensino superior santo antônio", "acronym": "INESA" },
  { "institution": "faculdade senac palhoça", "acronym": "SENAC Palhoça" },
  { "institution": "centro de estudos superiores de jataí", "acronym": "null" },
  { "institution": "faculdade estácio de cotia", "acronym": "Estacio Cotia" },
  { "institution": "faculdade piauiense de processamento de dados", "acronym": "FPPD" },
  { "institution": "faculdade de sabará", "acronym": "SOECS" },
  { "institution": "faculdade presidente antônio carlos de baependi", "acronym": "FAPAC BAEPENDI" },
  { "institution": "faculdade de ciências e letras de bragança paulista", "acronym": "FESB" },
  { "institution": "centro universitário maurício de nassau do rio de janeiro", "acronym": "UNINASSAU" },
  { "institution": "centro universitário sete de setembro", "acronym": "UNI7" },
  { "institution": "universidade vila velha", "acronym": "UVV" },
  { "institution": "faculdade oboé - faco", "acronym": "FACO" },
  { "institution": "centro universitário nobre de feira de santana", "acronym": "null" },
  { "institution": "faculdade cidade teológica pentecostal", "acronym": "FCTP" },
  { "institution": "universidade federal de catalão", "acronym": "UFCAT" },
  { "institution": "faculdade do instituto nacional de pós-graduação de são josé dos campos", "acronym": "FACULDADE INPG - SJC" },
  { "institution": "faculdade serra do carmo", "acronym": "FASEC" },
  { "institution": "instituto superior da afac", "acronym": "ISAFAC" },
  { "institution": "instituto taquaritinguense de ensino superior dr. aristides de carvalho schlobach", "acronym": "ITES" },
  { "institution": "faculdade santa fé", "acronym": "CESSF" },
  { "institution": "centro universitário mário palmério", "acronym": "UNIFUCAMP" },
  { "institution": "faculdade ages de jeremoabo", "acronym": "AGES" },
  { "institution": "centro universitário sagrado coração", "acronym": "UNISAGRADO" },
  { "institution": "faculdade dois de julho", "acronym": "F2J" },
  { "institution": "faculdade vizinhança vale do iguaçu", "acronym": "VIZIVALI" },
  { "institution": "faculdade anhanguera de salvador", "acronym": "FACDELTA" },
  { "institution": "instituto centro de ensino tecnológico - limoeiro do norte", "acronym": "CENTEC" },
  { "institution": "faculdade de tecnologia nilo de stéfani - jaboticabal", "acronym": "FATEC JBL" },
  { "institution": "instituto de educação superior do tapajós", "acronym": "ISET" },
  { "institution": "faculdade de indiara", "acronym": "FAIND" },
  { "institution": "faculdade evangélica de teologia de belo horizonte", "acronym": "FATE-BH" },
  { "institution": "faculdade geremário dantas", "acronym": "SFNSC" },
  { "institution": "faculdades integradas de itapetininga", "acronym": "FII" },
  { "institution": "faculdade de rondônia", "acronym": "FARO" },
  { "institution": "centro universitário paraíso", "acronym": "null" },
  { "institution": "faculdade de são bento", "acronym": "FSB" },
  { "institution": "faculdade de tecnologia do amapá", "acronym": "META" },
  { "institution": "faculdade presidente antônio carlos de congonhas", "acronym": "null" },
  { "institution": "faculdade de tecnologia de ferraz de vasconcelos", "acronym": "null" },
  { "institution": "faculdade redentor de paraíba do sul", "acronym": "FACREDENTOR" },
  { "institution": "faculdade amadeus", "acronym": "FAMA" },
  { "institution": "faculdade atenas passos", "acronym": "null" },
  { "institution": "faculdade mário quintana", "acronym": "FAMAQUI" },
  { "institution": "faculdade impactos - faci", "acronym": "FACI" },
  { "institution": "instituto friburgo de ensino superior", "acronym": "IFES" },
  { "institution": "instituto superior de educação do alto são francisco", "acronym": "ISAF" },
  { "institution": "faculdade estácio de imperatriz - estácio imperatriz", "acronym": "null" },
  { "institution": "faculdade de desenvolvimento do norte", "acronym": "FADENORTE" },
  { "institution": "claretiano - centro universitário", "acronym": "CLARETIANOBT" },
  { "institution": "faculdade metropolitana de parintins", "acronym": "FAMETRO" },
  { "institution": "faculdade una de pouso alegre", "acronym": "Una PousoAlegre" },
  { "institution": "faculdade teológica de ciências humanas e sociais logos", "acronym": "FAETEL" },
  { "institution": "faculdade de ensino superior e formação integral", "acronym": "FAEF" },
  { "institution": "faculdade de ciências da saúde", "acronym": "FACS" },
  { "institution": "faculdade de teologia da arquidiocese de brasília", "acronym": "FATEO" },
  { "institution": "faculdade cesumar de guarapuava", "acronym": "FAC-CESUMAR" },
  { "institution": "faculdade de medicina de itajubá", "acronym": "FMIT" },
  { "institution": "faculdades famep", "acronym": "FAMEP" },
  { "institution": "escola de educação física do exército", "acronym": "EsEFEx" },
  { "institution": "faculdade palotina", "acronym": "FAPAS" },
  { "institution": "faculdade gil gal", "acronym": "GILGAL" },
  { "institution": "centro universitário de excelência eniac", "acronym": "ENIAC" },
  { "institution": "complexo de ensino superior de palmas", "acronym": "CESUP" },
  { "institution": "fiep - faculdade internacional de evolução profissional", "acronym": "FIEP" },
  { "institution": "centro universitário da fundação educacional inaciana pe sabóia de medeiros", "acronym": "FEI" },
  { "institution": "instituto superior de educação albert einstein", "acronym": "ISALBE" },
  { "institution": "faculdade de tecnologia e ciências - ftc parnamirim", "acronym": "FTC Parnamirim" },
  { "institution": "faculdade de tecnologia de ensino superior", "acronym": "FATEC" },
  { "institution": "faculdade instituto rio de janeiro", "acronym": "FIURJ" },
  { "institution": "faculdade metropolitana de florianópolis", "acronym": "FAMEFLORIPA" },
  { "institution": "faculdade promove de sete lagoas", "acronym": "FSLMG" },
  { "institution": "faculdade intercultural da amazonia", "acronym": "FIAMA" },
  { "institution": "centro universitário social da bahia", "acronym": "UNISBA" },
  { "institution": "faculdade psicolog", "acronym": "FAPSI" },
  { "institution": "universidade estadual do maranhão", "acronym": "UEMA" },
  { "institution": "centro universitário de caratinga", "acronym": "UNEC" },
  { "institution": "faculdade ibra dos vales", "acronym": "FACULDADE FADIVALES" },
  { "institution": "centro federal de educação tecnológica de rio verde", "acronym": "CEFET-RIO VERDE" },
  { "institution": "faculdade pitágoras de joão pessoa", "acronym": "null" },
  { "institution": "faculdade brasileira de estudos avançados", "acronym": "FABEA" },
  { "institution": "faculdade de ensino de minas gerais", "acronym": "FACEMG" },
  { "institution": "ftrb - faculdade teológica reformada de brasília", "acronym": "null" },
  { "institution": "centro universitário de ensino, ciência e tecnologia do paraná", "acronym": "UniEnsino" },
  { "institution": "faculdade do sertão central", "acronym": "FASEC" },
  { "institution": "instituto federal de educação, ciência e tecnologia  do triângulo mineiro", "acronym": "IFTM" },
  { "institution": "faculdade maurício de nassau de duque de caxias", "acronym": "FMN CAXIAS" },
  { "institution": "faculdade de ciências humanas e sociais de araripina - facisa", "acronym": "FACISA" },
  { "institution": "faculdade de vila velha", "acronym": "null" },
  { "institution": "instituto universitário una de catalão", "acronym": "Catalana" },
  { "institution": "faculdade do recife", "acronym": "FAREC" },
  { "institution": "faculdade de sertãozinho", "acronym": "null" },
  { "institution": "faculdade senai são paulo", "acronym": "SENAI SP" },
  { "institution": "faculdade porto união", "acronym": "FPU" },
  { "institution": "faculdade de cafelândia", "acronym": "FAC" },
  { "institution": "faculdade união cultural do estado de são paulo", "acronym": "UCESP" },
  { "institution": "instituto superior de educação de duque de caxias", "acronym": "ISEDUC" },
  { "institution": "faculdade presidente antônio carlos de campos altos", "acronym": "FUNEES Campos Altos" },
  { "institution": "instituto superior de educação paulo martins", "acronym": "ISPAM" },
  { "institution": "faculdade promove de belo horizonte", "acronym": "PROMOVE" },
  { "institution": "universidade nove de julho", "acronym": "UNINOVE" },
  { "institution": "faculdade porto das águas", "acronym": "FAPAG" },
  { "institution": "faculdade evangélica de são paulo", "acronym": "null" },
  { "institution": "faculdade bela vista", "acronym": "FBV" },
  { "institution": "faculdade católica dom orione", "acronym": "FACDO" },
  { "institution": "faculdade anhanguera de sertãozinho", "acronym": "FASERT" },
  { "institution": "faculdade de tecnologia de cotia", "acronym": "FATEC COTIA" },
  { "institution": "centro universitário fundação de ensino octávio bastos - feob", "acronym": "UNIFEOB" },
  { "institution": "universidade alto vale do rio do peixe", "acronym": "UNIARP" },
  { "institution": "faculdade plus", "acronym": "PLUS" },
  { "institution": "faculdade do litoral sul paulista", "acronym": "FALS" },
  { "institution": "instituto salvador de ensino e cultura", "acronym": "ISEC" },
  { "institution": "faculdade atame", "acronym": "ATAME" },
  { "institution": "faculdade uniaba", "acronym": "FAC UNIABA" },
  { "institution": "faculdades integradas de são paulo", "acronym": "FISP" },
  { "institution": "faculdade de tecnologia de catalão", "acronym": "FATECA" },
  { "institution": "faculdade nacional do norte do paraná", "acronym": "FANNORTE" },
  { "institution": "faculdade de tecnologia pitágotas - unidade londrina", "acronym": "null" },
  { "institution": "falculdade presidente antônio carlos de divisa alegre", "acronym": "FUNEES Divisa Alegre" },
  { "institution": "faculdade do educador", "acronym": "FEDUC" },
  { "institution": "faculdade asa de brumadinho", "acronym": "IECEMB - FAB" },
  { "institution": "faculdade una de divinópolis", "acronym": "Una Divinopolis" },
  { "institution": "faculdade são francisco de assis", "acronym": "FASFA" },
  { "institution": "universidade estadual de roraima", "acronym": "UERR" },
  { "institution": "faculdade reunida", "acronym": "FAR" },
  { "institution": "faculdade de tecnologia de santana de parnaíba", "acronym": "FATEC-SPB" },
  { "institution": "centro universitário dinâmica das cataratas", "acronym": "UDC" },
  { "institution": "faculdade anhanguera de poços de caldas", "acronym": "null" },
  { "institution": "faculdades fatifajar - fatifajar arapoti", "acronym": "null" },
  { "institution": "faculdade de tecnologia senac passo fundo", "acronym": "SENAC PASSO FUNDO" },
  { "institution": "faculdade projeção do guará", "acronym": "null" },
  { "institution": "escola superior de relações públicas", "acronym": "ESURP" },
  { "institution": "faculdade uninassau patos", "acronym": "NASSAU PATOS" },
  { "institution": "faculdade de ciências da saúde moinhos de vento", "acronym": "FACSMV" },
  { "institution": "faculdade morgana potrich", "acronym": "FAMP" },
  { "institution": "faculdade presidente antônio carlos rio novo", "acronym": "FUNEES" },
  { "institution": "faculdade presidente antônio carlos de arcos", "acronym": "FUNEES Arcos" },
  { "institution": "universidade de franca", "acronym": "UNIFRAN" },
  { "institution": "faculdade sagrada família", "acronym": "FASF" },
  { "institution": "centro universitário ls", "acronym": "UNILS" },
  { "institution": "conservatório de música de niterói", "acronym": "CMN" },
  { "institution": "faculdade master do pará - famap tucumã", "acronym": "FAMAP" },
  { "institution": "faculdade de tecnologia certus", "acronym": "CERTUS" },
  { "institution": "centro universitário católica do tocantins", "acronym": "UNICATÓLICA" },
  { "institution": "faculdade de educação física de foz do iguaçu", "acronym": "null" },
  { "institution": "faculdade são basílio magno", "acronym": "FASBAM" },
  { "institution": "instituto superior de educação don domênico", "acronym": "IDON" },
  { "institution": "faculdade tecnológica de limoeiro do norte: ladislau pereira", "acronym": "FTLN" },
  { "institution": "faculdade invest de ciências e tecnologia", "acronym": "INVEST" },
  { "institution": "faculdade de educação e ciências gerenciais de são paulo", "acronym": "FECG-SP" },
  { "institution": "faculdade anglo latino", "acronym": "FAL" },
  { "institution": "faculdade santa cruz", "acronym": "FACRUZ" },
  { "institution": "faculdade facese", "acronym": "null" },
  { "institution": "faculdade metropolitana do marajó", "acronym": "FAMMA" },
  { "institution": "faculdade filadelfia de tecnologia", "acronym": "FAFILTEC" },
  { "institution": "faculdade de tecnologia de carapicuiba", "acronym": "FATEC" },
  { "institution": "faculdade arquidiocesana de pirapora", "acronym": "FAP" },
  { "institution": "centro superior de estudos jurídicos carlos drummond de andrade", "acronym": "CSEJCDA" },
  { "institution": "centro universitário maria milza", "acronym": "UNIMAM" },
  { "institution": "instituto superior de educação avantis", "acronym": "AVANTIS" },
  { "institution": "centro universitário de goiatuba", "acronym": "UNICERRADO" },
  { "institution": "faculdade católica rainha da paz de araputanga", "acronym": "FCARP" },
  { "institution": "faculdade unirb - piauí", "acronym": "F. UNIRB" },
  { "institution": "centro universitário teresa d'ávila", "acronym": "FATEA" },
  { "institution": "universidade castelo branco", "acronym": "UCB" },
  { "institution": "centro universitário cidade verde", "acronym": "UniCV" },
  { "institution": "faculdade presidente antônio carlos de barão de cocais", "acronym": "null" },
  { "institution": "centro de ensino superior sociesc de são bento do sul", "acronym": "null" },
  { "institution": "centro universitário dos guararapes", "acronym": "UNIFG" },
  { "institution": "faculdade ideal de patos", "acronym": "FACIPA" },
  { "institution": "instituto de ensino superior da fupesp", "acronym": "IESF" },
  { "institution": "faculdade presidente antônio carlos de carmópolis", "acronym": "FUNEES C. de Minas" },
  { "institution": "faculdade unirb - parnaíba", "acronym": "F. UNIRB" },
  { "institution": "faculdade estácio de ananindeua", "acronym": "null" },
  { "institution": "escola de ensino superior do agreste paraibano", "acronym": "EESAP" },
  { "institution": "faculdade de pedagogia e formação de normalistas de araguaína", "acronym": "ITPAC" },
  { "institution": "faculdade de tecnologia de horizonte", "acronym": "FATHOR" },
  { "institution": "faculdade de são lourenço", "acronym": "FASAMA" },
  { "institution": "faculdade anhanguera de serra", "acronym": "FPS" },
  { "institution": "faculdades unificadas de cataguases", "acronym": "FUC" },
  { "institution": "faculdades integradas asmec", "acronym": "ASMEC" },
  { "institution": "instituto federal de educação, ciência e tecnologia do espírito santo", "acronym": "IFES" },
  { "institution": "escola de sargentos de logística", "acronym": "EsSLog" },
  { "institution": "faculdade borges de mendonça", "acronym": "FBM" },
  { "institution": "faculdade cnec de educação de uberaba", "acronym": "FACEUB" },
  { "institution": "centro universitário de goiás", "acronym": "UNIGOIÁS" },
  { "institution": "faculdade ipessp", "acronym": "IPESSP" },
  { "institution": "faculdade ésper", "acronym": "ÉSPER" },
  { "institution": "centro de estudos superiores aprendiz", "acronym": "CESA" },
  { "institution": "faculdade pitágoras de belém", "acronym": "null" },
  { "institution": "faculdade adventista da bahia", "acronym": "FADBA" },
  { "institution": "faculdade católica de fortaleza", "acronym": "FCF" },
  { "institution": "faculdades integradas vital brazil", "acronym": "FVB" },
  { "institution": "faculdade univeritas universus veritas de vitória", "acronym": "VERITAS VITÓRIA" },
  { "institution": "faculdade de botucatu", "acronym": "FDB" },
  { "institution": "centro universitário unifatecie", "acronym": "UNIFATECIE" },
  { "institution": "faculdade de ciências sociais aplicadas", "acronym": "FACISA" },
  { "institution": "faculdade morumbi", "acronym": "FAMOR" },
  { "institution": "faculdade renovação de arapongas", "acronym": "RENOVAÇÃO" },
  { "institution": "faculdade de saúde santo agostinho de vitória da conquista", "acronym": "FASA" },
  { "institution": "faculdade de comunicação e design oswaldo cruz", "acronym": "null" },
  { "institution": "faculdade de dracena", "acronym": "null" },
  { "institution": "instituto superior da convenção nac. das assembleias de deus", "acronym": "ISCON" },
  { "institution": "faculdade presbiteriana augusto galvão", "acronym": "FAPAG" },
  { "institution": "faculdade de tecnologia de teresina", "acronym": "FACULDADE CET" },
  { "institution": "faculdade de tecnologia dr. thomaz novelino", "acronym": "FATEC Franca" },
  { "institution": "centro universitário do vale do jaguaribe", "acronym": "UNIJAGUARIBE" },
  { "institution": "faculdade aldete maria alves", "acronym": "FAMA" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro - faeterj", "acronym": "FAETERJ ITAPERUNA" },
  { "institution": "faculdade anhanguera de taubaté", "acronym": "null" },
  { "institution": "escola de artilharia de costa e antiaérea", "acronym": "EsCosAAe" },
  { "institution": "universidade de pernambuco", "acronym": "UPE" },
  { "institution": "faculdade de educação da serra", "acronym": "FASE" },
  { "institution": "faculdade de educação de jaru", "acronym": "UNICENTRO" },
  { "institution": "fgv / escola de relações internacionais", "acronym": "FGV/RI" },
  { "institution": "centro universitário metodista izabela hendrix", "acronym": "CEUNIH" },
  { "institution": "faculdade esamc campinas", "acronym": "ESAMC" },
  { "institution": "universidade estadual do ceará", "acronym": "UECE" },
  { "institution": "faculdade millennium", "acronym": "FACMILLENIUM" },
  { "institution": "faculdade ibra de taubaté", "acronym": "FACULDADE FITAU" },
  { "institution": "centro universitário uninovafapi", "acronym": "UNINOVAFAPI" },
  { "institution": "instituto brasileiro de ensino, desenvolvimento e pesquisa de brasília", "acronym": "IDP-BSB" },
  { "institution": "faculdade de tecnologia de jaraguá do sul", "acronym": "FATEJ" },
  { "institution": "faculdade vale do sorriso", "acronym": "UNIVALE" },
  { "institution": "faculdade de tecnologia senac minas - unidade belo horizonte", "acronym": "FTS" },
  { "institution": "faculdade gennari e peartree", "acronym": "FGP" },
  { "institution": "faculdade regional do centro sul de sergipe", "acronym": "FARSUL" },
  { "institution": "faculdade são paulo", "acronym": "FASP" },
  { "institution": "faculdade de administração de chapadão do sul", "acronym": "FACHASUL" },
  { "institution": "faculdade uninta itapipoca", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de belo horizonte", "acronym": "FUNEC Belo Horizonte" },
  { "institution": "faculdade estácio de natal", "acronym": "Estacio Natal" },
  { "institution": "faculdade senai de construção", "acronym": "null" },
  { "institution": "faculdade ideal wyden", "acronym": "Faci Wyden" },
  { "institution": "faculdade presidente antônio carlos de porteirinha", "acronym": "FUNEES Porteirinha" },
  { "institution": "centro universitário castelo branco", "acronym": "UNICB" },
  { "institution": "faculdade cristã de curitiba", "acronym": "FCC" },
  { "institution": "faculdade sapiens", "acronym": "SAPIENS" },
  { "institution": "centro universitário da amazônia", "acronym": "UNIESAMAZ" },
  { "institution": "faculdade terceira visão", "acronym": "F3V" },
  { "institution": "faculdade de agronomia una de itumbiara", "acronym": "null" },
  { "institution": "centro universitário padre joão bagozzi", "acronym": "UNIBAGOZZI" },
  { "institution": "faculdade evangélica de goianésia", "acronym": "FACEG" },
  { "institution": "faculdade uninassau feira de santana", "acronym": "null" },
  { "institution": "faculdade cenecista de senhor do bonfim", "acronym": "FACESB" },
  { "institution": "instituto superior de educação orígenes lessa", "acronym": "ISEOL" },
  { "institution": "cesrei faculdade", "acronym": "null" },
  { "institution": "universidade estadual do centro oeste", "acronym": "UNICENTRO" },
  { "institution": "faculdade de teologia e ciências humanas", "acronym": "ITEPAFACULDADES" },
  { "institution": "faculdade estácio de alagoinhas", "acronym": "null" },
  { "institution": "faculdade do centro educacional mineiro - facem", "acronym": "FACEM-BH" },
  { "institution": "faculdade de ciências humanas de pernambuco", "acronym": "FCHPE" },
  { "institution": "instituto superior de educação santo agostinho de pirapora", "acronym": "ISAP" },
  { "institution": "faculdade de ciências de timbaúba", "acronym": "FACET" },
  { "institution": "faculdade educacional de matelândia", "acronym": "FAMA" },
  { "institution": "centro universitário álvares penteado", "acronym": "FECAP" },
  { "institution": "faculdade laboro", "acronym": "Laboro" },
  { "institution": "faculdade estácio de sá de vitória", "acronym": "FESV" },
  { "institution": "faculdade sesi-sp de educação", "acronym": "FASESP" },
  { "institution": "faculdade de ciência e educação do caparaó", "acronym": "FACEC" },
  { "institution": "faculdade de tecnologia marista", "acronym": "CETEMA" },
  { "institution": "faculdade de tecnologia senai mario amato", "acronym": "SENAI" },
  { "institution": "centro universitário de várzea grande", "acronym": "UNIVAG" },
  { "institution": "faculdade de ciências jurídicas de luziânia", "acronym": "null" },
  { "institution": "faculdade anhanguera de jundiaí", "acronym": "null" },
  { "institution": "faculdade fatifajar jaguariaíva", "acronym": "FAJAR" },
  { "institution": "faculdade de tecnologia porto sul", "acronym": "FAPS" },
  { "institution": "faculdade internacional do delta", "acronym": "INTA" },
  { "institution": "faculdade christus", "acronym": "Christus Eusébio" },
  { "institution": "faculdade presidente antônio carlos de itabira", "acronym": "FUNEES Itabira" },
  { "institution": "centro universitário facol", "acronym": "UNIFACOL" },
  { "institution": "faculdade rudolf steiner", "acronym": "FRS" },
  { "institution": "faculdade de educação física da associação cristã de moços de sorocaba", "acronym": "FEFISO" },
  { "institution": "faculdades integradas de jacarepaguá", "acronym": "FIJ" },
  { "institution": "centro universiário uniftc salvador", "acronym": "null" },
  { "institution": "faculdade pitágoras de serrinha", "acronym": "null" },
  { "institution": "faculdade assembleiana do brasil", "acronym": "null" },
  { "institution": "faculdade stbnb", "acronym": "null" },
  { "institution": "faculdade de tecnologia bandeirantes", "acronym": "BANDTEC" },
  { "institution": "instituto federal de educação, ciência e tecnologia do acre", "acronym": "IFAC" },
  { "institution": "faculdade fernão dias", "acronym": "FAFE" },
  { "institution": "centro universitário anhanguera pitágoras ampli", "acronym": "null" },
  { "institution": "instituto de ensino superior do sul do maranhão", "acronym": "IESMA" },
  { "institution": "faculdade de medicina de barbacena", "acronym": "FAME" },
  { "institution": "universidade federal do rio grande do sul", "acronym": "UFRGS" },
  { "institution": "faculdade de ciências contábeis e de administração de empresas", "acronym": "FCCAE" },
  { "institution": "faculdade de ciência e tecnologia de montes claros", "acronym": "FACIT" },
  { "institution": "faculdade fipecafi", "acronym": "FIPECAFI" },
  { "institution": "faculdade gamaliel", "acronym": "FAGAMA" },
  { "institution": "centro universitário santa rita", "acronym": "UNIFASAR" },
  { "institution": "faculdade unirb -  ceará", "acronym": "F. UNIRB" },
  { "institution": "faculdade de administração de nova andradina", "acronym": "FANA" },
  { "institution": "faculdade de teologia e ciências humanas", "acronym": "ITEPAFACULDADES" },
  { "institution": "faculdade presidente antônio carlos de bom despacho", "acronym": "null" },
  { "institution": "faculdade canção nova", "acronym": "FCN" },
  { "institution": "faculdade iguapense santo augusto", "acronym": "FISA" },
  { "institution": "instituto de ensino superior de londrina - inesul", "acronym": "INESUL" },
  { "institution": "faculdade de são roque", "acronym": "FASAR" },
  { "institution": "faculdade de tecnologia em hotelaria, gastronomia e turismo de são paulo", "acronym": "HOTEC" },
  { "institution": "centro universitário joaquim nabuco de recife", "acronym": "UNINABUCO RECIFE" },
  { "institution": "centro universitário senac", "acronym": "RS" },
  { "institution": "faculdade de arujá", "acronym": "FAR" },
  { "institution": "faculdade wenceslau braz", "acronym": "null" },
  { "institution": "instituto educacional de castro", "acronym": "INEC" },
  { "institution": "faculdade sucesso", "acronym": "FACSU" },
  { "institution": "faculdade de ciências humanas de fortaleza", "acronym": "FCHFOR" },
  { "institution": "faculdade presidente antônio carlos de juatuba", "acronym": "FUNEES" },
  { "institution": "faculdade unisul de florianópolis", "acronym": "null" },
  { "institution": "centro universitário nossa senhora do patrocínio", "acronym": "CEUNSP" },
  { "institution": "faculdade itpac abaetetuba", "acronym": "ITPAC ABAET" },
  { "institution": "faculdade tamoios", "acronym": "FATA" },
  { "institution": "faculdade de tecnologia de são josé dos campos - jessen vidal", "acronym": "FATECSJCAMPOS" },
  { "institution": "faculdades integradas de cassilândia", "acronym": "FIC" },
  { "institution": "faculdade doctum de vila velha", "acronym": "DOCTUM" },
  { "institution": "centro universitário anhanguera de marabá", "acronym": "null" },
  { "institution": "faculdade univeritas universus veritas de contagem", "acronym": "VERITAS CONTAGEM" },
  { "institution": "faculdade atitude de educação continuada", "acronym": "FAEC" },
  { "institution": "faculdade de pedagogia de afonso cláudio", "acronym": "ISEAC" },
  { "institution": "faculdade de tecnologia professor antônio seabra", "acronym": "FATEC LINS" },
  { "institution": "centro de ensino superior riograndense guapore", "acronym": "CESURG Guapore" },
  { "institution": "facudade de morrinhos", "acronym": "FAM" },
  { "institution": "faculdades integradas de coxim", "acronym": "FICO" },
  { "institution": "faculdade teológica sul americana", "acronym": "FTSA" },
  { "institution": "centro universitário dante", "acronym": "UNIDANTE" },
  { "institution": "faculdade unirb - mossoró", "acronym": "F. UNIRB" },
  { "institution": "faculdade de nova esperança", "acronym": "null" },
  { "institution": "faculdade de ciências da saúde de unaí", "acronym": "FACISA" },
  { "institution": "faculdade unieducar", "acronym": "null" },
  { "institution": "link school of business", "acronym": "LSB" },
  { "institution": "faculdade são vicente", "acronym": "FASVIPA" },
  { "institution": "faculdade de direito avantis de itapema", "acronym": "AVANTIS Itapema" },
  { "institution": "faculdade do belo jardim", "acronym": "FBJ" },
  { "institution": "faculdade católica de rondonia", "acronym": "FCR" },
  { "institution": "facuminas faculdade", "acronym": "FACUMINAS" },
  { "institution": "universidade do grande rio professor josé de souza herdy", "acronym": "UNIGRANRIO" },
  { "institution": "faculdade uninassau aliança - redenção", "acronym": "Nassau Aliança" },
  { "institution": "faculdade anhanguera pitágoras votorantim", "acronym": "null" },
  { "institution": "faculdade conectada faconnect", "acronym": "FACONNECT" },
  { "institution": "faculdade de medicina estácio de juazeiro do norte", "acronym": "ESTÁCIO FMJ" },
  { "institution": "faculdade anhanguera unidade parauapebas", "acronym": "null" },
  { "institution": "centro universitário de rondônia", "acronym": "Unesc" },
  { "institution": "faculdade metropolitana são carlos", "acronym": "FAMESC" },
  { "institution": "trevisan escola superior de negócios", "acronym": "TREVISAN" },
  { "institution": "faculdade fraga de integração da cultura educação e pesquisa", "acronym": "FICEPE" },
  { "institution": "faculdades joão paulo ii - campus porto alegre", "acronym": "FJP" },
  { "institution": "faculdade descomplica", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de são gotardo", "acronym": "FUNEC São Gotardo" },
  { "institution": "faculdade pio decimo de caninde do sao francisco", "acronym": "FAPIDE" },
  { "institution": "faculdade de ciências sociais aplicadas e humanas de garanhuns", "acronym": "FAHUG" },
  { "institution": "escola superior de saúde de arcoverde", "acronym": "ESSA" },
  { "institution": "instituto internacional de estudos em saude", "acronym": "IIESAU" },
  { "institution": "faculdade de tecnologia senai roberto mange", "acronym": "null" },
  { "institution": "faculdade dos gênios", "acronym": "FAGENIUS" },
  { "institution": "instituto brasileiro de coaching", "acronym": "IBC" },
  { "institution": "faculdade qualittas - ead", "acronym": "null" },
  { "institution": "fundação universidade federal de rondônia", "acronym": "UNIR" },
  { "institution": "faculdades integradas iesgo", "acronym": "IESGO" },
  { "institution": "faculdade  são joaquim da barra", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai rio do sul", "acronym": "SENAI RIO DO SUL" },
  { "institution": "instituto unificado europeu do brasil iune-brasil", "acronym": "IUNEBRASIL" },
  { "institution": "faculdade de governança, engenharia e educação de são paulo", "acronym": "FGE-SP" },
  { "institution": "faculdade de ciências gerenciais de santos dumont", "acronym": "FACIG" },
  { "institution": "faculdade do estado do rio de janeiro", "acronym": "FAERJ" },
  { "institution": "faculdade novo horizonte de ipojuca", "acronym": "FNH" },
  { "institution": "faculdade de ensino superior paschoal dantas", "acronym": "null" },
  { "institution": "faculdade unirb - teresina", "acronym": "F. UNIRB" },
  { "institution": "faculdade evangélica de jaraguá", "acronym": "FEJA" },
  { "institution": "faculdade ismd", "acronym": "ISMD" },
  { "institution": "faculdade paranaense", "acronym": "FAPAR" },
  { "institution": "universidade do vale do sapucaí", "acronym": "UNIVÁS" },
  { "institution": "escola de economia de são paulo", "acronym": "EESP" },
  { "institution": "faculdade brasileira de estudos avançados -fabea", "acronym": "FABEA" },
  { "institution": "faculdade angel vianna", "acronym": "FAV" },
  { "institution": "centro universitário leonardo da vinci", "acronym": "UNIASSELVI" },
  { "institution": "faculdade princesa do oeste", "acronym": "FPO" },
  { "institution": "faculdade tamandaré", "acronym": "FAT" },
  { "institution": "faculdade de iporá", "acronym": "FAI" },
  { "institution": "faculdades doctum de caratinga", "acronym": "DOCTUM" },
  { "institution": "instituto educatie", "acronym": "EDUCATIE" },
  { "institution": "faculdade estácio do amazonas - estácio amazonas", "acronym": "ESTÁCIO AMAZONAS" },
  { "institution": "faculdade liberdade - educação e tecnologia", "acronym": "Faliber" },
  { "institution": "instituto manchester paulista de ensino superior", "acronym": "IMAPES" },
  { "institution": "escola superior em meio ambiente", "acronym": "ESMA" },
  { "institution": "universidade cruzeiro do sul", "acronym": "UNICSUL" },
  { "institution": "faculdade pitágoras de alagoinhas", "acronym": "FPA" },
  { "institution": "universidade federal do rio grande do norte", "acronym": "UFRN" },
  { "institution": "faculdade anhanguera de jacareí", "acronym": "null" },
  { "institution": "faculdade itec", "acronym": "null" },
  { "institution": "fefisa - faculdades integradas de santo andré", "acronym": "FEFISA" },
  { "institution": "escola superior de ciências da santa casa de misericórdia de vitória", "acronym": "EMESCAM" },
  { "institution": "faculdade pitágoras unopar de caldas novas", "acronym": "null" },
  { "institution": "faculdade reges de ribeirão preto", "acronym": "null" },
  { "institution": "faculdade anhanguera de taboão da serra", "acronym": "FATS" },
  { "institution": "faculdade de americana", "acronym": "FAM" },
  { "institution": "faculdade jk brasília - recanto das emas", "acronym": "JK BSB RE" },
  { "institution": "centro universitário estácio juiz de fora - estácio juiz de fora", "acronym": "null" },
  { "institution": "faculdade êxito", "acronym": "FAEX" },
  { "institution": "faculdade barretos", "acronym": "FB" },
  { "institution": "cenac - centro de ensino nacional", "acronym": "null" },
  { "institution": "faculdade estácio de sá de ourinhos", "acronym": "FAESO" },
  { "institution": "faculdade qi brasil", "acronym": "FAQI" },
  { "institution": "escola bahiana de medicina e saúde pública", "acronym": "EBMSP" },
  { "institution": "universidade federal de lavras", "acronym": "UFLA" },
  { "institution": "centro universitário aeso -  barros melo", "acronym": "Uniaeso" },
  { "institution": "universidade santo amaro", "acronym": "UNISA" },
  { "institution": "centro universitário maurício de nassau de são luís", "acronym": "UNINASSAU" },
  { "institution": "instituto federal de educação, ciência e tecnologia do piauí", "acronym": "IFPI" },
  { "institution": "faculdade oboé - faco", "acronym": "FACO" },
  { "institution": "faculdade santa izildinha", "acronym": "FIESI" },
  { "institution": "centro universitário fasipe", "acronym": "null" },
  { "institution": "faculdade de pinhais", "acronym": "FAPI" },
  { "institution": "instituto de ciências exatas", "acronym": "UNEB" },
  { "institution": "faculdade cnec nova petrópolis", "acronym": "null" },
  { "institution": "instituto de ensino superior da funlec de bonito", "acronym": "IESF" },
  { "institution": "universidade ceuma", "acronym": "UNICEUMA" },
  { "institution": "centro universitário união das américas descomplica", "acronym": "UNIAMÉRICA" },
  { "institution": "faculdade de tecnologia de bragança paulista", "acronym": "null" },
  { "institution": "faculdade são francisco da paraíba", "acronym": "FASP" },
  { "institution": "faculdade de educação tecnológica barra mansa", "acronym": "FAETERJ B.MANSA" },
  { "institution": "faculdade de educação de costa rica", "acronym": "FECRA" },
  { "institution": "centro universitário de salvador", "acronym": "UNICEUSA" },
  { "institution": "faculdade alfredo nasser de casa nova", "acronym": "FAN" },
  { "institution": "faculdade de tecnologia senai campo grande", "acronym": "null" },
  { "institution": "faculdade santo antônio de feira de santana", "acronym": "FSAF" },
  { "institution": "fundação universidade do estado de santa catarina", "acronym": "UDESC" },
  { "institution": "faculdade de auriflama", "acronym": "FAU" },
  { "institution": "sistemas de ensino em ciências e tecnologias", "acronym": "SECTRAS" },
  { "institution": "faculdade de tecnologia santa rita de cássia", "acronym": "FATEC  SANTA RITA" },
  { "institution": "faculdade da construção e do trabalho", "acronym": "FACT" },
  { "institution": "universidade de itaúna", "acronym": "UI" },
  { "institution": "faculdade associada brasil - ead", "acronym": "null" },
  { "institution": "faculdade idor de ciências médicas", "acronym": "Faculdade IDOR" },
  { "institution": "faculdades network - campus sumaré", "acronym": "null" },
  { "institution": "faculdade de tecnologia do piauí", "acronym": "FATEPI" },
  { "institution": "faculdade mogiana", "acronym": "FAMOGI" },
  { "institution": "centro federal de educação tecnológica de cuiabá", "acronym": "null" },
  { "institution": "franklincovey business school", "acronym": "FCBS" },
  { "institution": "faculdades integradas de ariquemes", "acronym": "NOVA FIAR" },
  { "institution": "instituto serra dourada", "acronym": "null" },
  { "institution": "faculdade ágora - fag", "acronym": "FAG" },
  { "institution": "faculdade cnec campo largo", "acronym": "null" },
  { "institution": "universidade federal do amapá", "acronym": "UNIFAP" },
  { "institution": "faculdade presidente antônio carlos de elói mendes", "acronym": "null" },
  { "institution": "universidade municipal de são caetano do sul", "acronym": "USCS" },
  { "institution": "centro universitário do paraná", "acronym": "UNIPA" },
  { "institution": "centro universitário cesuca", "acronym": "null" },
  { "institution": "faculdade de tecnologia de pompeia - shunji nishimura", "acronym": "FATEC Pompeia" },
  { "institution": "faculdades de inovação e tecnologia de minas gerais", "acronym": "FIT MG" },
  { "institution": "faculdade marechal rondon de bauru", "acronym": "FMR-BAURU" },
  { "institution": "faculdade católica do ceará", "acronym": "FCC" },
  { "institution": "faculdades famep unidade euclides da cunha - ba", "acronym": "FAMEP" },
  { "institution": "faculdade educacional de colombo", "acronym": "FAEC" },
  { "institution": "faculdade esamc jundiaí", "acronym": "ESAMC" },
  { "institution": "faculdade pitágoras bacabal mearim", "acronym": "null" },
  { "institution": "faculdade de informática lemos de castro", "acronym": "FILC" },
  { "institution": "centro universitário ítalo-brasileiro", "acronym": "UNIÍTALO" },
  { "institution": "faculdade de suzano", "acronym": "UNISUZ" },
  { "institution": "faculdade de ciências médicas e paramédicas fluminense", "acronym": "SEFLU" },
  { "institution": "centro universitário leonardo da vinci", "acronym": "UNIVINCI" },
  { "institution": "faculdade anhanguera de nova iguaçu", "acronym": "null" },
  { "institution": "faculdade uninassau maracanaú", "acronym": "null" },
  { "institution": "faculdade de tecnologia de assis", "acronym": "FATEC Assis" },
  { "institution": "faculdade de jacareí", "acronym": "null" },
  { "institution": "faculdade maurício de nassau de itabuna", "acronym": "FMN DE ITABUNA" },
  { "institution": "faculdade de ciências e tecnologia de unaí - factu", "acronym": "FACTU" },
  { "institution": "instituto superior de informática", "acronym": "ISI" },
  { "institution": "centro universitário de ciências e tecnologia do maranhão", "acronym": "UniFacema" },
  { "institution": "faculdade unibrasília", "acronym": "BRASÍLIA" },
  { "institution": "faculdade integrada do brasil", "acronym": "FAIBRA" },
  { "institution": "faculdade adventista da amazônia", "acronym": "FAAMA" },
  { "institution": "faculdade são francisco do ceará", "acronym": "FASC" },
  { "institution": "faculdade tiradentes de feira de santana", "acronym": "FITS" },
  { "institution": "faculdade  senac criciúma", "acronym": "Senac Criciúma" },
  { "institution": "centro universitário serra dos órgãos", "acronym": "UNIFESO" },
  { "institution": "faculdade educaremt", "acronym": "EDUCARE" },
  { "institution": "faculdade de castelo - multivix castelo", "acronym": "Multivix Castelo" },
  { "institution": "faculdade de gestao e negocios de cachoeirinha", "acronym": "FGN CACHOEIRINH" },
  { "institution": "fundação oswaldo cruz", "acronym": "Fiocruz" },
  { "institution": "universidade federal de pelotas", "acronym": "UFPEL" },
  { "institution": "faculdade de informática de ouro preto do oeste", "acronym": "FIOURO" },
  { "institution": "faculdade sucesso", "acronym": "FAS" },
  { "institution": "faculdade de tecnologia fama", "acronym": "FAMA" },
  { "institution": "centro universitário das faculdades associadas de ensino - fae", "acronym": "UNIFAE" },
  { "institution": "faculdade lusófona do rio de janeiro", "acronym": "FL-RJ" },
  { "institution": "faculdade baiana de direito e gestão", "acronym": "null" },
  { "institution": "faculdade bi campinas", "acronym": "null" },
  { "institution": "faculdade da região serrana", "acronym": "FARESE" },
  { "institution": "faculdade amec trabuco", "acronym": "AMEC" },
  { "institution": "escola dieese de ciências do trabalho", "acronym": "DIEESE" },
  { "institution": "faculdade madre tereza", "acronym": "FAMAT" },
  { "institution": "faculdade cedin", "acronym": "CEDIN" },
  { "institution": "faculdade erich fromm", "acronym": "FACEF" },
  { "institution": "instituto de educação superior santo agostinho", "acronym": "IESA" },
  { "institution": "centro universitário cambury", "acronym": "UniCambury" },
  { "institution": "faculdade de tecnologia senai brusque", "acronym": "SENAI/SC BRUSQUE" },
  { "institution": "faculdade criativo de ciências aplicadas", "acronym": "FACTIVO" },
  { "institution": "faculdade santo antônio - sjc", "acronym": "FSA" },
  { "institution": "instituto superior de educação professora nair fortes abu-merhy", "acronym": "ISEFOR" },
  { "institution": "universidade iguaçu", "acronym": "UNIG" },
  { "institution": "faculdade prime", "acronym": "Faprime" },
  { "institution": "faculdade positivo joinville", "acronym": "null" },
  { "institution": "faculdade cenecista de ituberá", "acronym": "CNECBA" },
  { "institution": "faculdade autônoma de direito", "acronym": "FADISP" },
  { "institution": "faculdade tecnológica de pernambuco", "acronym": "FATEPE" },
  { "institution": "faculdade tecnológica falcão", "acronym": "FTF" },
  { "institution": "faculdade evangélica raízes", "acronym": "RAÍZES" },
  { "institution": "faculdade regional de minas gerais", "acronym": "FACMINAS" },
  { "institution": "faculdade presidente antônio carlos de nova lima", "acronym": "null" },
  { "institution": "faculdade cimo", "acronym": "FAC" },
  { "institution": "faculdade marquês de olinda", "acronym": "FMO" },
  { "institution": "faculdade maurício de nassau de londrina", "acronym": "FMN LONDRINA" },
  { "institution": "faculdade de teologia evangélica em curitiba - fatev", "acronym": "FATEV" },
  { "institution": "união de escolas superiores da funeso", "acronym": "UNESF" },
  { "institution": "faculdade de porto feliz", "acronym": "null" },
  { "institution": "faculdade de filosofia e teologia paulo vi", "acronym": "FFTP" },
  { "institution": "instituto santo agostinho de ensino superior - isa", "acronym": "null" },
  { "institution": "faculdade de mauá - fama", "acronym": "FAMA" },
  { "institution": "escola superior de educação física de muzambinho", "acronym": "ESEFM" },
  { "institution": "fgw - faculdade de gestão woli", "acronym": "WOLI" },
  { "institution": "centro universiário uniftc", "acronym": "null" },
  { "institution": "faculdade de turismo de nova andradina", "acronym": "FATUR" },
  { "institution": "centro universitário mater dei", "acronym": "null" },
  { "institution": "faculdade de direito, ciências e tecnologia santa maria mada", "acronym": "FADICT" },
  { "institution": "faculdade intercontinental", "acronym": "FAICON" },
  { "institution": "faculdade santa rita de chapecó", "acronym": "null" },
  { "institution": "faculdade de tecnologia saint paul", "acronym": "null" },
  { "institution": "faculdade de direito de araguaína", "acronym": "ITPAC" },
  { "institution": "faculdade de ciências humanas do vale do piranga", "acronym": "FAVAP" },
  { "institution": "instituto superior de educação balão vermelho", "acronym": "ISEBV" },
  { "institution": "faculdade sebrae", "acronym": "null" },
  { "institution": "faculdade isae brasil", "acronym": "ISAE" },
  { "institution": "faculdade estácio de pernambuco - estácio pernambuco", "acronym": "null" },
  { "institution": "faculdade de tecnologia ipanema", "acronym": "null" },
  { "institution": "instituto machadense de ensino superior", "acronym": "IMES" },
  { "institution": "escola de sociologia e política de são paulo", "acronym": "ESP" },
  { "institution": "instituto de ensino superior franciscano", "acronym": "IESF" },
  { "institution": "faculdade de tecnologia expoente", "acronym": "FATEC-EXPOENTE" },
  { "institution": "faculdade unirb - arapiraca", "acronym": "F. UNIRB" },
  { "institution": "faculdades integradas maria imaculada", "acronym": "FIMI" },
  { "institution": "faculdade anclivepa de natal", "acronym": "null" },
  { "institution": "faculdade de desenvolvimento e integração regional", "acronym": "FADIRE" },
  { "institution": "instituto superior de educação de caxias", "acronym": "ISEC" },
  { "institution": "faculdade de educação superior do paraná", "acronym": "FESPPR" },
  { "institution": "faculdade de rolim de moura", "acronym": "FAROL" },
  { "institution": "centro universitário das faculdades integradas de ourinhos", "acronym": "Unifio" },
  { "institution": "faculdade seb de ribeirão preto", "acronym": "SEBRP" },
  { "institution": "faculdade da fronteira - faf", "acronym": "FAF" },
  { "institution": "universidade federal do rio de janeiro", "acronym": "UFRJ" },
  { "institution": "faculdade de tecnologia e ciências - ftc teixeira de freitas", "acronym": "FTC" },
  { "institution": "faculdade municipal professor franco montoro de mogi guaçu", "acronym": "FMPFM" },
  { "institution": "centro universitário vale do rio verde", "acronym": "null" },
  { "institution": "faculdade de apucarana", "acronym": "FAP" },
  { "institution": "faculdade marechal cândido rondon", "acronym": "FAMAR" },
  { "institution": "instituto superior de educação de garça", "acronym": "ISEG" },
  { "institution": "fass - faculdade social sul americana", "acronym": "null" },
  { "institution": "centro universitário celso lisboa", "acronym": "UCL" },
  { "institution": "faculdade pitagoras anhanguera de três lagoas", "acronym": "null" },
  { "institution": "instituto de pós-graduação e atualização em odontologia", "acronym": "IPENO" },
  { "institution": "faculdade unirb - feira de santana", "acronym": "F. UNIRB" },
  { "institution": "faculdade estácio de santo andré", "acronym": "ESTÁCIO SANTO ANDRÉ" },
  { "institution": "faculdade de tecnologia de são sebastião", "acronym": "Fatec SS" },
  { "institution": "faculdade padre anchieta de várzea paulista", "acronym": "ANCHIETA" },
  { "institution": "escola de engenharia de piracicaba", "acronym": "EEP/FUMEP" },
  { "institution": "faculdade ibra de minas gerais", "acronym": "FACULDADE FIBMG" },
  { "institution": "faculdade atenas sete lagoas", "acronym": "null" },
  { "institution": "faculdade de direito de contagem", "acronym": "FDCON" },
  { "institution": "faculdade brasil norte", "acronym": "FABRAN" },
  { "institution": "faculdade positivo londrina", "acronym": "FPL" },
  { "institution": "faculdade paulus de tecnologia e comunicação", "acronym": "FAPCOM" },
  { "institution": "instituto florence de ensino superior", "acronym": "IFES" },
  { "institution": "universidade evangélica de goiás", "acronym": "UniEVANGÉLICA" },
  { "institution": "universidade estadual de santa cruz", "acronym": "UESC" },
  { "institution": "faculdade dom bosco paraná", "acronym": "Dom Bosco Paraná" },
  { "institution": "faculdade de ciências biomédicas do espírito santo", "acronym": "PIO XII - BIO" },
  { "institution": "faculdade de arquitetura e urbanismo de pernambuco", "acronym": "FAUPE" },
  { "institution": "faculdade de educação são luís", "acronym": "FESL" },
  { "institution": "faculdade presidente antônio carlos de curvelo", "acronym": "FUNEES Curvelo" },
  { "institution": "faculdade estácio de sá de campo grande", "acronym": "FESCG" },
  { "institution": "faculdade de tecnologia ensitec", "acronym": "ENSITEC" },
  { "institution": "faculdade béthencourt da silva", "acronym": "FABES" },
  { "institution": "faculdade esamc uberlândia", "acronym": "ESAMC" },
  { "institution": "faculdade de fortaleza", "acronym": "FAFOR" },
  { "institution": "faculdade decisão", "acronym": "FADEC" },
  { "institution": "instituto superior de educação de guaratuba", "acronym": "ISEPE Guaratuba" },
  { "institution": "faculdade atualiza", "acronym": "ATUALIZA" },
  { "institution": "centro universitário brasileiro", "acronym": "UNIBRA" },
  { "institution": "faculdade de tecnologia ibrate", "acronym": "FAITEC" },
  { "institution": "faculdade itapuranga", "acronym": "null" },
  { "institution": "faculdade de tecnologia contec - unidade de carapina", "acronym": "CONTEC" },
  { "institution": "faculdade de ciências humanas esuda", "acronym": "FCHE" },
  { "institution": "instituto superior de educação berlaar", "acronym": "IBERLAAR" },
  { "institution": "faculdade de teologia de boa vista", "acronym": "FATEBOV" },
  { "institution": "faculdade nove de julho mauá", "acronym": "NOVE_MAUÁ" },
  { "institution": "faculdade regional palmitos", "acronym": "FAP" },
  { "institution": "faculdade ateneu", "acronym": "FATE" },
  { "institution": "faculdade unisul de itajaí", "acronym": "null" },
  { "institution": "faculdade de araçatuba", "acronym": "null" },
  { "institution": "universidade vale do rio doce", "acronym": "UNIVALE" },
  { "institution": "centro universitário do norte", "acronym": "UNINORTE" },
  { "institution": "universidade federal de ouro preto", "acronym": "UFOP" },
  { "institution": "faculdade brasileira de negócios inovadores", "acronym": "FABRANI" },
  { "institution": "faculdade santa terezinha", "acronym": "CEST" },
  { "institution": "faculdade de educação social da bahia", "acronym": "FAESB" },
  { "institution": "faculdade de tecnologia senai cic", "acronym": "null" },
  { "institution": "escola superior do ar", "acronym": "EAR" },
  { "institution": "faculdade atenas centro de minas", "acronym": "null" },
  { "institution": "instituto de educação superior unisul de itajaí", "acronym": "null" },
  { "institution": "faculdade ibmec são paulo", "acronym": "IBMEC SP" },
  { "institution": "faculdade de direito de alta floresta", "acronym": "null" },
  { "institution": "faculdade reges de osvaldo cruz", "acronym": "null" },
  { "institution": "faculdade metropolitana de novo hamburgo", "acronym": "FAMENOVO" },
  { "institution": "escola superior de cruzeiro prefeito hamilton vieira mendes", "acronym": "ESEFIC" },
  { "institution": "faculdade presidente antônio carlos de tupaciguara", "acronym": "null" },
  { "institution": "faculdades integradas do brasil unifuturo", "acronym": "Faculdade Unifuturo" },
  { "institution": "faculdade unyleya", "acronym": "null" },
  { "institution": "faculdades magsul", "acronym": "FAMAG" },
  { "institution": "faculdade vale do ipiranga", "acronym": "FAVAPI" },
  { "institution": "faculade presidente antônio carlos de nepomuceno", "acronym": "FUNEES Nepomuceno" },
  { "institution": "academia militar das agulhas negras", "acronym": "AMAN" },
  { "institution": "faculdade católica de anápolis", "acronym": "CATÓLICA DE ANÁPOLIS" },
  { "institution": "universidade são francisco", "acronym": "USF" },
  { "institution": "faculdade de direito da fundação escola superior do ministério público", "acronym": "null" },
  { "institution": "faculdade de direito de franca", "acronym": "FDF" },
  { "institution": "faculdade anhanguera de santa bárbara", "acronym": "null" },
  { "institution": "sinal faculdade de ciências humanas e sociais", "acronym": "SINAL" },
  { "institution": "faculdade unificada do estado de são paulo", "acronym": "FAUESP - online" },
  { "institution": "faculdade do cefi", "acronym": "FACEFI" },
  { "institution": "faculdade santo antonio de queimadas", "acronym": "FSAQ" },
  { "institution": "faculdade de guarulhos", "acronym": "FAG" },
  { "institution": "faculdade de música carlos gomes", "acronym": "FMCG" },
  { "institution": "universidade do vale do taquari", "acronym": "UNIVATES" },
  { "institution": "centro universitário redentor", "acronym": "FACREDENTOR" },
  { "institution": "faculdades integradas ipiranga", "acronym": "FAINTIPI" },
  { "institution": "faculdade de saúde, ciências e tecnologia - facesb", "acronym": "FACESB" },
  { "institution": "faculdade de ciência da computação e informática silva serpa", "acronym": "FASS" },
  { "institution": "faculdade católica cavanis do sudoeste do pará", "acronym": "FCCSPA" },
  { "institution": "instituto federal de educação, ciência e tecnologia baiano", "acronym": "IFBAIANO" },
  { "institution": "faculdade presidente antônio carlos de ubá", "acronym": "null" },
  { "institution": "faculdades integradas do ceará - unific", "acronym": "FACULDADE UniFIC" },
  { "institution": "faculdade fapaf", "acronym": "FAPAF" },
  { "institution": "faculdade inpro", "acronym": "FACINPRO" },
  { "institution": "faculdade de penápolis", "acronym": "null" },
  { "institution": "faculdade de ciências médicas e da saúde de juiz de fora", "acronym": "FCMS/JF" },
  { "institution": "centro de formação, treinamento e aperfeiçoamento", "acronym": "Cefor" },
  { "institution": "faculdade presidente antônio carlos de itanhandu", "acronym": "FAPACI" },
  { "institution": "universidade do planalto catarinense", "acronym": "UNIPLAC" },
  { "institution": "instituto federal de educação, ciência e tecnologia de são paulo", "acronym": "IFSP" },
  { "institution": "instituto federal de educação, ciência e tecnologia do maranhão", "acronym": "IFMA" },
  { "institution": "faculdade cosmopolita", "acronym": "null" },
  { "institution": "faculdades integradas rio branco granja vianna", "acronym": "FRB-GV" },
  { "institution": "faculdade pedro leopoldo", "acronym": "FPL" },
  { "institution": "faculdade ita educacional paulista", "acronym": "null" },
  { "institution": "centro universitário fadergs", "acronym": "FADERGS" },
  { "institution": "instituto uvb.br", "acronym": "IUVB" },
  { "institution": "faculdade de pedagogia de dores do indaiá", "acronym": "FAPEDI" },
  { "institution": "faculdade central do recife centro", "acronym": "FACENTRAL REC" },
  { "institution": "faculdade internacional de são luís", "acronym": "ISL" },
  { "institution": "faculdade pinheiro guimarães", "acronym": "FAPG" },
  { "institution": "faculdade de tecnologia francisco morato", "acronym": "FFRAMO" },
  { "institution": "faculdade campos belos", "acronym": "UNICAMPO" },
  { "institution": "centro universitário são judas tadeu", "acronym": "CSJT" },
  { "institution": "ajes - faculdade do vale do juruena", "acronym": "AJES" },
  { "institution": "universidade estácio de sá", "acronym": "UNESA" },
  { "institution": "faculdade de ribeirão preto", "acronym": "AFARP" },
  { "institution": "centro universitário são camilo - espírito santo", "acronym": "SÃO CAMILO-ES" },
  { "institution": "faculdade fernanda bicchieri", "acronym": "null" },
  { "institution": "faculdade do baixo parnaíba", "acronym": "FAP" },
  { "institution": "centro universitário campo real", "acronym": "null" },
  { "institution": "centro universitário cathedral", "acronym": "null" },
  { "institution": "universidade federal do ceará", "acronym": "UFC" },
  { "institution": "faculdade são luís", "acronym": "FSL" },
  { "institution": "real faculdade de brasília", "acronym": "FU" },
  { "institution": "faculdade de cornélio procópio", "acronym": "null" },
  { "institution": "faculdade la salle", "acronym": "null" },
  { "institution": "faculdade de tecnologia la salle - estrela", "acronym": "FACSALLE" },
  { "institution": "centro universitário de rio preto", "acronym": "UNIRP" },
  { "institution": "faculdade santa marcelina", "acronym": "FASM" },
  { "institution": "faculdade tiradentes de jaboatão dos guararapes", "acronym": "null" },
  { "institution": "faculdade magister", "acronym": "MAGISTER" },
  { "institution": "instituto de educação superior de barueri", "acronym": "IESB" },
  { "institution": "faculdade icesp", "acronym": "ICESP" },
  { "institution": "faculdade de direito de são bernardo do campo", "acronym": "FDSBC" },
  { "institution": "faculdade redentor metropolitana", "acronym": "FACREDENTOR" },
  { "institution": "faculdade de tecnologia em saúde", "acronym": "FATESA" },
  { "institution": "faculdade anhanguera de porto alegre", "acronym": "null" },
  { "institution": "faculdade anclivepa", "acronym": "ANCLIVEPA" },
  { "institution": "faculdade três pontas", "acronym": "FATEP" },
  { "institution": "faculdade elo", "acronym": "FAELO" },
  { "institution": "faculdade de tecnologia bandeirantes", "acronym": "BANDTEC" },
  { "institution": "escola de governo professor paulo neves de carvalho", "acronym": "EG" },
  { "institution": "universidade federal de itajubá - unifei", "acronym": "UNIFEI" },
  { "institution": "faculdade de recife", "acronym": "null" },
  { "institution": "universidade federal de santa maria", "acronym": "UFSM" },
  { "institution": "faculdade fractal", "acronym": "FRACTAL" },
  { "institution": "faculdade unilagos", "acronym": "UNILAGOS" },
  { "institution": "faculdade integrada instituto souza", "acronym": "FASOUZA" },
  { "institution": "centro universitário maurício de nassau paulista", "acronym": "UNINASSAU PAULISTA" },
  { "institution": "faculdade da associação brasiliense de educação", "acronym": "FABE" },
  { "institution": "fip - faculdade de iporã paraná", "acronym": "FIP" },
  { "institution": "centro universitário faema", "acronym": "UNIFAEMA" },
  { "institution": "faculdade de tecnologia jorge caram sabbag", "acronym": "FATEC Bebedouro" },
  { "institution": "insper instituto de ensino e pesquisa", "acronym": "null" },
  { "institution": "faculdade presbiteriana gammon", "acronym": "FAGAMMON" },
  { "institution": "instituto superior de educação de paraisópolis", "acronym": "FEP" },
  { "institution": "faculdade paulista de gestão e saúde", "acronym": "null" },
  { "institution": "faculdade anhangüera de dourados", "acronym": "null" },
  { "institution": "faculdade de rio claro", "acronym": "CBTA" },
  { "institution": "centro universitário luterano de santarém", "acronym": "CEULS" },
  { "institution": "faculdade teológica batista equatorial", "acronym": "FATEBE" },
  { "institution": "faculdade mega", "acronym": "MEGA" },
  { "institution": "faculdade nacional", "acronym": "FANAC" },
  { "institution": "universidade de sorocaba", "acronym": "UNISO" },
  { "institution": "universidade regional de blumenau", "acronym": "FURB" },
  { "institution": "faculdade macêdo de amorim", "acronym": "FAMAM" },
  { "institution": "faculdade vanguarda", "acronym": "null" },
  { "institution": "faculdade alfa umuarama", "acronym": "ALFA" },
  { "institution": "faculdade minas gerais", "acronym": "FAMIG" },
  { "institution": "faculdade madeira mamoré", "acronym": "FAMMA" },
  { "institution": "instituto de direito do nordeste - idn são luís", "acronym": "IDN São Luís" },
  { "institution": "faculdade de educação avançada do noroeste capixaba", "acronym": "FEAC" },
  { "institution": "faculdade intesp", "acronym": "INTESP" },
  { "institution": "faculdade são francisco xavier", "acronym": "FACSFX" },
  { "institution": "faculdade do estado de são paulo", "acronym": "ESP" },
  { "institution": "faculdade regional de filosofia, ciências e letras de candeias", "acronym": "FAC" },
  { "institution": "faculdade anhanguera de indaiatuba", "acronym": "null" },
  { "institution": "faculdade vale dos carajás", "acronym": "FVC" },
  { "institution": "faculdade da amazônia", "acronym": "FAMA" },
  { "institution": "faculdade de santa rita do passa quatro", "acronym": "null" },
  { "institution": "universidade estadual vale do acaraú", "acronym": "UVA" },
  { "institution": "centro universitário armando alvares penteado", "acronym": "FA-FAAP" },
  { "institution": "centro universitário dom bosco do rio de janeiro", "acronym": "UniDomBosco-RJ" },
  { "institution": "faculdade alfaunipac de capelinha", "acronym": "null" },
  { "institution": "faculdade de ciências aplicadas e tecnológicas do litoral sul", "acronym": "FACSUL" },
  { "institution": "faculdade de tecnologia de itapetininga - prof.antônio belizandro barbosa rezende", "acronym": "Fatec - Itapetininga" },
  { "institution": "centro universitário ages", "acronym": "UniAGES" },
  { "institution": "faculdade de ciências, educação, saúde, pesquisa e gestão", "acronym": "FSF" },
  { "institution": "faculdade única de ipatinga", "acronym": "FUNIP" },
  { "institution": "escola superior de itabira", "acronym": "null" },
  { "institution": "centro universitário unirb - alagoinhas", "acronym": "null" },
  { "institution": "faculdade de ensino superior do centro do paraná", "acronym": "UCP" },
  { "institution": "faculdade cidade luz", "acronym": "FACILUZ" },
  { "institution": "brain business school", "acronym": "BRAIN" },
  { "institution": "faculdade de ciências gerenciais - cotia", "acronym": "FCG - COTIA" },
  { "institution": "centro universitário maurício de nassau de teresina", "acronym": "null" },
  { "institution": "faculdade imepac de itumbiara", "acronym": "IMEPAC ITUMBIAR" },
  { "institution": "faculdades integradas da terra de brasília", "acronym": "FTB" },
  { "institution": "faculdade progresso", "acronym": "FAP" },
  { "institution": "centro universitário fundação santo andré", "acronym": "CUFSA" },
  { "institution": "universidade estadual de londrina", "acronym": "null" },
  { "institution": "faculdade de ciências e tecnologia de teresina", "acronym": "FACET" },
  { "institution": "faculdade integral de jaboatão dos guararapes", "acronym": "FIJ" },
  { "institution": "faculdade sociesc de educação de itajaí", "acronym": "null" },
  { "institution": "faculdade inconfidência", "acronym": "FI" },
  { "institution": "faculdade de engenharia e inovação técnico profissi", "acronym": "FEITEP" },
  { "institution": "faculdade doctum de manhuaçu - doctum", "acronym": "FCM" },
  { "institution": "fasul educacional ead", "acronym": "FASUL EDUCACIONAL" },
  { "institution": "faculdade de ciências aplicadas de limoeiro", "acronym": "null" },
  { "institution": "faculdade estácio da paraíba", "acronym": "ESTÁCIO PARAÍBA" },
  { "institution": "faculdade de educação e estudos sociais de uberlândia", "acronym": "FEESU" },
  { "institution": "centro universitário toledo wyden", "acronym": "Unitoledo Wyden" },
  { "institution": "faculdade de ensino superior dom bosco", "acronym": "FACDOMBOSCO" },
  { "institution": "escola brasileira de administração pública e de empresas", "acronym": "EBAPE" },
  { "institution": "faculdades integradas hélio alonso", "acronym": "FACHA" },
  { "institution": "faculdade anclivepa brasília", "acronym": "null" },
  { "institution": "faculdade fia de administração e negócios", "acronym": "FFIA" },
  { "institution": "faculdade sociesc de educação de são bento do sul", "acronym": "null" },
  { "institution": "faculdade de educação e cultura de ji-paraná", "acronym": "FAJIPA" },
  { "institution": "faculdades integradas espírita", "acronym": "FIES" },
  { "institution": "faculdade do litoral paranaense", "acronym": "FLP" },
  { "institution": "faculdade brasileira digital", "acronym": "FABRADI" },
  { "institution": "faculdade afonso mafrense", "acronym": "FAM" },
  { "institution": "centro universitário estácio de goiás", "acronym": "ESTÁCIO GOIÁS" },
  { "institution": "centro universitário amparense", "acronym": "UNIFIA" },
  { "institution": "facudade ieducare - fied", "acronym": "FIED" },
  { "institution": "instituto superior do litoral do paraná", "acronym": "ISULPAR" },
  { "institution": "universidade estadual de londrina", "acronym": "UEL" },
  { "institution": "instituto de ensino superior aquidauanense", "acronym": "IESA" },
  { "institution": "faculdade presidente antônio carlos de várzea da palma", "acronym": "FEES Várzea da Palma" },
  { "institution": "faculdade de tecnologia de presidente prudente", "acronym": "FATEC" },
  { "institution": "faculdades vale do piancó", "acronym": "FAVAP" },
  { "institution": "centro universitário assunção", "acronym": "UNIFAI" },
  { "institution": "centro de instrução de guerra eletrônica", "acronym": "CIGE" },
  { "institution": "escola superior de engenharia e gestão de valinhos - eseg", "acronym": "ESEG" },
  { "institution": "faculdade de ciências humanas e sociais do xingu e amazônia", "acronym": "FACX" },
  { "institution": "faculdade coelho neto", "acronym": "FACNET" },
  { "institution": "faculdade cnec itajaí", "acronym": "null" },
  { "institution": "instituto superior de educação de floresta", "acronym": "ISEF" },
  { "institution": "faculdade camaqüense de ciências contábeis e administrativas", "acronym": "FACCCA" },
  { "institution": "instituto de ensino superior de bauru", "acronym": "IESB" },
  { "institution": "faculdade pitágoras de divinópolis", "acronym": "PIT DIVINÓPOLIS" },
  { "institution": "faculdade de teologia e ciências humanas", "acronym": "FATECH" },
  { "institution": "faculdade luiz eduardo magalhães", "acronym": "FILEM" },
  { "institution": "faculdade auden educacional", "acronym": "FAED" },
  { "institution": "centro universitário anhanguera pitágoras unopar de campo grande", "acronym": "null" },
  { "institution": "faculdade de ciências agrárias de araripina", "acronym": "FACIAGRA" },
  { "institution": "faculdade vizcaya", "acronym": "VIZCAYA" },
  { "institution": "faculdade católica de santa catarina", "acronym": "FACASC" },
  { "institution": "faculdade promove de minas gerais", "acronym": "PROMOVE" },
  { "institution": "centro universitário municipal de franca", "acronym": "UNI-FACEF" },
  { "institution": "instituto paraibano de ensino renovado", "acronym": "INPER" },
  { "institution": "faculdade cristã da cidade", "acronym": "FCC" },
  { "institution": "faculdade - uninorte  barcarena", "acronym": "null" },
  { "institution": "faculdade de filosofia ciências e letras professora nair fortes abu-merhy", "acronym": "FAFI - PRONAFOR" },
  { "institution": "universidade salgado de oliveira", "acronym": "UNIVERSO" },
  { "institution": "centro universitário uniftec", "acronym": "null" },
  { "institution": "fatec arthur azevedo - mogi mirim", "acronym": "FATECMM" },
  { "institution": "instituto superior de educação do centro educacional nossa senhora auxiliadora", "acronym": "ISECENSA" },
  { "institution": "faculdade barddal", "acronym": "FB-SI" },
  { "institution": "faculdade de ciências exatas de garanhuns", "acronym": "FACEG" },
  { "institution": "faculdades integradas do vale do ivaí", "acronym": "null" },
  { "institution": "universidade paulista", "acronym": "UNIP" },
  { "institution": "faculdade presidente antônio carlos de uruana de minas", "acronym": "FUNEES" },
  { "institution": "faculdades integradas de ciências humanas, saúde e educação de guarulhos", "acronym": "FG" },
  { "institution": "faculdade pitágoras de arapiraca", "acronym": "null" },
  { "institution": "faculdade de teodoro sampaio", "acronym": "FTS" },
  { "institution": "instituto federal de educação, ciência e tecnologia de mato grosso", "acronym": "IFMT" },
  { "institution": "instituto federal de educação, ciência e tecnologia da bahia", "acronym": "IFBA" },
  { "institution": "centro de ensino superior sociesc de jaraguá do sul", "acronym": "null" },
  { "institution": "faculdade metropolitana de franca", "acronym": "FAMEF" },
  { "institution": "centro universitário municipal de são josé", "acronym": "USJ" },
  { "institution": "centro de ensino superior de altamira", "acronym": "null" },
  { "institution": "faculdade de tecnologia de jahu", "acronym": "FATEC-JAHU" },
  { "institution": "faculdade barão de jequiriça", "acronym": "FBJ DIGITAL" },
  { "institution": "centro universitário maurício de nassau de natal", "acronym": "UNINASSAU" },
  { "institution": "centro de ensino superior de divinópolis", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai paulo de tarso", "acronym": "FATEC SENAI PT" },
  { "institution": "faculdade ibra da grande são paulo", "acronym": "FACULDADE FAGRAN" },
  { "institution": "faculdade holística", "acronym": "FaHol" },
  { "institution": "faculdade pitágoras de brumado", "acronym": "null" },
  { "institution": "faculdade itpac garanhuns", "acronym": "ITPAC-GARANHUNS" },
  { "institution": "faculdade cal de artes cênicas", "acronym": "Faculdade Cal" },
  { "institution": "escola de engenharia kennedy", "acronym": "EEK" },
  { "institution": "faculdade de teologia umbandista", "acronym": "F.T.U." },
  { "institution": "faculdade de educação da ibiapaba", "acronym": "FAEDI" },
  { "institution": "ratio - faculdade teológica e filosófica", "acronym": "RATIO" },
  { "institution": "faculdade anhanguera de guarapari", "acronym": "null" },
  { "institution": "faculdade realiza", "acronym": "null" },
  { "institution": "faculdade são bernardo de tecnologia", "acronym": "SBTEC" },
  { "institution": "universidade federal de mato grosso do sul", "acronym": "UFMS" },
  { "institution": "universidade do estado do rio de janeiro", "acronym": "UERJ" },
  { "institution": "faculdade metropolitana de itajaí", "acronym": "FIMT" },
  { "institution": "faculdade de tecnologia paulista", "acronym": "null" },
  { "institution": "faculdade anhanguera de ribeirão das neves", "acronym": "null" },
  { "institution": "faculdade sogipa de educação física", "acronym": "null" },
  { "institution": "faculdade padrão", "acronym": "null" },
  { "institution": "faculdade univeritas universus veritas de joinville", "acronym": "VERITAS JOINVILLE" },
  { "institution": "centro universitário faminas", "acronym": "UNIFAMINAS" },
  { "institution": "faculdade de educação superior de tangará da serra", "acronym": "FAEST" },
  { "institution": "universidade positivo", "acronym": "UP" },
  { "institution": "instituto superior de educação de cariacica", "acronym": "ISEC" },
  { "institution": "faculdade funorte de januária", "acronym": "null" },
  { "institution": "faculdade lourenço filho", "acronym": "FLF" },
  { "institution": "faculdade finom de patos de minas", "acronym": "FINOM" },
  { "institution": "faculdade de administração e artes de limeira", "acronym": "FAAL" },
  { "institution": "instituto superior de educação ivoti", "acronym": "ISEI" },
  { "institution": "faculdades integradas rui barbosa", "acronym": "FIRB" },
  { "institution": "faculdade presidente antônio carlos de sabará", "acronym": "FUNEES Sabará" },
  { "institution": "faculdade caraguá", "acronym": "null" },
  { "institution": "faculdade de ensino superior do piauí", "acronym": "FAESPI" },
  { "institution": "faculdade integrada de campo grande", "acronym": "INTEGRADA" },
  { "institution": "faculdade do norte pioneiro de jacarezinho", "acronym": "FANORPIJAC" },
  { "institution": "instituto superior de tecnologia em horticultura", "acronym": "ISTHORTICULTURA" },
  { "institution": "faculdade anhanguera de primavera do leste", "acronym": "FIPL" },
  { "institution": "faculdade grande vitória", "acronym": "UNFGV" },
  { "institution": "faculdade atenas do sul baiano", "acronym": "null" },
  { "institution": "faculdade senai rio", "acronym": "null" },
  { "institution": "instituto de educação superior e inovação persona", "acronym": "null" },
  { "institution": "instituto luterano de ensino superior de itumbiara", "acronym": "ILES" },
  { "institution": "faculdade de tecnologia ipep de campinas", "acronym": "FATEC CAMPINAS" },
  { "institution": "universidade federal de jataí", "acronym": "UFJ" },
  { "institution": "faculdade saint germain sp", "acronym": "FSGSP" },
  { "institution": "instituto superior de educação de barretos", "acronym": "ISEB" },
  { "institution": "faculdade andreotti de maringá", "acronym": "FAM" },
  { "institution": "faculdade capacitar", "acronym": "null" },
  { "institution": "faculdades integradas adventistas de minas gerais", "acronym": "FADMINAS" },
  { "institution": "faculdade de tecnologia de taquaritinga", "acronym": "FATEC/TQ" },
  { "institution": "escola de direito do rio de janeiro", "acronym": "DIREITO RIO" },
  { "institution": "faculdade de ciências da saúde de são paulo", "acronym": "FACIS" },
  { "institution": "faculdades planalto central", "acronym": "FPC" },
  { "institution": "faculdades integradas do extremo sul da bahia", "acronym": "UNESULBAHIA" },
  { "institution": "faculdade anhanguera de divinópolis", "acronym": "null" },
  { "institution": "faculdade atenas valença", "acronym": "null" },
  { "institution": "faculdade cesma de maracanaú", "acronym": "FACESMA" },
  { "institution": "faculdade unirb - salvador", "acronym": "FACULDADE UNIRB" },
  { "institution": "faculdades integradas de diamantino", "acronym": "FID" },
  { "institution": "faculdade alfaunipac de guanhães", "acronym": "null" },
  { "institution": "instituto superior de educação auxilium", "acronym": "ISE AUXILIUM" },
  { "institution": "faculdade regional do jacuípe", "acronym": "FARJ" },
  { "institution": "faculdade paraense de ensino", "acronym": "FAPEN" },
  { "institution": "faculdade de ciências agrárias de andradina", "acronym": "FCAA" },
  { "institution": "faculdade messianica", "acronym": "null" },
  { "institution": "centro universitário maurício de nassau de joão pessoa", "acronym": "null" },
  { "institution": "universidade federal de são paulo", "acronym": "UNIFESP" },
  { "institution": "faculdade de ciências médicas de maricá", "acronym": "null" },
  { "institution": "escola superior de administração, marketing e comunicação de goiânia", "acronym": "ESAMC DE GOIÂNIA" },
  { "institution": "faculdade de presidente venceslau", "acronym": "FAPREV" },
  { "institution": "universidade de rio verde", "acronym": "FESURV" },
  { "institution": "instituto superior de educação almeida rodrigues", "acronym": "ISEAR" },
  { "institution": "faculdade pitágoras de bacabal", "acronym": "null" },
  { "institution": "universidade salvador", "acronym": "UNIFACS" },
  { "institution": "universidade do vale do rio dos sinos", "acronym": "UNISINOS" },
  { "institution": "centro universitário unidom - bosco", "acronym": "UNIDOM - BOSCO" },
  { "institution": "faculdade estácio de juazeiro", "acronym": "EstácioJuazeiro" },
  { "institution": "faculdade dos imigrantes - fai", "acronym": "FAI" },
  { "institution": "instituto de ensino superior de alagoas", "acronym": "IESA" },
  { "institution": "faculdade de direito e ciências sociais do leste de minas - fadileste", "acronym": "FADILESTE" },
  { "institution": "faculdade católica paulista", "acronym": "FACAP" },
  { "institution": "instituto de assistência médica ao servidor público estadual", "acronym": "IAMSPE" },
  { "institution": "faculdade meridional de porto alegre", "acronym": "IMED" },
  { "institution": "centro universitário inta", "acronym": "UNINTA" },
  { "institution": "instituto superior de educação são judas tadeu", "acronym": "ISESJT" },
  { "institution": "universidade de gurupi", "acronym": "UnirG" },
  { "institution": "faculdade pitágoras de tecnologia de contagem", "acronym": "null" },
  { "institution": "faculdade de tecnologia sebrae", "acronym": "FATEC SEBRAE" },
  { "institution": "faculdade presidente antônio carlos de maria da fé", "acronym": "FUNEES" },
  { "institution": "instituto de filosofia e de teologia paulo vi", "acronym": "IFITEPS" },
  { "institution": "faculdade projeção de ceilândia", "acronym": "FAPRO" },
  { "institution": "universidade federal de são carlos", "acronym": "UFSCAR" },
  { "institution": "unetri faculdades", "acronym": "UNETRI" },
  { "institution": "faculdade ages de tucano", "acronym": "Faculdade AGES" },
  { "institution": "faculdade de osasco", "acronym": "FCO" },
  { "institution": "faculdade bahiana de engenharia e ciências sociais aplicadas", "acronym": "FBE" },
  { "institution": "faculdade de saúde do sertão de pernambuco", "acronym": "FASPE" },
  { "institution": "faculdade de administração e negócios", "acronym": "FAN" },
  { "institution": "instituto tecnológico e das ciências sociais aplicadas e da saúde do centro educ. n. srª auxiliadora", "acronym": "ITCSAS/CENSA" },
  { "institution": "faculdades integradas paulista", "acronym": "FIP" },
  { "institution": "faculdade de mediação ívia corneli", "acronym": "FAMIC" },
  { "institution": "faculdade de ciências contábeis de itapetininga", "acronym": "FCCI" },
  { "institution": "faculdade de são bernardo do campo", "acronym": "FAPAN" },
  { "institution": "international orofacial academy rp", "acronym": "IOA RP" },
  { "institution": "faculdade ajes", "acronym": "AJES" },
  { "institution": "escola superior de propaganda e marketing de porto alegre", "acronym": "ESPM - POA" },
  { "institution": "faculdade de tecnologia da amazônia", "acronym": "FATEC" },
  { "institution": "faculdade projeção de taguatinga norte", "acronym": "FAPRO" },
  { "institution": "centro universitário maurício de nassau de campina grande", "acronym": "UNINASSAU CPV" },
  { "institution": "faculdade de tecnologia cachoeiro de itapemirim", "acronym": "FACI" },
  { "institution": "faculdade evangélica de senador canedo", "acronym": "FESCAN" },
  { "institution": "faculdade ilapeo", "acronym": "ILAPEO" },
  { "institution": "faculdade são paulo de santos", "acronym": "FSPS" },
  { "institution": "faculdade de tapejara", "acronym": "FAT" },
  { "institution": "universidade federal do piauí", "acronym": "UFPI" },
  { "institution": "instituto federal de educação, ciência e tecnologia do amapá", "acronym": "IFAP" },
  { "institution": "faculdade regional de leopoldina", "acronym": "null" },
  { "institution": "faculdade professor daltro", "acronym": "null" },
  { "institution": "universidade estadual de goiás", "acronym": "UEG" },
  { "institution": "faculdade de ibitinga", "acronym": "FAIB" },
  { "institution": "faculdade líder", "acronym": "null" },
  { "institution": "centro universitário unicuritiba", "acronym": "UniCuritiba" },
  { "institution": "faculdade de mirassol", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de recreio", "acronym": "FUNEES" },
  { "institution": "faculdade multieducativa", "acronym": "MULTIEDUCATIVA" },
  { "institution": "faculdade de ciências da fundação instituto tecnológico de osasco", "acronym": "FAC-FITO" },
  { "institution": "faculdade fael de curitiba", "acronym": "FAEL CURITIBA" },
  { "institution": "centro universitario avantis", "acronym": "UNIAVAN" },
  { "institution": "faculdade pensar", "acronym": "FP" },
  { "institution": "faculdade poliensino - fp", "acronym": "null" },
  { "institution": "faculdade planalto de administração e ciências econômicas", "acronym": "FACPLAN" },
  { "institution": "faculdade phênix de ciências humanas e sociais do brasil", "acronym": "PHENIX" },
  { "institution": "panamericana faculdade de arte e design", "acronym": "null" },
  { "institution": "faculdade estratego", "acronym": "ESTRATEGO" },
  { "institution": "faculdade dom bosco de capitão leônidas marques", "acronym": "null" },
  { "institution": "faculdade jaguar indaiá", "acronym": "FJI" },
  { "institution": "instituto católico de estudos superiores do piauí", "acronym": "ICESPI" },
  { "institution": "faculdade cidade de patos de minas", "acronym": "FPM" },
  { "institution": "centro de medicina especializada, pesquisa e ensino", "acronym": "CEMEPE" },
  { "institution": "faculdade de ciências e educação em saúde", "acronym": "FACCESA" },
  { "institution": "faculdade anhanguera do ceará", "acronym": "null" },
  { "institution": "faculdade de turismo - santos dumont", "acronym": "FACTURSD" },
  { "institution": "faculdade vértix trirriense", "acronym": "FVT" },
  { "institution": "faculdade anhanguera unidade paragominas", "acronym": "null" },
  { "institution": "faculdade madre thais", "acronym": "FMT" },
  { "institution": "centro universitário cnec de bento gonçalves", "acronym": "null" },
  { "institution": "faculdade centro paulistano", "acronym": "null" },
  { "institution": "faculdade avantis de florianópolis", "acronym": "AVANTIS" },
  { "institution": "faculdade de balsas", "acronym": "UNIBALSAS" },
  { "institution": "instituto federal de educação ciência e tecnologia de rondônia", "acronym": "IFRO" },
  { "institution": "faculdade avec de vilhena", "acronym": "AVEC" },
  { "institution": "faculdade maria thereza", "acronym": "FAMATH" },
  { "institution": "faculdade promove de alfenas", "acronym": "null" },
  { "institution": "instituto de ciências sociais aplicadas", "acronym": "UNEB" },
  { "institution": "faculdade de filosofia ciências e letras de penápolis", "acronym": "FAFIPE" },
  { "institution": "centro universitário presidente antônio carlos de barbacena", "acronym": "UNIPAC" },
  { "institution": "universidade cesumar", "acronym": "UNICESUMAR" },
  { "institution": "faculdade são francisco de piumhi", "acronym": "FASPI" },
  { "institution": "universidade cidade de são paulo", "acronym": "UNICID" },
  { "institution": "faculdade dourado", "acronym": "FD" },
  { "institution": "faculdade centro são paulo", "acronym": "FACESP" },
  { "institution": "faculdade ágora - administração, educação e cultura", "acronym": "FAAEC" },
  { "institution": "faculdades integradas da upis", "acronym": "UPIS" },
  { "institution": "faculdade três marias", "acronym": "FTM" },
  { "institution": "faculdades integradas aparício carvalho vilhena", "acronym": "FIMCAVILHENA" },
  { "institution": "faculdade de teologia integrada", "acronym": "FATIN" },
  { "institution": "faculdades integradas do sul de minas - fisminas", "acronym": "null" },
  { "institution": "faculdade união araruama de ensino s/s ltda.", "acronym": "FAC-UNILAGOS" },
  { "institution": "faculdade cásper líbero", "acronym": "FCL" },
  { "institution": "faculdade de tecnologia e educação superior e profissionaliz", "acronym": "FATESP" },
  { "institution": "faculdade de desenvolvimento sustentável de cruzeiro do sul", "acronym": "IEVAL" },
  { "institution": "faculdade do guarujá", "acronym": "FAGU" },
  { "institution": "faculdade mogiana do estado de são paulo", "acronym": "FMG" },
  { "institution": "faculdade alvorada de educação física e desporto", "acronym": "FAEFD" },
  { "institution": "faculdade legale", "acronym": "LEGALE" },
  { "institution": "faculdade visconde de cairú", "acronym": "FAVIC" },
  { "institution": "fundação universidade federal de ciências da saúde de porto alegre", "acronym": "UFCSPA" },
  { "institution": "faculdade pitágoras unopar de muriaé", "acronym": "null" },
  { "institution": "faculdade de educação", "acronym": "ISECUB" },
  { "institution": "faculdade de ciências contábeis de itabirito", "acronym": "FACCI" },
  { "institution": "centro de ensino superior do amapá", "acronym": "CEAP" },
  { "institution": "faculdade do comércio de são paulo", "acronym": "FAC-SP" },
  { "institution": "instituto teológico pastoral do ceará", "acronym": "ITEP" },
  { "institution": "universidade regional do noroeste do estado do rio grande do sul", "acronym": "UNIJUI" },
  { "institution": "faculdade virtual do brasil", "acronym": "FVB" },
  { "institution": "faculdade guarapuava", "acronym": "FG" },
  { "institution": "centro educacional em aeronáutica", "acronym": "CEA" },
  { "institution": "faculdade prof. luíz mário d'avila", "acronym": "FADAVILA" },
  { "institution": "faculdade kennedy de ipatinga", "acronym": "FKIPATINGA" },
  { "institution": "faculdades integradas machado de assis", "acronym": "FEMA" },
  { "institution": "centro universitário facens", "acronym": "UniFACENS" },
  { "institution": "itpac itacoatiara", "acronym": "ITPAC ITA" },
  { "institution": "faculdade maurício de nassau de caxias do sul", "acronym": "FMN Caxias Sul" },
  { "institution": "escola superior de engenharia e gestão de são paulo - eseg", "acronym": "ESEG" },
  { "institution": "centro de ensino superior de bacabeira", "acronym": "CESBA" },
  { "institution": "faculdade de educação vale do pajeú", "acronym": "FVP" },
  { "institution": "faculdade ideau de passo fundo", "acronym": "IDEAU" },
  { "institution": "faculdade multivix são mateus", "acronym": "MULTIVIX SÃO MATEUS" },
  { "institution": "faculdade feluma - saúde, tecnologia e ciência", "acronym": "Feluma" },
  { "institution": "ipd instituto de profissionalização digital", "acronym": "IPD" },
  { "institution": "faculdade de música souza lima", "acronym": "FMSL" },
  { "institution": "faculdade de ciências humanas de olinda", "acronym": "FACHO" },
  { "institution": "faculdade dom bosco", "acronym": "DOM BOSCO" },
  { "institution": "faculdade paulista de serviço social", "acronym": "FAPSS-SP." },
  { "institution": "faculdade de tecnologia professor fernando amaral de almeida prado", "acronym": "null" },
  { "institution": "universidade estadual de montes claros", "acronym": "UNIMONTES" },
  { "institution": "abeu - centro universitário", "acronym": "UNIABEU" },
  { "institution": "centro universitário sudoeste paulista", "acronym": "UniFSP" },
  { "institution": "faculdade egas moniz", "acronym": "FEM" },
  { "institution": "faculdade centro mato-grossense", "acronym": "null" },
  { "institution": "centro universitário de patos", "acronym": "UNIFIP" },
  { "institution": "faculdade de ciências jurídicas de sao joão da boa vista", "acronym": "null" },
  { "institution": "centro universitário estácio de belo horizonte - estácio bh", "acronym": "ESTÁCIO BH" },
  { "institution": "faculdade educamais", "acronym": "EDUCA+" },
  { "institution": "faculdade do norte de mato grosso", "acronym": "AJES" },
  { "institution": "faculdade de medicina", "acronym": "ITPAC" },
  { "institution": "faculdade são carlos", "acronym": "null" },
  { "institution": "faculdade nova roma caruaru", "acronym": "FNR CARUARU" },
  { "institution": "faculdade anhanguera de pelotas", "acronym": "null" },
  { "institution": "universidade federal de campina grande", "acronym": "UFCG" },
  { "institution": "faculdade presidente antônio carlos de bocaiúva", "acronym": "null" },
  { "institution": "faculdade senac jaraguá do sul", "acronym": "null" },
  { "institution": "centro universitário do vale do ribeira", "acronym": "UNIVR" },
  { "institution": "faculdade de ciências de goiana", "acronym": "FCG" },
  { "institution": "faculdade estácio são paulo de rondônia", "acronym": "Estácio FSP" },
  { "institution": "faculdade de itapetininga", "acronym": "FI" },
  { "institution": "faculdade salesiana do nordeste", "acronym": "FASNE" },
  { "institution": "centro universitário fg", "acronym": "UNIFG" },
  { "institution": "centro federal de educação tecnológica de minas gerais", "acronym": "CEFET/MG" },
  { "institution": "escola superior são judas de são bernardo do campo", "acronym": "null" },
  { "institution": "faculdade de ciências educacionais e tecnologias integradas", "acronym": "FACETI" },
  { "institution": "faculdade cgesp goiania", "acronym": "FAC CGESP" },
  { "institution": "faculdade presbiteriana mackenzie brasilia", "acronym": "FPMB" },
  { "institution": "faculdade de curitiba", "acronym": "null" },
  { "institution": "escola nacional de ciências estatísticas", "acronym": "ENCE" },
  { "institution": "faculdade de educação superior de chapecó", "acronym": "FACESC" },
  { "institution": "centro universitário doctum de teófilo otoni", "acronym": "UNIDOCTUM" },
  { "institution": "faculdade uninassau garanhuns", "acronym": "Nassau Garanhuns" },
  { "institution": "faculdade de vinhedo", "acronym": "FV" },
  { "institution": "faculdade teológica evangélica do rio de janeiro", "acronym": "FATERJ" },
  { "institution": "centro universitário maurício de uninassau de parnaíba", "acronym": "Uninassau Parnaíba" },
  { "institution": "faculdade educacional araucária", "acronym": "Facear" },
  { "institution": "faculdade de tecnologia de caxias do sul", "acronym": "FTC" },
  { "institution": "faculdade união de goyazes formosa", "acronym": "FUG" },
  { "institution": "faculdade modelo", "acronym": "FACIMOD" },
  { "institution": "faculdade sul da américa", "acronym": "SULDAMÉRICA" },
  { "institution": "faculdade senac blumenau", "acronym": "SENAC" },
  { "institution": "faculdade prisma de apucarana", "acronym": "FPA" },
  { "institution": "universidade católica do salvador", "acronym": "UCSAL" },
  { "institution": "faculdade de tecnologia informática", "acronym": "FATI" },
  { "institution": "faculdade do maranhão", "acronym": "FACAM-MA" },
  { "institution": "centro universitário do cerrado-patrocínio", "acronym": "UNICERP" },
  { "institution": "faculdade tecnológica dental ceeo", "acronym": "ESD-CEEO" },
  { "institution": "faculdade de tecnologia senai roberto mange", "acronym": "FATEC SENAI RM" },
  { "institution": "faculdade do instituto brasil", "acronym": "FIBRA" },
  { "institution": "faculdade dinâmica do vale do piranga", "acronym": "FADIP" },
  { "institution": "faculdade do nordeste da bahia", "acronym": "FANEB" },
  { "institution": "faculdade una de nova serrana", "acronym": "UNA" },
  { "institution": "faculdade rebouças de campina grande", "acronym": "FRCG" },
  { "institution": "centro universitário antônio eufrásio de toledo de presidente prudente - toledo prudente", "acronym": "TOLEDO PRUDENTE" },
  { "institution": "faculdade de tecnologia insaeos", "acronym": "INSAEOS" },
  { "institution": "faculdade delmiro gouveia", "acronym": "FDG" },
  { "institution": "faculdade de teologia de são paulo da igreja presbiteriana independente do brasil", "acronym": "null" },
  { "institution": "faculdade senac concórdia", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de uberaba", "acronym": "null" },
  { "institution": "faculdade de administração de cataguases", "acronym": "UNIPACAT" },
  { "institution": "faculdade anhanguera de governador valadares", "acronym": "null" },
  { "institution": "centro universitário do rio são francisco unirios", "acronym": "UNIRIOS" },
  { "institution": "centro universitário sociesc de blumenau", "acronym": "null" },
  { "institution": "faculdade de gestão, educação e de saúde", "acronym": "FGS" },
  { "institution": "faculdade de comunicação anhanguera unidade guarapari", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de são vicente de minas", "acronym": "FUNEES" },
  { "institution": "instituto superior de teologia e pastoral de bonfim", "acronym": "ISTEPAB" },
  { "institution": "faculdade de são marcos", "acronym": "FACSM" },
  { "institution": "faculdade são vicente de irati", "acronym": "FASVI" },
  { "institution": "faculdade para o desenvolvimento do sudeste tocantinense", "acronym": "FADES" },
  { "institution": "faculdade concórdia", "acronym": "FACC" },
  { "institution": "faculdade de tecnologia senai itajaí", "acronym": "FATEC SENAI ITAJAÍ" },
  { "institution": "faculdade de tecnologia e ciências da bahia", "acronym": "FATEC/BA" },
  { "institution": "universidade de araraquara", "acronym": "UNIARA" },
  { "institution": "instituto taubaté de ensino superior", "acronym": "I.T.E.S." },
  { "institution": "escola superior de agronomia de paraguaçu paulista", "acronym": "ESAPP" },
  { "institution": "centro universitário metropolitano da amazônia", "acronym": "UNIFAMAZ" },
  { "institution": "centro universitário da fundação educacional de barretos", "acronym": "UNIFEB" },
  { "institution": "sirius escola superior de tecnologia", "acronym": "SIRIUS" },
  { "institution": "faculdade de caldas novas", "acronym": "UNICALDAS" },
  { "institution": "instituto de ensino superior do amapá", "acronym": "IESAP" },
  { "institution": "escola superior batista do amazonas", "acronym": "ESBAM" },
  { "institution": "faculdade de ciências gerenciais alves fortes", "acronym": "FACE - ALFOR" },
  { "institution": "instituto federal de educação, ciência e tecnologia de mato grosso do sul", "acronym": "IFMS" },
  { "institution": "claretiano - faculdade de boa vista", "acronym": "CLARETIANOBV" },
  { "institution": "faculdade anasps", "acronym": "FANASPS" },
  { "institution": "faculdade hospital são paulo de muriaé", "acronym": "FAMEHSP" },
  { "institution": "faculdade do sertão do são francisco", "acronym": "FASF" },
  { "institution": "centro de estudos de pessoal e forte duque de caxias", "acronym": "CEP" },
  { "institution": "fg faculdade global", "acronym": "FG" },
  { "institution": "centro universitário unisep", "acronym": "CEUUN" },
  { "institution": "faculdade batista brasileira", "acronym": "FBB" },
  { "institution": "faculdade de direito do sul de minas", "acronym": "FDSM" },
  { "institution": "cisne - faculdade tecnológica de quixadá", "acronym": "CFTQ" },
  { "institution": "faculdade  de ciências e tecnologia professor dirson maciel de barros", "acronym": "FADIMAB" },
  { "institution": "faculdade dominius", "acronym": "FAD" },
  { "institution": "faculdade de imperatriz wyden", "acronym": "Facimp Wyden" },
  { "institution": "faculdade estácio cachoeiro de itapemirim", "acronym": "Estácio FECI" },
  { "institution": "faculdade anclivepa de gestão e humanologia", "acronym": "FAGH" },
  { "institution": "voas - instituto de aprendizagem superior", "acronym": "VOAS" },
  { "institution": "faculdade dom bosco de ubiratã", "acronym": "DOM BOSCO UBIRATÃ" },
  { "institution": "faculdade claretiana de teologia", "acronym": "null" },
  { "institution": "universidade federal do paraná", "acronym": "UFPR" },
  { "institution": "faculdade integrada de palmeiras de goiás", "acronym": "FAI" },
  { "institution": "faculdade de filosofia, ciências e letras de itapetininga", "acronym": "FFCLI" },
  { "institution": "faculdades integradas de rio verde", "acronym": "FIRVE" },
  { "institution": "faculdade fasipe de sorriso", "acronym": "FFS" },
  { "institution": "instituto de ensino superior de alphaville", "acronym": "IESA" },
  { "institution": "faculdade cultura inglesa", "acronym": "FCI" },
  { "institution": "instituto universitário una de conselheiro lafaiete", "acronym": "null" },
  { "institution": "faculdade promove de tecnologia", "acronym": "FPTEC" },
  { "institution": "centro universitário são camilo", "acronym": "SAO CAMILO" },
  { "institution": "faculdade unibras do maranhão", "acronym": "FACBRAS" },
  { "institution": "universidade metodista de piracicaba", "acronym": "UNIMEP" },
  { "institution": "faculdade aberta do tocantins", "acronym": "FAT" },
  { "institution": "faculdade da polícia militar", "acronym": "FPM" },
  { "institution": "faculdade serrana de saúde", "acronym": "FASS" },
  { "institution": "faculdade nazarena do brasil", "acronym": "FNB" },
  { "institution": "instituto superior de educação professor aldo muylaert", "acronym": "ISEPAM" },
  { "institution": "faculdade de administração de empresas ibrafem", "acronym": "IBRAFEM" },
  { "institution": "faculdade maurício de nassau de uberlândia", "acronym": "FMN Uberlândia" },
  { "institution": "centro universitário imepac - araguari", "acronym": "null" },
  { "institution": "faculdade telos de campinas", "acronym": "FATELOS" },
  { "institution": "instituto infnet rio de janeiro", "acronym": "INFNET" },
  { "institution": "centro universitário barriga verde", "acronym": "UNIBAVE" },
  { "institution": "ibptech faculdade de ciências forenses e tecnologia", "acronym": "null" },
  { "institution": "escola de formação complementar do exército", "acronym": "EsFCEx" },
  { "institution": "faculdade de jundiaí", "acronym": "null" },
  { "institution": "faculdade paraíso fortaleza", "acronym": "FAP" },
  { "institution": "faculdade uninassau brasília", "acronym": "Nassau Brasília" },
  { "institution": "escola de equitação do exército", "acronym": "EsEqEx" },
  { "institution": "faculdade leonardo da vinci - santa catarina", "acronym": "FAVINCI" },
  { "institution": "faculdade de tecnologia senai dourados", "acronym": "FATEC Dourados" },
  { "institution": "instituto de educação superior e inovação invenio", "acronym": "INVENIO" },
  { "institution": "centro universitário facex", "acronym": "UNIFACEX" },
  { "institution": "faculdade presidente antônio carlos de aimorés", "acronym": "null" },
  { "institution": "faculdade de ciências odontológicas", "acronym": "FCO" },
  { "institution": "faculdade de tecnologia e ciências - ftc juazeiro do norte", "acronym": "FTC JUAZEIRO CE" },
  { "institution": "faculdade fucape", "acronym": "FUCAPE" },
  { "institution": "faculdade do sertão", "acronym": "UESSBA" },
  { "institution": "faculdade uninassau petrolina", "acronym": "null" },
  { "institution": "faculdade life", "acronym": "FACLIFE" },
  { "institution": "centro universitário estácio da amazônia", "acronym": "ESTÁCIO AMAZÔNIA" },
  { "institution": "centro universitário santa cecília", "acronym": "Unicea" },
  { "institution": "universidade fumec", "acronym": "FUMEC" },
  { "institution": "faculdade decision de negócios", "acronym": "null" },
  { "institution": "faculdade esamc goiânia", "acronym": "ESAMC GOIÂNIA" },
  { "institution": "faculdade de pará de minas", "acronym": "FAPAM" },
  { "institution": "faculdade central de cristalina", "acronym": "FACEC" },
  { "institution": "faculdade unibras do norte goiano", "acronym": "FACBRAS" },
  { "institution": "faculdade de direito milton campos", "acronym": "FDMC" },
  { "institution": "faculdade são judas de guarulhos", "acronym": "null" },
  { "institution": "faculdade pitágoras de irecê", "acronym": "FPI" },
  { "institution": "centro universitário católica do leste de minas gerais", "acronym": "UNILESTE" },
  { "institution": "instituto de educação superior raimundo sá", "acronym": "IESRSA" },
  { "institution": "faculdade assis gurgacz", "acronym": "FAG TOLEDO" },
  { "institution": "faculdade de tecnologia ibratec", "acronym": "IBRATEC" },
  { "institution": "faculdade integrada de maringá", "acronym": "INTEGRADA" },
  { "institution": "faculdade de tecnologia de bauru", "acronym": "FATEC" },
  { "institution": "faculdade claretiana de brasilia", "acronym": "FCB" },
  { "institution": "faculdade de direito de varginha - fadiva", "acronym": "FADIVA" },
  { "institution": "faculdades integradas oswaldo cruz", "acronym": "null" },
  { "institution": "faculdade anhanguera de pindamonhangaba", "acronym": "null" },
  { "institution": "faculdade maurício de nassau de florianópolis", "acronym": "FMN FLORIPA" },
  { "institution": "faculdade de tecnologia lourenço filho", "acronym": "FATEC FLF" },
  { "institution": "faculdade calafiori", "acronym": "CALAFIORI" },
  { "institution": "faculdade de ciências sociais aplicadas de marabá", "acronym": "FACIMAB" },
  { "institution": "universidade do estado do amazonas", "acronym": "UEA" },
  { "institution": "faculdade pestalozzi de franca", "acronym": "FAPESF" },
  { "institution": "faculdade presidente antônio carlos de barroso", "acronym": "FUNEES" },
  { "institution": "faculdade centro oeste do paraná", "acronym": "FACEOPAR" },
  { "institution": "faculdade bandeirantes de ciências exatas e humanas", "acronym": "FACEX" },
  { "institution": "centro universitário sociesc", "acronym": "null" },
  { "institution": "faculdade solidaria do oeste paulista", "acronym": "FASOL" },
  { "institution": "faculdade de goiânia - unidade 1", "acronym": "FAG" },
  { "institution": "centro universitário unifacear", "acronym": "null" },
  { "institution": "instituto de educação superior presidente kennedy - centro de formação de profissionais de educação", "acronym": "IFESP" },
  { "institution": "faculdade figueiredo costa - fic", "acronym": "null" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro - faeterj", "acronym": "FAETERJ PETRÓPOLIS" },
  { "institution": "faculdade villas boas", "acronym": "FVB" },
  { "institution": "faculdade paulista de ciências da saúde", "acronym": "FPCS" },
  { "institution": "faculdade univeritas universus veritas de nova iguaçu", "acronym": "UNIVERITAS NI" },
  { "institution": "faculdade metropolitana do cariri", "acronym": "FAMEC" },
  { "institution": "faculdade de tecnologia lourenço filho", "acronym": "FATEC - FLF" },
  { "institution": "centro universitário ingá", "acronym": "UNINGÁ" },
  { "institution": "faculdade de ciências contábeis luiz mendes", "acronym": "LUMEN FACULDADES" },
  { "institution": "centro universitário de valença", "acronym": "UNIFAA" },
  { "institution": "faculdade anhanguera de brasília", "acronym": "null" },
  { "institution": "centro universitário unifas", "acronym": "null" },
  { "institution": "escola superior de estatística da bahia", "acronym": "ESEB" },
  { "institution": "instituto superior de educação dos inconfidentes", "acronym": "ISEI" },
  { "institution": "faculdade diadema", "acronym": "FAD" },
  { "institution": "faculdade anhanguera de são josé do rio preto", "acronym": "FASJRP" },
  { "institution": "faculdade de santa inês", "acronym": "FSI" },
  { "institution": "faculdade do sertao baiano", "acronym": "FASBE" },
  { "institution": "faculdade batista de minas gerais", "acronym": "FBMG" },
  { "institution": "faculdade itpac bragança", "acronym": "ITPAC BRAGANÇA" },
  { "institution": "faculdade de são caetano do sul", "acronym": "null" },
  { "institution": "faculdade de ciências de wenceslau braz", "acronym": "FACIBRA" },
  { "institution": "faculdade de medicina do sertão", "acronym": "FMS" },
  { "institution": "faculdades integradas desembargador sávio brandão", "acronym": "FAUSB" },
  { "institution": "centro universitário brasília do estado de goiás", "acronym": "UniBRASÍLIA" },
  { "institution": "faculdade de tecnologia e ciências de pernambuco", "acronym": "FATEC" },
  { "institution": "faculdade de ensino superior da cidade de feira de santana", "acronym": "FAESF/UNEF" },
  { "institution": "centro universitário famec", "acronym": "UNIFAMEC" },
  { "institution": "instituto superior de ciências aplicadas - isca", "acronym": "ISCA" },
  { "institution": "faculdade santa casa", "acronym": "FSC" },
  { "institution": "instituto centro de ensino tecnológico - cariri", "acronym": "CENTEC" },
  { "institution": "centro universitário barriga verde / unibave cocal do sul", "acronym": "UNIBAVE" },
  { "institution": "instituto de ensino superior brasileiro", "acronym": "ESB" },
  { "institution": "faculdade de tecnologia senac maringá", "acronym": "null" },
  { "institution": "faculdade de enfermagem são vicente de paula", "acronym": "FESVIP" },
  { "institution": "centro universitário la salle do rio de janeiro", "acronym": "UNILASALLE/RJ" },
  { "institution": "universidade federal do recôncavo da bahia", "acronym": "UFRB" },
  { "institution": "centro universitário jorge amado", "acronym": "UNIJORGE" },
  { "institution": "faculdade estácio de bauru", "acronym": "Estácio BAURU" },
  { "institution": "faculdade méliès", "acronym": "MÉLIÈS" },
  { "institution": "faculdade de ciências humanas,exatas e da saúde do piauí", "acronym": "FAHESP" },
  { "institution": "faculdade rehagro", "acronym": "Rehagro" },
  { "institution": "faculdade de educação superior de divinópolis", "acronym": "null" },
  { "institution": "instituto superior de educação de iguape", "acronym": "ISE IGUAPE" },
  { "institution": "faculdade integrada de arapongas", "acronym": "INTEGRADA" },
  { "institution": "instituto de ensino superior santa cecília", "acronym": "IESC" },
  { "institution": "faculdade de ciências e educação do espírito santo", "acronym": "UNIVES" },
  { "institution": "faculdade anhanguera de são luiz", "acronym": "null" },
  { "institution": "centro universitário eurípedes de marília", "acronym": "UNIVEM" },
  { "institution": "faculdade senac pernambuco", "acronym": "SENACPE" },
  { "institution": "faculdade dom adelio tomasin", "acronym": "FADAT" },
  { "institution": "faculdade de ciências administrativas", "acronym": "FCA" },
  { "institution": "faculdade imes", "acronym": "IMES" },
  { "institution": "centro universitário de tecnologia carlos drummond de andrade", "acronym": "null" },
  { "institution": "faculdade famercosul", "acronym": "FSUL" },
  { "institution": "faculdade uniateneu", "acronym": "FATE" },
  { "institution": "faculdade multivix serra", "acronym": "MULTIVIX SERRA" },
  { "institution": "faculdade ages de senhor do bonfim", "acronym": "Faculdade AGES" },
  { "institution": "faculdade brasil empreender", "acronym": "FABEMP" },
  { "institution": "centro universitário de tecnologia e negócios carlos drummond de andrade", "acronym": "UniTen CDA" },
  { "institution": "faculdade estácio de ibiúna", "acronym": "ESTÁCIO IBIÚNA" },
  { "institution": "faculdade cers", "acronym": "CERS" },
  { "institution": "faculdade mogiana do estado de são paulo", "acronym": "FAMOESP" },
  { "institution": "centro de ensino e pesquisa do pró-cardíaco", "acronym": "Procep" },
  { "institution": "faculdade fortium", "acronym": "null" },
  { "institution": "faculdade anhanguera de sorriso", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai concórdia", "acronym": "CET CONCÓRDIA" },
  { "institution": "faculdade santo andre", "acronym": "FASA" },
  { "institution": "faculdade de agronegócios de holambra", "acronym": "FAAGROH" },
  { "institution": "faculdade são francisco da paraíba campina grande", "acronym": "null" },
  { "institution": "instituto de ensino superior de formação profissional", "acronym": "IESFORP" },
  { "institution": "faculdade presidente antônio carlos", "acronym": "FAPAC" },
  { "institution": "faculdade de tecnologia senai joinville", "acronym": "SENAI DE JOINVILLE" },
  { "institution": "centro universitário estácio de sergipe", "acronym": "null" },
  { "institution": "faculdade de formação de professores da mata sul", "acronym": "FAMASUL" },
  { "institution": "faculdade petrus", "acronym": "FAPETRUS" },
  { "institution": "faculdade escola de ayurveda", "acronym": "FAESDA" },
  { "institution": "faculdade politécnica de campo grande", "acronym": "POLITÉCNICA" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro", "acronym": "FAETERJ CAXIAS" },
  { "institution": "faculdade de roseira", "acronym": "FARO" },
  { "institution": "escola superior madre celeste", "acronym": "ESMAC" },
  { "institution": "faculdade de filosofia ciências e letras carlos queiroz", "acronym": "FAFIQUE" },
  { "institution": "faculdade anhanguera do rio grande", "acronym": "null" },
  { "institution": "centro universitário facisa", "acronym": "UNIFACISA" },
  { "institution": "faculdade cenecista de vila velha", "acronym": "FACEVV" },
  { "institution": "centro universitário fieo", "acronym": "UNIFIEO" },
  { "institution": "faculdade andré da silveira", "acronym": "FADS" },
  { "institution": "faculdade de tecnologia da paraíba", "acronym": "FATECPB" },
  { "institution": "faculdade presidente antônio carlos de nanuque", "acronym": "FUNEES Nanuque" },
  { "institution": "faculdade presidente antônio carlos de são romão", "acronym": "FUNEES" },
  { "institution": "faculdade piemonte", "acronym": "FAP" },
  { "institution": "centro universitário fai", "acronym": "null" },
  { "institution": "faculdade do trabalho", "acronym": "FATRA" },
  { "institution": "instituto de ensino superior de americana", "acronym": "IESA" },
  { "institution": "centro universitário autônomo do brasil", "acronym": "UNIBRASIL" },
  { "institution": "faculdade de ciências médicas e jurídica", "acronym": "FACMED" },
  { "institution": "faculdade de tecnologia do cooperativismo", "acronym": "null" },
  { "institution": "faculdade betim", "acronym": "FABE" },
  { "institution": "faculdade netcom", "acronym": "FANET" },
  { "institution": "universidade católica de petrópolis", "acronym": "UCP" },
  { "institution": "centro universitário maurício de nassau de belém", "acronym": "null" },
  { "institution": "faculdade freire de ensino", "acronym": "FFE" },
  { "institution": "centro universitário de lavras", "acronym": "UNILAVRAS" },
  { "institution": "instituto de ensino superior de minas gerais", "acronym": "IESMIG" },
  { "institution": "faculdade de tecnologia faesa", "acronym": "CET-FAESA" },
  { "institution": "faculdade novo hamburgo", "acronym": "FACULDADE IENH" },
  { "institution": "faculdade metropolitana de lages", "acronym": "FAMELAGES" },
  { "institution": "faculdade uninassau vilhena", "acronym": "null" },
  { "institution": "faculdade ibam", "acronym": "null" },
  { "institution": "faculdade de direito de itú", "acronym": "FADITU" },
  { "institution": "centro universitário anhanguera de são paulo", "acronym": "null" },
  { "institution": "escola superior de criciúma - esucri", "acronym": "ESUCRI" },
  { "institution": "faculdade presidente dutra", "acronym": "FAP" },
  { "institution": "universidade de brasília", "acronym": "UNB" },
  { "institution": "centro universitário nossa senhora aparecida", "acronym": "UNIFANAP" },
  { "institution": "faculdade roberto miranda", "acronym": "FRM SP" },
  { "institution": "faculdade tecnologia eduvale - avaré", "acronym": "FATEC EDUVALE" },
  { "institution": "faculdade de tecnologia de assis", "acronym": "null" },
  { "institution": "faculdade de selvíria", "acronym": "FAS" },
  { "institution": "centro universitário icesp", "acronym": "Unicesp" },
  { "institution": "faculdade uninassau mossoró", "acronym": "null" },
  { "institution": "faculdade integrada das cataratas ejovem", "acronym": "eJOVEM" },
  { "institution": "faculdade de ciências biológicas e da saúde", "acronym": "FACIBIOS" },
  { "institution": "faculdade alfaunipac de araçuaí", "acronym": "null" },
  { "institution": "escola municipal de gestão do legislativo", "acronym": "EMUGLE" },
  { "institution": "faculdade da cidade de maceió", "acronym": "FACIMA" },
  { "institution": "faculdade ipemed de ciências médicas", "acronym": "IPEMED" },
  { "institution": "faculdade santa rita de cássia", "acronym": "IFASC" },
  { "institution": "faculdade de tecnologia ibratec de maceió", "acronym": "IBRATEC" },
  { "institution": "faculdade unime de educação e comunicação", "acronym": "FEC" },
  { "institution": "centro universitário ceuni - fametro", "acronym": "FAMETRO" },
  { "institution": "faculdade do planalto central", "acronym": "FAPLAC" },
  { "institution": "centro universitário senac", "acronym": "SENACSP" },
  { "institution": "academia judicial do tribunal de justiça de santa catarina", "acronym": "AJ" },
  { "institution": "universidade estadual do piauí", "acronym": "UESPI" },
  { "institution": "ipog - instituto de pós-graduação & graduação", "acronym": "IPOG" },
  { "institution": "faculdade uninassau cuiabá", "acronym": "Nassau Cuiabá" },
  { "institution": "faculdade herrero", "acronym": "FATEC" },
  { "institution": "faculdade cidade de aparecida de goiânia", "acronym": "FacCidade" },
  { "institution": "faculdade de odontologia", "acronym": "ITPAC" },
  { "institution": "faculdades integradas de taquara", "acronym": "FACCAT" },
  { "institution": "faculdade monte pascoal", "acronym": "FMP" },
  { "institution": "faculdade evolução alto oeste potiguar", "acronym": "FACEP" },
  { "institution": "centro universitário paulistano", "acronym": "UNIPAULISTANA" },
  { "institution": "faculdade uninta iguatu", "acronym": "null" },
  { "institution": "instituto superior de educação do sul do piauí", "acronym": "ISESPI" },
  { "institution": "faculdade de ribeirão pires", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai luzerna", "acronym": "SENAI LUZERNA" },
  { "institution": "faculdades integradas qualis", "acronym": "FIQ" },
  { "institution": "faculdade maurício de nassau serra talhada", "acronym": "FMN SERRA" },
  { "institution": "faculdade uninassau vitória da conquista", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de são joão nepomuceno", "acronym": "null" },
  { "institution": "faculdade pitágoras unopar de juazeiro do norte", "acronym": "null" },
  { "institution": "faculdades integradas de naviraí", "acronym": "FINAV" },
  { "institution": "faculdade de filosofia ciências e letras souza marques", "acronym": "FFCLSM" },
  { "institution": "faculdade leonardo da vinci", "acronym": "ULBRA" },
  { "institution": "faculdade doctum da serra", "acronym": "DOCTUM" },
  { "institution": "faculdade estácio de pimenta bueno", "acronym": "Estácio FAP" },
  { "institution": "faculdade de ciências econômicas e administrativas de vila velha", "acronym": "null" },
  { "institution": "instituto brasileiro de ensino, desenvolvimento e pesquisa de são paulo", "acronym": "IDP-SP" },
  { "institution": "universidade metodista de são paulo", "acronym": "UMESP" },
  { "institution": "faculdade de alta floresta", "acronym": "FAF" },
  { "institution": "universidade federal rural do rio de janeiro", "acronym": "UFRRJ" },
  { "institution": "faculdade do centro leste - cariacica", "acronym": "UCL" },
  { "institution": "faculdade nacional de curitiba", "acronym": "FANAC" },
  { "institution": "funvic - faculdade funvic de mococa", "acronym": "null" },
  { "institution": "faculdade união de quedas do iguaçu", "acronym": "FAQ" },
  { "institution": "instituto federal de educação, ciência e tecnologia do ceará", "acronym": "IFCE" },
  { "institution": "faculdade anhanguera de jequié", "acronym": "FPJ" },
  { "institution": "faculdade católica de ciências econômicas da bahia", "acronym": "FACCEBA" },
  { "institution": "universidade federal do sul da bahia", "acronym": "UFSB" },
  { "institution": "faculdade de ciências médicas do pará", "acronym": "FACIMPA" },
  { "institution": "faculdade de ciências gerenciais", "acronym": "null" },
  { "institution": "faculdade univeritas universus veritas de montes claros", "acronym": "UNIVERITAS MC" },
  { "institution": "faculdade meridional", "acronym": "IMED" },
  { "institution": "faculdade miriense", "acronym": "null" },
  { "institution": "faculdades integradas de rondonópolis", "acronym": "FAIR" },
  { "institution": "faculdade meridional rs", "acronym": "IMED" },
  { "institution": "instituto federal de educação, ciência e tecnologia do rio grande do sul", "acronym": "IFRS" },
  { "institution": "faculdade da serra", "acronym": "SERRAVIX" },
  { "institution": "faculdade do clube náutico mogiano", "acronym": "FCNM" },
  { "institution": "faculdade de engenharia souza marques", "acronym": "FESM" },
  { "institution": "faculdade de tecnologia de brasília", "acronym": "FTB" },
  { "institution": "faculdade master do pará - famap xinguara", "acronym": "FAMAP" },
  { "institution": "centro universitário arthur sá earp neto", "acronym": "UNIFASE" },
  { "institution": "faculdade evolução alto oeste potiguar", "acronym": "FACEP" },
  { "institution": "centro universitário funvic", "acronym": "UNIFUNVIC" },
  { "institution": "sustentare escola de negócios", "acronym": "Sustentare" },
  { "institution": "faculdade unida de vitória", "acronym": "FACULDADE UNIDA" },
  { "institution": "faculdade uninassau sobral", "acronym": "null" },
  { "institution": "centro universitário do instituto de educação superior de brasília - iesb", "acronym": "IESB" },
  { "institution": "faculdade de ciências sociais de ibiraçu", "acronym": "null" },
  { "institution": "faculdade de jussara", "acronym": "FAJ" },
  { "institution": "faculdade anhanguera de rio claro", "acronym": "null" },
  { "institution": "faculdade alura.tech", "acronym": "Alura.Tech" },
  { "institution": "faculdade pitágoras de pouso alegre", "acronym": "FPPA" },
  { "institution": "instituto superior de educação interlagos", "acronym": "ISE INTERLAGOS" },
  { "institution": "centro universitário do piauí", "acronym": "UNIFAPI" },
  { "institution": "faculdade de caieiras", "acronym": "null" },
  { "institution": "faculdade de tecnologia de taubaté", "acronym": "FATEC TAUBATÉ" },
  { "institution": "faculdades joão paulo ii", "acronym": "FJP" },
  { "institution": "centro de educação técnica da utramig", "acronym": "UTRAMIG" },
  { "institution": "faculdade de administração de campina verde", "acronym": "FACAV" },
  { "institution": "faculdade maurício de nassau de impetratriz", "acronym": "FMN IMPERATRIZ" },
  { "institution": "faculdade apogeu", "acronym": "APOGEU" },
  { "institution": "faculdade de direito de sorocaba", "acronym": "FADI" },
  { "institution": "faculdade de tecnologia senai telêmaco borba", "acronym": "null" },
  { "institution": "faculdade mato grosso do sul", "acronym": "FACSUL" },
  { "institution": "centro universitário de itapira uniesi", "acronym": "UNIESI" },
  { "institution": "faculdade inova mais de são paulo", "acronym": "FIMSP" },
  { "institution": "faculdade de ciências gerenciais de são gotardo", "acronym": "CESG" },
  { "institution": "instituto superior de educação ranchariense", "acronym": "ISER" },
  { "institution": "faculdade volpe miele", "acronym": "FVM" },
  { "institution": "faculdade de ciências e tecnologia de birigui", "acronym": "FATEB" },
  { "institution": "instituto de ensino superior capixaba", "acronym": "IESCAPI" },
  { "institution": "faculdade republicana", "acronym": "null" },
  { "institution": "centro universitário espírito-santense/faesa", "acronym": "null" },
  { "institution": "faculdade anhanguera de marabá", "acronym": "null" },
  { "institution": "faculdade jardins", "acronym": "FACJARDINS" },
  { "institution": "faculdade unilagos", "acronym": "UNILAGOS" },
  { "institution": "faculdade instituto brasileiro de ensino", "acronym": "FACIBE" },
  { "institution": "faculdades integradas ipep", "acronym": "FIPEP" },
  { "institution": "instituto superior de educação de campos gerais", "acronym": "ISEC" },
  { "institution": "centro universitário sumaré", "acronym": "null" },
  { "institution": "instituto de ensino superior de foz do iguaçu", "acronym": "IESFI" },
  { "institution": "faculdade da região sisaleira", "acronym": "FARESI" },
  { "institution": "faculdade serra dourada", "acronym": "null" },
  { "institution": "faculdade novo milênio", "acronym": "FNM" },
  { "institution": "faculdade de educação de vitória", "acronym": "AUFES" },
  { "institution": "faculdade de tecnologia de sertãozinho", "acronym": "FATEC SERTÃOZINHO" },
  { "institution": "faculdade maurício de nassau de juiz de fora", "acronym": "FMN JF" },
  { "institution": "faculdade alfaunipac de almenara", "acronym": "null" },
  { "institution": "escola de direito de são paulo - fgv direito sp", "acronym": "FGV DIREITO SP" },
  { "institution": "faculdade mozarteum de são paulo", "acronym": "FAMOSP" },
  { "institution": "faculdade de direito de vila velha", "acronym": "null" },
  { "institution": "universidade ibirapuera", "acronym": "UNIB" },
  { "institution": "centro universitário maurício de nassau de juazeiro do norte", "acronym": "UNINASSAU" },
  { "institution": "faculdade de tecnologia alto médio são francisco", "acronym": "FAC FUNAM" },
  { "institution": "faculdade santo agostinho de sete lagoas", "acronym": "FASASETE" },
  { "institution": "faculdade anhanguera de valparaíso", "acronym": "null" },
  { "institution": "instituto federal de educação, ciência e tecnologia do tocantins", "acronym": "IFTO" },
  { "institution": "instituto de educação tecnológica eireli", "acronym": "IETEC" },
  { "institution": "universidade comunitária da região de chapecó", "acronym": "UNOCHAPECÓ" },
  { "institution": "faculdade barddal de artes aplicadas", "acronym": "null" },
  { "institution": "faculdade unificada do estado de são paulo", "acronym": "FAUESP" },
  { "institution": "instituto superior de educação", "acronym": "ISED-TRÊS PONTAS" },
  { "institution": "faculdade atticus", "acronym": "null" },
  { "institution": "faculdade de filosofia, ciências e letras de ibitinga", "acronym": "FAIBI" },
  { "institution": "faculdade de tecnologia mário de andrade", "acronym": "FTMA" },
  { "institution": "faculdade luso-brasileira", "acronym": "FALUB" },
  { "institution": "faculdade de filosofia santa dorotéia", "acronym": "FFSD" },
  { "institution": "faculdade de tecnologia em saúde - iahcs", "acronym": "FATESA" },
  { "institution": "faculdades integradas de patos", "acronym": "FIP" },
  { "institution": "centro universitário do instituto mauá de tecnologia", "acronym": "CEUN-IMT" },
  { "institution": "centro universitário - católica de santa catarina em jaraguá do sul", "acronym": "Católica em Jaraguá" },
  { "institution": "faculdade de tecnologia senac cascavel", "acronym": "null" },
  { "institution": "instituto de desenvolvimento educacional da amazônia", "acronym": "IDEA" },
  { "institution": "faculdade brasileira de cachoeiro", "acronym": "null" },
  { "institution": "faculdade baiana do senhor do bonfim", "acronym": "FABASB" },
  { "institution": "faculdade cesumar de ponta grossa", "acronym": "FAC-CESUMAR" },
  { "institution": "faculdade são marcos", "acronym": "FASAMAR" },
  { "institution": "faculdade brasileira de educação e cultura", "acronym": "FABEC BRASIL" },
  { "institution": "faculdade alfa de teófilo otoni", "acronym": "ALFA" },
  { "institution": "faculdade embu das artes", "acronym": "FAEM" },
  { "institution": "faculdade fleming de osasco", "acronym": "A. Fleming" },
  { "institution": "faculdade de tecnologia de araçatuba", "acronym": "FATEC Araçatuba" },
  { "institution": "faculdades integradas vianna júnior", "acronym": "FIVJ" },
  { "institution": "centro universitário de paulínia", "acronym": "UNIFACP" },
  { "institution": "faculdade de santa bárbara d'oeste", "acronym": "FAP" },
  { "institution": "faculdade de tecnologia de franco da rocha", "acronym": "null" },
  { "institution": "faculdades associadas de são paulo", "acronym": "FASP" },
  { "institution": "faculdade edufor", "acronym": "EDUFOR" },
  { "institution": "faculdade de ciências do tocantins", "acronym": "FACIT" },
  { "institution": "faculdade evangélica cristo rei", "acronym": "FECR" },
  { "institution": "faculdade de tecnologia de itatiba", "acronym": "Fatec Itatiba" },
  { "institution": "centro universitário católica de quixadá", "acronym": "UNICATÓLICA" },
  { "institution": "universidade presbiteriana mackenzie", "acronym": "MACKENZIE" },
  { "institution": "faculdade unida de são paulo - ead", "acronym": "FAUSP - EAD" },
  { "institution": "faculdade de tecnologia avançada", "acronym": "null" },
  { "institution": "faculdade de são bernardo do campo - fasb", "acronym": "FASB I" },
  { "institution": "faculdade do noroeste de minas", "acronym": "FINOM" },
  { "institution": "faculdade cepep", "acronym": "null" },
  { "institution": "faculdade estácio de florianópolis - estácio florianópolis", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de cruz das almas", "acronym": "null" },
  { "institution": "faculdade de agronomia una de conselheiro lafaiete", "acronym": "null" },
  { "institution": "instituto superior de educação vera cruz", "acronym": "ISE VERA CRUZ" },
  { "institution": "faculdade filos", "acronym": "null" },
  { "institution": "faculdade maurício de nassau de pelotas", "acronym": "FMN PELOTAS" },
  { "institution": "faculdade de tecnologia e ciências de minas gerais", "acronym": "FATEC MG" },
  { "institution": "faculdade de ciências da saúde igesp", "acronym": "null" },
  { "institution": "faculdade ecoar", "acronym": "FAECO" },
  { "institution": "faculdade master de parauapebas - famap", "acronym": "FAMAP" },
  { "institution": "faculdade de tecnologia de são caetano do sul", "acronym": "FATEC SCS" },
  { "institution": "faculdade de administração de santo antônio do monte", "acronym": "FASAM" },
  { "institution": "faculdade maurício de nassau de campos dos goytacazes", "acronym": "FMN GOYTACAZES" },
  { "institution": "itpac cruzeiro do sul", "acronym": "ITPAC - CZ" },
  { "institution": "faculdade de tecnologia de itapira", "acronym": "FATEC Itapira" },
  { "institution": "centro universitário farias brito", "acronym": "null" },
  { "institution": "faculdade galileu", "acronym": "FG" },
  { "institution": "centro universitário do norte de são paulo", "acronym": "UNORTE" },
  { "institution": "faculdade da indústria curitiba", "acronym": "FAIND/CTBA" },
  { "institution": "faculdade de tecnologia de nova andradina", "acronym": "null" },
  { "institution": "faculdade unibrasília de minas gerais", "acronym": "BRASÍLIA" },
  { "institution": "faculdade cotemig", "acronym": "COTEMIG" },
  { "institution": "faculdade metropolitana", "acronym": "POLIFUCS" },
  { "institution": "centro universitário unibta", "acronym": "null" },
  { "institution": "faculdade regional de riachão do jacuípe", "acronym": "FARJ" },
  { "institution": "faculdade de engenharia são paulo", "acronym": "FESP" },
  { "institution": "faculdade do centro educacional santa isabel", "acronym": "FACESI" },
  { "institution": "instituto de ensino superior de garça", "acronym": "IESG" },
  { "institution": "centro de ensino superior de palmas", "acronym": "CESUP" },
  { "institution": "faculdade de ciências jurídicas de tianguá", "acronym": "null" },
  { "institution": "itpac manacapuru", "acronym": "ITPAC MANACA" },
  { "institution": "faculdades doctum de ipatinga", "acronym": "null" },
  { "institution": "escola de ciências sociais fgv cpdoc", "acronym": "FGV CPDOC" },
  { "institution": "faculdade de direito de uruguaiana - anhanguera", "acronym": "null" },
  { "institution": "faculdade ciências da vida", "acronym": "FCV" },
  { "institution": "faculdade metodista centenário", "acronym": "FMC" },
  { "institution": "escola superior da amazônia de abaetetuba", "acronym": "ESAMAZ" },
  { "institution": "faculdade dom bosco de goioerê", "acronym": "DOM BOSCO GOIOERÊ" },
  { "institution": "faculdade evangélica de ceres", "acronym": "FACER" },
  { "institution": "faculdade de ciências médicas de minas gerais", "acronym": "FCMMG" },
  { "institution": "instituto são josé dos campos de ensino superior", "acronym": "ISJCES" },
  { "institution": "faculdade de educação de tangará da serra", "acronym": "null" },
  { "institution": "faculdade de educação superior de sete lagoas", "acronym": "null" },
  { "institution": "faculdade cenecista de capivari", "acronym": "FACECAP" },
  { "institution": "centro universitário do norte de minas", "acronym": "Uninorte Minas" },
  { "institution": "faculdade de tecnologia e ciências de jequié", "acronym": "FTC" },
  { "institution": "instituto de ensino e pesquisa do hospital sírio-libanês", "acronym": "IEP/HSL" },
  { "institution": "fi - faculdade iguaçu", "acronym": "FI" },
  { "institution": "faculdade estácio de cuiabá", "acronym": "ESTÁCIO CUIABÁ" },
  { "institution": "centro universitário do sul de minas", "acronym": "UNIS-MG" },
  { "institution": "faculdade de tecnologia ipep de são paulo", "acronym": "FATEC SP" },
  { "institution": "faculdade de teologia cardeal eugênio sales", "acronym": "FCS" },
  { "institution": "faculdade do sertão do pajeú", "acronym": "FASP" },
  { "institution": "faculdade de economia e processamento de dados de foz do iguaçu", "acronym": "FEPI" },
  { "institution": "faculdade metropolitana do pará", "acronym": "Fametro" },
  { "institution": "faculdade estácio de são mateus - estácio são mateus", "acronym": "null" },
  { "institution": "faculdade anhanguera de cascavel", "acronym": "null" },
  { "institution": "universidade federal do maranhão", "acronym": "UFMA" },
  { "institution": "faculdade unina", "acronym": "null" },
  { "institution": "centro de ensino superior cesul", "acronym": "CESUL" },
  { "institution": "centro de ensino superior de catalão", "acronym": "null" },
  { "institution": "faculdade bem te vi ltda", "acronym": "FAC-BTV" },
  { "institution": "universidade de fortaleza", "acronym": "UNIFOR" },
  { "institution": "faculdade de educação e cultura montessori", "acronym": "FAMEC" },
  { "institution": "faculdade nove de julho guarulhos", "acronym": "NOVE-GUARULHOS" },
  { "institution": "faculdade de gestão e inovação", "acronym": "FGI" },
  { "institution": "faculdade foz do iguaçu", "acronym": "FAFIG" },
  { "institution": "faculdade da amazônia", "acronym": "FAAM" },
  { "institution": "faculdade escola sobral de oliveira", "acronym": "FAESDO" },
  { "institution": "instituto de educação e tecnologias", "acronym": "INET" },
  { "institution": "faculdade uninassau palmas", "acronym": "Nassau Palmas" },
  { "institution": "faculdade de tecnologia senai gaspar ricardo junior", "acronym": "null" },
  { "institution": "faculdade de sorocaba", "acronym": "null" },
  { "institution": "faculdade seqüencial", "acronym": "null" },
  { "institution": "unama faculdade da amazônia de boa vista", "acronym": "UNAMA" },
  { "institution": "faculdade itanhaém", "acronym": "FAITA" },
  { "institution": "faculdade anglo", "acronym": "null" },
  { "institution": "faculdade adelmar rosado", "acronym": "FAR" },
  { "institution": "faculdade zacarias de góes", "acronym": "FAZAG" },
  { "institution": "faculdade de tecnologia do vale do ivaí", "acronym": "FATEC-IVAI" },
  { "institution": "instituto federal de educação, ciência e tecnologia de minas gerais", "acronym": "IFMG" },
  { "institution": "instituto de ensino superior thathi", "acronym": "FACULDADE THATHI COC" },
  { "institution": "faculdade de tecnologia luiz adelar scheuer", "acronym": "FATEC- JF" },
  { "institution": "faculdade raimundo marinho", "acronym": "FRM" },
  { "institution": "faculdade novoeste", "acronym": "NOVOESTE" },
  { "institution": "instituto macapaense de ensino superior", "acronym": "IMMES" },
  { "institution": "faculdades sulamérica bahia", "acronym": "null" },
  { "institution": "faculdade duarte coelho", "acronym": "FDC" },
  { "institution": "centro universitário fael", "acronym": "UNIFAEL" },
  { "institution": "faculdade de tecnologia e ciências de itabuna", "acronym": "FTC" },
  { "institution": "instituto racine de educação superior", "acronym": "Racine" },
  { "institution": "faculdades integradas da américa do sul", "acronym": "FAC INTEGRA" },
  { "institution": "faculdades integradas de paranaíba - fipar", "acronym": "FIPAR" },
  { "institution": "faculdade de tecnologia senai cascavel", "acronym": "null" },
  { "institution": "faculdade de tecnologia pentágono", "acronym": "FATEP" },
  { "institution": "faculdade instituto superior de educação do paraná - fainsep", "acronym": "FAINSEP" },
  { "institution": "faculdade pitágoras de juiz de fora", "acronym": "FPJF" },
  { "institution": "estácio fatern - faculdade estácio do rio grande do norte", "acronym": "ESTÁCIO FATERN" },
  { "institution": "centro universitário unic", "acronym": "null" },
  { "institution": "faculdade trilógica keppe & pacheco", "acronym": "Keppe & Pacheco" },
  { "institution": "faculdade de engenharia pitágoras de santarém", "acronym": "null" },
  { "institution": "centro universitário estadual da zona oeste", "acronym": "UEZO" },
  { "institution": "instituto superior de educação de ibiúna", "acronym": "ISEI" },
  { "institution": "faculdade de tecnologia de guarulhos", "acronym": "FATEC GR" },
  { "institution": "faculdade de tecnologia diamante", "acronym": "FATED" },
  { "institution": "faculdade de ciências sociais aplicadas do sul de minas - facesm", "acronym": "FACESM" },
  { "institution": "faculdade união araruama de ensino s/s ltda.", "acronym": "FAC-UNILAGOS" },
  { "institution": "faculdade impacta", "acronym": "IMPACTA" },
  { "institution": "faculdade anhanguera de linhares", "acronym": "null" },
  { "institution": "faculdade de itapecerica da serra", "acronym": "FIT" },
  { "institution": "faculdade seb lafaiete", "acronym": "SEBLF" },
  { "institution": "centro universitário metrocamp wyden", "acronym": "UniMetrocamp Wyden" },
  { "institution": "faculdades doctum de guarapari", "acronym": "DOCTUM" },
  { "institution": "faculdade brasileira cristã", "acronym": "FBC" },
  { "institution": "faculdade senac minas", "acronym": "FSM" },
  { "institution": "centro universitário max planck", "acronym": "UniMAX" },
  { "institution": "faculdade anhanguera de são joão de meriti", "acronym": "null" },
  { "institution": "centro universitário maurício de nassau de barreiras", "acronym": "UNINASSAU" },
  { "institution": "faculdade fleming", "acronym": "SEF" },
  { "institution": "faculdade integrada de araguatins", "acronym": "FAIARA" },
  { "institution": "faculdade israelita de ciências da saúde albert einstein", "acronym": "FICSAE" },
  { "institution": "faculdade sergipana", "acronym": "FASER" },
  { "institution": "faculdade paschoal dantas", "acronym": "FPD" },
  { "institution": "escola superior de aviação civil", "acronym": "ESAC" },
  { "institution": "faculdade cenecista de sete lagoas", "acronym": "FCSL" },
  { "institution": "faculdade de nanuque", "acronym": "FANAN" },
  { "institution": "faculdade de tecnologia de matão", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de tucuruí", "acronym": "null" },
  { "institution": "centro universitário vale do cricaré", "acronym": "UNIVC" },
  { "institution": "universidade federal do sul e sudeste do pará", "acronym": "UNIFESSPA" },
  { "institution": "faculdade de hortolândia", "acronym": "FACH" },
  { "institution": "instituto federal de educação, ciência e tecnologia  da paraíba", "acronym": "IFPB" },
  { "institution": "faculdade práxis", "acronym": "FIPEP" },
  { "institution": "faculdade multivix cariacica", "acronym": "MULTIVIX CARIAC" },
  { "institution": "faculdade de lucélia", "acronym": "null" },
  { "institution": "centro universitário universo juiz de fora", "acronym": "null" },
  { "institution": "universidade federal do cariri", "acronym": "UFCA" },
  { "institution": "centro universitário estácio do recife", "acronym": "null" },
  { "institution": "faculdade reconhecer", "acronym": "FR" },
  { "institution": "faculdade de tecnologia egídio josé da silva", "acronym": "FATEGIDIO" },
  { "institution": "centro universitário don domênico", "acronym": "UNIDON" },
  { "institution": "faculdades integradas de jahu", "acronym": "FIJ" },
  { "institution": "faculdade de ibiporã", "acronym": "null" },
  { "institution": "faculdade univeritas universus veritas anápolis", "acronym": "VERITAS ANÁPOLIS" },
  { "institution": "faculdade do instituto panamericano", "acronym": "FACIPAN" },
  { "institution": "centro universitário cesmac", "acronym": "CESMAC" },
  { "institution": "faculdade de tecnologia estudante rafael almeida camarinha - marilia", "acronym": "Fatec Marilia" },
  { "institution": "faculdade faccentro", "acronym": "null" },
  { "institution": "faculdade anhanguera de são josé-sc", "acronym": "FASJ-SC" },
  { "institution": "centro universitário do sul goiano", "acronym": "Facbras" },
  { "institution": "faculdade pitágoras unopar de itajubá", "acronym": "null" },
  { "institution": "faculdade unopar de ciências jurídicas de santa cruz do sul", "acronym": "null" },
  { "institution": "faculdade de educação superior de pernambuco", "acronym": "FAESPE" },
  { "institution": "faculdade pitágoras de rio verde", "acronym": "null" },
  { "institution": "faculdade unopar de ciências jurídicas de petrolina", "acronym": "null" },
  { "institution": "faculdade metodista de teologia e ciências humanas da amazônia", "acronym": "FATEO-PVH" },
  { "institution": "centro universitário de brasília", "acronym": "UNICEUB" },
  { "institution": "soberana faculdade de saúde de petrolina", "acronym": "SOBERANA" },
  { "institution": "faculdade alves lima", "acronym": "FAAL" },
  { "institution": "faculdade presidente antônio carlos de montes claros", "acronym": "FUNEC Montes Claros" },
  { "institution": "instituto aria", "acronym": "null" },
  { "institution": "centro universitário fbv wyden", "acronym": "UniFBV Wyden" },
  { "institution": "faculdade presidente antônio carlos de visconde do rio branco", "acronym": "null" },
  { "institution": "instituto superior de educação de são paulo", "acronym": "SINGULARIDADES/ISESP" },
  { "institution": "universidade estadual do paraná", "acronym": "UNESPAR" },
  { "institution": "faculdade são tomás de aquino", "acronym": "FACESTA" },
  { "institution": "faculdade senai de tecnologia de poços de caldas", "acronym": "FATEC POÇOS" },
  { "institution": "faculdade doctum de vitória", "acronym": "DOCTUM" },
  { "institution": "faculdade uniterp", "acronym": "FACTERP" },
  { "institution": "escola superior dom helder câmara", "acronym": "ESDHC" },
  { "institution": "faculdade presidente antônio carlos de uberlândia", "acronym": "null" },
  { "institution": "faculdade internacional da paraíba", "acronym": "FPB" },
  { "institution": "instituto federal de educação, ciência e tecnologia sul-rio-grandense", "acronym": "IFSul" },
  { "institution": "faculdade cearense", "acronym": "FAC" },
  { "institution": "centro universitário de itajubá", "acronym": "FEPI" },
  { "institution": "instituto legislativo brasileiro", "acronym": "ILB" },
  { "institution": "centro de instrução de aviação do exército", "acronym": "CIAvEx" },
  { "institution": "faculdade de medicina de garanhuns", "acronym": "null" },
  { "institution": "conservatório brasileiro de música - centro universitário brasileiro de educação", "acronym": "CBM-UniCBE" },
  { "institution": "faculdade de filosofia ciências e letras de ituverava", "acronym": "FFCL" },
  { "institution": "centro de ensino superior de vitória", "acronym": "CESV" },
  { "institution": "faculdade fanorpi bandeirantes", "acronym": "FANORPI" },
  { "institution": "universidade da integração internacional da lusofonia afro-brasileira", "acronym": "UNILAB" },
  { "institution": "instituto serzedello corrêa", "acronym": "ISC-TCU" },
  { "institution": "gaia", "acronym": "Gaia" },
  { "institution": "faculdade orígenes lessa", "acronym": "FACOL" },
  { "institution": "faculdade de letras de nova andradina", "acronym": "FALENA" },
  { "institution": "escola superior aberta do brasil", "acronym": "ESAB" },
  { "institution": "centro universitário senai cimatec", "acronym": "SENAI CIMATEC" },
  { "institution": "centro universitário vale do salgado", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de lambari", "acronym": "FAPAC LAMBARI" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro fernando mota", "acronym": "FAETERJ-Rio" },
  { "institution": "faculdade de odontologia de manaus", "acronym": "FOM" },
  { "institution": "faculdade santo agostinho de itabuna", "acronym": "FASAI" },
  { "institution": "faculdade zumbi dos palmares", "acronym": "FAZP" },
  { "institution": "faculdade silva neto", "acronym": "FASINE" },
  { "institution": "faculdade dos palmares", "acronym": "FAP" },
  { "institution": "faculdade sant´anna de salto", "acronym": "FASAS" },
  { "institution": "universidade luterana do brasil", "acronym": "ULBRA" },
  { "institution": "centro universitário da fundação herminio ometto", "acronym": "FHO" },
  { "institution": "faculdade estácio de barbacena- estáciobarbacena", "acronym": "null" },
  { "institution": "escola superior do sul de santa catarina", "acronym": "null" },
  { "institution": "instituto superior de educação de salgueiro", "acronym": "ISES" },
  { "institution": "faculdade anhanguera de catanduva", "acronym": "FAC" },
  { "institution": "faculdade de ciências médicas cemepe", "acronym": "CEMEPE" },
  { "institution": "faculdade de artes dulcina de moraes", "acronym": "FADM" },
  { "institution": "faculdade doutor francisco maeda", "acronym": "FAFRAM" },
  { "institution": "universidade federal de santa catarina", "acronym": "UFSC" },
  { "institution": "faculdade unise", "acronym": "null" },
  { "institution": "universidade de passo fundo", "acronym": "UPF" },
  { "institution": "faculdade pitágoras de  mossoró", "acronym": "null" },
  { "institution": "faculdade pentágono - fapen", "acronym": "FAPEN" },
  { "institution": "instituto federal de educação, ciência e tecnologia farroupilha", "acronym": "IFFarroupilha" },
  { "institution": "centro universitário católico salesiano auxilium", "acronym": "UNISALESIANO" },
  { "institution": "faculdade de tecnologia de cruzeiro do oeste", "acronym": "FACO" },
  { "institution": "faculdade ages de medicina de irecê", "acronym": "Faculdade AGES" },
  { "institution": "universidade pitágoras unopar anhanguera", "acronym": "null" },
  { "institution": "fundação universidade federal do abc", "acronym": "UFABC" },
  { "institution": "faculdade de ciências, letras e educação do noroeste do paraná", "acronym": "FACLENOR" },
  { "institution": "faculdade de santo andré", "acronym": "null" },
  { "institution": "escola de educação permanente", "acronym": "EEP" },
  { "institution": "centro universitário maurício de nassau de salvador", "acronym": "Uninassau Salvador" },
  { "institution": "faculdade de tecnologia e ciências", "acronym": "FTC RECIFE" },
  { "institution": "faculdade de piracanjuba", "acronym": "FAP" },
  { "institution": "faculdades famep unidade lago da pedra - ma", "acronym": "FAMEP" },
  { "institution": "faculdade martha falcão wyden", "acronym": "FMF Wyden" },
  { "institution": "faculdade de administração de empresas de paraíso do tocantins", "acronym": "FAP" },
  { "institution": "faculdade de jaboticabal", "acronym": "FAJAB" },
  { "institution": "faculdade dom ricardo", "acronym": "FDR" },
  { "institution": "faculdade interação americana", "acronym": "FIA" },
  { "institution": "faculdade fasipe df", "acronym": "FACDF" },
  { "institution": "faculdade de tecnologia ad1", "acronym": "AD1" },
  { "institution": "centro universitário augusto motta", "acronym": "UNISUAM" },
  { "institution": "universidade de mogi das cruzes", "acronym": "UMC" },
  { "institution": "faculdade pitágoras de medicina de eunápolis", "acronym": "null" },
  { "institution": "faculdade de tecnologia do nordeste", "acronym": "FATENE" },
  { "institution": "faculdade de tecnologia senai chapecó", "acronym": "SENAI" },
  { "institution": "faculdade de administração e ciências contábeis luzwell", "acronym": "LUZWELL" },
  { "institution": "faculdade são salvador", "acronym": "FSS" },
  { "institution": "instituto de ensino superior materdei", "acronym": "IES-MATERDEI" },
  { "institution": "faculdade de tecnologia de jundiaí", "acronym": "FATEC/JD" },
  { "institution": "faculdade álvares de azevedo", "acronym": "FAATESP" },
  { "institution": "faculdade da fraternidade de valença", "acronym": "FFV" },
  { "institution": "instituto superior de educação santa rita de cássia", "acronym": "ISESC" },
  { "institution": "faculdade de ciências da saúde pitágoras de codó", "acronym": "null" },
  { "institution": "faculdade cbes", "acronym": "CBES" },
  { "institution": "faculdade dehoniana", "acronym": "DEHONIANA" },
  { "institution": "faculdade de tecnologia de sinop", "acronym": "FASTECH" },
  { "institution": "faculdade brasileira de tributação", "acronym": "FBT" },
  { "institution": "faculdade nip de ciências aplicadas", "acronym": "FANIP" },
  { "institution": "faculdade de tecnologia faesa - vila velha", "acronym": "CETFAESA" },
  { "institution": "faculdade instituto paulista de ensino", "acronym": "FIPEN" },
  { "institution": "faculdade de belém", "acronym": "FABEL" },
  { "institution": "centro universitário internacional", "acronym": "UNINTER" },
  { "institution": "centro universitário mario pontes jucá", "acronym": "UMJ" },
  { "institution": "faculdade de sobral", "acronym": "FASOL" },
  { "institution": "faculdade sulamérica brasília", "acronym": "null" },
  { "institution": "faculdade porto seguro", "acronym": "FAPS" },
  { "institution": "faculdade de santo antonio da platina", "acronym": "null" },
  { "institution": "faculdade pitágoras de luziânia", "acronym": "null" },
  { "institution": "escola superior de educação", "acronym": "ESJUS" },
  { "institution": "faculdade de gestao e negocios de teresina", "acronym": "FGN TERESINA" },
  { "institution": "centro universitário estácio de são luís", "acronym": "null" },
  { "institution": "faculdade joão paulo primeiro", "acronym": "FAJOPP" },
  { "institution": "faculdade metodista conexional", "acronym": "FACO" },
  { "institution": "instituto federal de educação, ciência e tecnologia do rio de janeiro", "acronym": "IFRJ" },
  { "institution": "faculdade de educação ciências e artes dom bosco de monte aprazível", "acronym": "FAECA DOM BOSCO" },
  { "institution": "centro universitário santa maria", "acronym": "Unifsm" },
  { "institution": "faculdade sant ana", "acronym": "IESSA" },
  { "institution": "faculdade de piraju", "acronym": "null" },
  { "institution": "faculdade paranapanema", "acronym": "FP" },
  { "institution": "faculdade presidente antônio carlos de são gonçalo do rio abaixo", "acronym": "FUNEC SGRA" },
  { "institution": "faculdade de tecnologia e administração edufor", "acronym": "EDUFOR" },
  { "institution": "centro universitário santa cruz de curitiba", "acronym": "UNI SANTA CRUZ" },
  { "institution": "faculdade católica de mato grosso", "acronym": "FACC-MT" },
  { "institution": "universidade feevale", "acronym": "FEEVALE" },
  { "institution": "faculdade jk - unidade i - gama", "acronym": "null" },
  { "institution": "faculdade de ciências sociais e agrárias de itapeva", "acronym": "FAIT" },
  { "institution": "faculdade de medicina de campos", "acronym": "FMC" },
  { "institution": "centro universitário itop", "acronym": "UNITOP" },
  { "institution": "faculdade de ciências humanas de aguaí", "acronym": "FACHA" },
  { "institution": "faculdade presidente antônio carlos de itajubá", "acronym": "null" },
  { "institution": "faculdade metropolitana do litoral rio grandense", "acronym": "FAMELI" },
  { "institution": "faculdade de tecnologia de adamantina", "acronym": "null" },
  { "institution": "faculdade são leopoldo mandic", "acronym": "null" },
  { "institution": "faculdade horizontina", "acronym": "FAHOR" },
  { "institution": "faculdade ideau de caxias do sul", "acronym": "IDEAU" },
  { "institution": "faculdade de filosofia, ciências e letras de boa esperança", "acronym": "FAFIBE" },
  { "institution": "faculdade autônoma do brasil - cabo de santo agostinho", "acronym": "FBR - Cabo" },
  { "institution": "faculdade de teologia fortaleza - fatefor", "acronym": "FATEFOR" },
  { "institution": "faculdade nove de julho de osasco", "acronym": "NOVE-OSASCO" },
  { "institution": "faculdade jk - unidade ii - gama", "acronym": "null" },
  { "institution": "faculdade presidente antõnio carlos de perdões", "acronym": "FUNEES Perdões" },
  { "institution": "centro universitário unifaat", "acronym": "UNIFAAT" },
  { "institution": "faculdade univeritas universus veritas de goiânia", "acronym": "VERITAS GOIÂNIA" },
  { "institution": "centro universitário internacional signorelli", "acronym": "UNISIGNORELLI" },
  { "institution": "faculdade estácio de alagoas - estácio fal", "acronym": "ESTÁCIO FAL" },
  { "institution": "faculdade vasconcellos & souza", "acronym": "null" },
  { "institution": "instituto superior de educação mãe de deus", "acronym": "ISEMD" },
  { "institution": "faculdade uberlandense de núcleos integrados de ensino, serviço social e aprendizagem", "acronym": "FAESSA" },
  { "institution": "fatec- faculdade senai de tecnologia", "acronym": "FATEC" },
  { "institution": "faculdade de turismo de caratinga", "acronym": "FATUR" },
  { "institution": "faculdade rio parnaíba", "acronym": "FARP" },
  { "institution": "faculdade de jandaia do sul", "acronym": "FAFIJAN" },
  { "institution": "instituto de filosofia e teologia de goiás", "acronym": "IFITEG" },
  { "institution": "faculdade unis são lourenço", "acronym": "null" },
  { "institution": "faculdade impacto de porangatu", "acronym": "FIP" },
  { "institution": "escola superior de educação física de jundiaí", "acronym": "ESEFJ" },
  { "institution": "universidade do estado de mato grosso carlos alberto reyes maldonado - unemat", "acronym": "UNEMAT" },
  { "institution": "centro universitário geraldo di biase", "acronym": "UGB" },
  { "institution": "faculdade de tecnologia de ponta porã", "acronym": "FATEP" },
  { "institution": "faculdade menna barreto", "acronym": "FMB" },
  { "institution": "faculdade de tecnologia bandeirantes", "acronym": "FATECBAND" },
  { "institution": "faculdade retama", "acronym": "RETAMA" },
  { "institution": "strong business school bs", "acronym": "null" },
  { "institution": "faculdade anhanguera de alvorada", "acronym": "null" },
  { "institution": "faculdade filadélfia", "acronym": "FAFIL" },
  { "institution": "faculdade de tecnologia contec", "acronym": "FACONTEC" },
  { "institution": "faculdade de barueri", "acronym": "FCGB" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro - faeterj", "acronym": "null" },
  { "institution": "faculdade de inovação do paraná", "acronym": "FAI" },
  { "institution": "faculdade pecege", "acronym": "PECEGE" },
  { "institution": "centro universitário campo limpo paulista", "acronym": "UNIFACCAMP" },
  { "institution": "faculdade sociesc de jaraguá do sul", "acronym": "SOCIESC" },
  { "institution": "faculdade de educação de nova andradina", "acronym": "FENA" },
  { "institution": "faculdade noroeste", "acronym": "FAN" },
  { "institution": "faculdade de tecnologia centec - sertão central", "acronym": "FTC SERTÃO CENTRAL" },
  { "institution": "universidade de vassouras", "acronym": "UNIVASSOURAS" },
  { "institution": "instituto municipal de ensino superior de catanduva", "acronym": "IMES CATANDUVA" },
  { "institution": "diretoria de formação e desenvolvimento profissional", "acronym": "FUNDAJ" },
  { "institution": "faculdade ages de medicina", "acronym": "Faculdade AGES" },
  { "institution": "faculdade de tecnologia senac curitiba portão", "acronym": "null" },
  { "institution": "faculdade ibg", "acronym": "IBG" },
  { "institution": "universidade estadual do sudoeste da bahia", "acronym": "UESB" },
  { "institution": "faculdade de tecnologia nova palhoça", "acronym": "FATENP" },
  { "institution": "faculdade de educação e tecnologia iracema", "acronym": "null" },
  { "institution": "centro universitário de união da vitória", "acronym": "UNIUV" },
  { "institution": "faculdade maurício de nassau de betim", "acronym": "FMN Betim" },
  { "institution": "faculdade de ciências sociais aplicadas", "acronym": "FACISA" },
  { "institution": "faculdade eficaz", "acronym": "EFICAZ" },
  { "institution": "faculdade anhanguera de contagem", "acronym": "null" },
  { "institution": "faculdade santa teresa", "acronym": "null" },
  { "institution": "faculdade teológica batista de brasília", "acronym": "FTBB" },
  { "institution": "instituto superior de ensino e pesquisa de cambuí", "acronym": "ISEPEC" },
  { "institution": "fundação universidade federal do vale do são francisco", "acronym": "UNIVASF" },
  { "institution": "faculdade de tecnologia porto das monções", "acronym": "FAMO" },
  { "institution": "faculdade de tecnologia aerotd", "acronym": "AEROTD" },
  { "institution": "centro universitário lusíada", "acronym": "UNILUS" },
  { "institution": "faculdade do rio de janeiro", "acronym": "null" },
  { "institution": "faculdade uninassau jaboatão dos guararapes", "acronym": "UNINASSAU" },
  { "institution": "faculdade de tecnologia de são josé do rio preto", "acronym": "FATECRP" },
  { "institution": "centro universitário fanor wyden", "acronym": "UniFanor Wyden" },
  { "institution": "faculdades integradas rio branco", "acronym": "FRB" },
  { "institution": "instituto assis gurgacz", "acronym": "null" },
  { "institution": "faculdade católica dom aquino de cuiabá", "acronym": "FAC" },
  { "institution": "universidade josé do rosário vellano", "acronym": "UNIFENAS" },
  { "institution": "universidade estadual do norte do paraná", "acronym": "UENP" },
  { "institution": "fundação universidade federal do tocantins", "acronym": "UFT" },
  { "institution": "faculdade unirb - camaçari", "acronym": "FACULDADE UNIRB" },
  { "institution": "faculdade presidente antônio carlos de liberdade", "acronym": "FUNEES" },
  { "institution": "instituto de ensino superior fucapi", "acronym": "CESF" },
  { "institution": "faculdade pitágoras unopar anhanguera de londrina", "acronym": "null" },
  { "institution": "faculdade inbec", "acronym": "INBEC" },
  { "institution": "faculdade de tecnologia tupy de são bento do sul", "acronym": "FTT-SBS" },
  { "institution": "faculdade de comunicação social de hortolândia", "acronym": "FCSH" },
  { "institution": "faculdade de tecnologia do ipiranga", "acronym": "FATECIPI" },
  { "institution": "faculdade de medicina de marília", "acronym": "FAMEMA" },
  { "institution": "centro universitário da cidade", "acronym": "UNIVERCIDADE" },
  { "institution": "faculdade de ibaiti", "acronym": "FEATI" },
  { "institution": "faculdade de ciências da saúde do grupo hospitalar conceição", "acronym": "FACS - GHC" },
  { "institution": "escola superior nacional de seguros", "acronym": "ESNS" },
  { "institution": "faculdade de tecnologia estácio de curitiba", "acronym": "FATEC ESTÁC CURITIBA" },
  { "institution": "faculdade de tecnologia de americana", "acronym": "FATEC-AM" },
  { "institution": "centro de educação tecnológico da fundação indaiatubana de educação e cultura", "acronym": "FIEC" },
  { "institution": "faculdades famep unidade fortaleza  ce", "acronym": "FAMEP" },
  { "institution": "centro de ensino superior de sete lagoas", "acronym": "null" },
  { "institution": "faculdade cesmac do agreste", "acronym": "CESMAC Agreste" },
  { "institution": "centro universitário funorte", "acronym": "UNIFUNORTE" },
  { "institution": "faculdade funorte de janaúba", "acronym": "null" },
  { "institution": "centro universitário do vale do araguaia", "acronym": "UNIVAR" },
  { "institution": "faculdade reges de realeza", "acronym": "null" },
  { "institution": "faculdade anhanguera de itapetininga", "acronym": "null" },
  { "institution": "universidade são judas tadeu", "acronym": "USJT" },
  { "institution": "faculdade de tecnologia de piracicaba", "acronym": "Fatec Piracicaba" },
  { "institution": "faculdade do amazonas", "acronym": "IAES" },
  { "institution": "faculdade malta", "acronym": "FACMA" },
  { "institution": "faculdade de medicina de jundiaí", "acronym": "FMJ" },
  { "institution": "faculdade de ciências aplicadas piauiense", "acronym": "FACAPI" },
  { "institution": "faculdade de administração de santa cruz do rio pardo", "acronym": "FASC" },
  { "institution": "faculdade presidente antônio carlos de ribeirão das neves", "acronym": "null" },
  { "institution": "faculdade gianna beretta", "acronym": "null" },
  { "institution": "faculdade pedro ii", "acronym": "FAPE2" },
  { "institution": "universidade de santa cruz do sul", "acronym": "UNISC" },
  { "institution": "faculdade praia grande", "acronym": "FPG" },
  { "institution": "centro universitário da região da campanha", "acronym": "null" },
  { "institution": "faculdade brasiliense de negócios", "acronym": "FBN" },
  { "institution": "faculdade metropolitana de teresina", "acronym": "null" },
  { "institution": "instituto metropolitano de ensino superior", "acronym": "IMES" },
  { "institution": "faculdade de filosofia ciências e letras de macaé", "acronym": "FAFIMA" },
  { "institution": "escola superior de educação corporativa", "acronym": "ESEC" },
  { "institution": "instituto federal de educação, ciência e tecnologia de roraima", "acronym": "IFRR" },
  { "institution": "universidade federal do espírito santo", "acronym": "UFES" },
  { "institution": "faculdade aiec", "acronym": "AIEC / FAAB" },
  { "institution": "faculdade cesgranrio", "acronym": "FACESGRANRIO" },
  { "institution": "faculdade fadam de maracanaú", "acronym": "FADAM" },
  { "institution": "faculdade exata educacional", "acronym": "FEE" },
  { "institution": "instituto municipal de ensino superior de bebedouro victório cardassi", "acronym": "IMESB" },
  { "institution": "faculdade nossa senhora de fátima", "acronym": "FACULDADE FÁTIMA" },
  { "institution": "faculdade para o desenvolvimento de pernambuco", "acronym": "FADEPE" },
  { "institution": "instituto sociesc de jaraguá do sul", "acronym": "SOCIESC" },
  { "institution": "faculdade univest de educação", "acronym": "UNIVEST" },
  { "institution": "faculdade anhangüera de bauru", "acronym": "null" },
  { "institution": "faculdade de inhumas - fac-mais", "acronym": "FACMAIS" },
  { "institution": "faculdade paulista de ciências aplicadas", "acronym": "FPCA" },
  { "institution": "faculdade salesiana de santa teresa", "acronym": "FSST" },
  { "institution": "faculdade estácio de cascavel", "acronym": "null" },
  { "institution": "centro universitário araguaia", "acronym": "UNIARAGUAIA" },
  { "institution": "faculdade estácio de carapicuíba", "acronym": "ESTÁCIO CARAPICUÍBA" },
  { "institution": "instituto de direito do norte - idn manaus", "acronym": "IDN Manaus" },
  { "institution": "faculdade de direito 8 de julho", "acronym": "null" },
  { "institution": "faculdades integradas toledo de três lagoas", "acronym": "TOLEDO" },
  { "institution": "faculdade de tecnologia e educação de goiás", "acronym": "FATEG" },
  { "institution": "faculdade de tecnologia senai jaraguá do sul", "acronym": "FATEC" },
  { "institution": "faculdade de tecnologia e desenvolvimento de competências", "acronym": "FDC" },
  { "institution": "centro universitário unicarioca", "acronym": "UNICARIOCA" },
  { "institution": "faculdade americas international college", "acronym": "FAMG" },
  { "institution": "unama faculdade da amazônia de rio branco", "acronym": "FMN RIO BRANCO" },
  { "institution": "faculdade de direito serra dourada", "acronym": "null" },
  { "institution": "instituto de ensino superior planalto", "acronym": "IESPLAN" },
  { "institution": "unidade de ensino superior expoente", "acronym": "UNIEXP" },
  { "institution": "faculdade internacional cidade viva", "acronym": "null" },
  { "institution": "instituto de serra dourada", "acronym": "null" },
  { "institution": "instituto superior de educação ocidente", "acronym": "OCIDEMNTE" },
  { "institution": "universidade federal de viçosa", "acronym": "UFV" },
  { "institution": "faculdade candido mendes de vitória", "acronym": "FCMV" },
  { "institution": "faculdade engenheiro salvador arena", "acronym": "FESA" },
  { "institution": "faculdade marechal rondon de guarulhos", "acronym": "FMR-GUARULHOS" },
  { "institution": "faculdade cenecista de rondonópolis", "acronym": "FACER" },
  { "institution": "instituto superior de educação elvira dayrell", "acronym": "ISEED" },
  { "institution": "faculdades ipede", "acronym": "IPEDE" },
  { "institution": "instituto amazônico de ensino superior", "acronym": "IAMES" },
  { "institution": "faculdade ideal de alto horizonte", "acronym": "UnIDEAL" },
  { "institution": "faculdade anhanguera de ipatinga", "acronym": "null" },
  { "institution": "fetac - faculdade de educação, tecnologia e administração de caarapó", "acronym": "FETAC" },
  { "institution": "faculdade ccaa", "acronym": "FAC CCAA" },
  { "institution": "faculdade educacional de medianeira", "acronym": "UDC Medianeira" },
  { "institution": "faculdades famep unidade irará - ba", "acronym": "FAMEP" },
  { "institution": "faculdade de campo verde", "acronym": "FCV" },
  { "institution": "instituto nacional de ensino superior e pós-graduação padre gervásio", "acronym": "INAPÓS" },
  { "institution": "faculdade de tecnologia - fatec de ribeirão preto", "acronym": "null" },
  { "institution": "faculdade estácio de são josé do rio preto", "acronym": "EstácioSÃO JOSÉ" },
  { "institution": "faculdade de tecnologia e negócios de catalão", "acronym": "FATENC" },
  { "institution": "instituto superior de educação antonino freire - iseaf", "acronym": "ISEAF" },
  { "institution": "faculdade santo agostinho", "acronym": "FACSA" },
  { "institution": "faculdade de ciências e saúde edufor", "acronym": "EDUFOR" },
  { "institution": "escola superior associada de goiânia", "acronym": "ESUP" },
  { "institution": "centro universitário são roque", "acronym": "UNISÃO ROQUE" },
  { "institution": "universidade paranaense", "acronym": "UNIPAR" },
  { "institution": "faculdade viva vitória", "acronym": "FAVIVA" },
  { "institution": "faculdade do vale elvira dayrell - faved", "acronym": "FAVED" },
  { "institution": "faculdade estácio de curitiba", "acronym": "ESTÁCIO CURITIBA" },
  { "institution": "faculdade ítalo brasileira", "acronym": "FIB" },
  { "institution": "faculdade de ensino superior refferencial", "acronym": "FAREFF" },
  { "institution": "centro universitário campos de andrade", "acronym": "UNIANDRADE" },
  { "institution": "faculdade de tecnologia do vale do itajaí", "acronym": "FATEC" },
  { "institution": "faculdade faccat", "acronym": "FACCAT" },
  { "institution": "faculdade fasipe mato grosso", "acronym": "FFMT" },
  { "institution": "faculdade nossa senhora de lourdes", "acronym": "FNSL" },
  { "institution": "faculdade logos", "acronym": "FALOG" },
  { "institution": "faculdades integradas dos campos gerais", "acronym": "CESCAGE" },
  { "institution": "faculdade de administração", "acronym": "IPH" },
  { "institution": "faculdades integradas coração de jesus", "acronym": "FAINC" },
  { "institution": "instituto federal de educação, ciência e tecnologia  do amazonas", "acronym": "IFAM" },
  { "institution": "faculdade de tecnologia de capão bonito", "acronym": "FATEC-CB" },
  { "institution": "universidade federal de alagoas", "acronym": "UFAL" },
  { "institution": "faculdade multivix de cachoeiro", "acronym": "Multivix Cachoeiro" },
  { "institution": "pontifícia universidade católica de goiás", "acronym": "PUC GOIÁS" },
  { "institution": "faculdade de tecnologia de barretos", "acronym": "FATEB" },
  { "institution": "sobresp - faculdade de ciências da saúde", "acronym": "SOBRESP" },
  { "institution": "instituto de ensino superior da grande florianópolis", "acronym": "IESGF" },
  { "institution": "faculdade de educação em ciências da saúde", "acronym": "FECS" },
  { "institution": "faculdade são tomaz de aquino", "acronym": "FSTA" },
  { "institution": "instituto de ensino superior de cuiabá", "acronym": "null" },
  { "institution": "faculdade escola de negócios excellence", "acronym": "FAENE" },
  { "institution": "faculdade ages de jacobina", "acronym": "Faculdade AGES" },
  { "institution": "faculdade de educação de patos de minas", "acronym": "FAEP" },
  { "institution": "faculdade unifametro cascavel", "acronym": "null" },
  { "institution": "faculdade de medicina de são josé do rio preto", "acronym": "FAMERP" },
  { "institution": "faculdade anhanguera de sumaré", "acronym": "FACSUMARÉ" },
  { "institution": "faculdade de educação costa braga", "acronym": "FCB" },
  { "institution": "faculdade presidente antônio carlos de matozinhos", "acronym": "FUNEES Matozinhos" },
  { "institution": "universidade federal do estado do rio de janeiro", "acronym": "UNIRIO" },
  { "institution": "centro de ensino superior de jataí", "acronym": "CESUT" },
  { "institution": "faculdade estácio do pantanal", "acronym": "Estácio FAPAN" },
  { "institution": "faculdade são francisco do ceará - crato", "acronym": "FASC" },
  { "institution": "faculdade anhanguera de ciências jurídicas, gerenciais e educação de sinop", "acronym": "null" },
  { "institution": "faculdade planalto de ciência da computação", "acronym": "FACPLAN" },
  { "institution": "faculdade de tecnologia, educação superior e profissional", "acronym": "FATESP" },
  { "institution": "instituto superior de educação da zona oeste", "acronym": "ISE ZONA OESTE" },
  { "institution": "faculdade do povo", "acronym": "FAPSP" },
  { "institution": "faculdade do sudeste goiano", "acronym": "FASUG" },
  { "institution": "faculdade de música do espírito santo maurício de oliveira", "acronym": "FAMES" },
  { "institution": "faculdade ibra de brasília", "acronym": "FACULDADE FABRAS" },
  { "institution": "faculdade prominas de montes claros", "acronym": "PROMINAS" },
  { "institution": "faculdade presidente antônio carlos de raul soares", "acronym": "FUNEES Raul Soares" },
  { "institution": "faculdade de direito de garanhuns", "acronym": "FDG" },
  { "institution": "faculdade de ciências da bahia - faciba", "acronym": "FACIBA" },
  { "institution": "faculdade anhanguera de marilia", "acronym": "FAM" },
  { "institution": "instituto superior de educação franciscano nossa senhora de fátima", "acronym": "FATIMA" },
  { "institution": "faculdade uninassau arapiraca", "acronym": "null" },
  { "institution": "faculdade do centro educacional de niterói", "acronym": "FACEN" },
  { "institution": "faculdade de primavera", "acronym": "CESPRI" },
  { "institution": "escola de contas e capacitação professor pedro aleixo", "acronym": "null" },
  { "institution": "instituto superior de educação de santa maria da vitória", "acronym": "FACITE" },
  { "institution": "faculdade anísio teixeira de feira de santana", "acronym": "FAT" },
  { "institution": "faculdade santo agostinho de pirapora", "acronym": "FASAP" },
  { "institution": "faculdade presidente antônio carlos de conselheiro lafaiete", "acronym": "null" },
  { "institution": "faculdade padrão", "acronym": "PADRAO" },
  { "institution": "faculdade famep - unidade parambu - ce", "acronym": "FAMEP" },
  { "institution": "faculdade de tecnologia de catanduva", "acronym": "FATEC Catanduva" },
  { "institution": "instituto teológico franciscano", "acronym": "ITF" },
  { "institution": "faculdade unida de são paulo", "acronym": "FAUSP" },
  { "institution": "faculdade pitágoras de nova lima", "acronym": "null" },
  { "institution": "faculdade estadual de direito do norte pioneiro", "acronym": "FUNDINOPI" },
  { "institution": "faculdade escola politécnica de inovação e conhecimento aplicado", "acronym": "ÉPICA" },
  { "institution": "faculdade barros melo recife", "acronym": "UNIAESO" },
  { "institution": "fundação faculdade de filosofia, ciências e letras de mandaguari", "acronym": "FAFIMAN" },
  { "institution": "faculdade de filosofia, ciências e letras do alto são francisco", "acronym": "FASF" },
  { "institution": "faculdade de tecnologia professor waldomiro may", "acronym": "FATEC CR" },
  { "institution": "centro universitário presidente antônio carlos de araguari", "acronym": "UNIPAC" },
  { "institution": "faculdade rio sono", "acronym": "RISO" },
  { "institution": "faculdade fasipe cuiabá", "acronym": "FFC" },
  { "institution": "faculdade do descobrimento", "acronym": "FACDESCO" },
  { "institution": "faculdade imesp monitor", "acronym": "IMESP" },
  { "institution": "faculdade de amambai", "acronym": "FIAMA" },
  { "institution": "faculdade de ciências humanas do vale do rio grande", "acronym": "FCHVRG" },
  { "institution": "faculdade de rancharia", "acronym": "null" },
  { "institution": "faculdade unifahe", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de são domingos do prata", "acronym": "FUNEES" },
  { "institution": "centro universitário atenas", "acronym": "UniAtenas" },
  { "institution": "instituto de pesquisas, ensino e gestão em saúde", "acronym": "IPGS" },
  { "institution": "libertas - faculdades integradas", "acronym": "LIBERTAS" },
  { "institution": "faculdades joão paulo ii - pelotas", "acronym": "FJP -PELOTAS" },
  { "institution": "faculdade de ciências contábeis e administrativas de cachoeiro do itapemirim", "acronym": "FACCACI" },
  { "institution": "faculdade ceres", "acronym": "FACERES" },
  { "institution": "faculdade de ciências jurídicas de paraíso do tocantins", "acronym": "FCJP" },
  { "institution": "centro de ensino superior de arcoverde", "acronym": "CESA" },
  { "institution": "instituto de ensino superior de mairiporã", "acronym": "IESM" },
  { "institution": "faculdade são bento do rio de janeiro", "acronym": "FSB/RJ" },
  { "institution": "faculdade pitagoras de petrolina", "acronym": "null" },
  { "institution": "fatec - faculdade de teologia e ciências de votuporanga", "acronym": "FATEC" },
  { "institution": "faculdade de direito de itabira", "acronym": "FDI" },
  { "institution": "faculdade paulista são josé", "acronym": "FPSJ" },
  { "institution": "faculdades integradas de araraquara", "acronym": "FIAR" },
  { "institution": "faculdade empreendedora aurora", "acronym": "FACEMP" },
  { "institution": "faculdade honpar", "acronym": "null" },
  { "institution": "faculdade batista do rio de janeiro", "acronym": "FABAT" },
  { "institution": "christus faculdade do piauí", "acronym": "CHRISFAPI" },
  { "institution": "faculdade de ensino superior brasileira - faculdade febras", "acronym": "FEBRAS" },
  { "institution": "faculdade de ciências ambientais de caratinga", "acronym": "DOCTUM" },
  { "institution": "pontifícia universidade católica de são paulo", "acronym": "PUCSP" },
  { "institution": "centro universitário u:verse", "acronym": "null" },
  { "institution": "faculdade pitágoras instituto camillo filho", "acronym": "PITÁGORAS ICF" },
  { "institution": "faculdade para o desenvolvimento sustentável da amazônia", "acronym": "FADESA" },
  { "institution": "instituto de educação superior são francisco de assis de teófilo otoni", "acronym": "IESFATO" },
  { "institution": "pontifícia universidade católica de campinas", "acronym": "PUC-CAMPINAS" },
  { "institution": "faculdade de petrolina", "acronym": "FACAPE" },
  { "institution": "faculdade dínamo educação", "acronym": "DÍNAMO" },
  { "institution": "faculdade são judas de terra boa", "acronym": "FSJ" },
  { "institution": "faculdade primeiro de janeiro", "acronym": "FPJ" },
  { "institution": "universidade franciscana", "acronym": "UFN" },
  { "institution": "faculdade de ciências e educação sena aires", "acronym": "FACESA" },
  { "institution": "faculdade de tecnologia de itaquaquecetuba", "acronym": "Fatec Itaqua" },
  { "institution": "faculdade de monte alto", "acronym": "FMA" },
  { "institution": "faculdade de tecnologia são mateus", "acronym": "FATESM" },
  { "institution": "centro universitário de votuporanga", "acronym": "UNIFEV" },
  { "institution": "faculdade dom bosco de maringa", "acronym": "DOM BOSCO" },
  { "institution": "faculdade educar da ibiapaba", "acronym": "FAEDI" },
  { "institution": "faculdade inova", "acronym": "INOVA" },
  { "institution": "faculdade três de maio", "acronym": "SETREM" },
  { "institution": "escola superior são francisco de assis", "acronym": "ESFA" },
  { "institution": "faculdade de ciências médicas da santa casa são paulo", "acronym": "FCMSCSP" },
  { "institution": "faculdade de ensino e cultura do ceará", "acronym": "FAECE" },
  { "institution": "faculdade santo antonio de itabuna", "acronym": "FSAI" },
  { "institution": "faculdade de santana de parnaíba", "acronym": "FASP" },
  { "institution": "faculdade professor miguel ângelo da silva santos", "acronym": "FEMASS" },
  { "institution": "faculdade anhanguera de rio branco", "acronym": "null" },
  { "institution": "faculdade de tecnologia e ciências do alto paranaíba", "acronym": "FATAP" },
  { "institution": "faculdade são judas tadeu", "acronym": "FSJT" },
  { "institution": "faculdade de tecnologia de itaquera", "acronym": "FATEC ITAQUERA" },
  { "institution": "faculdade anhanguera de itapeva", "acronym": "FAI" },
  { "institution": "faculdade bilac de são josé dos campos", "acronym": "BILAC" },
  { "institution": "faculdade solidária de sobradinho", "acronym": "FASOL" },
  { "institution": "faculdade anhangüera de piracicaba", "acronym": "null" },
  { "institution": "faculdade de tecnologia ipeno", "acronym": "FACTIPENO" },
  { "institution": "faculdade unicentral", "acronym": "null" },
  { "institution": "faculdade de ciências humanas são paulo", "acronym": "FACH" },
  { "institution": "universidade da região de joinville", "acronym": "UNIVILLE" },
  { "institution": "faculdade serigy", "acronym": "FASERGY" },
  { "institution": "ceddu - centro de ensino superior", "acronym": "CEDDU" },
  { "institution": "faculdade brasileira de ensino, pesquisa e extensão", "acronym": "FABEX" },
  { "institution": "faculdade leonardo da vinci de são gonçalo", "acronym": "null" },
  { "institution": "centro universitário do rio de janeiro", "acronym": "UNIRJ" },
  { "institution": "centro universitário metropolitano de maringá", "acronym": "UNIFAMMA" },
  { "institution": "centro universitário facunicamps", "acronym": "FACUNICAMPS" },
  { "institution": "faculdade de ciências jurídicas de jacobina", "acronym": "null" },
  { "institution": "faculdade catuaí", "acronym": "ICES" },
  { "institution": "faculdade independente butantã", "acronym": "F I B" },
  { "institution": "centro universitário universo recife", "acronym": "null" },
  { "institution": "faculdade de educação superior de paragominas", "acronym": "FACESP" },
  { "institution": "faculdade alpha", "acronym": "Alpha" },
  { "institution": "faculdade una de jataí", "acronym": "Una" },
  { "institution": "faculdade pitágoras de aracati", "acronym": "null" },
  { "institution": "faculdade itataia", "acronym": "FACIT" },
  { "institution": "instituto maranhense de ensino e cultura", "acronym": "IMEC" },
  { "institution": "centro universitario projeção", "acronym": "UNIPROJEÇÃO" },
  { "institution": "faculdade de milagres ceará", "acronym": "FAMICE" },
  { "institution": "faculdade xv de agosto", "acronym": "FAQ" },
  { "institution": "faculdade una de itumbiara", "acronym": "null" },
  { "institution": "faculdade unime de ciências exatas e tecnológicas", "acronym": "FCT" },
  { "institution": "faculdade de tecnologia senac pelotas", "acronym": "FATEC SENAC PELOTAS" },
  { "institution": "faculdade anhanguera de imperatriz", "acronym": "null" },
  { "institution": "ej - faculdade de tecnologia em aviação civil", "acronym": "EJ" },
  { "institution": "faculdade de ensino de pesquisa do cooperativismo", "acronym": "FEPCOOP" },
  { "institution": "instituto federal de educação, ciência e tecnologia de sergipe", "acronym": "IFS" },
  { "institution": "faculdade santo agostinho", "acronym": "FASA" },
  { "institution": "instituto de ensino superior de fortaleza", "acronym": "IESF" },
  { "institution": "faculdade mercúrio", "acronym": "FAMERC" },
  { "institution": "centro universitário unigran capital", "acronym": "null" },
  { "institution": "faculdade única de formação e ensino", "acronym": "FUNIFE" },
  { "institution": "faculdade unibras da bahia", "acronym": "FACBRAS" },
  { "institution": "faculdade de administração de campo belo", "acronym": "FACAMP" },
  { "institution": "faculdade pitágoras de itumbiara", "acronym": "null" },
  { "institution": "faculdade de ciências humanas e exatas do sertão do são francisco", "acronym": "FACESF" },
  { "institution": "faculdade escola paulista de direito", "acronym": "FACEPD" },
  { "institution": "centro universitário ideau", "acronym": "UNIDEAU" },
  { "institution": "instituto superior de educação santa cruz", "acronym": "ISED" },
  { "institution": "centro técnico-educacional superior do oeste paranaense", "acronym": "CTESOP" },
  { "institution": "faculdade de tecnologia do abc", "acronym": "FATEC-ABC" },
  { "institution": "faculdade de educação superior de pouso alegre", "acronym": "null" },
  { "institution": "faculdade aparecida de goiânia", "acronym": "FAP" },
  { "institution": "alfa - faculdade de teófilo otoni", "acronym": "ALFA - TO" },
  { "institution": "faculdade de guararapes", "acronym": "FAG" },
  { "institution": "universidade do sul de santa catarina", "acronym": "UNISUL" },
  { "institution": "pontifícia universidade católica do rio de janeiro", "acronym": "PUC-RIO" },
  { "institution": "instituto de ensino superior miguel de cervantes", "acronym": "IESMC" },
  { "institution": "faculdade exponencial", "acronym": "FIE" },
  { "institution": "instituto federal de educação, ciência e tecnologia do sertão pernambucano", "acronym": "IF Sertão" },
  { "institution": "faculdade adventista do paraná", "acronym": "FAP" },
  { "institution": "centro universitário una de bom despacho", "acronym": "UNA" },
  { "institution": "instituto santo tomás de aquino", "acronym": "ISTA" },
  { "institution": "faculdades integradas cesumar de arapongas", "acronym": "CESUMAR" },
  { "institution": "fundação faculdades luiz meneghel", "acronym": "FFALM" },
  { "institution": "centro universitário unifafibe", "acronym": "FAFIBE" },
  { "institution": "faculdade pitagoras de jacobina", "acronym": "null" },
  { "institution": "faculdades integradas zona da mata", "acronym": "FIZM" },
  { "institution": "centro universitário unifacottur", "acronym": "null" },
  { "institution": "faculdade de ciências humanas e sociais de igarassu", "acronym": "FACIG" },
  { "institution": "escola de comunicação, mídia e informação", "acronym": "FGV ECMI" },
  { "institution": "faculdade de tecnologia e ciências do distrito federal", "acronym": "FATECDF" },
  { "institution": "faculdade de ciências jurídicas de belo jardim", "acronym": "null" },
  { "institution": "centro universitário da serra gaúcha", "acronym": "FSG" },
  { "institution": "faculdade de filosofia ciências e letras deputado josé laviola", "acronym": "FAFICIL" },
  { "institution": "faculdade cnec joinville", "acronym": "null" },
  { "institution": "universidade de ribeirão preto", "acronym": "UNAERP" },
  { "institution": "faculdade nossa senhora aparecida", "acronym": "FNSA" },
  { "institution": "centro de ensino superior de nova serrana", "acronym": "null" },
  { "institution": "escola de administração de empresas de são paulo - fgv eaesp", "acronym": "FGV-EAESP" },
  { "institution": "faculdade reges de junqueirópolis", "acronym": "null" },
  { "institution": "faculdade atenas sul de minas", "acronym": "null" },
  { "institution": "universidade tecnológica federal do paraná", "acronym": "UTFPR" },
  { "institution": "faculdade de gestao e negocios de salvador", "acronym": "FGN" },
  { "institution": "faculdade paulo picanço", "acronym": "FACPP" },
  { "institution": "faculdade de administração de fátima do sul", "acronym": "FAFS" },
  { "institution": "centro universitário santa maria da glória", "acronym": "UNISMG" },
  { "institution": "faculdade dom luis de orleans e bragança", "acronym": "FDL" },
  { "institution": "faculdade tecnológica santanna", "acronym": "FACSANTANNA" },
  { "institution": "faculdade de mirandópolis", "acronym": "FAM" },
  { "institution": "universidade federal rural da amazônia", "acronym": "UFRA" },
  { "institution": "faculdade de inovação tecnológica de campo grande", "acronym": "null" },
  { "institution": "faculdade maranhense", "acronym": "FAM" },
  { "institution": "faculdade unirb - fortaleza", "acronym": "F. UNIRB" },
  { "institution": "faculdade thiago ferreira", "acronym": "FAT" },
  { "institution": "universidade veiga de almeida", "acronym": "UVA" },
  { "institution": "centro universitário fip-moc", "acronym": "UNIFIPMoc" },
  { "institution": "faculdade jk - plano piloto", "acronym": "null" },
  { "institution": "faculdade tecnologia expert", "acronym": "FATEX" },
  { "institution": "faculdade astorga", "acronym": "FAAST" },
  { "institution": "faculdade de anicuns", "acronym": "FA" },
  { "institution": "faculdade de tecnologia de são carlos", "acronym": "FATEC São Carlo" },
  { "institution": "centro universitário de volta redonda", "acronym": "UNIFOA" },
  { "institution": "faculdade bssp", "acronym": "null" },
  { "institution": "faculdade la salle - caxias", "acronym": "FACSALLE" },
  { "institution": "faculdade de engenharia e agrimensura de pirassununga - feap", "acronym": "FEAP" },
  { "institution": "faculdade de direito de vitória", "acronym": "FDV" },
  { "institution": "faculdade uriel de almeida leitão", "acronym": "null" },
  { "institution": "faculdade insted", "acronym": "null" },
  { "institution": "faculdade jk de tecnologia", "acronym": "FACJK" },
  { "institution": "faculdade evangélica do meio norte", "acronym": "FAEME" },
  { "institution": "faculdade de matupá", "acronym": "UNIFAMA" },
  { "institution": "escola de inteligência - esint/abin", "acronym": "ESINT/ABIN" },
  { "institution": "faculdade ibgen", "acronym": "null" },
  { "institution": "centro universitário de barra mansa", "acronym": "UBM" },
  { "institution": "faculdade de ciências humanas do estado de são paulo", "acronym": "FACIC" },
  { "institution": "faculdade santa helena", "acronym": "FSH" },
  { "institution": "centro universitário do sudeste mineiro", "acronym": "UNICSUM" },
  { "institution": "faculdade do complexo educacional santo andré", "acronym": "FACESA" },
  { "institution": "faculdade anhanguera de ciências sociais aplicadas de sinop", "acronym": "FACISAS" },
  { "institution": "faculdade unibrasília de ciências econômicas de minas gerais", "acronym": "BRASÍLIA" },
  { "institution": "centro universitário uni-ban", "acronym": "UNI-BAN" },
  { "institution": "faculdade estadual de educação física de jacarezinho", "acronym": "FAEFIJA" },
  { "institution": "faculdade de tecnologia do transporte", "acronym": "null" },
  { "institution": "faculdade de ciências sociais aplicadas de minas gerais", "acronym": "FACISAMG" },
  { "institution": "faculdade de saúde e humanidades ibituruna", "acronym": "FASI" },
  { "institution": "faculdade modal", "acronym": "Modal" },
  { "institution": "faculdade anhanguera de santo antônio de jesus", "acronym": "null" },
  { "institution": "faculdade pitágoras de  camaçari", "acronym": "null" },
  { "institution": "faculdade cleber leite", "acronym": "FCL" },
  { "institution": "faculdade ibeco", "acronym": "IBECO" },
  { "institution": "faculdade birigui", "acronym": "FABI" },
  { "institution": "instituto superior de educação de bicas", "acronym": "ISEB" },
  { "institution": "faculdade de direito do vale do rio doce", "acronym": "FADIVALE" },
  { "institution": "centro universitário uniftc", "acronym": "null" },
  { "institution": "faculdade para o desenvolvimento do sudeste tocantinense", "acronym": "FADES" },
  { "institution": "escola de enfermagem da fundação técnico educacional souza marques", "acronym": "EEFTESM" },
  { "institution": "faculdade anchieta do recife", "acronym": "FAR" },
  { "institution": "faculdade de ciências jurídicas de itumbiara", "acronym": "null" },
  { "institution": "centro universitário goyazes", "acronym": "UniGOYAZES" },
  { "institution": "faculdade presidente antônio carlos de vespasiano", "acronym": "FUNEES" },
  { "institution": "faculdade de ciências jurídicas de santa maria", "acronym": "UNISM" },
  { "institution": "faculdade de tecnologia alcides maya", "acronym": "AMTEC" },
  { "institution": "faculdade de ciências jurídicas de altamira", "acronym": "null" },
  { "institution": "faculdade do vale do itapecurú", "acronym": "FAI" },
  { "institution": "centro universitário unifacig", "acronym": "null" },
  { "institution": "faculdade de educação regional serrana", "acronym": "FUNPAC" },
  { "institution": "faculdade reges de dracena", "acronym": "null" },
  { "institution": "instituto de ensino superior múltiplo", "acronym": "IESM" },
  { "institution": "faculdade uninassau parnamirim", "acronym": "null" },
  { "institution": "faculdade de direito anhanguera unidade guarapari", "acronym": "null" },
  { "institution": "faculdade padre dourado fortaleza", "acronym": "FACPED FORTALEZA" },
  { "institution": "faculdade sociesc de educação de jaraguá do sul", "acronym": "null" },
  { "institution": "faculdade de educação santa terezinha", "acronym": "FEST" },
  { "institution": "faculdade sudoeste", "acronym": "FASU" },
  { "institution": "faculdade campos elíseos", "acronym": "FCE" },
  { "institution": "faculdade de ciências sociais aplicadas e de tecnologias de agua boa", "acronym": "FACESA" },
  { "institution": "faculdade presidente antônio carlos de itaobim", "acronym": "FUNEES Itaobim" },
  { "institution": "faculdade pitágoras de tianguá ce", "acronym": "null" },
  { "institution": "faculdade anhanguera de uberlândia", "acronym": "null" },
  { "institution": "escola superior de estudos empresariais e informática", "acronym": "ESEEI" },
  { "institution": "faculdade de ciências educacionais", "acronym": "FACE" },
  { "institution": "instituto militar de engenharia", "acronym": "IME" },
  { "institution": "faculdade inpg de blumenau", "acronym": "FACINPG" },
  { "institution": "faculdade de aracaju", "acronym": "FACAR" },
  { "institution": "instituto federal de educação, ciência e tecnologia de pernambuco", "acronym": "IFPE" },
  { "institution": "instituto racine de educação superior", "acronym": "RACINE" },
  { "institution": "pontifícia universidade católica do paraná", "acronym": "PUCPR" },
  { "institution": "faculdade estácio de feira de santana", "acronym": "ESTÁCIO FEIRA DE SAN" },
  { "institution": "faculdade anhanguera de joinville", "acronym": "null" },
  { "institution": "centro universitário de tecnologia de curitiba", "acronym": "UNIFATEC" },
  { "institution": "instituto federal de educação, ciência e tecnologia goiano", "acronym": "IF Goiano" },
  { "institution": "faculdade centro sul", "acronym": "null" },
  { "institution": "faculdade de informática de cuiabá", "acronym": "FIC" },
  { "institution": "faculdade de tecnologia dom amaury castanho", "acronym": "Fatec Itu" },
  { "institution": "universidade de marília", "acronym": "UNIMAR" },
  { "institution": "faculdade de farmácia e bioquímica", "acronym": "ITPAC" },
  { "institution": "centro de ensino superior do vale são francisco", "acronym": "CESVASF" },
  { "institution": "universidade gama filho", "acronym": "UGF" },
  { "institution": "faculdade anhanguera de mogi guaçu", "acronym": "FAMG" },
  { "institution": "instituto professor flávio vellini", "acronym": "null" },
  { "institution": "faculdade excelência", "acronym": "FAEX" },
  { "institution": "faculdade são camilo", "acronym": "null" },
  { "institution": "faculdade de gestao e negocios de maceio", "acronym": "FGN MACEIO" },
  { "institution": "fundação universidade federal da grande dourados", "acronym": "UFGD" },
  { "institution": "faculdade do vale do são francisco", "acronym": "FAVASF" },
  { "institution": "instituição de ensino superior de cacoal", "acronym": "FANORTE CACOAL" },
  { "institution": "faculdades metropolitanas de cuiabá", "acronym": "FAMEC" },
  { "institution": "faculdade focus", "acronym": "Focus" },
  { "institution": "faculdade unibrasília sul", "acronym": "BRASÍLIA" },
  { "institution": "faculdade de santa cruz da bahia", "acronym": "FSC" },
  { "institution": "faculdade idd", "acronym": "IDD" },
  { "institution": "faculdade trilógica nossa senhora de todos os povos", "acronym": "FATRI EAD" },
  { "institution": "faculdade do tapajós", "acronym": "FAT" },
  { "institution": "centro universitário unisan", "acronym": "UNISAN" },
  { "institution": "faculdade de educação e tecnologia da amazônia", "acronym": "FAM" },
  { "institution": "instituto de ensino superior de rondônia", "acronym": "IESUR" },
  { "institution": "faculdade monteiro lobato", "acronym": "FATO" },
  { "institution": "faculdades integradas de aracruz", "acronym": "FAACZ" },
  { "institution": "instituto municipal de ensino superior de assis", "acronym": "IMESA" },
  { "institution": "faculdade empresarial de chapecó", "acronym": "FAEM" },
  { "institution": "faculdade luciano feijão", "acronym": "FLF" },
  { "institution": "faculdade metropolitana do planalto norte", "acronym": "FAMEPLAN" },
  { "institution": "instituto superior de educação porto seguro", "acronym": "ISEPS" },
  { "institution": "faculdade via sapiens", "acronym": "FVS" },
  { "institution": "faculdade univeritas universus veritas de são gonçalo", "acronym": "UNIVERITAS SG" },
  { "institution": "centro universitário estácio de brasília - estácio brasília", "acronym": "ESTÁCIO BRASÍLIA" },
  { "institution": "faculdade de ciências tecnológicas de fortaleza", "acronym": "FCTFOR" },
  { "institution": "faculdade santo antonio", "acronym": "FSA" },
  { "institution": "faculdade católica de pouso alegre", "acronym": "FACAPA" },
  { "institution": "faculdades integradas vale do rio verde", "acronym": "FIVAR" },
  { "institution": "faculdade nectar", "acronym": "null" },
  { "institution": "faculdade são francisco de assis", "acronym": "FSFA" },
  { "institution": "centro universitário do estado do pará", "acronym": "CESUPA" },
  { "institution": "faculdade de tecnologia cenecista de rio negrinho", "acronym": "null" },
  { "institution": "claretiano - centro universitário", "acronym": "null" },
  { "institution": "faculdade latino americana de educação", "acronym": "FLATED" },
  { "institution": "faculdade anhanguera de são caetano", "acronym": "null" },
  { "institution": "escola de administração fazendária", "acronym": "ESAF" },
  { "institution": "faculdade de ciências sociais dos palmares", "acronym": "FACIP" },
  { "institution": "centro universitário unihorizontes", "acronym": "null" },
  { "institution": "faculdades integradas stella maris de andradina", "acronym": "FISMA" },
  { "institution": "faculdade vicentina - favi", "acronym": "FAVI" },
  { "institution": "faculdades integradas são judas tadeu", "acronym": "SJT" },
  { "institution": "instituto superior de educação do rio de janeiro", "acronym": "ISERJ" },
  { "institution": "faculdade de tecnologia de piracicaba", "acronym": "FATEP" },
  { "institution": "instituto de educação e ensino superior de campinas", "acronym": "IESCAMP" },
  { "institution": "formação faculdade integrada", "acronym": "FFI" },
  { "institution": "faculdade são paulo de presidente venceslau", "acronym": "FASPREV" },
  { "institution": "faculdade brasileira de ciências jurídicas", "acronym": "FBCJ" },
  { "institution": "faculdade cecap do lago norte", "acronym": "CECAP" },
  { "institution": "faculdade de empreendedorismo e ciências humanas", "acronym": "FACULDADE FAECH" },
  { "institution": "escola brasileira de medicina chinesa", "acronym": "EBRAMEC" },
  { "institution": "faculdade estácio de maringá", "acronym": "Estácio Maringá" },
  { "institution": "faculdade de tecnologia senai londrina", "acronym": "null" },
  { "institution": "centro universitário fluminense", "acronym": "UNIFLU" },
  { "institution": "faculdade de tecnologia de ourinhos", "acronym": "FATEC" },
  { "institution": "faculdade pitagoras de campina grande", "acronym": "null" },
  { "institution": "universidade anhanguera de são paulo", "acronym": "UNIAN - SP" },
  { "institution": "instituto superior de educação professora lúcia dantas", "acronym": "ISEL" },
  { "institution": "instituto municipal de ensino superior de são manuel", "acronym": "IMESSM" },
  { "institution": "faculdade palas atena de chopinzinho", "acronym": "FPA" },
  { "institution": "faculdade delta", "acronym": "FACULDADE DELTA" },
  { "institution": "faculdade unifael porto alegre", "acronym": "FAEL POA" },
  { "institution": "faculdade dom luciano mendes", "acronym": "FDLM" },
  { "institution": "faculdade santa casa de belo horizonte", "acronym": "FSCBH" },
  { "institution": "faculdade intervale", "acronym": "INTERVALE" },
  { "institution": "faculdade cesurg marau", "acronym": "null" },
  { "institution": "faculdade de tecnologia consultime", "acronym": "FATEC" },
  { "institution": "escola superior de teologia e espiritualidade franciscana", "acronym": "ESTEF" },
  { "institution": "faculdade ideau de bagé", "acronym": "IDEAU" },
  { "institution": "faculdade da amazônia de porto velho", "acronym": "null" },
  { "institution": "faculdade doctum de serra", "acronym": "DOCTUM" },
  { "institution": "faculdade metropolitana de horizonte", "acronym": "FMH" },
  { "institution": "centro universitário uninorte", "acronym": "null" },
  { "institution": "faculdade de tecnologia ined - unidade venda nova", "acronym": "PITÁGORAS/TEC" },
  { "institution": "faculdade guilherme guimbala", "acronym": "FGG" },
  { "institution": "faculdade de educação silva serpa", "acronym": "FASS" },
  { "institution": "faculdade da região dos lagos", "acronym": "FERLAGOS" },
  { "institution": "faculdade presidente antônio carlos de tocantins", "acronym": "FUNEES" },
  { "institution": "faculdade energia", "acronym": "null" },
  { "institution": "faculdade 28 de agosto de ensino e pesquisa", "acronym": "28 de Agosto" },
  { "institution": "centro universitário doutor leão sampaio", "acronym": "UNILEÃO" },
  { "institution": "faculdade marechal rondon de mauá", "acronym": "FMR-MAUÁ" },
  { "institution": "instituto universitário são judas de são bernardo do campo", "acronym": "null" },
  { "institution": "faculdade de tecnologia senac amazonas", "acronym": "null" },
  { "institution": "faculdade de artes, ciências e tecnologias", "acronym": "FACET" },
  { "institution": "faculdade innovate de anápolis", "acronym": "INNOVATE" },
  { "institution": "centro regional universitário de espírito santo do pinhal", "acronym": "UNIPINHAL" },
  { "institution": "faculdade doctum de juiz de fora", "acronym": "DOCTUM" },
  { "institution": "faculdade casa do estudante", "acronym": "FACE" },
  { "institution": "faculdade de tecnologia de garça", "acronym": "FATECGA" },
  { "institution": "faculdade verde norte", "acronym": "FAVENORTE" },
  { "institution": "escola de comunicações", "acronym": "EsCom" },
  { "institution": "faculdade metropolitana", "acronym": "UNNESA" },
  { "institution": "faculdade presidente antônio carlos de lagoa santa", "acronym": "FUNEES" },
  { "institution": "faculdade qualittas", "acronym": "QUALITTAS" },
  { "institution": "faculdade de tecnologia ippeo", "acronym": "IPPEO" },
  { "institution": "faculdade de tecnologia e ciências - ftc petrolina", "acronym": "FTC Petrolina" },
  { "institution": "faculdade de administração de itabirito", "acronym": "FAI" },
  { "institution": "faculdade de educacao e tecnologia do para", "acronym": "FAETE" },
  { "institution": "faculdade padre cícero", "acronym": "null" },
  { "institution": "universidade federal da fronteira sul", "acronym": "UFFS" },
  { "institution": "faculdade de ciencias humanas e sociais de serra talhada", "acronym": "FACHUSST" },
  { "institution": "faculdade presidente antônio carlos de andré da silveira", "acronym": "null" },
  { "institution": "faculdade metropolitana do estado de são paulo", "acronym": "FAMEESP" },
  { "institution": "faculdade são bento da bahia", "acronym": "FSBB" },
  { "institution": "instituto brasiliense de tecnologia e ciência", "acronym": "IBTC" },
  { "institution": "universidade de são paulo", "acronym": "USP" },
  { "institution": "escola superior de artes célia helena", "acronym": "null" },
  { "institution": "faculdade de medicina de olinda", "acronym": "FMO" },
  { "institution": "faculdade santo agostinho de vitória da conquista", "acronym": "FASAVIC" },
  { "institution": "faculdade presidente antônio carlos de piumhi", "acronym": "FUNEES Piumhi" },
  { "institution": "centro universitário barão de mauá", "acronym": "CBM" },
  { "institution": "centro universitário favip wyden", "acronym": "UniFavip Wyden" },
  { "institution": "faculdade de minas bh", "acronym": "FAMINAS-BH" },
  { "institution": "faculdade factum", "acronym": "Factum" },
  { "institution": "faculdade de tecnologia alto tietê", "acronym": "FATECALTOTIETÊ" },
  { "institution": "faculdade anhangüera de campinas", "acronym": "null" },
  { "institution": "faculdade de formação de professores de araripina", "acronym": "FAFOPA" },
  { "institution": "instituto de ensino superior e formação avançada de vitória", "acronym": "IESFAVI" },
  { "institution": "universidade tuiuti do paraná", "acronym": "UTP" },
  { "institution": "faculdade de palmeiras de goiás - facmais", "acronym": "FacMais" },
  { "institution": "instituto de educação e ensino superior de samambaia", "acronym": "IESA" },
  { "institution": "faculdade de elesbão veloso", "acronym": "FAEVE" },
  { "institution": "faculdade metropolitana de coari", "acronym": "FAMETRO" },
  { "institution": "faculdade de araraquara", "acronym": "FARA" },
  { "institution": "faculdade pitágoras unopar de brumado", "acronym": "null" },
  { "institution": "faculdade anhanguera de tecnologia de jundiaí", "acronym": "null" },
  { "institution": "faculdade estadual de filosofia ciências e letras de cornélio procópio", "acronym": "FAFICOP" },
  { "institution": "instituto superior de ciências da saúde", "acronym": "INCISA" },
  { "institution": "faculdade unisul de balneário camboriú", "acronym": "null" },
  { "institution": "faculdade famart", "acronym": "null" },
  { "institution": "universidade potiguar", "acronym": "UNP" },
  { "institution": "faculdade rhema", "acronym": "FACUR" },
  { "institution": "faculdade unicesumar de corumbá", "acronym": "null" },
  { "institution": "faculdade santissima trindade", "acronym": "FAST" },
  { "institution": "universidade santa úrsula", "acronym": "USU" },
  { "institution": "ilum escola de ciência", "acronym": "null" },
  { "institution": "faculdade de ensino superior da paraíba", "acronym": "FESP" },
  { "institution": "faculdade barddal de ciências contábeis", "acronym": "null" },
  { "institution": "faculdade atual", "acronym": "FAAT" },
  { "institution": "faculdade bertioga", "acronym": "FABE" },
  { "institution": "faculdade de ciências jurídicas de assis", "acronym": "null" },
  { "institution": "centro universitário faesa", "acronym": "null" },
  { "institution": "centro universitário fibra", "acronym": "UNIFIBRA" },
  { "institution": "faculdade do centro-oeste", "acronym": "FICO" },
  { "institution": "centro universitário christus", "acronym": "UNICHRISTUS" },
  { "institution": "faculdade educa brasil noel de mello", "acronym": "null" },
  { "institution": "faculdade projeção de sobradinho", "acronym": "FAPRO" },
  { "institution": "faculdade tecnológica inap", "acronym": "FAT- INAP" },
  { "institution": "centro universitário dom pedro ii", "acronym": "UNIDOMPEDRO" },
  { "institution": "faculdade de tecnologia coesp", "acronym": "FCOESP" },
  { "institution": "faculdade européia de tecnologia e ciências humanas - eurotech", "acronym": "EUROTECH" },
  { "institution": "faculdade entre rios do piauí", "acronym": "FAERPI" },
  { "institution": "faculdade conhecimento & ciência", "acronym": "FCC" },
  { "institution": "faculdade albert einstein", "acronym": "FALBE" },
  { "institution": "centro universitário anhanguera de fortaleza", "acronym": "UNIPITÁGORAS" },
  { "institution": "instituto tecnológico de aeronáutica", "acronym": "ITA" },
  { "institution": "faculdade ad 1", "acronym": "UNISABER/AD1" },
  { "institution": "instituto superior de ciencias policiais", "acronym": "ISCP" },
  { "institution": "faculdade de ensino superior de parnaíba", "acronym": "FAESPA" },
  { "institution": "escola de matemática aplicada", "acronym": "EMAp-FGV" },
  { "institution": "faculdade horus säo miguel", "acronym": "FSJ" },
  { "institution": "faculdade brasília de são paulo", "acronym": "FTBSP" },
  { "institution": "faculdade ibmec", "acronym": "IBMEC" },
  { "institution": "faculdade anhanguera de limeira", "acronym": "null" },
  { "institution": "faculdade jk sobradinho", "acronym": "null" },
  { "institution": "universidade estadual de ponta grossa", "acronym": "UEPG" },
  { "institution": "faculdades integradas padrão", "acronym": "FIP GUANAMBI" },
  { "institution": "faculdade eugênio gomes", "acronym": "FEG" },
  { "institution": "faculdade unirb - serrinha", "acronym": "FACULDADE UNIRB" },
  { "institution": "universidade federal dos vales do jequitinhonha e mucuri", "acronym": "UFVJM" },
  { "institution": "faculdade presidente antônio carlos de raposos", "acronym": "FUNEES Raposos" },
  { "institution": "faculdade - uninorte  altamira", "acronym": "null" },
  { "institution": "universidade do estado do rio grande do norte", "acronym": "null" },
  { "institution": "universidade regional do cariri", "acronym": "URCA" },
  { "institution": "faculdade de teconologia da zona sul", "acronym": "FATEC ZONASUL" },
  { "institution": "faculdade de orlândia", "acronym": "FAO" },
  { "institution": "faculdade de tecnologia e ciências - ftc itapipoca", "acronym": "FTC Itapipoca" },
  { "institution": "faculdade de tecnologia de mogi das cruzes", "acronym": "FATEC-MC" },
  { "institution": "centro de estudos em direito e negócios", "acronym": "null" },
  { "institution": "faculdade almeida rodrigues", "acronym": "FAR" },
  { "institution": "centro universitário unifateb", "acronym": "null" },
  { "institution": "faculdade de tecnologia gap", "acronym": "GAP" },
  { "institution": "faculdade ith", "acronym": "ITH" },
  { "institution": "faculdade vale do aço", "acronym": "FAVALE" },
  { "institution": "faculdade latino-americana", "acronym": "FLAM" },
  { "institution": "instituto belo horizonte de ensino superior", "acronym": "IBHES" },
  { "institution": "faculdade união paulistana", "acronym": "FAUP" },
  { "institution": "faculdade internacional de ciências empresariais", "acronym": "FICE" },
  { "institution": "faculdade paiva andrade", "acronym": "FPA" },
  { "institution": "faculdade salesiana de pindamonhangaba", "acronym": "FASP" },
  { "institution": "faculdades associadas de uberaba - fazu", "acronym": "FAZU" },
  { "institution": "universidade la salle", "acronym": "UNILASALLE" },
  { "institution": "instituto superior de educação do sertão do pajeú", "acronym": "ISESP" },
  { "institution": "centro universitário mauá de brasília", "acronym": "UNIMAUÁ" },
  { "institution": "faculdade novo horizonte", "acronym": "FNH" },
  { "institution": "instituto moinhos de vento", "acronym": "null" },
  { "institution": "faculdades integradas regionais de avaré", "acronym": "FIRA" },
  { "institution": "faculdade da cidade de são gonçalo", "acronym": "FACI" },
  { "institution": "faculdade quirinópolis", "acronym": "FAQUI" },
  { "institution": "faculdade municipal de palhoça", "acronym": "FMP" },
  { "institution": "faculdade biopark ii", "acronym": "BIOPARK II" },
  { "institution": "faculdade integrada cesumar de curitiba", "acronym": "CESUMAR" },
  { "institution": "escola de instrução especializada", "acronym": "EsIE" },
  { "institution": "faculdade internacional de são paulo", "acronym": "FINSP" },
  { "institution": "faculdade brasileira de tecnologia", "acronym": "FBT" },
  { "institution": "faculdade de tecnologia de são roque", "acronym": "FATEC-SR" },
  { "institution": "centro de educação superior da foz do itajaí - cesfi", "acronym": "CESFI" },
  { "institution": "faculdade de tietê", "acronym": "null" },
  { "institution": "universidade federal da paraíba", "acronym": "UFPB" },
  { "institution": "faculdade nacional sênior", "acronym": "FANSÊNIOR" },
  { "institution": "faculdade de itaituba", "acronym": "FAI" },
  { "institution": "faculdade católica do cariri", "acronym": "null" },
  { "institution": "faculdade stella maris - fsm", "acronym": "FSM" },
  { "institution": "escola dominicana de teologia", "acronym": "null" },
  { "institution": "faculdade de ensino superior da amazônia", "acronym": "FESAM" },
  { "institution": "escola superior de gestão", "acronym": "ESGe" },
  { "institution": "faculdade evoluir", "acronym": "FAEV" },
  { "institution": "faculdade luterana rui barbosa", "acronym": "FALURB" },
  { "institution": "faculdade horizonte", "acronym": "FACHORIZONTE" },
  { "institution": "centro universitário de excelência", "acronym": "UNEX" },
  { "institution": "focca - faculdade de olinda", "acronym": "FOCCA" },
  { "institution": "faculdades integradas potencial", "acronym": "FIP" },
  { "institution": "faculdade opus 365", "acronym": "null" },
  { "institution": "faculdade alagoana de administração", "acronym": "FAA" },
  { "institution": "faculdade pitágoras de piripiri", "acronym": "null" },
  { "institution": "faculdade montes claros", "acronym": "FACULMONTES" },
  { "institution": "centro universitário funcesi", "acronym": "UNIFUNCESI" },
  { "institution": "faculdade paschoal dantas ead", "acronym": "FPD - EAD" },
  { "institution": "centro universitário estácio de ribeirão preto", "acronym": "ESTÁCIO RIBEIRÃO PRE" },
  { "institution": "universidade da amazônia", "acronym": "UNAMA" },
  { "institution": "faculdade integral cantareira", "acronym": "F.I.C." },
  { "institution": "faculdade das atividades empresariais de teresina", "acronym": "FAETE" },
  { "institution": "faculdade amazonas", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de jaboatão dos guararapes", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de são lourenço", "acronym": "FUNEES São Lourenço" },
  { "institution": "faculdades impactos brasil", "acronym": "FACIB" },
  { "institution": "faculdade presidente antônio carlos de ponte nova", "acronym": "null" },
  { "institution": "faculdade equipe", "acronym": "null" },
  { "institution": "faculdade ibmec distrito federal", "acronym": "IBMEC/DF" },
  { "institution": "faculdade de ciências educacionais de capim grosso", "acronym": "FCG" },
  { "institution": "universidade tiradentes", "acronym": "UNIT" },
  { "institution": "faculdade unirb parnamirim", "acronym": "F.UNIRB" },
  { "institution": "faculdade de engenharia e tecnologia do instituto mairiporã de ensino superior", "acronym": "IMENSU-FET" },
  { "institution": "faculdade nove de julho de são bernardo do campo", "acronym": "NOVE-SBC" },
  { "institution": "faculdade cenecista de rio bonito", "acronym": "FACERB" },
  { "institution": "faculdade de ciências educacionais de sergipe", "acronym": "FCES" },
  { "institution": "faculdade grau s ensino superior", "acronym": "GRAU" },
  { "institution": "faculdade de tecnologia em saúde cieph", "acronym": "FACTES" },
  { "institution": "centro universitário do rio grande do norte", "acronym": "UNI-RN" },
  { "institution": "instituto de educação e inovação", "acronym": "IEDi" },
  { "institution": "faculdade senac/rs", "acronym": "SENACRS" },
  { "institution": "trevisan escola superior de negócios-", "acronym": "TEN" },
  { "institution": "centro universitário ufbra", "acronym": "UFBRA" },
  { "institution": "faculdade de ensino superior da amazônia reunida", "acronym": "FESAR" },
  { "institution": "faculdade do estado do maranhão", "acronym": "FACEM" },
  { "institution": "faculdade de ensino superior", "acronym": "FAES" },
  { "institution": "faculdades joão paulo ii - rio grande", "acronym": "FJP" },
  { "institution": "faculdade anhanguera de guarulhos", "acronym": "null" },
  { "institution": "faculdade de administração e ciências econômicas ltda", "acronym": "FACEC" },
  { "institution": "centro universitário luterano de palmas", "acronym": "CEULP" },
  { "institution": "faculdade católica imaculada conceição do recife", "acronym": "CATÓLICA" },
  { "institution": "faculdade de estudos sociais do espírito santo", "acronym": "PIO XII" },
  { "institution": "faculdade domus sapiens", "acronym": "FDS" },
  { "institution": "instituto de tecnologia e liderança", "acronym": "Inteli" },
  { "institution": "faculdade uninabuco são lourenço da mata", "acronym": "null" },
  { "institution": "faculdade de educação do piauí", "acronym": "FAEPI" },
  { "institution": "faculdade guaianás", "acronym": "FAG" },
  { "institution": "faculdade presidente antônio carlos de alvarenga", "acronym": "FUNEES" },
  { "institution": "escola superior de gestão comercial e marketing", "acronym": "ESIC" },
  { "institution": "faculdade barddal de letras", "acronym": "FB-LETRAS" },
  { "institution": "centro universitário santo agostinho", "acronym": "UNIFSA" },
  { "institution": "faculdades integradas de cruzeiro", "acronym": "FIC" },
  { "institution": "centro de educação superior de blumenau", "acronym": "CESBLU" },
  { "institution": "instituto cuiabá de ensino e cultura", "acronym": "ICEC" },
  { "institution": "faculdade lions", "acronym": "FAC-LIONS" },
  { "institution": "faculdade de ciências de timbaúba", "acronym": "FACET" },
  { "institution": "faculdade presidente antônio carlos de conceição das alagoas", "acronym": "FUNEES" },
  { "institution": "faculdade de direito de cachoeiro do itapemirim", "acronym": "FDCI" },
  { "institution": "faculdade doctum de ipatinga", "acronym": "null" },
  { "institution": "faculdade de tecnologia senac ponta grossa", "acronym": "null" },
  { "institution": "faculdade são luís de frança", "acronym": "FSLF" },
  { "institution": "faculdade de educação  e tecnologia de são carlos", "acronym": "FETEC - São Carlos" },
  { "institution": "faculdade de ciências sociais aplicadas de belo horizonte", "acronym": "FACISABH" },
  { "institution": "faculdade de sumaré", "acronym": "FECGS" },
  { "institution": "faculdade  fasup", "acronym": "FASUP" },
  { "institution": "faculdade pitagoras anhanguera de sao joão da boa vista", "acronym": "null" },
  { "institution": "faculdade independente do nordeste", "acronym": "FAINOR" },
  { "institution": "faculdade de tecnologia de guaratinguetá", "acronym": "FATEC GT" },
  { "institution": "faculdades integradas einstein de limeira", "acronym": "FIEL" },
  { "institution": "escola superior una de conselheiro lafaiete", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de divino", "acronym": "FUNEES" },
  { "institution": "faculdade anhanguera de ciências e tecnologia de brasília", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de lajinha", "acronym": "FUNEES Lajinha" },
  { "institution": "faculdade dom pedro ii de tecnologia", "acronym": "FDP II Tec" },
  { "institution": "faculdade de tecnologia michel", "acronym": "FATEMI" },
  { "institution": "faculdade estácio de castanhal", "acronym": "ESTÁCIO CASTANHAL" },
  { "institution": "faculdades integradas campo-grandenses", "acronym": "FIC" },
  { "institution": "centro de ensino superior do vale do parnaíba", "acronym": "CESVALE" },
  { "institution": "faculdade presbiteriana quinze de novembro", "acronym": "null" },
  { "institution": "faculdade de administração de teresina", "acronym": "FAT" },
  { "institution": "faculdades integradas urubupungá", "acronym": "FIU" },
  { "institution": "faculdade alfredo nasser de remanso", "acronym": "null" },
  { "institution": "faculdade flamingo", "acronym": "null" },
  { "institution": "centro universitário ibmr", "acronym": "null" },
  { "institution": "faculdades integradas anglo-americano", "acronym": "FIAA" },
  { "institution": "faculdade santa marcelina muriaé - fasm", "acronym": "FASM" },
  { "institution": "centro de ensino superior de lorena", "acronym": "null" },
  { "institution": "faculdade de tecnologia de sumaré", "acronym": "Fatec de Sumaré" },
  { "institution": "faculdade pólis civitas", "acronym": "null" },
  { "institution": "faculdade unimed", "acronym": "null" },
  { "institution": "centro universitário fametro", "acronym": "Unifametro" },
  { "institution": "faculdade presidente antônio carlos de ladainha", "acronym": "FUNEES" },
  { "institution": "faculdade mauá de goiás", "acronym": "null" },
  { "institution": "instituto esperança de ensino superior", "acronym": "IESPES" },
  { "institution": "faculdade sistema de ensino gaúcho", "acronym": "FASEG" },
  { "institution": "faculdade de tecnologia senai curitiba", "acronym": "null" },
  { "institution": "faculdade de ponta porã", "acronym": "FAP" },
  { "institution": "faculdade de direito de santa maria", "acronym": "FADISMA" },
  { "institution": "faculdade cuiabá", "acronym": "FAUC" },
  { "institution": "faculdade de ensino superior do lago", "acronym": "FAESLA" },
  { "institution": "faculdade cristã da amazônia", "acronym": "FCA" },
  { "institution": "faculdade cândido rondon de campo verde", "acronym": "null" },
  { "institution": "faculdade de tecnologia fabrai", "acronym": "FABRAI" },
  { "institution": "faculdade de tecnologia tecbrasil - unidade novo hamburgo", "acronym": "FTECBRASIL" },
  { "institution": "faculdade  integrada de advocacia da  amazônia", "acronym": "FINAMA" },
  { "institution": "faculdade de educação de porto velho", "acronym": "UNIRON" },
  { "institution": "centro de ensino superior de serra dourada", "acronym": "null" },
  { "institution": "faculdade ibmec distrito federal", "acronym": "null" },
  { "institution": "instituto educacional monte pascoal", "acronym": "null" },
  { "institution": "faculdade boa esperança", "acronym": "FABECA" },
  { "institution": "faculdade maurício de nassau de volta redonda", "acronym": "FMN VOLTA REDON" },
  { "institution": "faculdade de tecnologia de praia grande", "acronym": "FATECPG" },
  { "institution": "faculdade de educação física de araguaína", "acronym": "ITPAC" },
  { "institution": "faculdade presidente antônio carlos de monte carmelo", "acronym": "null" },
  { "institution": "centro universitário de jaguariúna", "acronym": "UniFAJ" },
  { "institution": "faculdade de educação tecnológica do estado do rio de janeiro - faeterj", "acronym": "FAETERJ BJ ITABAPOAN" },
  { "institution": "faculdade multiversa", "acronym": "Multiversa" },
  { "institution": "faculdade de tecnologia e ciências - ftc caruaru", "acronym": "FTC - Caruaru" },
  { "institution": "faculdade metropolitana de dias d'àvila", "acronym": "FACD'ÀVILA" },
  { "institution": "faculdade batista pioneira", "acronym": "null" },
  { "institution": "escola superior de administração, marketing e comunicação do morumbi", "acronym": "null" },
  { "institution": "faculdade pitágoras unopar de quixeramobim", "acronym": "null" },
  { "institution": "centro universitário presidente tancredo de almeida neves", "acronym": "UNIPTAN" },
  { "institution": "faculdade de tecnologia bsg-u", "acronym": "FBSG-U" },
  { "institution": "faculdade esamc santos", "acronym": "ESAMC" },
  { "institution": "faculdade luiz mário moutinho", "acronym": "FLMM" },
  { "institution": "faculdade pitágoras de tucuruí", "acronym": "null" },
  { "institution": "faculdade parque", "acronym": "FAP" },
  { "institution": "faculdades integradas dom pedro ii", "acronym": "DOMPEDRO" },
  { "institution": "faculdade senai de tecnologia mecatrônica", "acronym": "SENAI" },
  { "institution": "faculdade premier", "acronym": "PREMIER" },
  { "institution": "escola de engenharia de agrimensura", "acronym": "EEA" },
  { "institution": "universidade do estado do rio grande do norte", "acronym": "UERN" },
  { "institution": "centro universitário una de betim", "acronym": "null" },
  { "institution": "faculdade sinpain", "acronym": "SINPAIN" },
  { "institution": "centro universitário academia", "acronym": "Uniacademia" },
  { "institution": "faculdade tancredo neves", "acronym": "FTN" },
  { "institution": "centro universitário etep", "acronym": "null" },
  { "institution": "faculdade  arnaldo janssen", "acronym": "FAJANSSEN" },
  { "institution": "faculdade estácio de sá de vila velha", "acronym": "FESVV" },
  { "institution": "escola superior de ensino helena antipoff", "acronym": "ESEHA" },
  { "institution": "faculdade padre anchieta de várzea paulista", "acronym": "ANCHIETA" },
  { "institution": "universidade católica dom bosco", "acronym": "UCDB" },
  { "institution": "centro universitário módulo", "acronym": "MÓDULO" },
  { "institution": "faculdade de enfermagem", "acronym": "ITPAC" },
  { "institution": "centro universitário filadélfia", "acronym": "UNIFIL" },
  { "institution": "faculdade anhangüera de sorocaba", "acronym": "FSO" },
  { "institution": "faculdade de educação de guaratinguetá", "acronym": "FACEG" },
  { "institution": "faculdade de tecnologia rubens lara", "acronym": "FATEC-BS" },
  { "institution": "universidade estadual de campinas", "acronym": "UNICAMP" },
  { "institution": "centro universitário universo salvador", "acronym": "null" },
  { "institution": "faculdade doctum de almenara", "acronym": "DOCTUM" },
  { "institution": "instituto superior de educação ateneu", "acronym": "ISEAT" },
  { "institution": "faculdade alves faria", "acronym": "ALFA" },
  { "institution": "faculdade padrao de senador canedo", "acronym": "FAPSC" },
  { "institution": "faculdade de ciências e tecnologia de chapecó", "acronym": "FACITEC" },
  { "institution": "faculdade esamc sorocaba", "acronym": "ESAMC" },
  { "institution": "faculdade anhanguera de sobral", "acronym": "null" },
  { "institution": "faculdade anhanguera de itapecerica da serra", "acronym": "null" },
  { "institution": "centro universitário da grande dourados", "acronym": "UNIGRAN" },
  { "institution": "faculdade damásio educacional", "acronym": "FDE" },
  { "institution": "faculdade ietec", "acronym": "Ietec" },
  { "institution": "faculdade passionista de educação de curitiba", "acronym": "Fapec" },
  { "institution": "faculdade tecnologica anthropos", "acronym": "FATAN" },
  { "institution": "faneesp - faculdade nacional de educação e ensino superior do paraná", "acronym": "FANEESP" },
  { "institution": "faculdade tucuruí", "acronym": "FATUC" },
  { "institution": "faculdade lusófona da bahia", "acronym": "FL-BA" },
  { "institution": "faculdade serra geral", "acronym": "FASG" },
  { "institution": "faculdade prof. wlademir dos santos", "acronym": "WLASAN" },
  { "institution": "faculdade de educação e tecnologia de araraquara", "acronym": "FETEC - ARARAQUARA" },
  { "institution": "faculdade de engenharia e administração paulista", "acronym": "FEAP" },
  { "institution": "faculdade de tecnologia de são bernardo do campo", "acronym": "FATEC-SB" },
  { "institution": "centro universitário de lins", "acronym": "UNILINS" },
  { "institution": "centro universitário maurício de nassau de caruaru", "acronym": "Uninassau Caruaru" },
  { "institution": "centro universitário de jales", "acronym": "UNIJALES" },
  { "institution": "faculdades unificadas de leopoldina", "acronym": "FUL" },
  { "institution": "centro universitário do planalto central apparecido dos santos", "acronym": "UNICEPLAC" },
  { "institution": "faculdade católica do rio grande do norte", "acronym": "null" },
  { "institution": "universidade estadual do tocantins", "acronym": "UNITINS" },
  { "institution": "faculdade integrada de gestão e meio ambiente", "acronym": "FACIGMA" },
  { "institution": "faculdade presidente antônio carlos de mutum", "acronym": "FUNEES Mutum" },
  { "institution": "faculdade doutor leocádio josé correia", "acronym": "FALEC" },
  { "institution": "faculdade de ciências humanas e sociais aplicadas do cabo de santo agostinho", "acronym": "FACHUCA" },
  { "institution": "faculdade metodista de ciências humanas e exatas", "acronym": "METODISTA" },
  { "institution": "instituto superior de educação ibituruna", "acronym": "null" },
  { "institution": "faculdade estácio do amapá - estácio amapá", "acronym": "ESTÁCIO AMAPÁ" },
  { "institution": "faculdade metropolitana são carlos bji", "acronym": "FAMESC-BJI" },
  { "institution": "faculdade de tecnologia pedro rogério garcia", "acronym": "FATTEP" },
  { "institution": "faculdade passionista de educação de curitiba", "acronym": "Fapec" },
  { "institution": "faculdade logos internacional", "acronym": "FALI" },
  { "institution": "faculdade educacional jurídica e gerencial de oliveira", "acronym": "FEJUGO" },
  { "institution": "faculdade de engenharia de minas gerais - feamig", "acronym": "FEAMIG" },
  { "institution": "centro tecnológico positivo", "acronym": "CTPositivo" },
  { "institution": "faculdade de estudos avançados do pará", "acronym": "FEAPA" },
  { "institution": "faculdade raimundo marinho de penedo", "acronym": "null" },
  { "institution": "faculdade de lucas do rio verde", "acronym": "UNIFAMA" },
  { "institution": "faculdade presidente antônio carlos de belo oriente", "acronym": "FUNEES Belo Oriente" },
  { "institution": "faculdade área1 wyden", "acronym": "Área1 Wyden" },
  { "institution": "faculdades integradas de botucatu", "acronym": "null" },
  { "institution": "fundação dom cabral - ensino superior", "acronym": "FDC" },
  { "institution": "centro universitário do espírito santo", "acronym": "UNESC" },
  { "institution": "instituto superior de educação de cláudio", "acronym": "ISEC" },
  { "institution": "faculdade multivix nova venécia", "acronym": "MULTIVIX NOVA VENÉCI" },
  { "institution": "faculdades integradas de garanhuns", "acronym": "FACIGA" },
  { "institution": "faculdade de ciências aplicadas de limoeiro", "acronym": "FACAL" },
  { "institution": "fundação universidade federal do pampa - unipampa", "acronym": "UNIPAMPA" },
  { "institution": "faculdade anhanguera de goiânia", "acronym": "null" },
  { "institution": "instituto de ciências humanas e sociais aplicadas", "acronym": "ICHSA-TRÊS PONTAS" },
  { "institution": "instituto centro de ensino tecnológico - sobral", "acronym": "CENTEC" },
  { "institution": "centro universitário anhangüera", "acronym": "UNIFIAN" },
  { "institution": "faculdade cesusc", "acronym": "null" },
  { "institution": "faculdade esamc são paulo - esamc", "acronym": "ESAMC" },
  { "institution": "faculdade de estudos bíblicos interdisciplinares", "acronym": "FEBI" },
  { "institution": "faculdade dos cerrados piauienses", "acronym": "FCP" },
  { "institution": "faculdade de ciências contábeis de jequié", "acronym": "FCCJ" },
  { "institution": "faculdade reges de tupi paulista", "acronym": "null" },
  { "institution": "faculdade da fundação educacional araçatuba", "acronym": "FAC-FEA" },
  { "institution": "faculdade metropolitana de joinville", "acronym": "FAMEVILLE" },
  { "institution": "faculdade anhanguera de macapá", "acronym": "null" },
  { "institution": "centro universitário vértice", "acronym": "UNIVÉRTIX" },
  { "institution": "faculdade de ciências jurídicas de bom jesus da lapa", "acronym": "null" },
  { "institution": "faculdade cidade de joão pinheiro", "acronym": "FCJP" },
  { "institution": "faculdade itpac santa ines", "acronym": "ITPAC SANTA INÊS" },
  { "institution": "faculdade de tecnologia de ensino superior - fatec", "acronym": "CENTES" },
  { "institution": "faculdade alvorada paulista", "acronym": "FALP" },
  { "institution": "faculdade presbiteriana mackenzie rio", "acronym": "null" },
  { "institution": "faculdade de filosofia, ciências e letras de duque de caxias", "acronym": "FFCLDC" },
  { "institution": "faculdade de ciências contábeis de navirai", "acronym": "FACINAV" },
  { "institution": "faculdade doctum de carangola", "acronym": "DOCTUM" },
  { "institution": "faculdade de tecnologia césar lattes", "acronym": "null" },
  { "institution": "faculdade de tecnologia de novo cabrais", "acronym": "null" },
  { "institution": "faculdade de tecnologia de indaiatuba", "acronym": "FATEC-ID" },
  { "institution": "faculdade pio xii", "acronym": "PIO XII" },
  { "institution": "centro universitário de desenvolvimento do centro oeste", "acronym": "UNIDESC" },
  { "institution": "fajopa - faculdade joão paulo ii", "acronym": "FAJOPA" },
  { "institution": "faculdade uninassau belo horizonte", "acronym": "null" },
  { "institution": "faculdade unirb - alagoas", "acronym": "F. UNIRB" },
  { "institution": "faculdade de filosofia ciências e letras", "acronym": "FAFIL" },
  { "institution": "faculdade do vale", "acronym": "FAV" },
  { "institution": "faculdade unex", "acronym": "UNEX" },
  { "institution": "faculdade fabad", "acronym": "FABAD" },
  { "institution": "escola de ensino superior da fipe", "acronym": "FipeEES" },
  { "institution": "faculdades aggeu magalhães", "acronym": "FAMA" },
  { "institution": "instituto superior de educação de goiana", "acronym": "I.S.E.G." },
  { "institution": "faculdade de engenharia de resende", "acronym": "FER" },
  { "institution": "faculdade farus", "acronym": "FARUS" },
  { "institution": "faculdade pitágoras de jaboatão dos guararapes", "acronym": "null" },
  { "institution": "universidade federal da bahia", "acronym": "UFBA" },
  { "institution": "faculdade de educação e tecnologia do espírito santo", "acronym": "FETES" },
  { "institution": "faculdade inesp - instituto nacional de ensino e pesquisa", "acronym": "INESP" },
  { "institution": "faculdade de ciências jurídicas de aimorés", "acronym": "null" },
  { "institution": "centro universitário ateneu", "acronym": "null" },
  { "institution": "faculdade van gogh", "acronym": "FVG" },
  { "institution": "faculdade cenbrap", "acronym": "CENBRAP" },
  { "institution": "faculdade de tecnologia do comércio", "acronym": "FATEC-COMERCIO" },
  { "institution": "faculdade de ciências jurídicas anhanguera de arapiraca", "acronym": "null" },
  { "institution": "faculdade de integração do sertão", "acronym": "FIS" },
  { "institution": "faculdade de administração e negócios de sergipe", "acronym": "FANESE" },
  { "institution": "faculdade missioneira do paraná", "acronym": "FAMIPAR" },
  { "institution": "faculdade de computação e informática da fundação armando alvares penteado", "acronym": "FCI-FAAP" },
  { "institution": "faculdade única de contagem", "acronym": "FUNIC" },
  { "institution": "faculdade presidente antônio carlos de governador valadares", "acronym": "FAPAC - GV" },
  { "institution": "faculdade unopar de ciências jurídicas de sete lagoas", "acronym": "null" },
  { "institution": "faculdade estácio de canindé", "acronym": "ESTÁCIO CANINDÉ" },
  { "institution": "faculdade de educação superior de pernambuco", "acronym": "FACESP" },
  { "institution": "faculdade de tecnologia senai pernambuco", "acronym": "null" },
  { "institution": "faculdade paraná", "acronym": "FAP" },
  { "institution": "centro universitário maurício de nassau de maceió", "acronym": "UNINASSAUMACEI?" },
  { "institution": "faculdade terzius", "acronym": "TERZIUS" },
  { "institution": "universidade federal do acre", "acronym": "UFAC" },
  { "institution": "instituto superior de educação nossa senhora de sion", "acronym": "ISE - SION" },
  { "institution": "universidade federal de pernambuco", "acronym": "UFPE" },
  { "institution": "faculdade sensu", "acronym": "FAS" },
  { "institution": "faculdade andreotti", "acronym": "FA" },
  { "institution": "faculdade de marília", "acronym": "FAMAR" },
  { "institution": "faculdade kennedy", "acronym": "FK" },
  { "institution": "faculdade metropolitana de petrolina", "acronym": "FAM" },
  { "institution": "instituto de educacao superior latinoamericano", "acronym": "SIGLA IESLA" },
  { "institution": "instituto superior de educação de matias barbosa", "acronym": "ISEMB" },
  { "institution": "universidade federal do rio grande", "acronym": "FURG" },
  { "institution": "faculdade uninassau olinda", "acronym": "null" },
  { "institution": "faculdade ibiapaba", "acronym": "null" },
  { "institution": "faculdade polis das artes", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de central de minas", "acronym": "FUNEES" },
  { "institution": "centro universitário do planalto de araxá", "acronym": "UNIARAXÁ" },
  { "institution": "faculdade cci", "acronym": "FAC CCI" },
  { "institution": "faculdade de ciências e tecnologia paschoal dantas", "acronym": "null" },
  { "institution": "faculdade biopark", "acronym": "null" },
  { "institution": "faculdade de tecnologia senai antônio adolpho lobbe", "acronym": "null" },
  { "institution": "instituto federal de educação, ciencia e tecnologia de brasilia", "acronym": "IFB" },
  { "institution": "faculdade de santa catarina", "acronym": "FASC" },
  { "institution": "faculdade de avaré", "acronym": "IESA/FACCAA" },
  { "institution": "escola superior de secretariado de pernambuco", "acronym": "ESUSPE" },
  { "institution": "faculdade sna digital", "acronym": "SNA Digital" },
  { "institution": "faculdade de ciências econômicas da região carbonífera", "acronym": "FACIERC" },
  { "institution": "faculdade de pedagogia", "acronym": "ANAEC" },
  { "institution": "faculdade integrada carajás", "acronym": "FIC" },
  { "institution": "faculdade de integração do ensino superior do cone sul", "acronym": "FISUL" },
  { "institution": "faculdade unis pouso alegre", "acronym": "null" },
  { "institution": "faculdade senac goiás", "acronym": "FacSenacGO" },
  { "institution": "faculdade de tecnologia assessoritec", "acronym": "null" },
  { "institution": "faculdade norte-sul", "acronym": "FANS" },
  { "institution": "instituto matonense municipal de ensino superior", "acronym": "IMMES" },
  { "institution": "faculdades famep unidade teresina - p", "acronym": "FAMEP" },
  { "institution": "instituto politécnico doctum de vitória", "acronym": "null" },
  { "institution": "faculdade de ensino regional alternativa", "acronym": "FERA" },
  { "institution": "faculdade saberes", "acronym": "SABERES" },
  { "institution": "faculdade de campina grande do sul", "acronym": "FACSUL" },
  { "institution": "faculdade de administração de governador valadares", "acronym": "FAGV" },
  { "institution": "centro universitário padre albino", "acronym": "null" },
  { "institution": "faculdades integradas de taguaí", "acronym": "FIT" },
  { "institution": "faculdade de ciências educacionais do rio grande do norte", "acronym": "FACERN" },
  { "institution": "faculdade una de sete lagoas", "acronym": "Unaset" },
  { "institution": "faculdade de ensino superior de linhares", "acronym": "FACELI" },
  { "institution": "instituto superior de educação do alto são francisco", "acronym": "ISAF" },
  { "institution": "faculdade de comunicação social de passos", "acronym": "FACOMP" },
  { "institution": "faculdade de enfermagem luiza de marillac", "acronym": "FELM" },
  { "institution": "universidade municipal de são caetano do sul", "acronym": "null" },
  { "institution": "faculdade de tecnologia de minas gerais - fatemig", "acronym": "null" },
  { "institution": "faculdade cristã de educação superior", "acronym": "FACES" },
  { "institution": "faculdade de tecnologia ipiranga", "acronym": "FAC. IPIRANGA" },
  { "institution": "faculdade de ciências jurídicas de são josé do rio preto", "acronym": "null" },
  { "institution": "escola de governo em saúde pública de pernambuco", "acronym": "ESPPE" },
  { "institution": "faculdade dos guararapes", "acronym": "FG" },
  { "institution": "faculdade de ciências econômicas", "acronym": "FACAMP" },
  { "institution": "faculdade de tecnologia senai conde josé vicente de azevedo", "acronym": "null" },
  { "institution": "faculdade maurício de nassau de são josé dos campos", "acronym": "FMN SJ CAMPOS" },
  { "institution": "faculdade educacional de francisco beltrão", "acronym": "FEFB" },
  { "institution": "faculdade dom pedro ii de barreiras", "acronym": "DP II Barreiras" },
  { "institution": "escola superior de tecnologia e educação de porto ferreira", "acronym": "ESPF" },
  { "institution": "faculdade uninassau aliança - redenção", "acronym": "Nassau Aliança" },
  { "institution": "instituto superior de educação capimgrossense", "acronym": "ISEC" },
  { "institution": "faculdade de direito da alta paulista", "acronym": "FADAP" },
  { "institution": "faculdade uninassau fortaleza - parangaba", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de mogi guaçu", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de irecê", "acronym": "null" },
  { "institution": "escola agrotécnica federal de sousa", "acronym": "null" },
  { "institution": "fundação escola de governo ena", "acronym": "ENA" },
  { "institution": "faculdade ruy barbosa de tecnologia em processamento de dados", "acronym": "FRBTPD" },
  { "institution": "faculdade de ciências jurídicas e sociais aplicadas rio branco - firb", "acronym": "FIRB" },
  { "institution": "faculdade uninta", "acronym": "null" },
  { "institution": "faculdade de inovação tecnológica de londrina", "acronym": "FAC-CESUMAR" },
  { "institution": "escola agrotécnica federal de concórdia", "acronym": "EAFC" },
  { "institution": "escola superior da magistratura tocantinense", "acronym": "ESMAT" },
  { "institution": "instituto superior de educação de oliveira", "acronym": "ISEOL" },
  { "institution": "faculdade renovação de ponta grossa", "acronym": "RENOVAÇÃO" },
  { "institution": "faculdade wyden são paulo", "acronym": "Wyden SP" },
  { "institution": "faculdade de tecnologia da amazônia", "acronym": "FAZ" },
  { "institution": "faculdade doctum de administração e educação de vitória", "acronym": "DOCTUM" },
  { "institution": "faculdade de ciências da saúde de joinville", "acronym": "FCSJ" },
  { "institution": "faculdade de inovação tecnológica de ponta grossa", "acronym": "FAC-CESUMAR" },
  { "institution": "universidade estadual do piauí", "acronym": "null" },
  { "institution": "faculdade de ciências gerenciais", "acronym": "FACIG" },
  { "institution": "instituto superior de educação de cláudio", "acronym": "ISEC" },
  { "institution": "faculdade de tecnologia de jales", "acronym": "null" },
  { "institution": "faculdades integradas cesumar", "acronym": "CESUMAR" },
  { "institution": "faculdade de ciências contábeis de recife", "acronym": "FACCOR" },
  { "institution": "faculdade pitágoras de tecnologia de betim", "acronym": "PIT Betim" },
  { "institution": "faculdade de ciências da saúde", "acronym": "FASU" },
  { "institution": "instituto de ensino superior e pesquisa", "acronym": "INESP" },
  { "institution": "ipe instituto de pesquisas ecologicas", "acronym": "IPE" },
  { "institution": "faculdade uninabuco salvador", "acronym": "NABUCO SALVADOR" },
  { "institution": "faculdade doctum de saúde da serra", "acronym": "DOCTUM" },
  { "institution": "instituto superior de educação de divinópolis", "acronym": "ISED" },
  { "institution": "faculdade de ciências jurídicas de juiz de fora", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de marabá", "acronym": "null" },
  { "institution": "faculdade fae são josé dos pinhais", "acronym": "null" },
  { "institution": "faculdade de ciências sociais aplicadas de penedo", "acronym": "FCSAP" },
  { "institution": "faculdade univeritas universus veritas de osasco", "acronym": "UNIVERITAS OSA" },
  { "institution": "faculdade de ciências jurídicas e sociais aplicadas de primavera do leste", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas e gerenciais de garça", "acronym": "FAEG" },
  { "institution": "faculdade de arte e design", "acronym": "FAD" },
  { "institution": "faculdade de tecnologia de são carlos", "acronym": "null" },
  { "institution": "faculdade maurício de nassau de belo horizonte", "acronym": "FMN BH" },
  { "institution": "faculdade latino americana", "acronym": "FLA" },
  { "institution": "faculdade de direito da fundação armando alvares penteado", "acronym": "FAD-FAAP" },
  { "institution": "centro de ensino superior do tocantins", "acronym": "null" },
  { "institution": "academia da força aérea", "acronym": "AFA" },
  { "institution": "escola superior de advocacia pública", "acronym": "ESAP/PGE-RJ" },
  { "institution": "faculdade de ciências exatas e tecnológicas de união da vitória", "acronym": "null" },
  { "institution": "faculdade de tecnologia ined - unidade barreiro", "acronym": "null" },
  { "institution": "faculdade de tecnologia do senai horácio augusto da silveira", "acronym": "null" },
  { "institution": "faculdade uninabuco janga", "acronym": "null" },
  { "institution": "faculdade uninassau jaboatão", "acronym": "null" },
  { "institution": "faculdade integrada de vila velha", "acronym": "MULTIVIX VILA V" },
  { "institution": "faculdade horizontes", "acronym": "FH" },
  { "institution": "faculdade vitoriana de ciências contábeis", "acronym": "FVCC" },
  { "institution": "faculdade de ciências da saúde de ariquemes", "acronym": "FACISA" },
  { "institution": "faculdade de ciências jurídicas de jaú", "acronym": "null" },
  { "institution": "instituto brasileiro de direito tributário", "acronym": "IBDT" },
  { "institution": "faculdade de tecnologia de taubaté", "acronym": "null" },
  { "institution": "escola agrotécnica federal de alegre", "acronym": "EAFA" },
  { "institution": "faculdade unama de parauapebas", "acronym": "UNAMA PEBAS" },
  { "institution": "faculdade de tecnologia de itapira", "acronym": "null" },
  { "institution": "faculdade de direito de porto alegre", "acronym": "FADIPA" },
  { "institution": "faculdade de ciências jurídicas de franca", "acronym": "null" },
  { "institution": "faculdade de informática de passos", "acronym": "FIP" },
  { "institution": "faculdade de ciências gerenciais", "acronym": "FACIG" },
  { "institution": "faculdade guairacá de prudentópolis", "acronym": "FGP" },
  { "institution": "faculdade doctum de direito da serra", "acronym": "DOCTUM" },
  { "institution": "faculdade de tecnologia de indaiatuba", "acronym": "null" },
  { "institution": "faculdade fae araucária", "acronym": "FAE" },
  { "institution": "faculdade campo grande", "acronym": "FCG" },
  { "institution": "faculdade de direito de passos", "acronym": "FADIPA" },
  { "institution": "faculdade itaboraí", "acronym": "null" },
  { "institution": "instituto de educação superior guaianás", "acronym": "IESG" },
  { "institution": "instituto benjamin constant", "acronym": "IBC" },
  { "institution": "faculdade uninassau fortaleza savanah", "acronym": "Nassau Savanah" },
  { "institution": "hospital militar da área de recife", "acronym": "HMAR" },
  { "institution": "faculdade de ciências exatas e humanas da campanha", "acronym": "FCCP" },
  { "institution": "centro universitario universo belo horizonte", "acronym": "null" },
  { "institution": "escola superior de tecnologia e educação de rio claro", "acronym": "ESRC" },
  { "institution": "escola superior de adm., marketing e com. de perdizes", "acronym": "ESAMC PERDIZES" },
  { "institution": "faculdade anglo-americano de joão pessoa", "acronym": "FAAJP" },
  { "institution": "faculdade barão de piratininga", "acronym": "AES" },
  { "institution": "escola agrotécnica federal de sertão", "acronym": "EAFS" },
  { "institution": "faculdade de tecnologia senai suiço-brasileira paulo ernesto tolle", "acronym": "null" },
  { "institution": "faculdade de ciências exatas e tecnológicas santo agostinho - facet", "acronym": "FACET" },
  { "institution": "faculdade educação física de passos", "acronym": "FADEF" },
  { "institution": "faculdade de ciências jurídicas de paragominas", "acronym": "null" },
  { "institution": "faculdade estácio cotia", "acronym": "Estácio FAAC" },
  { "institution": "escola agrotécnica federal de inconfidentes", "acronym": "EAFI" },
  { "institution": "faculdade de ciências jurídicas de rio branco", "acronym": "null" },
  { "institution": "faculdade de educação thereza porto marques", "acronym": "FAETEC" },
  { "institution": "faculdade do sul da bahia", "acronym": "FASB" },
  { "institution": "faculdade uninabuco natal", "acronym": "null" },
  { "institution": "faculdade avantis de itapema", "acronym": "Avantis" },
  { "institution": "faculdade dom pedro ii de jequié", "acronym": "DP II" },
  { "institution": "faculdade de tecnologia de são josé dos campos - jessen vidal", "acronym": "null" },
  { "institution": "centro universitário presidente antônio carlos  juiz de fora", "acronym": "UNIPAC" },
  { "institution": "escola agrotécnica federal de machado", "acronym": "EAFM" },
  { "institution": "faculdade de tecnologia senai theobaldo de nigris", "acronym": "SP SENAI" },
  { "institution": "instituto pav", "acronym": "PAV" },
  { "institution": "faculdade renascença", "acronym": "FACRENA" },
  { "institution": "faculdade de ciências jurídicas de itabira", "acronym": "null" },
  { "institution": "faculdade doctum de guarapari", "acronym": "DOCTUM" },
  { "institution": "faculdade de estudos administrativos de minas gerais - fead-mg", "acronym": "FEAD - MG" },
  { "institution": "instituto superior de educação anglo-americano", "acronym": "ISEAAFI" },
  { "institution": "faculdade de engenharia elétrica de conselheiro lafaiete", "acronym": "FACEC" },
  { "institution": "faculdade de ciências jurídicas de alagoas", "acronym": "FCJAL" },
  { "institution": "faculdade de comunicação social jk", "acronym": "FACULDADE JK" },
  { "institution": "faculdade de administração jk", "acronym": "FACULDADE JK" },
  { "institution": "faculdade estadual de ciências econômicas de apucarana", "acronym": "FECEA" },
  { "institution": "escola agrotécnica federal de são joão evangelista", "acronym": "EAFSJE-MG" },
  { "institution": "instituto superior de ciências humanas e sociais aplicadas de abaeté", "acronym": "ISAB" },
  { "institution": "faculdade de tecnologia de campinas", "acronym": "null" },
  { "institution": "faculdade de ciências sociais aplicadas ibmec", "acronym": "FACULDADES IBMEC" },
  { "institution": "faculdade estadual de filosofia ciências letras união da vitória", "acronym": "FAFIUV" },
  { "institution": "faculdade de educação e estudos sociais de governador valadares", "acronym": "FUNEES G. Valadares" },
  { "institution": "faculdade  pitágoras", "acronym": "null" },
  { "institution": "faculdade itabirana de saúde", "acronym": "FISA" },
  { "institution": "faculdade de educação física montenegro", "acronym": "FAEFM" },
  { "institution": "faculdade de ciências jurídicas de alagoinhas", "acronym": "null" },
  { "institution": "faculdade do espírito santo", "acronym": "FACES" },
  { "institution": "escola saúde pública - espmt", "acronym": "ESPMT" },
  { "institution": "instituto superior de educação campo limpo paulista", "acronym": "ISECAMP" },
  { "institution": "instituto superior de educação fênix de bauru", "acronym": "FÊNIX" },
  { "institution": "faculdade católica de uberlândia", "acronym": "FCU" },
  { "institution": "faculdades integradas de jacareí", "acronym": "FIJ" },
  { "institution": "escola agrotécnica federal de satuba", "acronym": "EAFSATUBA" },
  { "institution": "instituto superior de educação cenecista de unaí", "acronym": "ISEC" },
  { "institution": "faculdade de administração de assis", "acronym": "FAA" },
  { "institution": "instituto de ensino superior do acre", "acronym": "IESACRE" },
  { "institution": "faculdade porto-alegrense", "acronym": "FAPA" },
  { "institution": "faculdade de tecnologia de botucatu", "acronym": "null" },
  { "institution": "faculdades integradas cesumar de guarapuava", "acronym": "CESUMAR" },
  { "institution": "faculdade de tecnologia senai mariano ferraz", "acronym": "null" },
  { "institution": "faculdade unida da paraíba", "acronym": "UNPB" },
  { "institution": "universidade estadual de feira de santana", "acronym": "null" },
  { "institution": "faculdades integradas de ponta porã", "acronym": "FIP" },
  { "institution": "faculdade itabirana de desenvolvimento das ciências e tecnologias", "acronym": "FATEC" },
  { "institution": "faculdade de tecnologia da zona leste", "acronym": "null" },
  { "institution": "faculdade de tecnologia de cotia", "acronym": "null" },
  { "institution": "faculdade de engenharia da fundação armando alvares penteado", "acronym": "FEFAAP" },
  { "institution": "instituto superior de educação cenecista de capivari", "acronym": "ISECC" },
  { "institution": "faculdade de ciências jurídicas de itapeva", "acronym": "null" },
  { "institution": "faculdade de odontologia de caruaru", "acronym": "FOC" },
  { "institution": "faculdade iepg", "acronym": "IEPG" },
  { "institution": "faculdade de tecnologia de itaquera", "acronym": "null" },
  { "institution": "instituto superior de educação equipe", "acronym": "ISEE" },
  { "institution": "faculdade de ciências jurídicas de catanduva", "acronym": "null" },
  { "institution": "faculdade de saúde e desenvolvimento humano santo agostinho", "acronym": "FS" },
  { "institution": "faculdade capixaba de cariacica", "acronym": "MULTIVIX CARIAC" },
  { "institution": "faculdade presidente antônio carlos de teófilo otoni", "acronym": "FUCJ T. Otoni" },
  { "institution": "hospital central do exército", "acronym": "HCE" },
  { "institution": "faculdade capixaba de nova venécia", "acronym": "null" },
  { "institution": "faculdade de informática do recife", "acronym": "FACIR" },
  { "institution": "instituto de ensino superior do rio grande do norte", "acronym": "IESRN" },
  { "institution": "instituto superior de educação nossa senhora de sion", "acronym": "ISE- SION" },
  { "institution": "faculdade de ciências jurídicas de santo antônio de jesus", "acronym": "null" },
  { "institution": "faculdades integradas do vale do iguaçu", "acronym": "null" },
  { "institution": "faculdade vale do iguaçu canoinhas", "acronym": "null" },
  { "institution": "faculdade brasil inteligente", "acronym": "null" },
  { "institution": "centro universitário de franca", "acronym": "null" },
  { "institution": "centro universitário municipal de são josé", "acronym": "null" },
  { "institution": "universidade do grande abc", "acronym": "UNIABC" },
  { "institution": "faculdade de ciências médicas de campina grande", "acronym": "FCM" },
  { "institution": "centro de educação superior - única", "acronym": "UNICA" },
  { "institution": "faculdade de moda de passos", "acronym": "FAMOPA" },
  { "institution": "faculdade fênix de bauru", "acronym": "FÊNIX" },
  { "institution": "faculdade politécnica de londrina", "acronym": "POLITÉCNICA" },
  { "institution": "faculdade de tecnologia de santana de parnaíba", "acronym": "null" },
  { "institution": "universidade alto vale do rio do peixe", "acronym": "null" },
  { "institution": "faculdade presidente antônio carlos de abre campo", "acronym": "FUNEES Abre Campo" },
  { "institution": "faculdade de filosofia ciências e letras nossa senhora de sion", "acronym": "FAFI/SION" },
  { "institution": "faculdade educacional de cornélio procópio", "acronym": "FACED" },
  { "institution": "faculdade estadual de ciências e letras de campo mourão", "acronym": "FECILCAM" },
  { "institution": "faculdade de medicina veterinária e zootecnia", "acronym": "FAMED" },
  { "institution": "instituto de assistência médica ao serviço público estadual", "acronym": "IAMSPE" },
  { "institution": "faculdade renovação de guarapuava", "acronym": "RENOVAÇÃO" },
  { "institution": "faculdade brasília de são paulo", "acronym": "FABRASP" },
  { "institution": "instituto nacional de cardiologia", "acronym": "INC" },
  { "institution": "faculdade de ciências jurídicas de uruguaiana", "acronym": "null" },
  { "institution": "faculdades integradas facvest", "acronym": "FACVEST" },
  { "institution": "faculdade de ciências jurídicas de jequié", "acronym": "null" },
  { "institution": "faculdade de tecnologia ined - unidade venda nova", "acronym": "null" },
  { "institution": "faculdade brasileira de recursos humanos", "acronym": "FBRH" },
  { "institution": "escola de magistratura do estado do rio de janeiro", "acronym": "EMERJ" },
  { "institution": "faculdade sudoeste paulista-itapetininga", "acronym": "FSP" },
  { "institution": "centro universitário de patos de minas", "acronym": "UNIPAM" },
  { "institution": "faculdade divinópolis", "acronym": "FACED" },
  { "institution": "instituto superior de educação do vale do juruena", "acronym": "AJES" },
  { "institution": "instituto superior de ciências agrárias", "acronym": "null" },
  { "institution": "instituto de ensino superior de mato grosso", "acronym": "IESMT" },
  { "institution": "faculdade adventista de administração do nordeste", "acronym": "FAAD" },
  { "institution": "escola de educação física de assis", "acronym": "EEFA" },
  { "institution": "universidade estadual paulista júlio de mesquita filho - duplicidade com cód 56", "acronym": "null" },
  { "institution": "faculdade fronteira", "acronym": "FECAF" },
  { "institution": "faculdade estadual de filosofia, ciências e letras de paranaguá", "acronym": "FAFIPAR" },
  { "institution": "escola de governo do maranhão", "acronym": "EGMA" },
  { "institution": "faculdades vale do carangola - favale", "acronym": "FAVALE" },
  { "institution": "faculdade presidente antônio carlos de mariana", "acronym": "FUCIJUR Mariana" },
  { "institution": "faculdade jk administração de valparaíso", "acronym": "FACULDADE JK" },
  { "institution": "faculdade de educação de alta floresta", "acronym": "FEAFLOR" },
  { "institution": "faculdade de ciências jurídicas de serra", "acronym": "null" },
  { "institution": "faculdades adamantinenses integradas", "acronym": "null" },
  { "institution": "faculdade são lucas porto velho", "acronym": "FSL" },
  { "institution": "escola de educação física do exército", "acronym": "null" },
  { "institution": "faculdade de estudos superiores de minas gerais", "acronym": "FEAD" },
  { "institution": "faculdade de ciências sociais aplicadas santo agostinho", "acronym": "FACISA" },
  { "institution": "instituto superior de educação de são gotardo", "acronym": "CESG" },
  { "institution": "universidade estadual do oeste do paraná", "acronym": "null" },
  { "institution": "faculdade de rondônia", "acronym": "FARO" },
  { "institution": "faculdade de tecnologia morumbi sul", "acronym": "FATEC-MS" },
  { "institution": "escola agrotécnica federal de barbacena", "acronym": "EAFB" },
  { "institution": "faculdade de ciências gerenciais padre arnaldo janssen", "acronym": "FAJANSSEN" },
  { "institution": "faculdade doctum de pedagogia da serra", "acronym": "DOCTUM" },
  { "institution": "instituto superior de educação padrão", "acronym": "ISE-PADRAO" },
  { "institution": "faculdade de tecnologia de mogi das cruzes", "acronym": "null" },
  { "institution": "faculdade uninabuco manaus", "acronym": "NABUCO MANAUS" },
  { "institution": "faculdade estadual de filosofia, ciências e letras de união da vitória", "acronym": "FAFI" },
  { "institution": "faculdade paraíbana", "acronym": "FAP" },
  { "institution": "faculdade de ciências e tecnologia do maranhão", "acronym": "FACEMA" },
  { "institution": "faculdade américa latina", "acronym": "null" },
  { "institution": "faculdade de tecnologia iesville", "acronym": "FATI" },
  { "institution": "escola superior de agronomia de paraguaçú paulista", "acronym": "ESAPP" },
  { "institution": "faculdade integrada de londrina", "acronym": "INTEGRADA" },
  { "institution": "faculdade de direito de caruaru", "acronym": "FADICA" },
  { "institution": "faculdade de tecnologia de itapetininga - prof.antônio belizandro barbosa rezende", "acronym": "null" },
  { "institution": "faculdade da academia brasileira de educação e cultura", "acronym": "FABEC" },
  { "institution": "faculdade de ciências sociais aplicadas de conselheiro lafaiete", "acronym": "FACESA" },
  { "institution": "instituto superior de educação da serra", "acronym": "ISES" },
  { "institution": "faculdade de ciências jurídicas de marília", "acronym": "null" },
  { "institution": "centro federal de educação tecnológica de bambuí", "acronym": "CEFET-BAMBUÍ" },
  { "institution": "instituto superior de educação anísio teixeira", "acronym": "ISEAT" },
  { "institution": "escola agrotécnica federal de cáceres", "acronym": "null" },
  { "institution": "instituição de ensino são francisco", "acronym": "IESF" },
  { "institution": "faculdade salesiana dom bosco de piracicaba", "acronym": "FSDB" },
  { "institution": "universidade regional do cariri", "acronym": "null" },
  { "institution": "universidade estadual de campinas", "acronym": "null" },
  { "institution": "faculdade do acre", "acronym": "FAC" },
  { "institution": "instituto superior de educação de santos dumont", "acronym": "ISESD" },
  { "institution": "faculdade de tecnologia de piracicaba", "acronym": "null" },
  { "institution": "instituto superior de educação de passos", "acronym": "ISEP" },
  { "institution": "faculdades integradas cesumar de londrina", "acronym": "CESUMAR" },
  { "institution": "faculdade de ciências sociais aplicadas", "acronym": "null" },
  { "institution": "instituto una de jataí", "acronym": "Una" },
  { "institution": "faculdade de filosofia e letras de diamantina", "acronym": "FAFIDIA" },
  { "institution": "faculdade vale do aporé", "acronym": "FIC" },
  { "institution": "escola de saúde pública do estado de mato grosso", "acronym": "ESPMT" },
  { "institution": "união de ensino superior de nova mutum", "acronym": "UNINOVA" },
  { "institution": "faculdade uninorte", "acronym": "FAC Norte" },
  { "institution": "faculdade adventista de educação do nordeste", "acronym": "FAENE" },
  { "institution": "faculdade de tecnologia senai roberto simonsen", "acronym": "null" },
  { "institution": "faculdade evilásio formiga", "acronym": "FEF" },
  { "institution": "academia militar das agulhas negras", "acronym": "null" },
  { "institution": "faculdade de tecnologia estudante rafael almeida camarinha - marilia", "acronym": "null" },
  { "institution": "centro federal de educação tecnológica de são vicente do sul", "acronym": "CEFETSVS" },
  { "institution": "faculdade do litoral sul", "acronym": "FLS" },
  { "institution": "faculdade de direito e negócios", "acronym": "FDN" },
  { "institution": "faculdade de administração são paulo", "acronym": "FAPI" },
  { "institution": "faculdade de tecnologia de bebedouro", "acronym": "null" },
  { "institution": "faculdade de ciências sociais e aplicadas de diamantino", "acronym": "UNED" },
  { "institution": "faculdade de artes do paraná", "acronym": "FAP" },
  { "institution": "faculdade una de catalão", "acronym": "UNA" },
  { "institution": "faculdade maurício de nassau de santo andré", "acronym": "FMN SANTO ANDRÉ" },
  { "institution": "escola de administração de brasília", "acronym": "EAB" },
  { "institution": "faculdade divinópolis", "acronym": "FACED" },
  { "institution": "universidade comunitária da região de chapecó", "acronym": "null" },
  { "institution": "faculdade de tecnologia sebrae", "acronym": "null" },
  { "institution": "faculdade juiz de fora", "acronym": "FJF" },
  { "institution": "instituto superior de ensino e pesquisa de ituiutaba", "acronym": "ISEPI" },
  { "institution": "faculdade de agronomia e engenharia florestal de garça", "acronym": "FAEF" },
  { "institution": "instituto superior de educação santa rita do sapucaí", "acronym": "ISE" },
  { "institution": "universidade estadual da paraíba", "acronym": "null" },
  { "institution": "faculdade professor dirson maciel de barros", "acronym": "null" },
  { "institution": "escola naval", "acronym": "EN" },
  { "institution": "faculdade de enfermagem de belo jardim", "acronym": "FAEB" },
  { "institution": "faculdades integradas norte capixaba - fanorte", "acronym": "null" },
  { "institution": "faculdade de economia da fundação armando alvares penteado", "acronym": "FEC-FAAP" },
  { "institution": "universidade do estado de mato grosso", "acronym": "null" },
  { "institution": "faculdade de ciências contábeis de lucélia", "acronym": "CEALPA" },
  { "institution": "faculdade do belo jardim", "acronym": "null" },
  { "institution": "instituto de ensino e pesquisa objetivo", "acronym": "IEPO" },
  { "institution": "faculdade anhanguera de ribeirão preto", "acronym": "null" },
  { "institution": "universidade do extremo sul catarinense", "acronym": "null" },
  { "institution": "centro universitário das faculdades associadas de ensino - fae", "acronym": "null" },
  { "institution": "faculdade integrada de guarapuava", "acronym": "INTEGRADA" },
  { "institution": "faculdade unama de castanhal", "acronym": "UNAMA CASTANHAL" },
  { "institution": "faculdade uninabuco belém", "acronym": "NABUCO BELÉM" },
  { "institution": "instituto superior de educação santo agostinho de teresina", "acronym": "ISA" },
  { "institution": "faculdade de artes plásticas da fundação armando alvares penteado", "acronym": "FAAP" },
  { "institution": "faculdade anhanguera de joinville", "acronym": "IESVILLE" },
  { "institution": "escola de música e belas artes do paraná", "acronym": "EMBAP" },
  { "institution": "faculdade eficaz", "acronym": "FACULDADE EFICAZ" },
  { "institution": "universidade do contestado", "acronym": "null" },
  { "institution": "faculdade de ciências jurídicas de pouso alegre", "acronym": "null" },
  { "institution": "faculdade projeção de planaltina", "acronym": "FAPRO" },
  { "institution": "faculdade maurício de nassau de maceió", "acronym": "FMN Maceió" },
  { "institution": "faculdade taboão", "acronym": "FECAF" },
  { "institution": "faculdade de tecnologia evolução", "acronym": "FECET" },
  { "institution": "faculdade de ciências agrárias e exatas de primavera do leste", "acronym": "null" },
  { "institution": "faculdade campos", "acronym": "FECAF" },
  { "institution": "instituto superior de música de são leopoldo", "acronym": "ISM" },
  { "institution": "centro de ensino superior do vale são francisco", "acronym": "null" },
  { "institution": "instituto politécnico doctum de guarapari", "acronym": "null" },
  { "institution": "faculdade de filosofia de passos", "acronym": "FAFIPA" },
  { "institution": "instituto superior de educação sant´ana", "acronym": "ISESA" },
  { "institution": "fatec cruzeiro - prof. waldomiro may", "acronym": "null" },
  { "institution": "faculdade esamc sorocaba", "acronym": "ESAMC" },
  { "institution": "faculdade de tecnologia senai anchieta", "acronym": "null" },
  { "institution": "centro universitário universo goiânia", "acronym": "null" },
  { "institution": "faculdade univeritas universus veritas são josé do rio preto", "acronym": "UNIVERITAS SJP" },
  { "institution": "faculdade de tecnologia internacional", "acronym": "FATEC INTERNACIONAL" },
  { "institution": "faculdade de ciências jurídicas de serrinha", "acronym": "null" },
  { "institution": "faculdade dom pedro ii - luis eduardo magalhães", "acronym": "FDP II LEM" },
  { "institution": "faculdade de ciências jurídicas de ribeirão das neves", "acronym": "null" },
  { "institution": "instituto tecnológico de caratinga", "acronym": "ITC" },
  { "institution": "faculdade de enfermagem de passos", "acronym": "FAENPA" },
  { "institution": "universidade do sul de santa catarina", "acronym": "null" },
  { "institution": "faculdade adventista de hortolândia - unasp hortolândia", "acronym": "FAH/UNASP-HT" },
  { "institution": "fundação de ensino superior de clevelândia", "acronym": "FESC" },
  { "institution": "faculdade de comunicação e marketing da fundação armando álvares penteado", "acronym": "FACOM-FAAP" },
  { "institution": "faculdade anglo-americano", "acronym": "FAA" },
  { "institution": "faculdade de tecnologia de mauá", "acronym": "null" },
  { "institution": "faculdade ruy barbosa de ciência da computação", "acronym": "FRBCC" },
  { "institution": "faculdade de engenharia de passos", "acronym": "FEP" },
  { "institution": "faculdade de ciências sociais e humanas sobral pinto", "acronym": "FAIESP" },
  { "institution": "faculdade municipal de palhoça", "acronym": "null" },
  { "institution": "faculdade de direito de bauru", "acronym": "FDB" },
  { "institution": "faculdade de tecnologia de lins", "acronym": "null" },
  { "institution": "faculdade de tecnologia de são roque", "acronym": "null" },
  { "institution": "faculdade vitoriana de tecnologia", "acronym": "FVT" },
  { "institution": "faculdade santa casa", "acronym": "FSC" },
  { "institution": "faculdade de administração e negócios de monte alto", "acronym": "FAN" },
  { "institution": "instituto de ensino superior de nova venécia", "acronym": "INESV" },
  { "institution": "escola nacional de formação e aperfeiçoamento de magistrados", "acronym": "ENFAM" },
  { "institution": "faculdades integradas de pedro leopoldo", "acronym": "FIPEL" },
  { "institution": "faculdade integrada de cariacica", "acronym": "MULTIVIX CARIAC" },
  { "institution": "faculdade unijuazeiro", "acronym": "FacJuazeiro" },
  { "institution": "faculdade renovação de londrina", "acronym": "RENOVAÇÃO" },
  { "institution": "faculdade de tecnologia - fatec de pompéia", "acronym": "null" },
  { "institution": "instituto superior de educação de tupi paulista", "acronym": "ISETP" },
  { "institution": "instituto estadual carlos gomes", "acronym": "IECG" },
  { "institution": "faculdade de serviço social de passos", "acronym": "FASESP" },
  { "institution": "instituto superior de educação de itabira", "acronym": "ISEI" },
  { "institution": "faculdade de direito de tangará da serra", "acronym": "null" },
  { "institution": "faculdade ruy barbosa de psicologia", "acronym": "FRBPSIC" },
  { "institution": "academia de polícia militar do espírito santo", "acronym": "APM/ES" },
  { "institution": "faculdade de tecnologia são carlos", "acronym": "FATESC" },
  { "institution": "faculdade pitágoras de minas gerais", "acronym": "null" },
  { "institution": "escola agrotécnica federal de ceres", "acronym": "null" },
  { "institution": "instituto superior de educação padre joão bagozzi", "acronym": "COSJISUP" },
  { "institution": "instituto de ensino superior integrado", "acronym": "IESI" },
  { "institution": "faculdade maurício de nassau de campinas", "acronym": "FMN CAMPINAS" },
  { "institution": "faculdade maria milza - campus factae", "acronym": "FAMAM-FACTAE" },
  { "institution": "faculdade anhanguera de educação, ciências e tecnologia de sorocaba", "acronym": "null" },
  { "institution": "hospital militar de área de brasília", "acronym": "HMAB" },
  { "institution": "ibet - instituto brasileiro de estudos tributários", "acronym": "null" },
  { "institution": "hospital militar de área de são paulo", "acronym": "HMASP" },
  { "institution": "instituto de ensino superior de dourados", "acronym": "IESD" },
  { "institution": "faculdade de ciências jurídicas de guanambi", "acronym": "null" },
  { "institution": "faculdade de serviço social de bauru", "acronym": "FSSB" },
  { "institution": "faculdade de ciências contábeis de alta floresta", "acronym": "FACTERFLOR" },
  { "institution": "centro superior una de catalão", "acronym": "UNACAT" },
  { "institution": "faculdade de ciências humanas de itabira", "acronym": "FACHI" },
  { "institution": "escola agrotécnica federal de araguatins", "acronym": "null" },
  { "institution": "faculdade de tecnologia fameg - fametec", "acronym": "FAMETEC" },
  { "institution": "faculdade de ciências jurídicas de santos dumont", "acronym": "FCJSD" },
  { "institution": "faculdade de ciências humanas e sociais aplicadas do cabo de santo agostinho", "acronym": "null" },
  { "institution": "faculdade de biblioteconomia e ciência da informação", "acronym": "FABCI" },
  { "institution": "faculdade de tecnologia de jacareí", "acronym": "null" },
  { "institution": "faculdade integrada de ponta grossa", "acronym": "INTEGRADA" },
  { "institution": "faculdade dourados", "acronym": "FAD" },
  { "institution": "faculdade de tecnologia da serra gaúcha - caxias do sul", "acronym": "null" },
  { "institution": "universidade estadual do norte do paraná", "acronym": "UENP" },
  { "institution": "instituto superior de ciências humanas e sociais aplicadas de abaeté", "acronym": "ISAB" },
  { "institution": "qualin - faculdade de saúde", "acronym": "QLN" },
  { "institution": "universidade do distrito federal", "acronym": "UnDF" },
  { "institution": "faculdade de ciências sociais aplicadas", "acronym": "null" },
  { "institution": "faculdade maurício de nassau são bernardo do campo", "acronym": "FMN SBC" },
  { "institution": "escola bahiana de administração", "acronym": "EBA" },
  { "institution": "faculdade de nutrição da fundação de ensino superior de passos", "acronym": "FANUTRI" },
  { "institution": "hospital de clínicas de porto alegre", "acronym": "null" },
  { "institution": "faculdade de ciências e tecnologia de birigui", "acronym": "null" },
  { "institution": "instituto superior de ciências agrárias", "acronym": "ISAP" },
  { "institution": "escola de engenharia de piracicaba", "acronym": "null" },
  { "institution": "faculdade estadual de educação ciências e letras de paranavaí", "acronym": "FAFIPA" },
  { "institution": "faculdade univates nova mutum", "acronym": "null" },
  { "institution": "faculdade uninassau ananindeua", "acronym": "NASSAU ANANINDEUA" },
  { "institution": "faculdade presbiteriana augusto galvão", "acronym": "FAPAG" },
  { "institution": "faculdade maria augusta ribeiro daher", "acronym": "FMA" },
  { "institution": "hospital militar da área de porto alegre", "acronym": "HMAPA" },
  { "institution": "faculdade de ciências e tecnologia paulistana", "acronym": "FACITEP" },
  { "institution": "faculdade belas artes de são paulo", "acronym": "FEBASP" },
  { "institution": "escola agrotécnica federal de uberlândia", "acronym": "EAFUDI" },
  { "institution": "centro universitário católico do sudoeste do paraná", "acronym": "UNICS" },
  { "institution": "instituto de ensino de segurança do pará", "acronym": "IESP" },
  { "institution": "faculdade maurício de nassau de sorocaba", "acronym": "FMN SOROCABA" },
  { "institution": "faculdade ibgen", "acronym": "IBGEN" },
  { "institution": "faculdade uninabuco maceió", "acronym": "null" },
  { "institution": "faculdade de inovação tecnológica de curitiba", "acronym": "FAC-CESUMAR" },
  { "institution": "faculdade cenecista de sinop", "acronym": "FACENOP" },
  { "institution": "faculdade pouso alegre", "acronym": "null" },
  { "institution": "faculdade aum", "acronym": "null" },
  { "institution": "faculdade de ouro preto do oeste", "acronym": "UNEOURO" },
  { "institution": "faculdade adventista de fisioterapia", "acronym": "FAFIS" },
  { "institution": "faculdade de ciências jurídicas de eunápolis", "acronym": "null" },
  { "institution": "faculdade da amazônia de marabá", "acronym": "null" },
  { "institution": "faculdade de direito de mogi mirim", "acronym": "FDMM" },
  { "institution": "faculdade uninabuco joão pessoa", "acronym": "null" },
  { "institution": "faculdade de administração de diadema", "acronym": "FAD" },
  { "institution": "seminário adventista latino-americano de teologia", "acronym": "SALT" },
  { "institution": "faculdade de administração de passos", "acronym": "FAP" },
  { "institution": "faculdade de porto velho", "acronym": "FIP" },
  { "institution": "escola de estudos superiores de viçosa", "acronym": "ESUV" },
  { "institution": "unama faculdade da amazônia de porto velho", "acronym": "null" },
  { "institution": "faculdade de educação ciências e letras de ponta porã", "acronym": "FECLEPP" },
  { "institution": "faculdade itop", "acronym": "ITOP" },
  { "institution": "faculdade de administração da fespsp", "acronym": "FADFESPSP" },
  { "institution": "faculdade de educação e ciências gerenciais de indaiatuba", "acronym": "FECGI" },
  { "institution": "faculdade de ciências contábeis de assis", "acronym": "FCCA" },
  { "institution": "faculdade de ciências biológicas jk", "acronym": "FACULDADE JK" },
  { "institution": "faculdade de tecnologia latino americano de anápolis", "acronym": "FLATEC" },
  { "institution": "instituto superior de ciências humanas e filosofia la salle", "acronym": "ISCHF LA SALLE" },
  { "institution": "escola superior de engenharia de porto velho", "acronym": "PORTO" },
  { "institution": "faculdade metrocamp", "acronym": "null" },
  { "institution": "faculdade univeritas universus veritas de ribeirão preto", "acronym": "UNIVERITAS RP" },
  { "institution": "faculdade de tecnologia empresarial", "acronym": "FTE" },
  { "institution": "instituto superior de educação santo agostinho", "acronym": "ISA" },
  { "institution": "fal estácio - faculdade estácio de natal", "acronym": "null" },
  { "institution": "faculdade de educação una de bom despacho", "acronym": "UNA" },
  { "institution": "instituto superior de educação de ituiutaba", "acronym": "ISEDI" },
  { "institution": "faculdade de ciências jurídicas de diamantina", "acronym": "FCJ" },
  { "institution": "faculdade de formação de professores de penedo", "acronym": "FFPP" },
  { "institution": "faculdade santa luzia", "acronym": "FSL" },
  { "institution": "faculdade de negócios", "acronym": "Ftec Negócios" },
  { "institution": "faculdade de rondônia", "acronym": "FARO" },
  { "institution": "faculdade de saúde tecsoma", "acronym": "FASTEC" },
  { "institution": "faculdade de ciências gerênciais de vilhena", "acronym": "FCGV" },
  { "institution": "instituto sociesc de itajaí", "acronym": "SOCIESC" },
];
