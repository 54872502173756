import { Autocomplete, Grid, TextField, Stack, Checkbox, FormControlLabel, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { ErrorMessage } from '../../../styles/globalStyles';
import { Controller } from "react-hook-form";
import { dddStatesBR } from "../../../staticData/dddStatesBR";
// import { citiesBR } from "../../../staticData/citiesBR";
// import { stateBR } from "../../../staticData/stateBR";

export const GeneralInformationForm = ({ props, handleOpenModal, edit }) => {
  const { register, control, errors, getValues, emailAlreadyUsed, checked, handleChange } = props;

  const validateEmailMatch = () => {
    const { email, confirm_email } = getValues();
    return email === confirm_email || "Os endereços de email não coincidem";
  };

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          
          <Grid item xs={12} sm={6}>
          
            <Stack direction="column">
              <TextField 
                id="name" 
                label="Nome" 
                variant="outlined" 
                margin="normal"
                required={true}
                sx={{ boxShadow: 1 }}
                {...register("name", { required: 'true' })}
              />
              {errors.name ? <ErrorMessage variant={"caption"}>Por favor, preencha o nome</ErrorMessage> : null}
            </Stack>

            <Stack direction="column">
              <TextField
                id="surname"
                label="Sobrenome"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }}
                {...register("surname", { required: 'true' })}
              />
              {errors.surname ? <ErrorMessage variant={"caption"}>Por favor, preencha o sobrenome</ErrorMessage> : null}
            </Stack>
          
            <Stack direction="column">
              <TextField
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }} 
                {...register("email", { required: 'true' })}
              />
              {errors.email ? <ErrorMessage variant={"caption"}>Por favor, preencha o email</ErrorMessage> : null}
              <ErrorMessage variant={"caption"}>{emailAlreadyUsed}</ErrorMessage>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack direction="column">
              <TextField
                id="confirm_email"
                name="confirm_email"
                type="email"
                label="Confirme seu Email"
                variant="outlined"
                margin="normal" 
                required={true}
                sx={{ boxShadow: 1 }}
                {...register("confirm_email", { required: 'true', validate: validateEmailMatch })}
              />
              {errors.confirm_email ? <ErrorMessage variant={"caption"}>Por favor, confirme o email</ErrorMessage> : null}
            </Stack>
          

            {!edit ? <Stack direction="column">
              <TextField
                id="password"
                type="password"
                label="Senha"
                variant="outlined"
                margin="normal"
                required={true} 
                sx={{ boxShadow: 1 }} 
                {...register("password", { required: 'true' })}
              />
              {errors.password ? <ErrorMessage variant={"caption"}>Por favor, preencha a senha</ErrorMessage> : null}
            </Stack>: null}
          
            <Stack direction="column">
                
              <Stack direction="row">
                
                <Stack direction="column" sx={{ mt: 2, mr: 2, width: '35%' }}>
                  <Controller 
                    name="ddd"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, data) => onChange(data)}
                        id="ddd"
                        options={dddStatesBR.map(({ ddd }) => ddd)}
                        sx={{ mb: 1, boxShadow: 1 }}
                        renderInput={(params) => 
                          <TextField 
                            {...params}
                            {...field}
                            inputRef={ref}
                            label="DDD" 
                            {...register("dddPhone", { required: 'true' })} 
                          />}
                      />
                    )}
                  />    
                  {errors.dddPhone ? <ErrorMessage variant={"caption"}>Por favor, preencha o DDD</ErrorMessage> : null}
                </Stack>
  
                <Stack direction="column" sx={{ width: '65%' }}>
                  <TextField
                    id="phone"
                    type={"tel"}
                    label="Celular"
                    variant="outlined"
                    margin="normal"
                    required={true} 
                    sx={{ boxShadow: 1 }} 
                    {...register("phone", { required: 'true' })}
                  />
                  {errors.phone ? <ErrorMessage variant={"caption"}>Por favor, preencha o celular</ErrorMessage> : null}
                </Stack>

              </Stack>
            </Stack>
          </Grid>

          <Stack direction="column" sx={{ my: 3, mx: 3}}>

            <Stack direction="column">
              <Controller
                name="acceptEmailMarketing"
                control={control}
                defaultValue={false}
                {...register("acceptEmailMarketing")}
                render={({ field: { value = checked, onChange = (e) => handleChange(e), ...field } }) => (
                  <FormControlLabel
                    label="Eu aceito receber e-mails da UP Carreiras com dicas personalizadas, insights de especialistas, conteúdos exclusivos e outros"
                    control={ <Checkbox {...field} checked={value} onChange={onChange} /> }
                  />
                )}
              />
            </Stack>

            <Stack direction="column">
              <Controller
                name="acceptPolicies"
                control={control}
                defaultValue={false}
                {...register("acceptPolicies", { required: 'true' })}
                render={({ field: { value = checked, onChange = (e) => handleChange(e), ...field } }) => (
                  <FormControlLabel
                    label="Eu aceito os Termos de uso e Política de Privacidade"
                    control={ <Checkbox {...field} checked={value} onChange={onChange} /> }
                  />
                )}
              />
              {errors.acceptPolicies ? <ErrorMessage variant={"caption"}>É preciso aceitas as políticas para prosseguir</ErrorMessage> : null}
            </Stack>

          </Stack>


          {/* {edit ? <Stack direction="row">
              
            <FormControl
              sx={{ mt: 2, width: "150px" }}
            >
              <InputLabel id="socialMediaType">Social Mídia</InputLabel>
              <Select
                labelId="socialMediaType"
                id="socialMediaType"
                label="Midia Social"
                sx={{ mr: 1, boxShadow: 1 }}
                {...register("socialMediaType")}
              >
                <MenuItem value={"Site"}>Site</MenuItem>
                <MenuItem value={"Behance"}>Behance</MenuItem>
                <MenuItem value={"Dribbble"}>Dribbble</MenuItem>
                <MenuItem value={"Linkedin"}>Linkedin</MenuItem>
                <MenuItem value={"Instagram"}>Instagram</MenuItem>
                <MenuItem value={"TikTok"}>Tik Tok</MenuItem>
                <MenuItem value={"Github"}>Github</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="socialMedia"
              type="text"
              label="Link"
              variant="outlined"
              margin="normal"
              sx={{ mt: 2, boxShadow: 1 }} 
              {...register("socialMedia")}
            />

          </Stack> : null} */}

          {/* {edit ? <Stack direction="column">
            <Controller
              name="addressCity"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, data) => onChange(data)}
                  options={citiesBR.map((option) => option)}
                  renderInput={(params) => <TextField
                    {...params}
                    {...field}
                    id="addressCity"
                    label="Cidade"
                    inputRef={ref}
                    required={true}
                    {...register("addressCity", { required: 'true' })}
                  />}
                  margin="normal"
                  fullWidth
                  sx={{ mt: 2, boxShadow: 1 }}
                />
              )}
            />
            {errors.status ? <ErrorMessage variant={"caption"}>Por favor, escolha a Cidade</ErrorMessage> : null}
          </Stack> : null}

          {edit ? <Stack direction="column">
              <Controller
                name="addressState"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, data) => onChange(data)}
                    options={stateBR.map((option) => option.nome)}
                    renderInput={(params) => <TextField
                      {...params}
                      {...field}
                      id="addressState"
                      label="Estado"
                      inputRef={ref}
                      required={true}
                      {...register("addressState", { required: 'true' })}
                    />}
                    margin="normal"
                    fullWidth
                    sx={{ mt: 2, boxShadow: 1 }}
                  />
                )}
              />
              {errors.status ? <ErrorMessage variant={"caption"}>Por favor, preencha o Estado</ErrorMessage> : null}
            </Stack> : null} */}

        </Grid>

        {/* <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 3 }} justifyContent={"flex-end"}>

          <Button type="submit" variant="contained">
            {edit ? "Salvar" : "Avançar"}
          </Button>

        </Stack> */}

      {/* </form> */}

    </>
  );
};

