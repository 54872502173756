import React from "react";
import { ButtonPrimary } from "../../../atoms/Buttons/ButtonPrimary/ButtonPrimary";
import { FiCheck, FiX } from "react-icons/fi";
import * as S from "./PriceCard.style";

export const PriceCard = ({ data }) => {    
  return (
    <>
      <S.Container id="card-price">

        <S.Card bestOffer={data.bestOffer}>
          <S.Title>{data.title}</S.Title>
          <S.Price>{data.installment}<S.PriceValue>{data.installmentValue}</S.PriceValue></S.Price>
          <S.PriceCondition>{data.paymentTerms}</S.PriceCondition>
          {/* <div className="trial">Free 30 day trial</div> */}

          <ul>
            {data.services.map((service) => {
              return(
                <li>
                  {
                    service.serviceIsPartOfPackage === true ? 
                      <FiCheck size={"20px"} color="#4baef5ff" strokeWidth={"5px"} /> : 
                      <FiX size={"20px"} color="#ec0a17ff" strokeWidth={"3px"} />
                  }
                  {service.description}
                </li>
              )
            } 
            )}
          </ul>

          {data.bestOffer ? 
            <a target="_blank" rel="noreferrer" href="https://forms.gle/C7VFvKJMH3cXRt2j8">
              <ButtonPrimary
                height={"50px"}
                text={"Comprar"}
                width={"200px"}
                margin={"20px 0px 20px 0px"}
              >Comprar</ButtonPrimary> 
            </a>
          : null}


        </S.Card>

      </S.Container>
    </>
  );
};
