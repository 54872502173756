import React, { useState, useContext, createContext, useCallback } from 'react'
import { useApolloClient } from '@apollo/client';
import { AUTH_USER } from '../services/Auth.services';

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const [authData, setAuthData] = useState(() => {
    const storagedUser = localStorage.getItem('@UP/User');

    if (!storagedUser) {
      return {};
    } else {
      return { user: JSON.parse(storagedUser) };
    }
  });

  const updateLocalStorageUser = useCallback((user) => {
    localStorage.setItem('@UP/User', JSON.stringify(user));
    setAuthData({ user });
    return { user };
  }, []);

  const signIn = useCallback(async ({ data }) => {
    setLoading(true);

    const result = await client.mutate({
      mutation: AUTH_USER,
      variables: data,
      fetchPolicy: 'no-cache',
    }).catch(() => {
      setLoading(false)      
    })
    
    const { token, user } = result.data.authUser;

    localStorage.setItem('@UP/Token', token);
    localStorage.setItem('@UP/User', JSON.stringify(user));

    setAuthData({ user });

    setLoading(false)
    return { user };

  }, [client]);

  const signOff = useCallback(async () => {
    await client.resetStore();

    localStorage.removeItem('@UP/Token');
    localStorage.removeItem('@UP/User');

    setAuthData({});
    
  }, [client]);

  return (
    <>
      <AuthContext.Provider value={{ ...authData, loading, signIn, signOff, updateLocalStorageUser }}>
        {children}
      </AuthContext.Provider>
    </>
  )
}

function useAuth() {
  const authData = useContext(AuthContext);

  if (!authData) {
    throw new Error('useAuth must be used within <AuthProvider>')
  }

  return authData;
}

export { useAuth, AuthProvider };