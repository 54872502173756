import React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import * as S from './HamburguerButton.style';

export const HamburguerButton = ({ color = "black", direction, onClick, handleOpenClose, backgroundColor }) => {
  return (
    <>
      <S.ContainerHamburguer id="container-hamburguer" direction={direction} backgroundColor={backgroundColor}>
        <S.Button onClick={onClick}>
          {handleOpenClose() ? <FiX size={32} color={color} /> : <FiMenu size={32} color={color} />}
        </S.Button>
      </S.ContainerHamburguer>
    </>
  );
};