import styled from 'styled-components';

export const Container = styled.section`
  background-color: rgb(3, 4, 94);
  color: white;
  display: flex;
  flex-direction: row;
  min-height: 700px;
  justify-content: center;
  text-align: center;
  align-items: center;
  overflow: auto;

  span:first-letter {
    text-transform: none!important;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

`

export const ContainerElement = styled.div`
  margin: 50px;
`

export const Title = styled.h1`
  font-size: 64px;
  margin: 20px 0px 20px 0px;
  width: 100%;

  @media screen and (max-width: 680px) {
    display: grid;
    justify-content: center;
  }
`

export const SubTitle = styled.h2`
  font-size: 22px;
  margin: 20px 60px;

  @media (max-width: 768px) {
    margin: 20px;
  }
`

export const HighlightedText = styled.span`
  text-decoration: underline rgb(9,232,94);
  text-transform: lowercase;
`
