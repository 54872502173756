import { Typography } from "@mui/material";
import { Container } from "@mui/material";
import { GeneralInformationForm } from "../../../components/Forms/GeneralInformationForm/GeneralInformationForm";

export const GeneralInformation = ({ props }) => {
  return (
    <Container maxWidth="md">

        <Typography variant="h1" align="center" sx={{ margin: 2 }}>Criar usuário</Typography>

        <GeneralInformationForm props={props} />

    </Container>
  );
};

